<template>
  <article class="h5_home_view">
    <div class="home_head">
      <div class="logo">
        <span>管理系统</span>
        <div class="menu_item_box">
                    <span
                      class="menu_item"
                      @click="tabHeadFn(index)"
                      v-for="(list, index) in ruleMenu"
                      :key="index"
                      v-show="list.check == 1 || list.expansion == 1"
                    >{{ list.title }}</span>
        </div>
      </div>
      <div class="right_box">
        <span class="mr_10">{{ user_name }}</span>
        <span class="mr_10" @click="ipWhite">IP白名单</span>
        <span class="mr_10" @click="passShow = true">修改密码</span>
        <span class="mr_10" @click="delRedisFn">更新缓存</span>
        <span @click="outLoginFn" class="cur_po">退出</span>
      </div>
    </div>
    <el-dialog v-model="passShow" title="修改密码" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">原密码</div>
          <div class="com_from_right">
            <el-input v-model="oldPass" type="password" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">新密码</div>
          <div class="com_from_right">
            <el-input v-model="pass" type="password" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">确认密码</div>
          <div class="com_from_right">
            <el-input v-model="restpass" type="password" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
              v-model="two_auth_code"
              type="text"
              placeholder="请输入"
              size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="passShow = false">取消</el-button>
                    <el-button type="primary" @click="editPassFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <div class="home_body">
      <el-scrollbar height="100vh">
        <div class="home_left">
          <el-menu class="el-menu-vertical-demo" :default-active="homeIndex">
            <el-submenu
              :index="index + ''"
              v-for="(list, index) in navItem"
              @click="goRouter(list.path)"
              v-show="(list.check == 1 || list.expansion == 1) && list.type == 2"
              :key="index"
            >
              <template #title>
                <span :style="list.seleteItem">{{ list.title }}</span>
              </template>
              <el-menu-item
                v-for="(next, nIndex) in list.children"
                :index="next.path"
                v-show="(next.check == 1 || next.expansion == 1) && next.type == 2"
                @click="goRouter(next.path)"
                :key="nIndex"
              >{{ next.title }}
              </el-menu-item>
              <!--<el-menu-item index="classList" @click="goRouter('/classList')">分类管理</el-menu-item>
<el-menu-item index="articleList" @click="goRouter('/articleList')">文章管理</el-menu-item>
<el-menu-item index="bannerList" @click="goRouter('/bannerList')">海报管理</el-menu-item>
<el-menu-item index="videoList" @click="goRouter('/videoList')">视频管理</el-menu-item>

              <el-menu-item index="hotArticleList" @click="goRouter('/hotArticleList')">热搜文章管理</el-menu-item>-->
            </el-submenu>
          </el-menu>
        </div>
      </el-scrollbar>

      <div class="home_right">
        <el-scrollbar height="100vh">
          <router-view v-slot="{ Component }">
            <keep-alive :max="1" include="invitebindingList">
              <component :is="Component"/>
            </keep-alive>
          </router-view>
        </el-scrollbar>
      </div>
    </div>
  </article>
</template>

<script>
import md5 from "blueimp-md5";

export default {
  name: "home",
  data() {
    return {
      oldPass: "",
      two_auth_code: "",
      pass: "",
      restpass: "",
      user_name: "",
      navHeader: [],
      routerItem: [],
      navItem: [],
      ruleMenu: [],
      headerIndex: 0,
      passShow: false,
      homeIndex: ''
    };
  },
  created() {
    this.homeIndex = this.$route.path
    this.ruleMenu = window.localStorage.getItem("ruleMenu")
      ? JSON.parse(window.localStorage.getItem("ruleMenu"))
      : [];
    this.ruleMenu.forEach((val, index) => {
      this.navHeader.push(val);
      this.routerItem.push(val.children);
      if (
        (val.check == 1 || val.expansion == 1) &&
        !window.sessionStorage.getItem("headerIndex")
      ) {
        window.sessionStorage.setItem("headerIndex", index);
      }
    });
    this.headerIndex = window.sessionStorage.getItem("headerIndex")
      ? window.sessionStorage.getItem("headerIndex")
      : 0;
    this.navItem = this.routerItem[this.headerIndex];
    this.user_name = window.userName;
    if (!window.sessionStorage.getItem("oneArticleClass")) {
      this.classFn(1, 1);
      this.classFn(2, 1);
      this.classFn(3, 1);
      this.classFn(4, 1);
    }
    if (!window.sessionStorage.getItem("portList")) {
      this.portListFn();
    }

    const seleteItem = {
      color: "#08b",
      fontSize: "18px"
    };
    this.$watch(
      () => this.$route.path,
      newpath => {
        this.navItem.forEach(item => {
          if (newpath == item.path) {
            item.seleteItem = seleteItem;
          } else if (
            item.children &&
            item.children.find(child_item => {
              return child_item.path == newpath;
            })
          ) {
            item.seleteItem = seleteItem;
          } else {
            item.seleteItem = "";
          }
        });
      },
      {immediate: true}
    );
  },
  methods: {
    tabHeadFn(index) {
      window.sessionStorage.setItem("headerIndex", index);
      this.headerIndex = index;
      this.navItem = this.routerItem[this.headerIndex];
      let path;
      // this.navItem.forEach((val) => {
      //   if (val.path && val.check == 1) {
      //     path = val.path;
      //   }
      // });
      const {navItem} = this;
      for (let i = 0; i < navItem.length; i++) {
        if (navItem[i].path && navItem[i].check == 1) {
          path = navItem[i].path;
          break;
        }
      }
      if (path.trim() == '') {
        return false
      }
      this.$router.push(path);
    },
    delRedisFn() {
      this.$ajax(`${this.Utils.URL.goURL}clear_data`, {}, "post")
        .then(res => {
          let ruleLists = res.power_list ? res.power_list : [];

          ruleLists = ruleLists.sort((a, b) => {
            return a.id - b.id;
          });
          ruleLists = ruleLists.sort((a, b) => {
            return a.sort - b.sort;
          });
          let arr = [];
          let arr1 = [];
          ruleLists.forEach((val, index) => {
            if (val.type == "1") {
              val.children = [];
              arr.push(val);
            }
            if (
              (val.check == 1 || val.expansion == 1) &&
              val.path
            ) {
              arr1.push(val);
            }

          });

          let ruleFormat = obj => {
            ruleLists.forEach(val => {
              if (obj.children) {
                if (
                  obj.id == val.parent_id &&
                  obj.id != val.id
                ) {
                  val.children = [];
                  obj.children.push(val);
                  ruleFormat(val);
                }
              }
            });
          };
          arr.forEach(val => {
            ruleFormat(val);
          });
          window.localStorage.setItem(
            "ruleMenu",
            JSON.stringify(arr)
          );
          window.localStorage.setItem(
            "ruleRouter",
            JSON.stringify(arr1)
          );

          this.$message.success("操作成功");
          setTimeout(() => {
            window.location.reload();
          }, 300);
        })
        .catch(res => {
          this.$message.error(res.msg);
        });
    },
    editPassFn() {
      if (this.oldPass === "") {
        this.$message.error("请输入原密码");
        return false;
      }
      if (this.restpass != this.pass) {
        this.$message.error("密码不一致");
        return false;
      }
      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {
        old_passwd: md5(this.oldPass),
        two_auth_code: this.two_auth_code,
        pass: md5(this.pass),
        confirm_passwd: md5(this.restpass),
      };
      let url = "update_pass";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
        .then(res => {
          this.passShow = false;
          this.$message.success("修改成功");
        })
        .catch(res => {
          this.$message.error(res.msg);
        });
    },
    classFn(type, level) {
      let req = {
        type_content_id: type
      };
      let url = level == 1 ? "type_one_list" : "type_two_list";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "get")
        .then(res => {
          let arr = res ? res : [];
          let arr2 = [];
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.type_zh
            };
          });
          if (type == 1) {
            window.sessionStorage.setItem(
              "oneArticleClass",
              JSON.stringify(arr2)
            );
          }
          if (type == 2) {
            window.sessionStorage.setItem(
              "oneImgClass",
              JSON.stringify(arr2)
            );
          }
          if (type == 3) {
            window.sessionStorage.setItem(
              "oneVideoClass",
              JSON.stringify(arr2)
            );
          }
          if (type == 4) {
            window.sessionStorage.setItem(
              "oneHotArticleClass",
              JSON.stringify(arr2)
            );
          }
        })
        .catch(res => {
          // this.$message.error(res.msg);
        });
    },
    portListFn() {
      let req = {
        type_content_id: 1
      };
      this.$ajax(`${this.Utils.URL.goURL}port_list`, req, "get")
        .then(res => {
          let arr = res ? res : [];
          let arr2 = [];
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.port
            };
          });
          window.sessionStorage.setItem(
            "portList",
            JSON.stringify(arr2)
          );
        })
        .catch(res => {
          // this.$message.error(res.msg);
        });
    },
    outLoginFn() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    ipWhite() {
      this.goRouter("/ipWhite");
      this.navItem = this.navItem.find(item => {
        return item.path == "/ipWhite";
      })
        ? this.navItem
        : [
          {
            check: "1",
            expansion: "0",
            path: "/ipWhite",
            title: "IP白名单",
            type: "2"
          }
        ];
    }
  },
  watch: {
    $route() {
      this.homeIndex = this.$route.path
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.h5_home_view {
  .home_head {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    background: $T1;

    .logo {
      color: $FFF;
      font-size: 24px;
      padding: 0 20px;
      display: flex;
      align-items: center;

      .menu_item_box {
        font-size: 18px;
        font-weight: bold;
        display: flex;

        .menu_item:hover {
          color: $T4;
        }

        .menu_item {
          cursor: pointer;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }

    .right_box {
      color: $FFF;
      font-size: 16px;
      padding: 0 20px;

      span {
        font-weight: 800;
        cursor: pointer;
      }

      span:hover {
        color: $T4;
      }
    }
  }

  .home_body {
    display: flex;
    width: 100%;

    .home_left {
      background: $FFF;
      width: 230px;
      min-height: 100%;
      height: 100vh;
    }

    .home_right {
      background: $FFF;
      padding: 20px;
      margin: 0 20px;
      border-radius: 8px;
      width: calc(100% - 240px);
    }
  }
}
</style>
