<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          类型
        </div>
        <div class="com_from_right">
          <el-select
            v-model="selectVal1"
            clearable
            filterable
            placeholder="请选择"
            size="small"
            @change="selectFn"
          >
            <el-option v-for="item in options1"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item" v-if="type == 2">
        <div class="com_from_left">
          一级分类
        </div>
        <div class="com_from_right">
          <el-select
            v-model="selectVal2"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option v-for="item in options2"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item" v-show="type == 1">
        <div class="com_from_left">
          分类图标
        </div>
        <div class="com_from_right">
          <el-upload
            class="avatar-uploader"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            data="cn"
            :on-success="handleAvatarSuccess"
            :on-change="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrls" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="com_from_item" v-if="type == 1">
        <div class="com_from_left">
          终端
        </div>
        <div class="com_from_right">

          <el-checkbox-group v-model="selectVal3">
            <el-checkbox v-for="list in prots" :label="list.value">{{ list.label }}</el-checkbox>
          </el-checkbox-group>

        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          {{ type }}级分类(中文)
        </div>
        <div class="com_from_right">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          {{ type }}级分类(英文)
        </div>
        <div class="com_from_right">
          <el-input
            v-model="inputVal2"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          排序
        </div>
        <div class="com_from_right">
          <el-input
            v-model="sort"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "classListEdit",
  data() {
    return {
      options1: [{
        value: '1',
        label: '文章'
      },
        {
          value: '2',
          label: '图片'
        },
        {
          value: '3',
          label: '视频'
        },
        {
          value: '4',
          label: '热搜文章'
        }],
      options2: [],
      prots: [],
      selectVal3: [],
      selectVal2: '',
      selectVal1: '',
      inputVal2: '',
      sort: '',
      inputVal1: '',
      startTime: '',
      endTime: '',
      selectArr: [{value: '钱包', label: '钱包'}],
      type: this.$route.query.type ? this.$route.query.type : '1',
      opt: this.$route.query.opt ? this.$route.query.opt : '1',
      imageUrls: '',
      imageUrl: '',
      imageUrlENs: '',
      imageUrlEN: '',
      id: ''
    }
  },
  created() {
    this.prots = JSON.parse(window.sessionStorage.getItem('portList'))
    if (this.opt === 'edit') {
      let obj = window.localStorage.getItem('listDataInfo') ? JSON.parse(window.localStorage.getItem('listDataInfo')) : {}
      this.selectVal1 = obj.type_id
      this.selectVal2 = obj.one_type_id
      this.id = obj.id
      this.selectFn(obj.type_id)
      if (this.type == 1) {
        this.inputVal1 = obj.one_type
        this.inputVal2 = obj.one_type_en
        this.sort = obj.sort
        this.imageUrls = this.Utils.URL.goURL + obj.icon_url
        this.imageUrl = obj.icon_url
        this.selectVal3 = obj.client_type_id.split(',')
      } else {
        this.inputVal1 = obj.two_type
        this.inputVal2 = obj.two_type_en
        this.sort = obj.sort
      }

    }
  },
  methods: {
    selectFn(vals) {
      if (!vals) {
        return false
      }
      let req = {
        type_content_id: this.selectVal1
      }

      this.$ajax(`${this.Utils.URL.goURL}type_one_list`, req, 'get')
        .then(res => {
          let arr = res ? res : []
          arr.forEach((val, index) => {
            this.options2[index] = {
              value: val.id,
              label: val.type_zh
            }
          })
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      this.uploadFn(file, 'cn')
    },
    beforeAvatarUploadEn(file) {
      this.uploadFn(file, 'en')

    },
    uploadFn(file, type) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      const formData = new FormData()
      let str = file.name.split('.')
      let last = str[str.length - 1].toLowerCase()
      if (!/png|jpg|jpeg/.test(last)) {
        this.$message.error('上传错误,文件格式必须为：png/jpg/jpeg');
        return;
      }
      let time = parseInt(new Date().getTime())
      str = 'image_upload' + time + '.' + last
      formData.append('files', file.raw, str)
      formData.append('dir', 'classImg')
      this.$ajax(`${this.Utils.URL.goURL}upload`, formData, 'post', 2500000, true)
        .then(res => {
          if (type == 'en') {
            this.imageUrlENs = this.Utils.URL.goURL + res.url
            this.imageUrlEN = res.url
          } else {
            this.imageUrls = this.Utils.URL.goURL + res.url
            this.imageUrl = res.url
          }

        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    submitFn() {
      if (this.type == 2 && !this.selectVal2) {
        this.$message.error('请选择一级分类')
        return false
      }
      if (this.type == 1 && !this.selectVal3.length) {
        this.$message.error('请选择终端')
        return false
      }
      if (this.selectVal1 === '') {
        this.$message.error('请选择分类类型')
        return false
      }
      if (this.inputVal1 === '') {
        this.$message.error('请输入中文描述')
        return false
      }
      if (this.inputVal2 === '') {
        this.$message.error('请输入英文描述')
        return false
      }
      if (!this.imageUrls && this.type == 1) {
        this.$message.error('请上传图片')
        return false
      }
      let url = this.type == 1 ? 'created_one_type' : 'created_two_type'


      let req = {
        type_content_id: this.selectVal1,
        type_zh: this.inputVal1,
        type_en: this.inputVal2,
        sort: this.sort,

      }
      if (this.type == 1) {
        req.icon_url = this.imageUrl
        req.client_type_id = this.selectVal3.join(',')
      }
      if (this.type == 2 && this.selectVal2) {
        req.type_id = this.selectVal2
      }
      if (this.opt === 'edit') {
        url = this.type == 1 ? 'update_one_type' : 'update_two_type'
        req.id = this.id
        req.type_id = this.selectVal1
        req.one_type_id = this.selectVal2
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
        .then(res => {
          this.$message.success('操作成功,两秒后自动跳转')

          this.classFn(this.selectVal1, 1)


        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    classFn(type, level) {
      let req = {
        type_content_id: type
      }
      let url = level == 1 ? 'type_one_list' : 'type_two_list'

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
        .then(res => {
          let arr = res ? res : []
          let arr2 = []
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.type_zh
            }
          })
          if (type == 1) {
            window.sessionStorage.setItem('oneArticleClass', JSON.stringify(arr2))
          }
          if (type == 2) {
            window.sessionStorage.setItem('oneImgClass', JSON.stringify(arr2))
          }
          if (type == 3) {
            window.sessionStorage.setItem('oneVideoClass', JSON.stringify(arr2))
          }
          if (type == 4) {
            window.sessionStorage.setItem('oneHotArticleClass', JSON.stringify(arr2))
          }
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
  }
}
</script>

<style scoped>

</style>