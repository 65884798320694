<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">转出地址</div>
        <div class="com_item_input">
          <el-input v-model="addr_out" placeholder="请输入" size="small">	
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
          <div class="com_item_tx">接收地址</div>
          <div class="com_item_input">
            <el-input v-model="addr_in" placeholder="请输入" size="small">
            </el-input>
          </div>
        </div>
      <div class="com_item_search">
        <div class="com_item_tx">接收狀態</div>
        <div class="com_item_input">
          <el-select v-model="status_in" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in statusObj" :key="key" :label="item" :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">转出状态</div>
        <div class="com_item_input">
          <el-select v-model="status_out" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in statusObj1" :key="key" :label="item" :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>轉出地址</th>
          <th>類別</th>
          <th>幣種</th>
          <th>發送數量</th>
          <th>轉出狀態</th>
          <th>轉出時間</th>
          <th>轉出HASH</th>
          <th>預計獲得數量</th>
          <th>損耗數量</th>
          <th>訂單ID</th>
          <th>收款地址</th>
          <th>收款狀態</th>
          <th>收款hash</th>
          <th>接收時間</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list) in resData" :key="list.id">
            <td>{{ list.withdraw_addr }}</td>
            <td>{{ list.from_chain }}</td>
            <td>{{ list.deposit_coin_code }}</td>
            <td>{{ list.deposit_coin_amt }}</td>
            <td>{{ list.withdraw_state }}</td>
            <td>{{ list.out_time }}</td>
            <td>{{ list.withdraw_txid }}</td>
            <td>{{ list.receive_coin_amt }}</td>
            <td>{{ list.loss_amt }}</td>
            <td>{{ list.order_id }}</td>
            <td>{{ list.destination_addr }}</td>
            <td>{{ list.detail_state }}</td>
            <td>{{ list.transaction_id }}</td>
            <td>{{ list.in_time  }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.deposit_coin_amt)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.receive_coin_amt)}}</td>
            <td>{{scientificNotationToString(subtotal.loss_amt)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(sum.deposit_coin_amt)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(sum.receive_coin_amt)}}</td>
            <td>{{scientificNotationToString(sum.loss_amt)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page" v-show="resData.length">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>


  </article>
</template>
  
<script>
export default {
  name: "mix-token-record",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      status_in: '',
      status_out: '',
      statusObj: {
        // 'wait_deposit_send': '等待存币发送',
        // 'timeout': '超时',
        // 'wait_exchange_push': '等待交换信息推送',
        // 'wait_exchange_return': '等待交换信息返回',
        // 'wait_receive_send': '等待接收币种发送',
        // 'wait_receive_confirm': '等待转出确认',
        // 'receive_complete': '完成',
        // 'wait_refund_send': '等待退原币币种发送',
        // 'wait_refund_confirm': '等待退币确认',
        // 'refund_complete': '等待退币已完成',
        // 'confirm': '提币中',
        // 'success': '成功',
        // 'fail': '失败',
        'wait_deposit_send': '等待存币',
        'wait_deposit_send_fail': '存币失败',
        'wait_exchange_push': '正在兑换中...', 
        'wait_exchange_return': '正在兑换中...',
        'wait_exchange_return_success': '正在兑换中...',
        'wait_receive_send': '兑换完成，等待发币', 
        'wait_enough_send':'正在发币中...',
        'wait_receive_confirm': '发币确认中...', 
        'receive_complete': '发币完成',
        'wait_refund_send': '等待退币', 
        'wait_refund_confirm': '退币确认中...', 
        'refund_complete': '退币完成',
        'timeout': '兑换超时',
        'ERROR': '正在兑换中...',
        'wait_for_information':'请联系客服'
      },
      statusObj1: {
        'confirm': '提币中',
        'success': '成功',
        'fail': '失败',
      },
      startTime: '',
      endTime: '',
      addr_in: '',
      addr_out: '',
      subtotal: {},
      sum: {},
    };
  },
  created() {
    this.resInit();
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.addr_in) {
        req.addr_in = this.addr_in;
      }
      if (this.addr_out) {
        req.addr_out = this.addr_out;
      }
      if (this.status_in) {
        req.status_in = this.status_in;
      }
      if (this.status_out) {
        req.status_out = this.status_out;
      }
      if (this.startTime) {
        req.start = this.startTime;
      }
      if (this.endTime) {
        req.end = this.endTime;
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/flash_records`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.total = parseFloat(res.total)
          this.resData.forEach((list) => {
              list.withdraw_state = this.statusObj1[list.withdraw_state] ? this.statusObj1[list.withdraw_state] : list.withdraw_state
              list.detail_state = this.statusObj[list.detail_state] ? this.statusObj[list.detail_state] : list.detail_state
              // list.title = this.typeObj[list.title] ? this.typeObj[list.title] : list.title
              list.in_time = parseFloat(list.in_time) > 0 ? this.dataFormat(list.in_time) : '-'
              list.out_time = parseFloat(list.out_time) > 0 ? this.dataFormat(list.out_time) : '-'

            })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>
