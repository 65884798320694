<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">狀態</div>
        <div class="com_item_input">
          <el-select v-model="status" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box">
      <!-- <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button> -->
      <el-button
          type="primary"
          @click="historyData(1)"
      >
      歷史數據
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>钱包ID</th>
          <th>總淨入金金額</th>
          <th>一期淨入金</th>
          <th>二期淨入金</th>
          <th>一期總入金</th>
          <th>二期總入金</th>
          <th>一期總出金</th>
          <th>二期總出金</th>
          <th>狀態</th>
          <th>更新时间</th>
          <th>解冻开始时间</th>
          <th>解冻结束时间</th>
          <th v-show="!isShowHistory">操作</th>

        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.wallet_id }}</td>
            <td>
              <span v-if="list.total_team_net_recharge < 0" style="color: red">{{ scientificNotationToString(list.total_team_net_recharge) }}</span>
              <span v-else>{{ scientificNotationToString(list.total_team_net_recharge) }}</span>
            </td>
            <td>{{ list.phase_one_team_net_recharge }}</td>
            <td>{{ list.phase_two_team_net_recharge }}</td>
            <td>{{ list.phase_one_team_recharge }}</td>
            <td>{{ list.phase_two_team_recharge }}</td>
            <td>{{ list.phase_one_team_withdraw }}</td>
            <td>{{ list.phase_two_team_withdraw }}</td>
            <td>{{ list.status }}</td>
            <td>{{ list.updated_at }}</td>
            <td>{{ list.un_block_time }}</td>
            <td>{{ list.un_block_end_time }}</td>
            <td class="tab_btn" v-show="!isShowHistory">
              <el-button type="primary" size="small" @click="changeStatus(list)">凍結/解凍</el-button>
            </td>
          </tr>
          <!-- <tr v-show="resData.length  && false">
          <td>小计</td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.recharge)}} USDT</td>
          <td>{{scientificNotationToString(subtotal.extract)}} USDT</td>
          <td>{{scientificNotationToString(subtotal.net_income)}} USDT</td>
          <td>{{scientificNotationToString(subtotal.invest_amount)}} USDT</td>
        </tr>
        <tr v-show="resData.length && false">
          <td>总计</td>
          <td></td>
          <td>{{scientificNotationToString(sum.recharge)}} USDT</td>
          <td>{{scientificNotationToString(sum.extract)}} USDT</td>
          <td>{{scientificNotationToString(sum.net_income)}} USDT</td>
          <td>{{scientificNotationToString(sum.invest_amount)}} USDT</td>
        </tr> -->

        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="isShow" title="凍結/解凍" :width="500">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">Fin-用戶當前狀態</div>
          <div class="com_from_right">
            <p>{{ currentStatusItem }}</p>
          </div>
        </div>
        <div class="com_from_item" v-show="currentStatusItem !== '正常(倒計時中)'">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" placeholder="请输入" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="submitFn" v-show="currentStatusItem === '凍結'">解凍</el-button>
          <el-button type="primary" @click="submitFn" v-show="currentStatusItem === '正常'">凍結</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "teamRechargeReport",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      inputVal1: "",
      status: "",
      statusList: [
        { label: '正常', value: '1' },
        { label: '凍結', value: '2' },
        { label: '正常(倒計時中)', value: '3' }
      ],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "wallet_id",
        "recharge",
        "extract",
        "net_income",
        "invest_amount",
      ],
      isShow: false,
      currentStatusItem: '',
      two_auth_code: '',
      currentWalletId: '',
      isShowHistory: false,

    };
  },
  created() {

    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    historyData(index) {
      this.page = index
      this.resData = []
      this.isShowHistory = true
      let req = {
        page: index,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.status) {
        req.status = this.status;
      }
      this.$ajax(`${this.Utils.URL.goURL}team/total_team_recharge_history`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);
          this.resData.forEach((list) => {
            list.total_team_net_recharge = parseFloat(list.total_team_net_recharge)
            list.updated_at = this.dataFormat(list.updated_at)
            
            if (list.status == 1 && list.un_block_end_time > 0) {
              list.status = 3
            }
            list.status = this.statusList[list.status - 1].label
            list.un_block_end_time = parseFloat(list.un_block_end_time) > 0 ? this.dataFormat(list.un_block_end_time) : '-'
            list.un_block_time = parseFloat(list.un_block_time) > 0 ? this.dataFormat(list.un_block_time) : '-'
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    submitFn() {
      if (!this.two_auth_code) {
        this.$message.error("请输入谷歌验证码");
        return;
      }
      let req = {
        wallet_id: this.currentWalletId,
        status: this.currentStatusItem === '正常' ? 2 : 1,
        two_auth_code: this.two_auth_code,
      }
      this.$ajax(`${this.Utils.URL.goURL}team/team_withdraw_status`, req, 'post', 2500000, false, true)
        .then(res => {
          this.two_auth_code = ''
          this.isShow = false
          this.resInit()
          this.$message.success('提交成功')
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    changeStatus(list) {
      this.two_auth_code = ''
      this.isShow = true
      this.currentStatusItem = list.status
      this.currentWalletId = list.wallet_id
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index; // index 1
      if (this.isShowHistory) {
        this.historyData(index)
      } else {
        this.resInit();
      }
    },
    resInit() {
      this.resData = []
      this.isShowHistory = false
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.status) {
        req.status = this.status;
      }
      this.$ajax(`${this.Utils.URL.goURL}team/total_team_recharge`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);
          this.resData.forEach((list) => {
            list.total_team_net_recharge = parseFloat(list.total_team_net_recharge)
            list.updated_at = this.dataFormat(list.updated_at)
            
            if (list.status == 1 && list.un_block_end_time > 0) {
              list.status = 3
            }
            list.status = this.statusList[list.status - 1].label
            list.un_block_end_time = parseFloat(list.un_block_end_time) > 0 ? this.dataFormat(list.un_block_end_time) : '-'
            list.un_block_time = parseFloat(list.un_block_time) > 0 ? this.dataFormat(list.un_block_time) : '-'
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>