<template>
  <article class="">
    <div class="com_search_box" v-if="isEditButton">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="goDetails"
          >新增账号</el-button
        >
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
          <th>序号</th>
          <th>账号</th>
          <th>昵称</th>
          <th>状态</th>
          <th>角色</th>
          <th>创建时间</th>
          <th>操作</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>
              {{
                page == 1
                  ? index + 1
                  : index == 0
                  ? (page - 1) * 10 + 1
                  : (page - 1) * 10 + index + 1
              }}
            </td>
            <td>{{ list.account_number }}</td>
            <td>{{ list.nick }}</td>
            <td>
              {{ list.power == 1 ? "正常" : list.power == 2 ? "冻结" : "删除" }}
            </td>
            <td>
              <span class="mr_5" v-for="role in list.role_list">{{
                role.role_name
              }}</span>
            </td>
            <td>{{ dataFormat(list.created_at) }}</td>
            <td class="tab_btn">
              <span class="btn" v-if="isEditButton" @click="goDetails(list)"
                >编辑</span
              >
              <span class="btn" v-if="isLockButton" @click="resetFn(index)"
                >重置账号</span
              >
              <span
                class="btn"
                v-if="isLockButton"
                @click="changeStatusShow(index, list.power)"
                >{{
                  list.power == 1 ? "冻结" : list.power == 2 ? "解冻" : ""
                }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
        layout="pager"
        :total="total"
        :current-page="page"
        @current-change="pageFn"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" :title="'重置账号'" :width="600">
      <div class="com_search_box">
        <div class="com_item_search">
          <div class="com_item_tx">谷歌验证码</div>
          <div class="com_item_input mr_10">
            <el-input v-model="two_auth_code" placeholder="请输入" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAlert = false">关闭</el-button>
          <el-button type="primary" @click="submitFn">提交</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="statusShow" title="修改账号状态" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
              v-model="two_auth_code_thaw"
              type="text"
              placeholder="请输入"
              size="small"
            >
            </el-input>
          </div>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="statusShow = false">取消</el-button>
          <el-button type="primary" @click="changeStatusShow">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "adminList",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      two_auth_code: "",
      isAlert: false,
      resData: {},
      statusShow: false, //显示隐藏模态框,
      Index: 0, //用于保存每行下标,
      two_auth_code_thaw: "", //用于解冻账户的谷歌验证吗
    };
  },
  created() {
    this.resInit();
  },
  methods: {
    goDetails(node) {
      let obj;
      if (node) {
        obj = node;
      } else {
        obj = {};
      }
      window.sessionStorage.setItem("listDataInfo", JSON.stringify(obj));
      this.goRouter("/adminEdit");
    },
    submitFn() {
      this.$ajax(
        `${this.Utils.URL.goURL}initialize_account`,
        {
          user_id: this.resData[this.curIndex].id,
          two_auth_code: this.two_auth_code,
        },
        "post"
      )
        .then((res) => {
          this.$confirm(`操作成功`, `密码为:${res.pass},请妥善发给账号持有者`, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
          });
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    resetFn(index) {
      this.isAlert = true;
      this.curIndex = index;
    },
    delFn(index) {
      this.curIndex = index;
      this.$confirm(
        `是否${this.resData[index].power == 1 ? "冻结" : "解冻"}`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$ajax(
            `${this.Utils.URL.goURL}frozen_unseal_user`,
            this.resData[index].power == 1
              ? {
                  opt_type: this.resData[index].power == 1 ? 2 : 1,
                  user_id: this.resData[index].id,
                }
              : {
                  opt_type: this.resData[index].power == 1 ? 2 : 1,
                  user_id: this.resData[index].id,
                  two_auth_code: this.two_auth_code_thaw,
                },
            "post"
          )
            .then((res) => {
              this.$message.success("操作成功");
              this.resData[index].power =
                this.resData[index].power == 1 ? 2 : 1;
            })
            .catch((res) => {
              this.$message.error(res.msg);
            });
        })
        .catch(() => {});
    },
    // 显示隐藏修改账号状态模态框
    changeStatusShow(index, power) {
      if (Number(index)) {
        this.Index = index;
      }

      if (power == "2") {
        this.statusShow = true;
      } else {
        this.statusShow = false;
        this.delFn(this.Index);
      }
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };

      this.$ajax(`${this.Utils.URL.goURL}admin_list`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
  },
};
</script>

<style scoped>
</style>