<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>团队长钱包ID</th>
        <th>入金数量(USDT)</th>
        <th>出金数量(USDT)</th>
        <th>净入金数量(USDT)</th>
        <th>日期</th>

        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.wallet_id}}</td>
          <td>{{scientificNotationToString(list.recharge)}} USDT</td>
          <td>{{scientificNotationToString(list.withdraw)}} USDT</td>
          <td>{{scientificNotationToString(list.net_income)}} USDT</td>
          <td>{{(list.date_str)}}</td>

        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.recharge)}} USDT</td>
          <td>{{scientificNotationToString(subtotal.withdraw)}} USDT</td>
          <td>{{scientificNotationToString(subtotal.net_income)}} USDT</td>
          <td></td>

        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td>{{scientificNotationToString(sum.recharge)}} USDT</td>
          <td>{{scientificNotationToString(sum.withdraw)}} USDT</td>
          <td>{{scientificNotationToString(sum.net_income)}} USDT</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "zlbRechargeReport",
  data() {
    return {
      page: 1,
      size: 30,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      other_total: {},
      usd_total: {},
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      filterVal: [
        "wallet_id",
        "recharge",
        "withdraw",
        "net_income",
        "date_str",
      ],
    };
  },
  created() {


    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;

      this.resInit();

    },
    pageFn(index, type) {
      this.page = index;
      this.resInit();

    },
    resInit () {
      let req = {
        page: this.page,
        size: this.size,
        user_type: 2,
      };

      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.tx_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}club_net_income_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal: {}
            this.sum = res.sum ? res.sum : {}

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },

  }
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>