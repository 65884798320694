<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="wallet_address" placeholder="請輸入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">转出状态</div>
        <div class="com_item_input">
          <el-select v-model="transfer_out" clearable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in transferOutList" :key="key" :label="item.label" :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">接收状态</div>
        <div class="com_item_input">
          <el-select v-model="status" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in statusList" :key="key" :label="item.label" :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>钱包地址</th>
          <th>FSP 數量</th>
          <th>转出 BEP20<br />USDT 数量</th>
          <th>转出 ERC20<br />USDT 数量</th>
          <th>转出 TRC20<br />USDT 数量</th>
          <th>转出 FUSD 数量</th>
          <th>转出状态</th>
          <th>转出时间</th>
          <th>转出 USDT hash</th>
          <th>转出 FUSD hash</th>
          <th>退款 hash</th>
          <th>鏈類型</th>
          <th>备注</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.wallet_address }}</td>
            <td>{{ scientificNotationToString(list.purchase_amount) }}</td>
            <td>{{ scientificNotationToString(list.bsc_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(list.eth_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(list.tron_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(list.fusd_transfer_amount) }}</td>
            <td>{{ list.transfer_status }}</td>
            <td>{{ list.transfer_time }}</td>
            <td>{{ list.transfer_usdt_tx_id }}</td>
            <td>{{ list.transfer_fusd_tx_id }}</td>
            <td>{{ list.refund_tx_id }}</td>
            <td>{{ list.refund_chain_type }}</td>
            <td>{{ list.note }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td>{{ scientificNotationToString(subtotal.purchase_amount) }}</td>
            <td>{{ scientificNotationToString(subtotal.bsc_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(subtotal.eth_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(subtotal.tron_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(subtotal.fusd_transfer_amount) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td>{{ scientificNotationToString(sum.purchase_amount) }}</td>
            <td>{{ scientificNotationToString(sum.bsc_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(sum.eth_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(sum.tron_transfer_amount) }}</td>
            <td>{{ scientificNotationToString(sum.fusd_transfer_amount) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

  </article>
</template>
    
<script>
export default {
  name: "new-investment-report",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      wallet_address: '',
      startTime: '',
      endTime: '',
      transfer_out: '',
      transferOutList: {
        1: { label: '广播中', value: '1' },
        2: { label: '成功', value: '2' },
        3: { label: '失败', value: '3' },
      },
      status: '',
      statusList: {
        1: { label: '待處理', value: '1' },
        2: { label: '接收中', value: '2' },
        3: { label: '完成', value: '3' },
        4: { label: '失败', value: '4' },
      },
      tHeader: [],
      filterVal: [
        "wallet_address",
        "purchase_amount",
        "bsc_transfer_amount",
        "eth_transfer_amount",
        "tron_transfer_amount",
        "fusd_transfer_amount",
        "transfer_status",
        "transfer_time",
        "transfer_usdt_tx_id",
        "transfer_fusd_tx_id",
        "refund_tx_id",
        "refund_chain_type",
        "note"
      ],
      subtotal: {},
      sum: {},
    };
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_address) {
        req.wallet_address = this.wallet_address;
      }
      if (this.transfer_out) {
        req.transfer_status = this.transfer_out;
      }
      if (this.status) {
        req.receive_status = this.status;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}invest/invest_record`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);
          this.subtotal = res.subtotal ? res.subtotal : {};
          this.sum = res.sum ? res.sum : {};
          this.resData.forEach((list) => {
            list.transfer_status = this.transferOutList[list.transfer_status].label;
            list.receive_status = this.statusList[list.receive_status].label;
            list.transfer_time = +list.transfer_time > 0 ? this.dataFormat(list.transfer_time) : '-';
            list.receive_time = +list.receive_time > 0 ? this.dataFormat(list.receive_time) : '-';
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>
    
<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>