<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <section class="com_search_box">

      <div class="com_item_search">
        <el-button type="primary"  size="small" @click="singFn(item, item.type)" v-for="item in listButton" :key="item.toString()">{{ item.text }}</el-button>
      </div>
    </section>

    <div class=" mt_40" v-for="(list, index) in dataRes" :key="index">
      <h2>{{list.title}}</h2>
      <table class="com-table">
        <thead class="com_thead">
          <th style="width: 70%">{{list.title}}錢包地址</th>
          <th style="width: 15%">私鑰狀態</th>
          <th style="width: 15%">操作</th>
        </thead>
        <tbody class="com_tbody">
          <tr>
            <td>{{list.detail?.addr}}</td>
            <td>{{list.detail?.pri_enabled ? '正常' : '未录入'}}</td>
            <td class="tab_btn">
              <span class="btn" @click="singFn(1, list.title)">重傳私鑰</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="titleModal" :width="750">
      <div class="com_search_box">

        <div class="com_item_search">
          <div class="com_item_tx">公钥</div>
          <div class="com_item_input mr_10" style="width: 550px">
            <el-input
                v-model="keyR.pub_key"
                placeholder=""
                :readonly="true"
                rows="7"
                type="textarea"
                size="small">
            </el-input>
          </div>
          <span class="color_T1 cur_po" @click="copyText(keyR.pub_key)">复制</span>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">秘钥密文</div>
          <div class="com_item_input" style="width: 550px">
            <el-input
                v-model="toAddr"
                placeholder="请输入"
                rows="7"
                type="textarea"
                size="small">
            </el-input>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">谷歌验证码</div>
          <div class="com_item_input">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary"  @click="submitKeyFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "addFthAddress",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      two_auth_code: '',
      resData: {},
      keyR: {},
      curIndex: '',
      isAlert: false,
      gasPriceData: {},
      toAddr: '',
      dataRes: [
        {
          title: 'ftcmain',
        },
        {
          title: 'BSC',
        },
        {
          title: 'ETH',
        },
        {
          title: 'TRON',
        },
      ],
      titleModal: '',
      chain_type: '',
      listButton: [
        {
          text: '上傳ftcmain私鑰',
          type: 'ftcmain'
        },
        {
          text: '上傳BSC私鑰',
          type: 'BSC'
        },
        {
          text: '上傳ETH私鑰',
          type: 'ETH'
        },
        {
          text: '上傳TRON私鑰',
          type: 'TRON'
        }
      ]
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },
    resInit () {
      let url = 'sign/hot_addr'
      let req = {
        usage: 'fusdHot'
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
          .then(res => {
            this.resData = res ? res : {}
            this.resData.map(i => {
              this.dataRes.forEach((j, indexj) => {
                if (j.title.toLowerCase() === i.chain_type) {
                  this.dataRes[indexj].detail = i
                }
              })
            })
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    singFn (item, chain_type) {
      this.isAlert = true
      this.toAddr = ''
      this.two_auth_code = ''
      this.chain_type = chain_type
      // this.curIndex = index
      switch (item) {
        case '上傳ftcmain私鑰':
          this.titleModal = '上传ftcmain钱包地址私钥'
          break
        case '上傳BSC私鑰':
          this.titleModal = '上传BSC钱包地址私钥'
          break
        case '上傳ETH私鑰':
          this.titleModal = '上传ETH钱包地址私钥'
          break
        case '上傳TRON私鑰':
          this.titleModal = '上传TRON钱包地址私钥'
          break
      }
      let req = {
        usage: 'fusdHot'
      }
      this.$ajax(`${this.Utils.URL.goURL}sign/public_key`, req, 'get')
          .then(res => {
            this.keyR = res ? res : {}

          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitKeyFn() {
      // let index = this.curIndex
      let address = this.dataRes.filter(i => i.title.toLowerCase() === this.chain_type.toLowerCase())
      if (this.toAddr === '') {
        this.$message.error('请填写私钥')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'sign/upload_hot_key'
      let req = {
        id: this.keyR.id,
        key: this.toAddr,
        two_auth_code: this.two_auth_code,
        usage: 'fusdHot',
        chain_type: this.chain_type.toLowerCase(),
        addr: address[0]?.detail?.addr
      }
      this.$confirm(`${this.curIndex === '' ? `是否上传${this.chain_type}钱包地址私` : '是否重传私钥'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('成功')
              this.isAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
  },
}
</script>

<style scoped>

</style>