<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>钱包ID</th>
        <th>绑定公链钱包地址</th>
        <th>链类型</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.user_id}}
          </td>
          <td>{{list.addr}}</td>
          <td>{{list.chain_type}}</td>

        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "addressList",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      typeObj: {
        1: {label: '返还投资金额', value: '1'},
        2: {label: '返还用户质押资金', value: '2'},
        3: {label: '返还认购合伙人资金', value: '3'},
        4: {label: '返还创建合伙人资金', value: '4'},
        5: {label: '合伙人有上下级关系', value: '5'},
      },
      filterVal: [
        "user_id",
        "addr",
        "chain_type",
      ],
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
      this.resInit();
    }
    // this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      } else {
        this.$message.error('请输入钱包ID');
        return false
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.status = this.selectVal1;
      }
      if (this.selectVal2) {
        req.tx_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}public_wallet_addr`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>