<template>
    <article class="">
      <h2 class="com_h2_title">{{$route.meta.title}}</h2>
        <div class="com_search_box" >
            <div class="com_item_search">
                <div class="com_item_tx">类型</div>
                <div class="com_item_input">
                    <el-select
                            v-model="selectVal1"
                               clearable
                               filterable
                               placeholder="请选择"
                               size="small"
                               @change="selectFn"
                    >
                        <el-option  v-for="item in options1"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                        </el-option>

                    </el-select>
                </div>
            </div>
            <div class="com_item_search">
                <div class="com_item_tx">一级分类</div>
                <div class="com_item_input">
                    <el-select
                            v-model="selectVal2"
                            clearable
                            filterable
                            placeholder="请选择"
                            size="small">
                        <el-option  v-for="item in options2"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                        </el-option>

                    </el-select>
                </div>
            </div>
            <div class="com_item_search">
                <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
            </div>
        </div>
        <div class="com_search_box" v-if="isEditButton">
            <div class="com_item_search">
                <el-button type="primary" size="small" @click="goRouter('/classListEdit', {type: '1'})">新增一级分类</el-button>
            </div>
            <div class="com_item_search">
                <el-button type="primary" size="small" @click="goRouter('/classListEdit', {type: '2'})">新增二级分类</el-button>
            </div>
        </div>
        <div class="tab_box">
            <table class="com-table">
                <thead class="com_thead">
                    <th>序号</th>
                    <th>类别</th>
                    <th>一级分类</th>
                    <th>二级分类</th>
                    <th>排序</th>
                    <th>创建时间</th>
                    <th>操作</th>
                </thead>
                <tbody class="com_tbody">
                    <tr v-for="(list, index) in resData" :key="list.id">
                        <td>{{page == 1 ? index + 1 : index == 0 ? ((page - 1)* 10) + 1 : ((page - 1) * 10) + index + 1}}</td>
                        <td>{{list.type_text}}</td>
                        <td>{{list.one_type}}</td>
                        <td>{{list.two_type}}</td>
                        <td>{{list.sort}}</td>
                        <td>{{dataFormat(list.created_at)}}</td>
                        <td class="tab_btn">
                            <span class="btn" v-if="isEditButton" @click="goEdit(list)">修改</span>
                            <span class="btn" v-if="isDelButton" @click="delFn(index)">删除</span>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="com_page">
            <el-pagination
                    layout="pager"
                    :total="total"
                    :current-page="page"
                    @current-change="pageFn"
            >
            </el-pagination>
        </div>

    </article>
</template>

<script>
    export default {
        name: "classList",
        data() {
            return {
                options1: [{
                    value: '1',
                    label: '文章'
                }, {
                    value: '2',
                    label: '图片'
                },
                {
                    value: '3',
                    label: '视频'
                },
                {
                    value: '4',
                    label: '热搜文章'
                }],
                options2: [],
                page: 1,
                size: 10,
                total: '',
                resData: [],
                curIndex: '',
                selectVal1: '',
                selectVal2: '',
                selectVal3: '',
                startTime: '',
                endTime: '',
                selectArr: [{value: '钱包', label: '钱包'}]
            }
        },
        created () {
            this.resInit()
        },
        methods: {
            goEdit (list) {
                window.localStorage.setItem('listDataInfo', JSON.stringify(list))
                if (list.two_type === '') {
                    this.goRouter('/classListEdit', {type: '1', opt: 'edit'})
                } else {
                    this.goRouter('/classListEdit', {type: '2', opt: 'edit'})
                }
            },
            selectFn (vals) {
                if (!vals) {
                    return false
                }
                let req = {
                    type_content_id: this.selectVal1
                }
                this.$ajax(`${this.Utils.URL.goURL}type_one_list`, req, 'get')
                    .then(res => {
                        let arr = res ? res : []
                        let arr2 = []
                        arr.forEach((val, index) => {
                            arr2[index] = {
                                value: val.id,
                                label: val.type_zh
                            }
                        })
                        this.options2 = arr2
                        if (this.selectVal1 == 1) {
                            window.sessionStorage.setItem('oneArticleClass', JSON.stringify(arr2))
                        }
                        if (this.selectVal1 == 2) {
                            window.sessionStorage.setItem('oneImgClass', JSON.stringify(arr2))
                        }
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },
            resInit () {
                let req = {
                    page: this.page,
                    size: this.size
                }
                if (this.selectVal2) {
                    req.type_id = this.selectVal2
                }
                this.$ajax(`${this.Utils.URL.goURL}type_list`, req, 'get')
                    .then(res => {
                        this.resData = res.list ? res.list : []
                        this.total = parseFloat(res.total)
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },
            delFn (index) {
                this.curIndex = index
                this.$confirm('是否删除', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ajax(`${this.Utils.URL.goURL}delete_type`, {
                        id: this.resData[index].id
                    }, 'post')
                        .then(res => {
                            this.resData.splice(index, 1)
                        })
                        .catch(res => {
                            this.$message.error(res.msg)
                        })
                }).catch(() => {

                });
            },
            pageFn (index, type) {
                if (type == 'search' && index == 1 && !this.selectVal2) {
                    this.$message.error('请选择一级分类')
                }
                this.page = index
                this.resInit()
            }
        }
    }
</script>

<style scoped>

</style>