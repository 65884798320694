<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <!-- <div class="com_item_search">
        <div class="com_item_tx">社区名称</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">负责人</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div> -->

      <div class="com_item_search">
        <div class="com_item_tx">报销类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <!-- <div class="com_item_search">
        <div class="com_item_tx">社区类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div> -->
      <div class="com_item_search">
        <div class="com_item_tx">审核状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>钱包ID</th>
        <th>报销数量(USDT)</th>
        <th>剩余额度(USDT)</th>
        <th>累计报销数量(USDT)</th>
        <th>累计贡献值</th>
        <th>联系电话</th>
        <th>报销类型</th>
        <th>图片资料</th>
        <th>视频资料</th>
        <th>bsc钱包地址</th>
        <th>申请状态</th>
        <th>审核备注</th>
        <th>申请时间</th>
        <th>审核时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.user_id}}</td>
          <td>{{list.amount}}</td>
          <td>{{list.surplus_amount}}</td>
          <td>{{list.total_reimburse}}</td>
          <td>{{list.total_net_income}}</td>
          <td>{{list.phone_tx}}</td>
          <td>{{list.reason_tx}}</td>
          <td>
            <p v-for="(li, index) in list.pic_link_arr" :key="index">
              <a v-show="li" :href="Utils.URL.imgURL+li" target="_blank"><img :src="Utils.URL.imgURL+li" width="50"/></a>
            </p>
          </td>
          <td>
              <a :href="Utils.URL.imgURL+list.video_link" v-show="list.video_link" target="_blank">{{Utils.URL.imgURL+list.video_link}}</a>
          </td>
          <td>{{list.bsc_addr}}</td>
          <td>{{list.status_tx}}</td>
          <td>{{list.reason}}</td>
          <td>{{list.created_at}}</td>
          <td>{{list.operated_at}}</td>
          <td class="tab_btn">
            <span class="btn " v-if="list.status == 1" @click="showFn(index, 2)">通过</span>
            <span class="btn " v-if="list.status == 1" @click="showFn(index, 3)">拒绝</span>
            <span class="btn" @click="goRouter('/communityReimburseDetails', {id: list.user_id})">报销明细</span>

          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <el-dialog v-model="isShow" :title="curType == 2 ? '审核通过' : '审核拒绝' " width="500px">
      <section class="com_from_box">

        <div class="com_from_item" >
          <div class="com_from_left">审核备注</div>
          <div class="com_from_right" >
            <el-input type="textarea"
                      rows="5"
                      v-model="reason"
                      size="small">

            </el-input>

          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">温馨提示</div>
          <div class="com_from_right">
            {{curType == 2 ? '确定通过本次申请?' :  '确定拒绝本次申请？'}}
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isShow = false">取消</el-button>
                    <el-button type="primary" @click="editRateFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>



  </article>
</template>

<script>
export default {
  name: "communityReimburseReport",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      options: [],
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal4: "",
      selectVal1: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      expenses: '',
      reason: '',
      min: '',
      max: '',
      isShow: false,
      passShow: false,
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'MD+', 'MD++', 'MD+++'],
      // typeObj: {
      //   1: {label: '工作室', value: '1'},
      //   2: {label: '俱乐部', value: '2'},
      //   3: {label: '体系', value: '3'},
      //   4: {label: '分公司', value: '4'},
      // },
      typeObj1: {
        1: {label: '申请中', value: '1'},
        2: {label: '已通过', value: '2'},
        3: {label: '已拒绝', value: '3'},
      },
      typeObj2: {
        1: {label: '组织社员活动经费', value: '1'},
        2: {label: '社区空间装修', value: '2'},
        3: {label: '申请备用金', value: '3'},
      },
      filterVal: [
        "user_id",
        "amount",
        "surplus_amount",
        "total_reimburse",
        "total_net_income",
        "phone_tx",
        "reason_tx",
        "pic_link_arr",
        "video_link",
        "bsc_addr",
        "status_tx",
        "reason",
        "created_at",
        "operated_at",
      ],
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showFn (index, type) {
      this.curIndex = index
      this.curType = type
      this.two_auth_code = ''
      this.reason = ''

      this.isShow = true
    },
    editRateFn () {
      let index = this.curIndex

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'submit_community_activity_operation'
      let req = {
        id: this.resData[index].id,
        status: this.curType,
        two_auth_code: this.two_auth_code,
        reason: this.reason,
      }

      if (this.reason == '') {

        this.$message.error('请填写备注')
        return  false
      }

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.$message.success('操作成功')
            this.resInit()
            this.isShow = false
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.user_id = this.inputVal1;
      }
      // if (this.inputVal2) {
      //   req.community_name = this.inputVal2;
      // }
      // if (this.inputVal3) {
      //   req.name = this.inputVal3;
      // }
      if (this.inputVal4) {
        req.bsc_addr = this.inputVal3;
      }
      if (this.selectVal1) {
        req.reason_id = this.selectVal1;
      }
      // if (this.selectVal2) {
      //   req.community_level = this.selectVal2;
      // }
      if (this.selectVal3 !== '') {
        req.status = this.selectVal3;
      }
      if (this.selectVal4) {
        req.fail_status = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}community_activity_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.phone_tx = '+' + list.area_code + '-' + list.phone
              list.status_tx = this.typeObj1[list.status] ? this.typeObj1[list.status].label: list.status
              // list.level_tx = this.typeObj[list?.level] ? this.typeObj[list?.level].label: list?.level
              list.reason_tx = this.typeObj2[list.reason_id] ? this.typeObj2[list.reason_id].label: list.reason_id
              list.pic_link_arr = list.pic_link.split(',')
              list.operated_at = this.dataFormat(list.operated_at)
              list.created_at = this.dataFormat(list.created_at)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>
