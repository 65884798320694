<template>
  <article class="">
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">昵称</div>
        <div class="com_from_right">
          <el-input v-model="nick" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">账号</div>
        <div class="com_from_right">
          <el-input v-model="account_number" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item" v-for="(item, index) in tl_addrs" :key="item">
        <div class="com_from_left">绑定地址{{ item }}</div>
        <div class="com_from_right">
          <el-input
            v-model="tl_addr[index]"
            placeholder="请输入"
            size="small"
            style="width: 370px"
          >
          </el-input>
        </div>
        <span style="color: red; font-size: 12px">选填</span>
        <button @click="increase_tl_addrs">+</button>
        <button @click="decrease_tl_addrs(index)" v-if="item !== 1">-</button>

      </div>
      <span style="color: #0088bb"
        >注：绑定地址后，该账号仅能查看改地址下的团队绑定相关数据，不绑定则无法查看任何团队数据</span
      >

      <div class="com_from_item">
        <div class="com_from_left">密码</div>
        <div class="com_from_right">
          <el-input
            type="password"
            v-model="pass"
            placeholder="请输入"
            size="small"
          >
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">确认密码</div>
        <div class="com_from_right">
          <el-input
            type="password"
            v-model="restpass"
            placeholder="请输入"
            size="small"
          >
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">状态</div>
        <div class="com_from_right">
          <el-select
            v-model="power"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option label="正常" value="1"> </el-option>
            <el-option label="冻结" value="2"> </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">绑定角色</div>
        <div class="com_from_right">
          <el-checkbox-group v-model="checkedArr" @change="CheckFn">
            <el-checkbox
              v-for="role in roleData"
              :key="role.role_id"
              :label="role.role_id"
              >{{ role.role_name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">谷歌验证码</div>
        <div class="com_from_right">
          <el-input v-model="two_auth_code" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left"></div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import md5 from "blueimp-md5";
export default {
  name: "adminEdit",
  data() {
    return {
      roleArr: [],
      checkedArr: [],
      pass: "",
      restpass: "",
      account_number: "",
      two_auth_code: "",
      nick: "",
      tl_addr: ["", "", "", "", ""],
      id: "",
      power: "1",
      roleData: [],
      tl_addrs: 1,
      refInputs: [],
    };
  },
  created() {
    let obj = window.sessionStorage.getItem("listDataInfo")
      ? JSON.parse(window.sessionStorage.getItem("listDataInfo"))
      : {};
    this.roleArr = obj.role_list ? obj.role_list : [];
    this.roleArr.forEach((val) => {
      this.checkedArr.push(val.role_id);
    });
    this.id = obj.id;
    this.nick = obj.nick;
    this.account_number = obj.account_number;
    if (obj.addr_list) {
      this.tl_addr = obj.addr_list.split(",");
      this.tl_addrs = this.tl_addr.length;
      if (this.tl_addrs !== 5) {
        this.tl_addr.length = 5;
      }
    }
    this.power = obj.power;
    this.roleData = window.sessionStorage.getItem("roleData")
      ? JSON.parse(window.sessionStorage.getItem("roleData"))
      : [];
    if (!this.roleData.length) {
      this.resInit();
    }
  },
  methods: {
    increase_tl_addrs() {
      if (this.tl_addrs == 5) {
        this.$message.warning("最多只能绑定五个地址");
        return;
      }
      this.tl_addrs += 1;
    },
    decrease_tl_addrs(index) {
      this.tl_addr.splice(index, 1, "");
      this.tl_addrs -= 1;
    },
    CheckFn(val) {},
    resInit() {
      let req = {
        page: 1,
        size: 10000,
      };

      this.$ajax(`${this.Utils.URL.goURL}role_list`, req, "get")
        .then((res) => {
          this.roleData = res.list ? res.list : [];
          window.sessionStorage.setItem(
            "roleData",
            JSON.stringify(this.roleData)
          );
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    submitFn() {
      let addr_list = "";
      this.tl_addr.forEach((item) => {
        if (item) {
          addr_list += item + ",";
        }
      });

      if (!this.nick) {
        this.$message.error("请输入昵称");
        return false;
      }
      if (!this.account_number) {
        this.$message.error("请输入账号");
        return false;
      }
      if (!this.pass && !this.id) {
        this.$message.error("请输入密码");
        return false;
      }
      if (this.restpass != this.pass) {
        this.$message.error("密码不一致");
        return false;
      }
      if (!this.power) {
        this.$message.error("请选择账号状态");
        return false;
      }
      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      if (!this.checkedArr.length) {
        this.$message.error("请选择角色");
        return false;
      }
      let url = "created_admin";

      let req = {
        nick: this.nick,
        user_name: this.account_number,
        two_auth_code: this.two_auth_code,
        power: this.power,
        role_id: this.checkedArr.join(","),
      };
      if (this.pass) {
        req.pass = md5(this.pass);
      }
      if (this.id) {
        url = "update_admin_role";
        req.user_id = this.id;
      }

      req.addr_list = addr_list.substring(0, addr_list.length - 1);

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
        .then((res) => {
          this.$message.success("操作成功,两秒后自动跳转");
          setTimeout(() => {
            this.goBack();
          }, 1500);
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.com_from_item button {
  background: #0088bb;
  border: none;
  border-radius: 50%;
  color: white;
  width: 25px;
  height: 25px;
  margin: 0 5px;
  cursor: pointer;
}
</style>