<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">錢包地址</div>
        <div class="com_item_input">
          <el-input v-model="user_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <!-- <div class="com_search_box">
        <el-button
            type="primary"
            @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
        >
          导出excel（本页）
        </el-button>
      </div> -->

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>錢包ID</th>
          <th>質押類別</th>
          <th>LP數量</th>
          <th>質押週期</th>
          <th>已發放利息SCF</th>
          <th>已發放利息FUSD</th>
          <th>未提取收益SCF</th>
          <th>未提取收益FUSD</th>
          <th>已提取收益SCF</th>
          <th>已提取收益FUSD</th>
          <th>開始時間</th>
          <th>結束時間</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ list.order_type }}</td>
            <td>{{ list.lp_amt }}</td>
            <td>{{ list.duration_day }}</td>
            <td>{{ list.scf_total_profit }}</td>
            <td>{{ list.fusd_total_profit }}</td>
            <td>{{ list.scf_no_draw_profit }}</td>
            <td>{{ list.fusd_no_draw_profit }}</td>
            <td>{{ list.scf_draw_profit }}</td>
            <td>{{ list.fusd_draw_profit }}</td>
            <td>{{ list.start_at }}</td>
            <td>{{ list.end_at }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(subtotal.scf_total) }}</td>
            <td> {{ scientificNotationToString(subtotal.fusd_total) }}</td>
            <td> {{ scientificNotationToString(subtotal.scf_no_draw) }}</td>
            <td> {{ scientificNotationToString(subtotal.fusd_no_draw) }}</td>
            <td> {{ scientificNotationToString(subtotal.scf_draw) }}</td>
            <td> {{ scientificNotationToString(subtotal.fusd_draw) }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="Object.keys(sum).length > 0">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(sum.scf_total) }}</td>
            <td> {{ scientificNotationToString(sum.fusd_total) }}</td>
            <td> {{ scientificNotationToString(sum.scf_no_draw) }}</td>
            <td> {{ scientificNotationToString(sum.fusd_no_draw) }}</td>
            <td> {{ scientificNotationToString(sum.scf_draw) }}</td>
            <td> {{ scientificNotationToString(sum.fusd_draw) }}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-show="isHasData" style="text-align: center;">
      <p><img src="../../assets/img/no_data.png" alt=""></p>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

  </article>
</template>
  
<script>
export default {
  name: "operationalDeport",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      subtotal: {},
      sum: {},
      resData: [],
      user_id: '',
      tHeader: [],
      // filterVal: [
      //   "invest_profit",
      //   "pool_profit",
      //   "coupons_profit",
      //   "md_average_profit",
      //   "team_profit",
      //   "direct_profit",
      //   "indirect_profit",
      //   "partner_profit",
      //   "order_amount",
      //   "spread_amount",
      // ],
      // isHasData: false,
      // time: this.dataFormat(new Date().getTime() / 1000),
      orderTypeList: [
        {
          value: 1,
          text: '快捷质押',
        },
        {
          value: 2,
          text: 'LP质押',
        },
        {
          value: 3,
          text: 'OTC质押',
        },
      ],
    };
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.resData = [];
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.user_id) {
        req.user_id = this.user_id;
      }
      this.$ajax(`${this.Utils.URL.goURL}mortgage_profit`, req, 'get')
        .then((res) => {
          if (res.list === null || res.list.length === 0) {
            this.$message.error("暂无数据");
            return;
          }
          this.resData = res.list ? res.list : []
          this.resData.forEach((list) => {
            list.start_at = parseFloat(list.start_at) > 0 ? this.dataFormat(list.start_at) : '-'
            list.end_at = parseFloat(list.end_at) > 0 ? this.dataFormat(list.end_at) : '-'
            list.order_type = this.orderTypeList[list.order_type - 1].text
            list.duration_day = list.duration_day == 7 ? '7天' : list.duration_day == 15 ? '15天' : list.duration_day == 30 ? '30天' : '-'
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
          this.subtotal = res.subtotal ? res.subtotal : {};
          this.sum = res.sum ? res.sum : {};
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>
  
<style scoped></style>
