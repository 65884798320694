<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">鏈類型</div>
        <div class="com_item_input">
          <el-select
            v-model="chain_type"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in listChainType"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">狀態</div>
        <div class="com_item_input">
          <el-select
            v-model="status"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in listStatus"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">種類</div>
        <div class="com_item_input">
          <el-select
            v-model="lp_type"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in listType"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>

      </div>

    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>
    <div>
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>錢包ID</th>
          <th>狀態</th>
          <th>種類</th>
          <th>鏈類型</th>
          <th>LP數量</th>
          <th>FTC數量</th>
          <th>USDT/FUSD數量</th>
          <th>時間</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.wallet_id }}</td>
            <td>{{ list.status }}</td>
            <td>{{ list.lp_type }}</td>
            <td>{{ list.chain_type }}</td>
            <td>{{ list.lp_amount }}</td>
            <td>{{ list.ftc_amount }}</td>
            <td>{{ list.usdt_amount }}</td>
            <td>{{ list.created_at }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ subtotal?.lp_amt }}</td>
            <td>{{ subtotal?.ftc_amt }}</td>
            <td>{{ subtotal?.usdt_amt }}</td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ sum?.lp_amt }}</td>
            <td>{{ sum?.ftc_amt }}</td>
            <td>{{ sum?.usdt_amt }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes"
        :total="total">
      </el-pagination>
    </div>
  </article>
</template>
  
<script>
export default {
  name: "liquidStakeOrder",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      subtotal: {},
      sum: {},
      wallet_id: '',
      chain_type: '',
      listChainType: [
        {
          value: 'bsc',
          text: 'BSC',
        },
        {
          value: 'ftcmain',
          text: 'FTC-MAIN',
        },
      ],
      status: '',
      listStatus: [
        {
          value: 1,
          text: '確認中',
        },
        {
          value: 2,
          text: '成功',
        },
        {
          value: 3,
          text: '失敗',
        }
      ],
      lp_type: '',
      listType: [
        {
          value: 1,
          text: '添加',
        },
        {
          value: 2,
          text: '移除',
        },
      ],
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      tHeader: [],
      filterVal: [
        'wallet_id',
        'status',
        'lp_type',
        'chain_type',
        'lp_amount',
        'ftc_amount',
        'usdt_amount',
        'created_at',
      ],
      resDataFullForExport: [],
    };
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  created() {
    this.resInit();
  },
  methods: {
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
      }
      this.$ajax(`${this.Utils.URL.goURL}liquidity/lp_record`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.created_at = this.dataFormat(list.created_at)
            list.status = this.listStatus[list.status - 1].text
            list.lp_type = list.lp_type == 1 ? '添加' : list.lp_type == 2 ? '移除' : '-'
          })
          if (this.resDataFullForExport.length > 0) {
            this.exportExcel(this.tHeader, this.filterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id) {
        req.wallet_id = this.wallet_id;
      }
      if (this.chain_type) {
        req.chain_type = this.chain_type;
      }
      if (this.status) {
        req.status = this.status;
      }
      if (this.startTime) {
        req.start = this.startTime;
      }
      if (this.endTime) {
        req.end = this.endTime;
      }
      if (this.lp_type) {
        req.tx_type = this.lp_type;
      }
      this.$ajax(`${this.Utils.URL.goURL}liquidity/lp_record`, req, "get")
        .then((res) => {
          if (res.list.length == 0) {
            this.$message.error("暂无数据");
          }
          this.resData = res.list ? res.list : []
          this.sum = res.sum ? res.sum : {}
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.resData.forEach((list) => {

            list.created_at = this.dataFormat(list.created_at)
            list.status = this.listStatus[list.status - 1].text
            list.lp_type = list.lp_type == 1 ? '添加' : list.lp_type == 2 ? '移除' : '-'
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;

        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>
  
<style scoped></style>