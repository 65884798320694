<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">钱包地址</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal2"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">上级ID</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal3"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">级别</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, index) in levelArr"
                :key="item"
                :label="item"
                :value="index"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
      <el-button
          type="primary"
          v-if="isGenerateButton"
          @click="goRouter('levelHistory')"
      >
        设置级别记录
      </el-button>
    </div>
    <div class="tab_box mt_40">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>钱包ID</th>
        <th>级别</th>
        <th>绑定上级ID</th>
        <th>链类型</th>
        <th>钱包地址</th>
        <th>创建时间</th>
        <th>所属国家</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td :rowspan="list.row " v-show="list.isShow">{{ (page - 1) * size + 1 + list.index }}</td>
          <td :rowspan="list.row " v-show="list.isShow">{{ list.user_id }}</td>
          <td :rowspan="list.row " v-show="list.isShow">
            <p @click="showDialogGiftTimeRemaining(list)" class="link">{{ list.level }}</p>
          </td>
          <td :rowspan="list.row " v-show="list.isShow">{{ list.parent_id }}</td>
          <td>{{list.chain_type}}</td>
          <td>{{list.addr}}</td>

          <td :rowspan="list.row " v-show="list.isShow">{{ list.created_at }}</td>
          <td :rowspan="list.row " v-show="list.isShow">{{list.country}}</td>
          <td class="tab_btn" :rowspan="list.row " v-show="list.isShow">
            <span class="btn"  @click="goRouter('personalLendingReport', {team_wallet_id: list.user_id, wallet_id: list.user_id, start: startTime, end: endTime})">借贷收益详情</span>
            <span class="btn"  @click="showLevelFn(index)" v-if="isEditButton">设置级别</span>
            <span class="btn"  @click="showEditFn(list)" v-if="isResetPassButton">绑定关系</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" title="管理级别设置" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">管理级别</div>
          <div class="com_from_right">
            <el-select
                v-model="level"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="(item, index) in levelArr"
                  :key="item"
                  :label="item"
                  :value="index"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">级别有效期</div>
          <div class="com_from_right">
            <el-select
                v-model="period_type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <!-- <el-option
                  label="永久有效"
                  value="1"
              >
                永久有效
              </el-option> -->
              <el-option
                  label="30天有效"
                  value="2"
              >
              30天有效
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="submitFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
    <el-dialog v-model="showEdit" title="绑定关系修改" width="600px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">用户ID</div>
          <div class="com_from_right">
            {{ alertData.user_id }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">绑定上级ID</div>
          <div class="com_from_right">
            {{ alertData.parent_id }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">变更关系类型</div>
          <div class="com_from_right">
            <el-select
                v-model="edit_type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  label="更改当前ID绑定关系"
                  value="1"
              >
              </el-option>
              <el-option
                  label="更改当前ID直邀下级绑定关系"
                  value="2"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="com_from_item" v-show="edit_type == 2">
          <div class="com_from_left">直邀下级列表</div>
          <div class="com_from_right">
            <el-button
                type="primary"

                @click="searchDirectUserFn"
            >
              查询
            </el-button>
          </div>
        </div>

        <div class="com_from_item" v-show="edit_type == 2">
          <div class="com_from_left"></div>
          <div class="com_from_right">
            <div style="height: 150px; overflow-y: scroll">
              <p v-for="(list, index) in childrenList" :key="index"><el-checkbox @change="(val)=> {addChildrenFn(val, index)}" :label="list" size="large" /></p>
            </div>
            <div class="com_page">
              <el-pagination
                  @size-change="handleSizeChangeUser"
                  @current-change="pageFnUser"
                  :current-page="pageUser"
                  :page-sizes="[10, 20, 50, 100, 1000]"
                  :page-size="sizeUser"
                  layout="total, prev, pager, next, sizes"
                  :total="totalUser"
              >
              </el-pagination>
            </div>

          </div>
        </div>

        <div class="com_from_item" v-show="childrenArr.length && edit_type == 2">
          <div class="com_from_left">已选择下级列表</div>
          <div class="com_from_right">
            <div style="height: 150px; overflow-y: scroll">
              <p v-for="(list, index) in childrenArr" :key="index">{{list}}</p>
            </div>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">新上级ID</div>
          <div class="com_from_right">
            <el-input
                v-model="new_parent_id"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="showEdit = false">取消</el-button>
                    <el-button type="primary" @click="submitEditFn">提交</el-button>
                </span>
      </template>
    </el-dialog>


    <!-- Gift remaining time -->
    <el-dialog v-model="isDialogGiftTimeRemaining" title="用戶級別" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">真實級別</div>
          <div class="com_from_right">
            <p><b>{{ objCurUser.achievement_level != 'AP' ? objCurUser.achievement_level : '普通用戶' }}</b></p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">贈送級別</div>
          <div class="com_from_right">
            <p><b>{{ objCurUser.set_by_admin ? objCurUser.level : '--' }}</b></p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">贈送剩餘時間</div>
          <div class="com_from_right">
            <!-- <p style="color: #0d84ff; font-size: 20px; font-weight: 500;"> -->
            <p style="font-weight: 500;">
              {{ objCurUser.set_by_admin ? objCurUser.left_days : '--'}} 天
            </p>
          </div>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="isDialogGiftTimeRemaining = false">返回</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "userList",
  data() {
    return {
      options2: [],
      resData: [],
      selectArr: [],
      selectVal1: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      curIndex: 0,
      curType: '',
      page: 1,
      size: 10,
      total: 0,
      totalUser: '',
      selectVal2: '',
      startTime: '',
      endTime: '',
      option1: [],
      option2: [],
      tHeader: [],
      period_type: '',
      level: '',
      isAlert: false,
      showEdit: false,
      alertData: {},
      new_parent_id: '',
      childrenList: [],
      childrenArr: [],
      edit_type: '',
      two_auth_code: '',
      pageUser: 1,
      sizeUser: 10,
      filterVal: [
        "user_id",
        "level",
        "parent_id",
        "chain_type",
        "addr",
        "created_at",
      ],
      isDialogGiftTimeRemaining: false,
      // valueTime: 0,
      // timer: null,
      objCurUser: {
        time: null
      },
    }
  },
  computed: {
    // formatTime() {
    //   const hour = Math.floor(this.valueTime / 3600);
    //   const minutes = Math.floor((this.valueTime % 3600) / 60);
    //   const seconds = this.valueTime % 60;
    //   return `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    // }
  },
  created() {

    // this.resInit()
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    // startTimer() {
    //   this.timer = setInterval(() => {
    //     if (this.valueTime > 0) {
    //       this.valueTime--;
    //     } else {
    //       clearInterval(this.timer);
    //     }
    //   }, 1000);
    // },
    // stopTimer() {
    //       clearInterval(this.timer);
    //     },
    showDialogGiftTimeRemaining(list) {
      this.isDialogGiftTimeRemaining = true;
      this.objCurUser = list;
      this.userLevel(list.user_id)
    },
    addChildrenFn (val, index) {
      if (val) {
        this.childrenArr.push(this.childrenList[index])
      } else {
        this.childrenArr.forEach((vals, indexs) => {
          if (vals == this.childrenList[index]) {
            this.childrenArr.splice(indexs, 1)
          }
        })
      }

    },
    userLevel(id) {
      // this.stopTimer();
      let req = {
        user_id: id
      }
      this.$ajax(`${this.Utils.URL.goURL}user_level`, req, 'get')
        .then(res => {
          this.objCurUser = res
          // this.valueTime = res.left_time
          // this.startTimer();
          // convert left_time to days
          this.objCurUser.time = Math.floor(res.left_time / 86400);
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    searchDirectUserFn () {
      let req = {
        page: this.pageUser,
        size: this.sizeUser,
        user_id: this.alertData.user_id
      }
      this.$ajax(`${this.Utils.URL.goURL}direct_user`, req, 'get')
          .then(res => {
            this.childrenList = res.list ? res.list : []
            this.totalUser = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    showEditFn (list) {
      this.alertData = list
      this.showEdit = true
      this.pageUser = 1
      this.childrenList = []
      this.childrenArr = []
      this.edit_type = ''
      this.new_parent_id = ''
      this.two_auth_code = ''
    },
    submitEditFn () {

      if (this.edit_type === "") {
        this.$message.error("请选择变更类型");
        return false;
      }
      if (this.new_parent_id === "") {
        this.$message.error("请输入新的上级ID");
        return false;
      }
      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {
        new_parent_id: this.new_parent_id,
        op_type: parseFloat(this.edit_type),
        two_auth_code: this.two_auth_code,

      };
      if (this.edit_type == 2){
        req.direct_ids= this.childrenArr
      }
      if (this.edit_type == 1){
        req.user_id= this.alertData.user_id
      }
      let url = "modify_relation";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post", 2500000, false, true)
          .then(res => {
            this.showEdit = false;
            this.$message.success("修改成功");
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    submitFn () {
      let index = this.curIndex
      if (this.level === "") {
        this.$message.error("请选择管理级别");
        return false;
      }
      if (this.period_type === "") {
        this.$message.error("请选择级别有效期");
        return false;
      }
      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {
        wallet_id: this.resData[index].user_id,
        level: this.level,
        period_type: this.period_type,
        two_auth_code: this.two_auth_code,

      };
      let url = "set_level";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isAlert = false;
            this.$message.success("修改成功");
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    showLevelFn (index) {
      this.curIndex = index
      this.two_auth_code = ''
      this.level = ''
      this.period_type = ''
      this.isAlert = true
    },
    handleSizeChangeUser(val) {
      this.pageUser = 1;
      this.sizeUser = val;
      this.searchDirectUserFn();
    },
    pageFnUser(index) {
      this.pageUser = index
      this.searchDirectUserFn()
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },

    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.inputVal1) {
        req.user_id = this.inputVal1
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2
      }
      if (this.inputVal3) {
        req.parent_id = this.inputVal3
      }
      if (this.selectVal1) {
        req.level = this.selectVal1
      }

      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}user_list`, req, 'get')
        .then(res => {
          let arr = res.list ? res.list : []
          let arrs = []
          arr.forEach((list, index) => {
            let ist = true
            let arrx = {}
            arrx = Object.assign({},list)
            arrx.user_id = list.user_id
            arrx.parent_id = list.parent_id
            arrx.created_at = this.dataFormat(list.created_at)
            arrx.level = this.levelArr[list.level]
            arrx.addr = ''
            arrx.chain_type = ''
            arrx.index = index
            arrx.row = list.addr_list.length
            if (list.addr_list.length) {
              list.addr_list.forEach(val => {
                arrx = Object.assign({},arrx)
                if (ist) {
                  arrx.isShow = true
                  ist = false
                } else {
                  arrx.isShow = false

                }
                arrx.addr = val.addr
                arrx.chain_type = val.chain_type
                arrs.push(arrx)
              })
            } else {
              arrx.isShow = true
              arrs.push(arrx)
            }

          })
          this.resData = arrs
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  },
}
</script>

<style scoped>
.link {
  color: rgb(0, 136, 187);
  cursor: pointer;
  text-decoration: underline;
}
</style>