<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">订单编号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">审核状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

    </div>


    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>订单编号</th>
        <th>钱包ID</th>
        <th>币种</th>
        <th>链类型</th>
        <th>购买数量</th>
        <th>付款金额</th>
        <th>付款币种</th>
        <th>支付方式</th>
        <th>用户支付账号</th>
        <th>付款用户姓名</th>
        <th>商户名称</th>
        <th>商户收款姓名</th>
        <th>商户收款账号</th>
        <th>订单完成时间</th>
        <th>审核状态</th>
        <th>付款截图</th>
        <th>证据记录</th>
        <th>奖励金额</th>
        <th>审核时间</th>
        <th>审核备注</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.order_id}}</td>
          <td>{{list.wallet_id}}</td>
          <td>{{list.symbol}}</td>
          <td>{{list.chain_type}}</td>
          <td>{{list.order_amount}}</td>
          <td>{{list.total}}</td>
          <td>{{list.currency}}</td>
          <td>{{list.pay_way_name}}</td>
          <td>{{list.account}}</td>
          <td>{{list.name}}</td>
          <td>{{list.merchant_nick}}</td>
          <td>{{list.otc_name}}</td>
          <td>{{list.otc_account}}</td>
          <td>{{list.created_at}}</td>
          <td>{{list.receive_status_tx}}</td>
          <td>
            <a v-show="list.image" :href="Utils.URL.imgURL+list.image" target="_blank"><img :src="Utils.URL.imgURL+list.image" width="50"/></a>
          </td>
          <td>
            <p v-for="li in list.evidence_arr">
              <a :href="Utils.URL.imgURL+li" target="_blank">{{Utils.URL.imgURL+li}}</a>
            </p>
          </td>
          <td>{{list.amount}}</td>
          <td>{{list.operate_at}}</td>
          <td>{{list.failure_reason}}</td>
          <td class="tab_btn">
            <span class="btn" v-if="list.receive_status == 2" @click="showFn(index, 2)">通过</span>
            <span class="btn" v-if="list.receive_status == 2" @click="showFn(index, 3)">拒绝</span>
            <span class="btn" v-if="list.receive_status == 5" @click="showFn(index, 4)">激活奖励</span>

          </td>
        </tr>

        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.amount)}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.amount)}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        </tbody>
      </table>
    </div>
    <el-dialog v-model="isShow" :title="curType == 2 ? '审核通过' : curType == 3 ? '审核拒绝' : '激活奖励'" width="500px">
      <section class="com_from_box">

        <div class="com_from_item" v-show="curType == 3">
          <div class="com_from_left">拒绝原因</div>
          <div class="com_from_right">
            <el-select
                v-model="selectFail"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in typeObj1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>

          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">温馨提示</div>
          <div class="com_from_right">
            {{curType == 2 ? '确定通过审核并发放奖励？' : curType == 3 ?  '确定拒绝本次审核？' : '激活后商户可重新提交订单交易资料'}}
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isShow = false">取消</el-button>
                    <el-button type="primary" @click="editRateFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>



  </article>
</template>

<script>
export default {
  name: "otcAwardReport",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      options: [],
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      expenses: '',
      selectFail: '',
      min: '',
      max: '',
      isShow: false,
      passShow: false,
      typeObj: {
        1: {label: '待领取', value: '1'},
        2: {label: '待审核', value: '2'},
        3: {label: '审核通过-待发放', value: '3'},
        4: {label: '审核通过-已发放', value: '4'},
        5: {label: '审核拒绝', value: '5'},
      },
      typeObj1: {
        1: {label: '提交的资料与订单信息不匹配', value: '1'},
        2: {label: '商户存在违规行为', value: '2'},
      },
      filterVal: [
        "order_id",
        "wallet_id",
        "symbol",
        "chain_type",
        "order_amount",
        "total",
        "currency",
        "pay_way_name",
        "account",
        "name",
        "merchant_nick",
        "otc_name",
        "otc_account",
        "created_at",
        "receive_status_tx",
        "image",
        "evidence_arr",
        "amount",
        "amount",
        "operate_at",
        "failure_reason",
      ],
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showFn (index, type) {
      this.curIndex = index
      this.curType = type
      this.two_auth_code = ''
      this.selectFail = ''

      this.isShow = true
    },
    editRateFn () {
      let index = this.curIndex

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = ''
      let req = {
        id: this.resData[index].order_id,

        two_auth_code: this.two_auth_code,
      }

      if (this.curType == 3 && this.selectFail == '') {

        this.$message.error('请选择拒绝原因')
        return  false
      }

      if (this.curType == 2) {
        req.op_type = 2
        url = 'buy/submit_receive_record'
      }
      if (this.curType == 4) {

        url = 'buy/submit_receive_edit'
      }
      if (this.curType == 3) {
        req.fail_status = this.selectFail
        req.op_type = 3
        url = 'buy/submit_receive_record'
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.$message.success('操作成功')
            this.resInit()
            this.isShow = false
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.order_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.inputVal3) {
        req.new_wallet_id = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.receive_status = this.selectVal2;
      }
      if (this.selectVal3 !== '') {
        req.horse_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.fail_status = this.selectVal4;
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}buy/receive_record`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :

              list.receive_status_tx = this.typeObj[list.receive_status] ? this.typeObj[list.receive_status].label: list.status
              list.evidence_arr = list.evidence.split(',')
              list.operate_at = this.dataFormat(list.operate_at)
              list.created_at = this.dataFormat(list.created_at)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>