<template>
<article class="">
  <!-- copy from page  superPartnerList-->
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="用戶錢包ID" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>
    <div class="com_search_box mt_40" >
      <div class="com_item_search">
        <el-button type="primary" v-if="isGenerateButton" size="small" @click="showAlertFn('', 'add')">创建Fin-Chain节点</el-button>
      </div>
    </div>
    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>用戶錢包ID</th>
        <th>現金券餘額</th>
        <th>FTC生態投資金額</th>
        <th>狀態</th>
        <th>動作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.wallet_id}}
          </td>
          <td>
            {{list.surplus_amount}}
          </td>
          <td>
            {{list.ecology_amt}}
          </td>
          <td>
            <span style="color: red" v-show="list.is_freeze == 1">凍結</span>
            <span style="color: #0088bb" v-show="list.is_freeze == 0">正常</span>
          </td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('superNodeRewardDetails', {wallet_id: list.wallet_id})">轉帳記錄</span>
            <el-button
                type="primary"
                @click="handleFreeze(list.wallet_id)"
            >
            <span v-show="list.is_freeze == 1">解冻</span>
            <span v-show="list.is_freeze == 0">凍結</span>
            </el-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" title="凍結錢包" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">管理员ID:</div>
          <div class="com_from_right disabled" >
            <el-input
                v-model="adminID"
                placeholder=""
                type="text"
                size="small" disabled>
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">凍結錢包ID:</div>
          <div class="com_from_right disabled" >
            <el-input
                v-model="walletID"
                placeholder=""
                type="text"
                size="small" disabled>
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right" >
            <el-input
                v-model="two_auth_code"
                placeholder="请输入"
                type="text"
                size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary" @click="submitFn">提交</el-button>
              </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "cash_coupon_transfer_record",
  data() {
    return {
      adminID: '',
      walletID: '',
      totalNode: 0,
      page: 1,
      size: 10,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      selectVal1: "",
      selectVal2: "",
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "wallet_id",
        "surplus_amount",
        "ecology_amt"
      ],
      isAlert: false,
      curIndex: 0,
      curType: '',
      option1: [{label: '全额创建', value: 1},{label: '10%认购费用创建', value: 2}],
      title: '',
      two_auth_code : '',
      area: '',
      introduction: '',
      type: '',
      typeObj: {
        1: {label: '确认中', value: '1'},
        2: {label: '认购中', value: '2'},
        3: {label: '交易失败-无需退款', value: '3'},
        4: {label: '失败-认购数量超出需退款', value: '4'},
        5: {label: '已完成', value: '5'},
        6: {label: '失败-认购不足需退款', value: '6'},
      },
    };
  },
  created() {
    this.resInit();
    this.adminID = window.localStorage.getItem('userName') ? window.localStorage.getItem('userName') : ''
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "狀態" || item.innerText == "動作" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
    title (val) {
      this.title = val.replace(/[^\a-\z\A-\Z\s]/g,'')
    },
  },
  methods: {
    handleFreeze(wallet_id) {
      this.walletID = wallet_id
      this.isAlert = true
    },
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.title = ''
      this.two_auth_code = ''
      this.introduction = ''
      this.area = ''
      this.type = ''
      if (type == 'edit') {
        this.curIndex = index
        this.title = this.resData[index].title
        this.introduction = this.resData[index].introduction
        this.type = this.resData[index].type
      }
    },
    submitFn() {
      const req = {
        // admin_id: this.adminID,
        wallet_id: this.walletID,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}super/super_node_reward_freeze`, req, 'post')
          .then(res => {
            this.two_auth_code = ''
            this.isAlert = false
            this.resInit()
            this.$message.success('提交成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    statusTx (type) {
      if (type == 1) {
        return '确认中'
      }
      if (type == 2) {
        return '认购中'
      }
      if (type == 3) {
        return '交易失败-无需退款'
      }
      if (type == 4) {
        return '失败-认购数量超出需退款'
      }
      if (type == 5) {
        return '已完成'
      }
      if (type == 6) {
        return '失败-认购不足需退款'
      }
      return type
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {

      let req = {
        page: this.page,
        size: this.size,
        wallet_id: ''
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      this.$ajax(`${this.Utils.URL.goURL}super/super_node_reward_summary_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            // this.subtotal = res.subtotal ? res.subtotal : {}
            // this.sum = res.sum ? res.sum : {}
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
.com-table .com_tbody .tab_btn span {
  display: inline-block;
}
.com-table .com_tbody .tab_btn .btn {
  color: rgb(0, 136, 187);
  margin-right: 10px;
}
.com-table .com_tbody .tab_btn .el-button {
  min-height: 32px;
  padding: 0.05rem 0.5rem;
}
.el-input.is-disabled /deep/ input{
  color: black;
}
</style>