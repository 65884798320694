<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>



    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">对账日期</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D"
              value-format="YYYY-M-D"
              placeholder="选择日期">
          </el-date-picker>
          -
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D"
              value-format="YYYY-M-D"
              placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>



      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>


    <div class="com_search_box mt_40">


      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>

    </div>


    <div>

      <table class="com-table mt_10">
        <thead class="com_thead">
        <th>序号</th>
        <th>对账日期</th>
        <th>对账数据范围(开始)</th>
        <th>对账数据范围(结束)</th>
        <th>币种</th>
        <th>链类型</th>
        <th>总充值</th>
        <th>跨链退款</th>
        <th>总提币</th>
        <th>跨链转出</th>
        <th>Swap兑换消耗</th>
        <th>链上共管地址余额</th>
        <th>链上撮合池余额</th>
        <th>误差值</th>


        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.datestr}}</td>
          <td>{{list.start_at}}</td>
          <td>{{list.end_at}}</td>
          <td>{{list.coin_symbol}}</td>
          <td>{{list.chain_type}}</td>
          <td>{{scientificNotationToString(list.recharge_num)}}</td>
          <td>{{scientificNotationToString(list.cross_refund)}}</td>
          <td>{{scientificNotationToString(list.withdraw_num)}}</td>
          <td>{{scientificNotationToString(list.cross_out)}}</td>
          <td class="tab_btn">
              <span class="btn" @click="goRouter('/mechanismProfitExcel',{symbol: list.coin_symbol, endTime: list.datestr, startTime: list.datestr, chain_type: list.chain_type  })">{{scientificNotationToString(list.borrow_loss)}}</span>
          </td>
          <td>{{scientificNotationToString(list.borrow_reserve)}}</td>
          <td>{{scientificNotationToString(list.pool_reserve)}}</td>
          <td>{{scientificNotationToString(list.difference)}}</td>



        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.recharge_num)}}</td>
          <td>{{scientificNotationToString(subtotal.cross_refund)}}</td>
          <td>{{scientificNotationToString(subtotal.withdraw_num)}}</td>
          <td>{{scientificNotationToString(subtotal.cross_out)}}</td>
          <td>{{scientificNotationToString(subtotal.borrow_loss)}}</td>
          <td>{{scientificNotationToString(subtotal.borrow_reserve)}}</td>
          <td>{{scientificNotationToString(subtotal.pool_reserve)}}</td>
          <td>{{scientificNotationToString(subtotal.difference)}}</td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(sum.recharge_num)}}</td>
          <td>{{scientificNotationToString(sum.cross_refund)}}</td>
          <td>{{scientificNotationToString(sum.withdraw_num)}}</td>
          <td>{{scientificNotationToString(sum.cross_out)}}</td>
          <td>{{scientificNotationToString(sum.borrow_loss)}}</td>
          <td>{{scientificNotationToString(sum.borrow_reserve)}}</td>
          <td>{{scientificNotationToString(sum.pool_reserve)}}</td>
          <td>{{scientificNotationToString(sum.difference)}}</td>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


  </article>
</template>

<script>
export default {
  name: "fundCheckh",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      inputVal5: "",
      inputVal6: "",
      selectVal1: "",
      selectVal2: "USDT",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: this.dataFormat((new Date().getTime() / 1000) - 86400, 'yyyy-M-d'),
      endTime: this.dataFormat(new Date().getTime() / 1000, 'yyyy-M-d'),
      sum: {},
      typeObj: {
        1: {label: '返还投资金额', value: '1'},
        2: {label: '返还用户质押资金', value: '2'},
        3: {label: '返还认购合伙人资金', value: '3'},
        4: {label: '返还创建合伙人资金', value: '4'},
        5: {label: '合伙人上下级关系奖励', value: '5'},
        6: {label: '投资收益', value: '6'},
        7: {label: '提取收益', value: '7'},
        8: {label: '解锁合伙人收益', value: '8'},
        9: {label: '释放认购合伙人资金', value: '9'},
        10: {label: '借贷池返还多余主币给借币者', value: '10'},
        11: {label: '管理后台转出撮合池资产', value: '11'},
        12: {label: 'fth兑换usdt交易', value: '12'},
        13: {label: '监管节点发放fth', value: '13'},
        17: {label: '聚餐活动奖励', value: '17'},
        26: {label: '新年送红包', value: '26'},
      },
      two_auth_code: '',
      num: '',
      isAlert: false,
      checkArr: [],
      filterVal: [
        "datestr",
        "start_at",
        "end_at",
        "coin_symbol",
        "chain_type",
        "recharge_num",
        "cross_refund",
        "withdraw_num",
        "cross_out",
        "borrow_loss",
        "borrow_reserve",
        "pool_reserve",
        "difference",
      ],

    };
  },
  created() {

    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.addr = this.inputVal1;
      }
      if (this.inputVal2) {
        req.multi_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.inputVal4) {
        req.min_amount = this.inputVal4
      }
      if (this.inputVal5) {
        req.max_amount = this.inputVal5
      }
      if (this.inputVal6) {
        req.user_id = this.inputVal6
      }
      if (this.selectVal1) {
        req.chain_type = this.selectVal1;
      }
      if (this.selectVal2) {
        req.chain_symbol = this.selectVal2;
      }
      if (this.selectVal3) {
        req.borrow_state = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}fund_check`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.total = parseFloat(res.total);
            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.end_at = this.dataFormat(list.end_at)
              list.start_at = this.dataFormat(list.start_at)


            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>