<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">任务编号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">团队长钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>




      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="resInit('all')"
      >
        导出excel
      </el-button>
    </div>



    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>计划任务编号</th>
        <th>团队长钱包ID</th>
        <th>团队标签</th>
        <th>團隊當前捐贈</th>
        <th>入金数据</th>
        <th>出金数据</th>
        <th>净入金数据</th>
        <th>OTC买币数据</th>
        <th>关联上级钱包ID</th>
        <th>日期</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.number_id}}</td>
          <td>{{list.wallet_id}}</td>
          <td>{{list.team_type_tx}}</td>
          <td>{{list.invest_amount}} USDT</td>
          <td>{{list.recharge}} USDT</td>
          <td>{{list.withdraw}} USDT</td>
          <td>{{list.net_income}} USDT</td>
          <td>{{list.otc_buy_coin}} USDT</td>
          <td>{{ (list.parent_id) }}</td>
          <td>{{ (list.created_at) }}</td>

        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


  </article>
</template>

<script>
export default {
  name: "zlb-data-report",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      selectVal1: "",
      startTime: "",
      endTime: "",
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "number_id",
        "wallet_id",
        "team_type_tx",
        "invest_amount",
        "recharge",
        "withdraw",
        "net_income",
        "otc_buy_coin",
        "parent_id",
        "created_at",
      ],
      isAlert: false,
      isRemove: false,
      curIndex: 0,
      curType: '',
      option1: [{label: '全额创建', value: 1}],
      title: '',
      two_auth_code : '',
      wallet_id: '',
      type: '',
      typeObj: {
        1: {label: '俱乐部', value: '1'},
        2: {label: '工作室', value: '2'},
        3: {label: '普通用户', value: '3'},
        4: {label: '临时标签', value: '4'},
      },
      exportData: []
    };
  },
  created() {
    if (this.$route.query.id) {
      this.inputVal1 = this.$route.query.id
      this.resInit()
    }


  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
    title (val) {
      this.title = val.replace(/[^\a-\z\A-\Z\s]/g,'')
    },
  },
  methods: {
    exportAllFn () {

    },
    showRemove (index) {
      this.curIndex = index
      this.two_auth_code = ''
      this.isRemove = true
    },
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.two_auth_code = ''
      this.wallet_id = ''
      this.type = ''
      if (type == 'edit') {
        this.curIndex = index
        this.wallet_id = this.resData[index].wallet_id
        this.type = this.resData[index].user_type
      }
    },
    removeSubmitFn() {
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }


      let req = {
        two_auth_code: this.two_auth_code,
        id: this.resData[this.curIndex].id
      }
      let url = 'remove_team_club'


      /*this.$confirm('是否新增/编辑机构用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {*/
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.two_auth_code = ''
            this.isRemove = false
            this.resInit()
            this.$message.success('移除成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      /*}).catch(() => {

      });*/

    },
    submitFn() {
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      if (this.type === '') {
        this.$message.error('请选择团队标签')
        return false
      }
      let req = {
        two_auth_code: this.two_auth_code,
        user_type: this.type,
      }
      let url
      if (this.curType == 'edit') {
        url = 'edit_team_club'
        req.id = this.resData[this.curIndex].id
      } else {
        url = 'add_team_club'
        if (this.wallet_id === '') {
          this.$message.error('请输入钱包ID')
          return false
        }
        req.wallet_id_list = this.wallet_id
      }

      /*this.$confirm('是否新增/编辑机构用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {*/
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.two_auth_code = ''
            this.wallet_id = ''

            this.type = ''
            this.isAlert = false
            this.resInit()
            this.$message.success('提交成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      /*}).catch(() => {

      });*/

    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit(export_type) {

      let req = {
        page: this.page,
        size: this.size,
      };
      if (export_type == 'all') {
        req.export_type = 'all'
      }
      if (this.inputVal1 == '') {
        this.$message.error('请输入任务编号');
        return false
      }
      if (this.inputVal1) {
        req.number_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.selectVal1) {
        req.user_type = this.selectVal1;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}club_net_income_list`, req, "get", 500000000)
          .then((res) => {

            if (export_type == 'all') {
              this.exportData = res.list ? res.list : []
              this.exportData.forEach((list) => {

                list.team_type_tx = this.typeObj[list.team_type] ? this.typeObj[list.team_type].label : list.team_type
                //list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
                list.created_at = this.dataFormat(list.created_at)
              })
              this.exportExcel(this.tHeader, this.filterVal, this.exportData, this.$route.meta.title)
            } else {
              this.resData = res.list ? res.list : [];
              this.total = parseFloat(res.total);
              this.subtotal = res.subtotal ? res.subtotal : {}
              this.sum = res.sum ? res.sum : {}
              this.resData.forEach((list) => {

                list.team_type_tx = this.typeObj[list.team_type] ? this.typeObj[list.team_type].label : list.team_type
                //list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
                list.created_at = this.dataFormat(list.created_at)
              })
            }
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>