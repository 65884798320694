<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">商户名称</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">收款账号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">商户通道</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"

          @click="showLevelFn('edit')"
      >
        添加银行卡
      </el-button>
    </div>
    <div class="tab_box mt_40">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>收款货币</th>
        <th>汇率</th>
        <th>姓名</th>
        <th>商户通道</th>
        <th>银行名称</th>
        <th>支行名行</th>
        <th>卡号</th>
        <th>状态</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.currency}}</td>
          <td>{{list.price}}</td>
          <td>{{list.name}}</td>
          <td>{{list.channel}}</td>
          <td>{{list.bank_name}}</td>
          <td>{{list.bank_branch}}</td>
          <td>{{list.bank_account}}</td>
          <td>{{list.status ? '启用' : '禁用'}}</td>
          <td class="tab_btn">
            <span class="btn"  @click="showLevelFn(index)" >设置</span>
          </td>
        </tr>



        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curIndex == 'edit' ? '新增银行卡' : '修改银行卡'" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">商户通道</div>
          <div class="com_from_right">
            <el-input
                v-model="channel"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">银行名称</div>
          <div class="com_from_right">
            <el-input
                v-model="bank_name"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">支行名称(可选)</div>
          <div class="com_from_right">
            <el-input
                v-model="bank_branch"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">姓名</div>
          <div class="com_from_right">
            <el-input
                v-model="name"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">卡号</div>
          <div class="com_from_right">
            <el-input
                v-model="bank_account"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">收款币种</div>
          <div class="com_from_right">
            <el-select
                v-model="currency"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option :label="list.currency_name + '(' + list.currency  + ')'" :value="list.currency" v-for="list in currencyList"></el-option>

            </el-select>
            <p>提示: 每种货币只能有一张银行卡收款</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">汇率设置(可选)</div>
          <div class="com_from_right">
            <el-input
                v-model="price"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
            <p >参考价格 {{visitData[currency]}} {{currency}} = 1 USDT</p>

          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">状态</div>
          <div class="com_from_right">
            <el-select
                v-model="status"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option label="启用" :value="true">启用</el-option>
              <el-option label="禁用" :value="false">禁用</el-option>

            </el-select>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="submitFn">提交</el-button>
                </span>
      </template>
    </el-dialog>



  </article>
</template>

<script>
export default {
  name: "merchantBankList",
  data() {
    return {
      resData: [],
      selectArr: [],
      selectVal1: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      curIndex: 0,
      curType: '',
      page: 1,
      size: 10,
      total: '',
      selectVal2: '',
      startTime: '',
      endTime: '',
      isAlert: false,
      two_auth_code: '',
      bank_account: '',
      price: '',
      bank_branch: '',
      channel: '',
      bank_name: '',
      currency: '',
      name: '',
      status: '',
      bank_id: '',
      currencyList: [],
      visitData: {}
    }
  },
  created() {
    this.currencyListFn()
    this.resInit()
  },
  mounted() {

  },
  methods: {
    currencyListFn () {
      this.$ajax(`${this.Utils.URL.goURL}buy/admin_select_currency`, {}, 'get')
          .then(res => {
            this.currencyList = res.list ? res.list : []
            this.currencyList.forEach(val => {
              this.visitData[val.currency] = val.visit_price
            })

          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn () {


      if (this.bank_account === "") {
        this.$message.error("请输入卡号");
        return false;
      }
      if (this.channel === "") {
        this.$message.error("请输入商户通道");
        return false;
      }
      if (this.bank_name === "") {
        this.$message.error("请输入银行名称");
        return false;
      }
      if (this.currency === "") {
        this.$message.error("请选择收款货币");
        return false;
      }
      if (this.name === "") {
        this.$message.error("请输入姓名");
        return false;
      }
      if (this.status === "") {
        this.$message.error("请选择开关状态");
        return false;
      }
      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }

      let req = {
        bank_account: this.bank_account,
        bank_branch: this.bank_branch,
        channel: this.channel,
        bank_name: this.bank_name,
        currency: this.currency,
        name: this.name,
        status: this.status,
        price: this.price,
        two_auth_code: this.two_auth_code,
        bank_id: this.bank_id,
      };
      let url = this.curIndex == 'edit' ? 'buy/add_bank' : 'buy/edit_bank'

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isAlert = false;
            this.$message.success("修改成功");
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    showLevelFn (index) {
      this.curIndex = index
      this.two_auth_code = ''
      if(this.curIndex == 'edit') {

        this.bank_account =  ''
        this.bank_branch = ''
        this.channel = ''
        this.bank_name = ''
        this.currency = ''
        this.name = ''
        this.status = ''
        this.bank_id = ''
        this.price = ''
      } else {
        this.price =  this.resData[index].price
        this.bank_account =  this.resData[index].bank_account
        this.bank_branch =  this.resData[index].bank_branch
        this.channel =  this.resData[index].channel
        this.bank_name =  this.resData[index].bank_name
        this.currency =  this.resData[index].currency
        this.name =  this.resData[index].name
        this.status =  this.resData[index].status
        this.bank_id =  this.resData[index].bank_id
      }
      this.isAlert = true
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },

    resInit() {
      let req = {
        page: this.page,
        size: this.size,

      }
      if (this.inputVal1) {
        req.name = this.inputVal1
      }
      if (this.inputVal2) {
        req.bank_account = this.inputVal2
      }
      if (this.inputVal3) {
        req.channel = this.inputVal3
      }
      if (this.selectVal1) {
        req.level = this.selectVal1
      }

      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}buy/bank_list`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []

          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  },
}
</script>

<style scoped>

</style>