<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">手续费地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">OTC合约地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <h3 class="mt_40">OTC老合约转出收款地址：{{addData.addr}}</h3>
    <section class="com_search_box">

      <div class="com_item_search">
        <el-button type="primary"  size="small" @click="showFn('')">修改收款地址</el-button>
      </div>
    </section>
    <div class="">
      <table class="com-table  mt_20">
        <thead class="com_thead">
        <th>序号</th>
        <th>链类型</th>
        <th>手续费地址</th>
        <th>地址资产</th>
        <th>OTC合约卖币钱包地址</th>
        <th>OTC钱包资产</th>
        <th>合约地址状态</th>
        <th>OTC地址创建时间</th>

        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.chain_type}}</td>
          <td>{{list.fee_addr}}</td>
          <td>{{list.fee_amount}}{{list.fee_symbol}}</td>
          <td>{{list.otc_addr}}</td>
          <td>
            <p v-for="fin in list.otc_wallet_list">
              {{fin.amount}} {{fin.symbol}}
            </p>
          </td>
          <td>{{list.is_enable_tx}}</td>
          <td>{{list.created_at}}</td>

        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isShow" :title="'修改收款地址' " width="500px">
      <section class="com_from_box">


        <div class="com_from_item">
          <div class="com_from_left">收款地址</div>
          <div class="com_from_right">
            <el-input
                v-model="addr"
                type="text"
                placeholder="请输入"
                size="small"
                style="width: 300px"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isShow = false">取消</el-button>
                    <el-button type="primary" @click="editRateFn">提交</el-button>
                </span>
      </template>
    </el-dialog>




  </article>
</template>

<script>
export default {
  name: "financeOtcContract",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      options: [],
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      expenses: '',
      reason: '',
      min: '',
      max: '',
      addr: '',
      isShow: false,
      passShow: false,
      addData: {},
      filterVal: [
        "chain_type",
        "fee_addr",
        "fee_amount",
        "otc_addr",
        "otc_wallet_list",
        "is_enable_tx",
        "created_at",
      ],
    };
  },
  created() {

    this.resInit();
    this.addInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showFn (index, type) {

      this.two_auth_code = ''
      this.addr = ''

      this.isShow = true
    },
    editRateFn () {

      if (this.addr === '') {
        this.$message.error('请输入收款地址')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'otc/set_otc_collect_addr'
      let req = {
        addr: this.addr,
        two_auth_code: this.two_auth_code,
      }


      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.$message.success('操作成功')
            this.resInit()
            this.isShow = false
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    addInit () {
      this.$ajax(`${this.Utils.URL.goURL}otc/otc_collect_addr`, {}, "get")
          .then((res) => {
            this.addData = res ? res : {};

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.fee_addr = this.inputVal1;
      }
      if (this.inputVal2) {
        req.otc_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.name = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.community_level = this.selectVal2;
      }
      if (this.selectVal3 !== '') {
        req.status = this.selectVal3;
      }
      if (this.selectVal4) {
        req.fail_status = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/otc_contract_addr_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.is_enable_tx = list.is_enable ? '已启用' : '未启用'
              list.created_at = this.dataFormat(list.created_at)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>