<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">终端</div>
        <div class="com_item_input">
          <el-select v-model="selectVal1" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">一级分类</div>
        <div class="com_item_input">
          <el-select v-model="selectVal2" clearable filterable placeholder="请选择" size="small" @change="selectFn">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">二级分类</div>
        <div class="com_item_input">
          <el-select v-model="selectVal3" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">标题</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box" v-if="isEditButton">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="goRouter('/articleListEdit')">新增文章</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
          <th>序号</th>
          <th>ID</th>
          <th>终端</th>
          <th>分类</th>
          <th>二级分类</th>
          <th>标题</th>
          <th>排序</th>
          <th>创建时间</th>
          <th>操作</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ page == 1 ? index + 1 : index == 0 ? ((page - 1) * 10) + 1 : ((page - 1) * 10) + index + 1 }}</td>
            <td>{{ list.id }}</td>
            <td>{{ list.port }}</td>
            <td>{{ list.one_type_zh }}</td>
            <td>{{ list.two_type_zh }}</td>
            <td>
              <p>{{ list.title_zh }}</p>
            </td>
            <td>{{ list.sort }}</td>
            <td>{{ dataFormat(list.created_at) }}</td>
            <td class="tab_btn">
              <span class="btn" @click="goDetails(index)" v-if="isEditButton">编辑</span>
              <span class="btn" @click="delFn(index)" v-if="isDelButton">删除</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination layout="pager" :total="total" :current-page="page" @current-change="pageFn">
      </el-pagination>
    </div>


  </article>
</template>

<script>
export default {
  name: "articleList",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      startTime: '',
      endTime: '',
      selectArr: [{ value: '钱包', label: '钱包' }]
    }
  },
  created() {
    // this.termInalFn()
    // this.selectFn(1, 1)
    this.options1 = JSON.parse(window.sessionStorage.getItem('portList'))
    this.options2 = JSON.parse(window.sessionStorage.getItem('oneArticleClass'))
    this.resInit()
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    goDetails(index) {
      // window.sessionStorage.setItem('listDataInfo', JSON.stringify(this.resData[index]))
      // this.goRouter('articleListEdit', {type: 'edit'})
      this.page = 1
      this.getDetailByEdit(this.resData[index].id)
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.port_id = this.selectVal1
      }
      if (this.selectVal2) {
        req.one_type = this.selectVal2
      }
      if (this.selectVal3) {
        req.two_type = this.selectVal3
      }
      if (this.inputVal1) {
        req.title = this.inputVal1
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}article_summary`, req, 'get')
        // this.$ajax(`${this.Utils.URL.goURL}article_list`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    delFn(index) {
      this.curIndex = index
      this.$confirm('是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}delete_article`, {
          type_id: 1,
          id: this.resData[index].id
        }, 'post')
          .then(res => {
            this.resData.splice(index, 1)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      }).catch(() => {

      });
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
    termInalFn() {
      let req = {
        type_content_id: 1
      }
      this.$ajax(`${this.Utils.URL.goURL}port_list`, req, 'get')
        .then(res => {
          let arr = res ? res : []
          let arr2 = []
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.port
            }
          })
          this.options1 = arr2

        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    selectFn(vals, type = 2) {
      if (!vals) {
        return false
      }
      let req = {
        type_content_id: '1'
      }
      let url = 'type_one_list'
      if (type == 2) {
        url = 'type_two_list'
        req.type_id = this.selectVal2
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
        .then(res => {
          let arr = type == 1 ? res ? res : [] : res.list ? res.list : []
          let arr2 = []
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.type_zh
            }
          })
          if (type == 1) {
            this.options2 = arr2
          } else {
            this.options3 = arr2
          }
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    getDetailByEdit(id) {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.port_id = this.selectVal1
      }
      if (this.selectVal2) {
        req.one_type = this.selectVal2
      }
      if (this.selectVal3) {
        req.two_type = this.selectVal3
      }
      if (this.inputVal1) {
        req.title = this.inputVal1
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}article_list?id=${id}`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []
          if (this.resData.length > 0) {
            window.sessionStorage.setItem('listDataInfo', JSON.stringify(this.resData[0]))
            this.goRouter('articleListEdit', { type: 'edit' })
          }
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }

}
</script>

<style scoped></style>