<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">转出地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">收款地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">转账状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>转出地址</th>
        <th>转出币种</th>
        <th>转出数量</th>
        <th>收款地址</th>
        <th>获得币种</th>
        <th>获得数量</th>
        <th>交易哈希</th>
        <th>转账时间</th>
        <th>转账状态</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.withdraw_addr}}</td>
          <td>{{list.deposit_coin_code}}</td>
          <td>{{scientificNotationToString(list.deposit_coin_amt)}}</td>
          <td>{{list.destination_addr}}</td>
          <td>{{list.receive_coin_code}}</td>
          <td>{{scientificNotationToString(list.receive_coin_amt)}}</td>
          <td>{{list.tx_id}}</td>
          <td>{{list.complete_time}}</td>
          <td>{{list.exec_state}}</td>

        </tr>

        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "batchTransferList",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      inputVal5: "",
      inputVal6: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      checkArr: [],
      typeObj: {
        'wait': {label: '待发起', value: 'wait'},
        'confirm': {label: '已发起', value: 'confirm'},
        'success': {label: '成功', value: 'success'},
        'fail': {label: '失败', value: 'fail'},
      },
      filterVal: [
        "withdraw_addr",
        "deposit_coin_code",
        "deposit_coin_amt",
        "destination_addr",
        "receive_coin_code",
        "receive_coin_amt",
        "tx_id",
        "complete_time",
        "exec_state",
      ],
    };
  },
  created() {

    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.withdraw_addr = this.inputVal1;
      }
      if (this.inputVal2) {
        req.destination_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.inputVal4) {
        req.min_amount = this.inputVal4
      }
      if (this.inputVal5) {
        req.max_amount = this.inputVal5
      }
      if (this.inputVal6) {
        req.user_id = this.inputVal6
      }
      if (this.selectVal1) {
        req.status = this.selectVal1;
      }
      if (this.selectVal2) {
        req.exec_state = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}trade/transfer_plan`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.exec_state = this.typeObj[list.exec_state] ? this.typeObj[list.exec_state].label : list.exec_state
              list.complete_time = this.dataFormat(list.complete_time)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>