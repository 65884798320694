<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    
      <section class="com_from_box">

        <div class="com_from_item">
          <div class="com_from_left">用戶錢包ID:</div>
          <div class="com_from_right" style="min-width: 350px;">
            <el-input v-model="user_id" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">FUSD數量:</div>
          <div class="com_from_right"  style="min-width: 350px;">
            <el-input v-model="amount" type="number" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">質押週期選擇:</div>
          <div class="com_from_right radio_group">
            <div class="radio_group_head">
              <p>FUSD</p>
              <p>SCF</p>
            </div>
            <el-radio-group v-model="pledge_cycle_selection">
              <el-radio label="FUSD-30" size="large">30天1.2%</el-radio>
              <el-radio label="SCF-30" size="large">30天1.4%</el-radio>
              <el-radio label="FUSD-15" size="large">15天1.1%</el-radio>
              <el-radio label="SCF-15" size="large">15天1.2%</el-radio>
              <el-radio label="FUSD-7" size="large">7天 1.0%</el-radio>
              <el-radio label="SCF-7" size="large">7天 1.0%</el-radio>
            </el-radio-group>
          </div>
        </div>
        

        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
      </section>
        <span class="dialog-footer">
          <el-button type="primary" @click="submit">確認</el-button>
        </span>
  </article>
</template>

<script>
export default {
  name: "staking-op-otc",
  data() {
    return {
      user_id: '',
      amount: '',
      pledge_cycle_selection: '',
      two_auth_code: '',
    };
  },
  methods: {
    submit() {
      
      if (!this.user_id) {
        this.$message.error("请输入用户钱包ID");
        return;
      }
      if (!this.amount) {
        this.$message.error("请输入FUSD數量");
        return;
      }
      // min amout = 100
      if (this.amount < 100) {
        this.$message.error("FUSD數量最小為100");
        return;
      }
      if (!this.pledge_cycle_selection) {
        this.$message.error("请选择質押週期");
        return;
      }
      if (!this.two_auth_code) {
        this.$message.error("请输入谷歌验证码");
        return;
      }
      let req = {
        two_auth_code: this.two_auth_code, 
        user_id: this.user_id,
        amount: this.amount,
        duration_day: this.pledge_cycle_selection,
        operator: ''
      };
      // need clear all info when submit success
      this.$ajax(`${this.Utils.URL.goURL}otcLp`, req, "post")
        .then((res) => {
          if (res.result) {
            this.user_id = '';
            this.amount = '';
            this.pledge_cycle_selection = '';
            this.two_auth_code = '';
            this.$message.success('成功');
          } else {
            this.$message.error(res.msg);
          }
          
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    
  },
};
</script>

<style scoped lang="scss">
.radio_group {
  &_head {
    display: flex;
    p {
      flex-basis: 50%;
      margin-bottom: 5px;
    }
  }
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .el-radio {
      flex-basis: 50%;
      margin: 5px 0;
    }
}
}

</style>