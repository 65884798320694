<template>
  <article class="">
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">代币简称</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal2"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>

    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>币种</th>
        <th>链类型</th>
        <th>总额度</th>
        <th>剩余额度</th>
        <th>地址</th>
        <th>价格(USDT)</th>
        <th>时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.coin_symbol }}</td>
          <td>{{ list.chain_type }}</td>
          <td>{{ list.total_quota }}</td>
          <td>{{ list.reserve_quota }}</td>
          <td>{{ list.addr }}</td>
          <td>{{ list.price }}</td>
          <td>{{ dataFormat(list.created_at) }}</td>

          <td class="tab_btn">
            <span class="btn" @click="showAlertFn(index, 'price')">价格设置</span>
            <span class="btn" @click="showAlertFn(index, 'quota')">额度设置</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" title="设置价格" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">币种价格(USDT)</div>
          <div class="com_from_right d_flex_1">
            <el-input v-model="clean_threshold" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>

      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="editPassFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
    <el-dialog v-model="isQuota" title="设置额度" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">额度设置</div>
          <div class="com_from_right d_flex_1">
            <el-input v-model="quota" type="text" placeholder="请输入" size="small"></el-input>
          </div>
          <p>({{reserve_quota}} + {{quota}}) = {{yquota}}</p>
        </div>
        <div class="com_from_item">
          <div class="com_from_left"></div>
          <div class="com_from_right d_flex_1">
            <p>当前剩余额度: {{reserve_quota}}</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>

      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isQuota = false">取消</el-button>
                    <el-button type="primary" @click="editQuotaFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "tokenList",
  data() {
    return {
      options1: [{value: true, label: '是'}, {value: false, label: '否'}],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      startTime: '',
      endTime: '',
      typeObj: {
        'waitFlash': {label: '闪兑中', value: 'waitFlash'},
        'waitDeploy': {label: '发布中', value: 'waitDeploy'},
        'tokenSuccess': {label: '合约已发布,未添加流动性', value: 'tokenSuccess'},
        'deploySuccess': {label: '合约已发布,已添加流动性', value: 'deploySuccess'},

      },
      isQuota: false,
      isAlert: false,
      clean_threshold: '',
      reserve_quota: '',
      quota: '',
      two_auth_code: '',
      selectArr: [{value: '钱包', label: '钱包'}]
    }
  },
  computed: {
    yquota () {
      if (this.quota !== '') {
        return window.windowBigNumber(this.reserve_quota).plus(this.quota).toString()
      }
    }
  },
  created() {

    this.resInit()
  },
  methods: {
    showAlertFn(index, type) {
      this.curIndex = index


      this.two_auth_code = ''
      if (type == 'price') {
        this.clean_threshold = this.resData[this.curIndex].price
        this.isAlert = true
      } else {
        this.quota = ''
        this.reserve_quota = this.resData[this.curIndex].reserve_quota
        this.isQuota = true
      }

    },
    editPassFn() {
      if (this.clean_threshold === "") {
        this.$message.error("请输入币种价格");
        return false;
      }

      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {
        id: this.resData[this.curIndex].id,
        price: (this.clean_threshold ),
        two_auth_code: this.two_auth_code,

      };
      let url = "pledge/set_price";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isAlert = false;
            this.resData[this.curIndex].price = (this.clean_threshold)
            this.$message.success("修改成功");
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    editQuotaFn() {
      if (this.quota === "") {
        this.$message.error("请输入额度");
        return false;
      }

      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {
        id: this.resData[this.curIndex].id,
        quota: this.quota,
        two_auth_code: this.two_auth_code,

      };
      let url = "pledge/quota";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isQuota = false;
            this.resInit()
            this.$message.success("修改成功");
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.state = this.selectVal1
      }
      if (this.selectVal2) {
        req.chain_type = this.selectVal2
      }
      if (this.inputVal1) {
        req.token_name = this.inputVal1
      }
      if (this.inputVal2) {
        req.symbol = this.inputVal2
      }
      if (this.inputVal3) {
        req.owner_addr = this.inputVal3
      }
      if (this.startTime) {
        req.begin = this.dataFormat((new Date(this.startTime)).getTime() / 1000)
      }
      if (this.endTime) {
        req.end = this.dataFormat((new Date(this.endTime)).getTime() / 1000)
      }

      this.$ajax(`${this.Utils.URL.goURL}pledge/list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },


  }

}
</script>

<style scoped>

</style>