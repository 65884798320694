<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box" >
      <div class="com_item_search">
        <el-button type="primary"  size="small" @click="showAlertFn('', 'add')">一键回收多签钱包资金</el-button>
      </div>
    </div>
    <div class="tab_box mt_40">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>链类型</th>
        <th>币种</th>
        <th>地址内资产数</th>
<!--        <th>Swap内资产数</th>-->
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.chain_type}}</td>
          <td>{{list.symbol}}</td>
          <td>{{list.amount}}</td>
<!--          <td>{{list.swap_amount}}</td>-->
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="passShow" title="回收多签钱包资金" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="passShow = false">取消</el-button>
                    <el-button type="primary" @click="editPassFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "institutionLoanAsset",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      inputVal2: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      selectArr: [{value: '钱包', label: '钱包'}],
      passShow: false,
      two_auth_code: '',
    }
  },
  created () {
    this.resInit()
  },
  methods: {
    showAlertFn () {
      this.two_auth_code = ''
      this.passShow = true
    },
    editPassFn() {
      let isTrue = false
      this.resData.forEach(val => {
        if (val.amount > 0) {
          isTrue = true
        }
      })
      if (!isTrue) {
        this.$message.error("地址资产余额不足");
        return false;
      }

      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {
        ad_id: this.$route.query.id,
        two_auth_code: this.two_auth_code,

      };
      let url = "organ/wallet_fin_recovery";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.passShow = false;
            this.$message.success("成功");
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    resInit () {
      let req = {
        addr: this.$route.query.addr,
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.port_id = this.selectVal1
      }
      if (this.selectVal2) {
        req.one_type = this.selectVal2
      }
      if (this.selectVal3) {
        req.two_type = this.selectVal3
      }
      if (this.inputVal1) {
        req.title = this.inputVal1
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/multi_addr_amount_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    pageFn (index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>