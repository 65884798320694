<template>
  <article class="">
    <h2 class="com_h2_title">{{ resData?.nickname }} 的委托訂單</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          用戶錢包ID
        </div>
        <div class="com_from_right">
          {{ resData?.user_id }}
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          剩余额度
        </div>
        <div class="com_from_right">
          {{ resData?.remaining_purchase_amount }} FSU
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          販賣數量
        </div>
        <div class="com_from_right">
          <el-input
            v-model="sell_amount"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
        <p class="link"><a @click="handleAll">全部</a></p>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          谷歌认证码
        </div>
        <div class="com_from_right">
          <el-input
            v-model="two_auth_code"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "otc-fsu-sales",
  data() {
    return {
      sell_amount: '',
      two_auth_code: '',
      resData: {},
    }
  },
  created() {
    if (!this.$route.query.order_id) {
      this.goRouter('otcCommissionOrderList')
    }
    this.resInit()
  },
  methods: {
    handleAll() {
      this.sell_amount = this.resData.remaining_purchase_amount
    },
    resInit () {
      let req = {
        order_id: this.$route.query.order_id
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/system_sell_config`, req, 'get')
          .then(res => {
            this.resData = res ? res : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn() {
      if (this.sell_amount === "") {
        this.$message.error("请输入认购数量");
        return false;
      }
      // sell_amount must be greater than 0
      if (+this.sell_amount <= 0) {
        this.$message.error("认购数量必须大于0");
        return false;
      }
      // check if sell_amount is greater than remaining_purchase_amount
      if (+this.sell_amount > +this.resData.remaining_purchase_amount) {
        this.$message.error("认购数量不能大于剩余额度");
        return false;
      }
      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {

        order_id: +this.$route.query.order_id,
        sell_amount: this.sell_amount,
        two_auth_code: this.two_auth_code,
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/system_order_sell`, req, 'post')
        .then(res => {
          this.$message.success('认购成功')
          this.goRouter('otcCommissionOrderList')
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },

  }
}
</script>

<style scoped>
.link {
  margin-left: 10px;
  color: rgb(0, 136, 187);
}
.link a {
  cursor: pointer;
}
.link a:hover {
  text-decoration: underline;
}
</style>