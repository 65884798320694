<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">变更类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">理财券面额</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, index) in ticketInfo"
                :key="index"
                :label="item.amount + '-' + item.days + '天'"
                :value="item.amount"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>

    <section class="com_search_box">

      <div class="com_item_search">
        <el-button type="primary"  size="small" @click="addTicket">赠送理财券</el-button>
        <el-button type="primary"  size="small" @click="showRemoveFn">移除理财券</el-button>
      </div>
    </section>

    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>钱包ID</th>
        <th>变更类型</th>
        <th>理财券面额</th>
        <th>变更数量</th>
        <th>剩余数量</th>
        <th>日期</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.wallet_id}}</td>
          <td>{{list.type}}</td>
          <td>{{list.amount}}</td>
          <td>{{scientificNotationToString(list.chang_numb)}}</td>
          <td>
            <p>500u-1天理财券: {{scientificNotationToString(list.fund_ticket_500)}}</p>
            <p>200u-1天理财券: {{scientificNotationToString(list.fund_ticket_200)}}</p>
            <p>100u-1天理财券: {{scientificNotationToString(list.fund_ticket_100_1)}}</p>
            <p>100u-7天理财券: {{scientificNotationToString(list.fund_ticket_100)}}</p>
          </td>
          <td>{{(list.time)}}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.amount)}}</td>
          <td> {{scientificNotationToString(subtotal.chang_numb)}}</td>
          <td>
            <p>500u-1天理财券: {{scientificNotationToString(subtotal.fund_ticket_500)}}</p>
            <p>200u-1天理财券: {{scientificNotationToString(subtotal.fund_ticket_200)}}</p>
            <p>100u-7天理财券: {{scientificNotationToString(subtotal.fund_ticket_100_1)}}</p>
            <p>100u-1天理财券: {{scientificNotationToString(subtotal.fund_ticket_100)}}</p>
          </td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.amount)}}</td>
          <td> {{scientificNotationToString(sum.chang_numb)}}</td>
          <td>
            <p>500u-1天理财券: {{scientificNotationToString(sum.fund_ticket_500)}}</p>
            <p>200u-1天理财券: {{scientificNotationToString(sum.fund_ticket_200)}}</p>
            <p>100u-7天理财券: {{scientificNotationToString(sum.fund_ticket_100_1)}}</p>
            <p>100u-1天理财券: {{scientificNotationToString(sum.fund_ticket_100)}}</p>
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" :title="'赠送理财券'" :width="750">
      <div class="com_search_box">
        <div>


        <div class="com_item_search">
          <div class="com_item_tx">钱包ID</div>
          <div class="com_item_input" style="width: 250px">
            <el-input
                v-model="wallet_id"
                rows="7"
                type="textarea"
                placeholder="请输入"
                size="small"
                @blur="walletFn"
            ></el-input>
            <span>(多个钱包ID请使用英文逗号分隔或空格)</span>
          </div>
        </div>
          <div class="com_item_search">
          <div class="com_item_tx">理财券面额</div>
          <div class="com_item_input" style="width: 250px">
            <el-select
                v-model="ticketVal"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="(item, index) in ticketInfo"
                  :key="index"
                  :label="item.amount + 'USDT-' + item.days +'天'"
                  :value="item.amount +'|'+item.days"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">赠送数量</div>
          <div class="com_item_input" style="width: 250px">
            <el-input
                v-model="ticket_num"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">谷歌验证码</div>
          <div class="com_item_input" style="width: 250px">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary"  @click="submitTicketFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isRemoveAlert" :title="'移除理财券'" :width="750">
      <div class="com_search_box">
        <div>


          <div class="com_item_search">
            <div class="com_item_tx">钱包ID</div>
            <div class="com_item_input mr_10" style="width: 250px">
              <el-input
                  v-model="wallet_id"
                  type="text"
                  placeholder="请输入"
                  size="small"
              ></el-input>
            </div>
            <el-button type="primary"  size="small" @click="ticketUserFn">查询</el-button>
          </div>
        </div>
      </div>
      <div class="com_search_box">
        <div>
          <h2 class="mt_10">查询结果: </h2>
          <h4 class="mt_10">钱包ID: {{tickUserData.wallet_id}}</h4>

          <div class="com_item_search mt_10">
            <div class="com_item_tx">500U-1天理财券</div>
            <div class="com_item_tx">持有数量: {{ tickUserData.fund_ticket_500 }}</div>
            <div class="com_item_input" style="width: 250px">
              <el-input
                  v-model="u500"
                  type="text"
                  placeholder="请输入移除数量"
                  size="small"
              ></el-input>
            </div>
          </div>
          <div class="com_item_search mt_10">
            <div class="com_item_tx">200U-1天理财券</div>
            <div class="com_item_tx">持有数量: {{ tickUserData.fund_ticket_200 }}</div>
            <div class="com_item_input" style="width: 250px">
              <el-input
                  v-model="u200"
                  type="text"
                  placeholder="请输入移除数量"
                  size="small"
              ></el-input>
            </div>
          </div>

          <div class="com_item_search mt_10">
            <div class="com_item_tx">100U-1天理财券</div>
            <div class="com_item_tx">持有数量: {{ tickUserData.fund_ticket_100_1 }}</div>
            <div class="com_item_input" style="width: 250px">
              <el-input
                  v-model="u100_1"
                  type="text"
                  placeholder="请输入移除数量"
                  size="small"
              ></el-input>
            </div>
          </div>
          <div class="com_item_search mt_10">
            <div class="com_item_tx">100U-7天理财券</div>
            <div class="com_item_tx">持有数量: {{ tickUserData.fund_ticket_100 }}</div>
            <div class="com_item_input" style="width: 250px">
              <el-input
                  v-model="u100"
                  type="text"
                  placeholder="请输入移除数量"
                  size="small"
              ></el-input>
            </div>
          </div>
          <div class="com_item_search">
            <div class="com_item_tx">谷歌验证码</div>
            <div class="com_item_input" style="width: 250px">
              <el-input
                  v-model="two_auth_code"
                  type="text"
                  placeholder="请输入"
                  size="small"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isRemoveAlert = false">关闭</el-button>
                <el-button type="primary"  @click="submitRemoveTicketFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "financialVoucherDetails",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      tx_id: "",
      tHeader: [],
      subtotal: {},
      isAlert: false,
      isRemoveAlert: false,
      ticket_num: '',
      wallet_id: '',
      two_auth_code: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      typeObj: {
        1: {label: '合伙人赠送理财券', value: '1'},
        3: {label: '系统派发理财券', value: '3'},
        4: {label: '获得他人赠送理财券', value: '4'},
        5: {label: '使用理财券', value: '5'},
        6: {label: '赠给他人理财券', value: '6'},
        7: {label: '失效扣除理财券', value: '7'},
        8: {label: '活动赠送理财券', value: '8'},
        9: {label: '管理后台赠送理财券', value: '9'},
        10: {label: '活动获赠理财券并自动使用', value: '10'},
        11: {label: '后台移除理财券', value: '11'},
        12: {label: '新用户注册获得理财券', value: '12'},
      },
      filterVal: [
        "lending_addr",
        "multi_addr",
        "hash",
        "numb",
        "lending_type",
        "nick",
        "chain_type",
        "symbol",
        "lending_numb",
        "interest",
        "lending_rate",
        "start",
        "end",
      ],
      ticketInfo: [],
      ticketVal: '',
      u500: '',
      u200: '',
      u100: '',
      u100_1: '',
      tickUserData: {}
    };
  },
  created() {
    this.resInit();
    this.$ajax(`${this.Utils.URL.goURL}ticket_term`, {}, "get")
        .then((res) => {
          this.ticketInfo = res.list ? res.list : [];

        })
        .catch((res) => {
          this.$message.error(res.msg);
        });

  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showRemoveFn () {
      this.two_auth_code = ''
      this.wallet_id = ''
      this.u100 = ''
      this.u100_1 = ''
      this.u200 = ''
      this.u500 = ''
      this.tickUserData = {}
      this.isRemoveAlert = true

    },
    ticketUserFn () {
      if (!this.wallet_id) {
        this.$message.error('请填写钱包ID')
        return false
      }
      let req = {
        wallet_id: this.wallet_id
      }
      this.$ajax(`${this.Utils.URL.goURL}user_ticket`, req, "get")
          .then((res) => {
            this.tickUserData = res ? res : {}
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
    submitRemoveTicketFn () {
      if (!this.wallet_id) {
        this.$message.error('请填写钱包ID')
        return false
      }
      let url = 'take_off_ticket'
      let req = {
        wallet_id: this.wallet_id,
        fund_ticket_500: this.u500 ? this.u500 : 0,
        fund_ticket_200: this.u200 ? this.u200 : 0,
        fund_ticket_100: this.u100 ? this.u100 : 0,
        fund_ticket_100_1: this.u100_1 ? this.u100_1 : 0,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm(`${'是否移除用户理财券'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('成功')
              this.isRemoveAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    walletFn () {
      if (this.wallet_id.length ) {
        this.wallet_id = this.wallet_id.replace(/\s+/g, ',')
        if ( this.wallet_id.substr(-1) == ',') {
          this.wallet_id = this.wallet_id.substr(0,this.wallet_id.length - 1)
        }
      }

    },
    submitTicketFn() {
      if (this.wallet_id === '') {
        this.$message.error('请填写钱包ID')
        return false
      }
      if (this.ticketVal === '') {
        this.$message.error('请选择理财券面额')
        return false
      }
      if (this.ticket_num === '') {
        this.$message.error('请填写赠送数量')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'give_ticket_admin'
      let req = {
        wallet_id: this.wallet_id,
        ticket_num: this.ticket_num,
        amount: this.ticketVal.split('|')[0],
        days: this.ticketVal.split('|')[1],
        two_auth_code: this.two_auth_code,
      }
      this.$confirm(`${'是否赠送理财券'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('成功')
              this.isAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    addTicket () {
      this.wallet_id = ''
      this.ticket_num = ''
      this.two_auth_code = ''
      this.isAlert = true
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.type = this.selectVal1;
      }
      if (this.selectVal2) {
        req.amount = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}financing_volume_details`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {

              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.type = this.typeObj[list.type] ? this.typeObj[list.type].label: list.type

              list.time = this.dataFormat(list.time)

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>