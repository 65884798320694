<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">团队长钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>下级钱包ID</th>
        <th>所属关系层级</th>
        <th>管理级别</th>
        <th>有效投资单金额(USDT)</th>
        <th>投资收益(USDT)</th>
        <th>贡献管理层收益(USDT)</th>
        <th>贡献直邀收益(USDT)</th>
        <th>贡献间邀收益(USDT)</th>
        <th>详情</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.user_id}}</td>
          <td>{{list.level_no}}</td>
          <td>{{list.level}}</td>
          <td>{{scientificNotationToString(list.invest_amount)}}</td>
          <td>{{scientificNotationToString(list.invest_profit)}}</td>
          <td>{{scientificNotationToString(list.team_profit)}}</td>
          <td>{{scientificNotationToString(list.invite_direct_profit)}}</td>
          <td>{{scientificNotationToString(list.invite_profit)}}</td>
          <td class="tab_btn" >
            <span class="btn" @click="showAlertFn(index)">贡献收益分配明细</span>
          </td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.invest_amount)}}</td>
          <td>{{scientificNotationToString(subtotal.invest_profit)}}</td>
          <td>{{scientificNotationToString(subtotal.team_profit)}}</td>
          <td>{{scientificNotationToString(subtotal.invite_direct_profit)}}</td>
          <td>{{scientificNotationToString(subtotal.invite_profit)}}</td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(sum.invest_amount)}}</td>
          <td>{{scientificNotationToString(sum.invest_profit)}}</td>
          <td>{{scientificNotationToString(sum.team_profit)}}</td>
          <td>{{scientificNotationToString(sum.invite_direct_profit)}}</td>
          <td>{{scientificNotationToString(sum.invite_profit)}}</td>
          <td></td>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" :title="'贡献收益分配明细'" :width="750">
      <div>
        <p class="color_T2">级差：GM 20%  、VP  30%、SVP 40% 、ED 50% 、 MD 60%</p>
        <table class="com-table mt_10">
          <thead class="com_thead">
          <th>序号</th>
          <th>钱包ID</th>
          <th>所属关系层级</th>
          <th>管理级别</th>
          <th>分得收益(USDT)</th>
          </thead>
          <tbody class="com_tbody">
          <tr v-for="(list, index) in nextResData" :key="index">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{list.user_id}}</td>
            <td>{{list.level_no}}</td>
            <td>{{list.level}}</td>
            <td>{{scientificNotationToString(list.gain_amount)}}</td>
          </tr>
          <tr v-show="nextResData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(nextSubtotal.gain_amount)}}</td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(nextSum.gain_amount)}}</td>

          </tr>

          </tbody>
        </table>
      </div>
      <div class="com_page">
        <el-pagination
            @size-change="nextHandleSizeChange"
            @current-change="nextPageFn"
            :current-page="nextPage"
            :page-sizes="[10, 20, 50, 100, 1000]"
            :page-size="nextSize"
            layout="total, prev, pager, next, sizes"
            :total="nextTotal"
        >
        </el-pagination>
      </div>

      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "teamProfitAudit",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      nextResData: [],
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      inputVal5: "",
      selectVal1: "bsc",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      coinInfo: [],
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      nextSubtotal: {},
      nextSum: {},
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      filterVal: [
        "user_id",
        "level_no",
        "level",
        "invest_amount",
        "invest_profit",
        "team_profit",
        "invite_direct_profit",
        "invite_profit",
      ],
      isAlert: false,
      nextPage: 1,
      nextSize: 100,
      nextTotal: '',
      curIndex: ''
    };
  },
  created() {
    if (this.inputVal1 && this.selectVal1) {
      this.resInit();
    }
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showAlertFn (index) {
      this.curIndex = index
      this.nextResData = []
      this.nextPage = 1
      this.isAlert = true
      this.nextResInit()
    },
    nextHandleSizeChange(val) {
      this.nextPage = 1;
      this.nextSize = val;
      this.nextResInit();
    },
    nextPageFn(index) {
      this.nextPage = index;
      this.nextResInit();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    nextResInit() {
      let index = this.curIndex
      let req = {
        user_id: this.inputVal1,
        child_id: this.resData[index].user_id,
        chain_type: this.selectVal1,
        page: this.nextPage,
        size: this.nextSize,
      };

      this.$ajax(`${this.Utils.URL.goURL}team_profit_detail`, req, "get")
          .then((res) => {
            let arr = res.list ? res.list : [];
            this.nextTotal = parseFloat(res.total);
            this.nextSubtotal = res.subtotal ? res.subtotal : {}
            this.nextSum = res.sum ? res.sum : {}
            arr.forEach(list => {
              list.level = this.levelArr[list.level]
            })

            this.nextResData = arr
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (!this.inputVal1 || !this.selectVal1) {
        this.$message.error('请输入钱包ID跟链类型');
        return false
      }
      if (this.inputVal1) {
        req.user_id = this.inputVal1;
      } else {
        this.$message.error('请输入钱包ID');
        return false
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.inputVal4) {
        req.min_amount = this.inputVal4
      }
      if (this.inputVal5) {
        req.max_amount = this.inputVal5
      }
      if (this.selectVal1) {
        req.chain_type = this.selectVal1;
      }
      if (this.selectVal2) {
        req.tx_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }/*
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }*/
      this.$ajax(`${this.Utils.URL.goURL}team_profit_check`, req, "get")
          .then((res) => {
            let arr = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            arr.forEach(list => {
              list.level = this.levelArr[list.level]
            })

            this.resData = arr
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>