<template>
    <article class="">
        <section class="com_from_box">
            <span style="color:#f33d3de8">*注：左键点击编辑该权限，右键点击为该权限添加下级权限或功能（父级Id请勿轻易更改）</span>
            <div class="com_from_item">
                <el-tree
                        ref="roleTree"
                        :data="treeData"
                        node-key="id"
                        :default-expand-all="true"
                        :props="defaultProps"
                        @node-click="editFn"
                        @check-change="treeChangeFn"
                        @node-contextmenu='show_addrule'
                />
                
            </div>
<!-- 
            <div class="com_from_item">
                <div class="com_from_left">

                </div>
                <div class="com_from_right">
                    <span class="btn" @click="editFn">新增</span>
                </div>
            </div> -->
        </section>

    </article>
</template>

<script>
    // import ruleList from '../mock-rule.json'
    export default {
        name: "adminRuleList",
        data() {
            return {
                pass: '',
                user_name: '',
                nick_name: '',
                treeData: [

                ],
                checkedArr: [],
                defaultProps: {
                    children: 'children',
                    label: 'title',
                },
            }
        },
        created () {
            /**/
            this.resInit()
        },
        methods: {
            resInit () {
                let req = {}
                this.$ajax(`${this.Utils.URL.goURL}power_list`, req, 'get')
                    .then(res => {
                        let ruleLists = res ? res.list : []

                        ruleLists = ruleLists.sort((a,b) => {
                            return a.id - b.id
                        })
                        ruleLists = ruleLists.sort((a, b) => {
                            return a.sort - b.sort;
                        });
                        let arr = []
                        ruleLists.forEach((val, index) => {
                            if (val.type == '1') {
                                val.children = []
                                arr.push(val)
                            }
                        })


                        let ruleFormat = (obj => {
                            ruleLists.forEach(val => {
                                if (obj.children) {
                                    if (obj.id == val.parent_id && obj.id != val.id) {
                                        val.children = []
                                        obj.children.push(val)
                                        ruleFormat(val)
                                    }
                                }
                            })
                        })
                        arr.forEach(val => {
                            ruleFormat(val)
                        })
                        this.treeData = arr
                        window.localStorage.setItem('ruleTree', JSON.stringify(arr))
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },
            editFn(node) {
                let obj
                if (node) {
                    obj = node
                } else {
                    obj = {}
                }
                window.sessionStorage.setItem('listDataInfo', JSON.stringify(obj))
                this.goRouter('/adminRuleEdit', {id: obj.id})
            },
            treeChangeFn() {},
            show_addrule(_,node){
                let obj
                if (node) {
                    obj = node
                } else {
                    obj = {}
                }
                window.sessionStorage.setItem('listDataInfo', JSON.stringify(obj))
                this.goRouter('/adminRuleEdit', {parent_id: obj.id})
            }
        }
    }
</script>

<style scoped>
    .el-tree{
        background: none;
    }
</style>