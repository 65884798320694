<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">节点名称</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>

    <div class="com_search_box" v-if="isEditButton">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="goRouter('/nodeListEdit')">新增</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>节点名称</th>
        <th>节点地址</th>
        <th>所在地区</th>
        <th>节点介绍</th>
        <th>添加时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.node_name }}</td>
          <td>{{ list.address }}</td>
          <td>{{ list.region }}</td>
          <td>{{ list.introduce }}</td>
          <td>{{ dataFormat(list.created_at) }}</td>
          <td class="tab_btn">
            <span class="btn" v-if="isEditButton" @click="goDetails(index)">编辑</span>
            <span class="btn" v-if="isDelButton" @click="showDelAlert(index)">删除</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isShow" title="删除节点" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isShow = false">取消</el-button>
                    <el-button type="primary" @click="delFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "nodeList",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 100,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      startTime: '',
      endTime: '',
      two_auth_code: '',
      selectArr: [{value: '钱包', label: '钱包'}],
      isShow: false
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    showDelAlert (index) {
      this.curIndex = index
      this.isShow = true
    },
    goDetails(index) {
      window.localStorage.setItem('listDataInfo', JSON.stringify(this.resData[index]))
      this.goRouter('nodeListEdit', {type: 'edit'})
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.inputVal1) {
        req.name = this.inputVal1
      }
      this.$ajax(`${this.Utils.URL.goURL}node_list`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    delFn(index) {

      this.$ajax(`${this.Utils.URL.goURL}del_node`, {
        code: this.two_auth_code,
        node_name: this.resData[this.curIndex].node_name
      }, 'post')
          .then(res => {
            this.isShow = false
            this.resData.splice(this.curIndex, 1)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>