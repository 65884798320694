<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          版本号
        </div>
        <div class="com_from_right">
          <el-input
            v-model="version_str"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          版本值
        </div>
        <div class="com_from_right">
          <el-input
            v-model="sub_version_str"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          渠道
        </div>
        <div class="com_from_right">
          <el-select
            v-model="channel_number"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in channelData"
                       :key="item.channel_number"
                       :label="item.channel_name"
                       :value="item.channel_number">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          是否强更
        </div>
        <div class="com_from_right">
          <el-select
            v-model="must_upgrade"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in option"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          下载页链接
        </div>
        <div class="com_from_right">
          <el-input
            v-model="download_url"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          APP包下载链接
        </div>
        <div class="com_from_right">
          <el-input
            v-model="h5_download_url"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          商店下载链接
        </div>
        <div class="com_from_right">
          <el-input
            v-model="app_store_url"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item" v-for="list in version_tip_list">
        <div class="com_from_left">
          {{list.type_title}}更新提示
        </div>
        <div class="com_from_right">
          <el-input type="textarea"
                    rows="5"
                    v-model="list.value"
                    size="small">

          </el-input>
        </div>
      </div>



      <div class="com_from_item">
        <div class="com_from_left">
          是否灰度
        </div>
        <div class="com_from_right">
          <el-select
            v-model="release_type"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in option1"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          灰度版本号
        </div>
        <div class="com_from_right">
          <el-input
            v-model="hd_version_str"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          灰度版本值
        </div>
        <div class="com_from_right">
          <el-input
            v-model="hd_sub_version_str"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          灰度渠道
        </div>
        <div class="com_from_right">
          <el-select
            v-model="hd_channel_number"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in channelData"
                       :key="item.channel_number"
                       :label="item.channel_name"
                       :value="item.channel_number">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          灰度下载链接
        </div>
        <div class="com_from_right">
          <el-input
            v-model="hd_download_url"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          灰度APP包下载链接
        </div>
        <div class="com_from_right">
          <el-input
            v-model="h5_hd_download_url"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          灰度商店下载链接
        </div>
        <div class="com_from_right">
          <el-input
            v-model="hd_app_store_url"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          灰度更新提示
        </div>
        <div class="com_from_right">
          <el-input type="textarea"
                    rows="5"
                    v-model="hd_release_log"
                    size="small">

          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          灰度更新英文提示
        </div>
        <div class="com_from_right">
          <el-input type="textarea"
                    rows="5"
                    v-model="hd_release_log_en"
                    size="small">

          </el-input>
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "versionSettingIOS",
  data() {
    return {
      version_str: '',
      sub_version_str: '',
      must_upgrade: '',
      channel_number: '',
      hd_channel_number: '',
      download_url: '',
      release_log: '',
      release_log_en: '',
      hd_version_str: '',
      hd_sub_version_str: '',
      hd_must_upgrade: '',
      release_type: '',
      hd_download_url: '',
      hd_app_store_url: '',
      app_store_url: '',
      h5_download_url: '',
      h5_hd_download_url: '',
      hd_release_log: '',
      hd_release_log_en: '',
      option: [{value: '1', label: '普通升级'}, {value: '2', label: '强制升级'}],
      option1: [{value: '1', label: '否'}, {value: '2', label: '是'}],
      type: 2,
      resData: {},
      channelData: [],
      version_tip_list: []
    }
  },
  created() {
    this.resInit()

  },
  methods: {
    submitFn() {
      let req = {
        op_system: parseFloat(this.type),
        version_str: this.release_type == 2 ? this.hd_version_str : this.version_str,
        sub_version_str: this.release_type == 2 ? this.hd_sub_version_str : this.sub_version_str,
        download_url: this.release_type == 2 ? this.hd_download_url : this.download_url,
        h5_download_url: this.release_type == 2 ? this.h5_hd_download_url : this.h5_download_url,
        app_store_url: this.release_type == 2 ? this.hd_app_store_url : this.app_store_url,
        release_log: this.release_type == 2 ? this.hd_release_log : this.release_log,
        release_log_en: this.release_type == 2 ? this.hd_release_log_en : this.release_log_en,
        channel_number: this.release_type == 2 ? this.hd_channel_number : this.channel_number,
        release_type: parseFloat(this.release_type),
        version_tip_list: this.version_tip_list,
        must_upgrade: parseFloat(this.release_type == 2 ? 2 : this.must_upgrade),
      }
      this.$ajax(`${this.Utils.URL.goURL}created_version`, req, 'post', 2500000, false, true)
        .then(res => {
          this.$message.success('操作成功')
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    resInit() {
      let req = {
        op_system: this.type
      }
      this.$ajax(`${this.Utils.URL.goURL}version_content`, req, 'get')
        .then(res => {
          // this.resData = res ? res : {}
          this.version_str = res.version_str
          this.sub_version_str = res.sub_version_str
          this.must_upgrade = res.must_upgrade
          this.download_url = res.download_url
          this.release_log = res.release_log
          this.release_log_en = res.release_log_en
          this.hd_sub_version_str = res.hd_sub_version_str
          this.release_type = res.release_type
          this.hd_must_upgrade = res.hd_must_upgrade == 2 ? '2' : '0'
          this.channel_number = res.channel_number
          this.hd_channel_number = res.hd_channel_number
          this.hd_version_str = res.hd_version_str
          this.hd_download_url = res.hd_download_url
          this.app_store_url = res.app_store_url
          this.hd_app_store_url = res.hd_app_store_url
          this.h5_download_url = res.h5_download_url
          this.h5_hd_download_url = res.h5_hd_download_url
          this.hd_release_log = res.hd_release_log
          this.hd_release_log_en = res.hd_release_log_en
          this.version_tip_list = res.version_tip_list ? res.version_tip_list : []
          let obj = {}
          let obj1 = {}
          let arr = []
          if (this.version_tip_list.length) {
            this.langList.forEach((val, index) => {
              obj[val.value] = val.label
            })
            this.version_tip_list.forEach((val, index) => {
              obj1[val.type] = val.type_title
            })
            for (let i in obj) {
              if (!obj1[i]) {
                arr.push({
                  type_title: obj[i],
                  type: i,
                  value: ''
                })
              }
            }
            this.version_tip_list = this.version_tip_list.concat(arr)
          } else {
            this.langList.forEach((val, index) => {
              this.version_tip_list[index] = {}
              this.version_tip_list[index].type_title = val.label
              this.version_tip_list[index].type = val.value
              this.version_tip_list[index].value = ''
            })
          }
        })
        .catch(res => {
          this.$message.error(res.msg)
        })

      this.$ajax(`${this.Utils.URL.goURL}channel_list`, {
        op_system: 2
      }, 'get')
        .then(res => {
          // this.resData = res ? res : {}
          this.channelData = res.list ? res.list : []
        })
        .catch(res => {
          this.$message.error(res.msg)
        })

    }
  }
}
</script>

<style scoped>

</style>