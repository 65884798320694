<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">团队长ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">级别变更ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">管理级别</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, index) in levelArr"
                :key="index"
                :label="item"
                :value="index"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">变更类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, index) in typeObj"
                :key="index"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>



    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>下级团队成员级别发生变更ID</th>
        <th>当前级别</th>
        <th>变更类型</th>
        <th>变更记录</th>
        <th>变更时间</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.user_id}}</td>
          <td>{{list.level_tx}}</td>
          <td>{{list.type_tx}}</td>
          <td>{{list.new_level_tx}}</td>
          <td>{{list.created_at}}</td>


        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


  </article>
</template>

<script>
export default {
  name: "team-invitation-relationship",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      selectVal1: "",
      selectVal2: "",
      startTime: "",
      endTime: "",
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "user_id",
        "level_tx",
        "type_tx",
        "new_level_tx",
        "created_at",
      ],
      isAlert: false,
      isRemove: false,
      curIndex: 0,
      curType: '',
      option1: [{label: '全额创建', value: 1}],
      title: '',
      two_auth_code : '',
      wallet_id: '',
      type: '',
      typeObj: {
        1: {label: '升级', value: '1'},
        2: {label: '降级', value: '2'},
      },
      exportData: []
    };
  },
  created() {
    if (this.$route.query.id) {
      this.inputVal1 = this.$route.query.id
      this.resInit()
    }



  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
    title (val) {
      this.title = val.replace(/[^\a-\z\A-\Z\s]/g,'')
    },
  },
  methods: {

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit(export_type) {

      let req = {
        page: this.page,
        size: this.size,
      };
      if (export_type == 'all') {
        req.export_type = 'all'
      }
      if (this.inputVal1 == '') {
        this.$message.error('请输入团队长ID');
        return false
      }
      if (this.inputVal1) {
        req.user_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.change_id = this.inputVal2;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.type = this.selectVal2;
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}team_level_change_list`, req, "get")
          .then((res) => {


            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              list.type_tx = this.typeObj[list.type] ? this.typeObj[list.type].label : list.type

              list.level_tx = this.levelArr[list.level] ? this.levelArr[list.level] : list.level
              list.new_level_tx = (this.levelArr[list.old_level] ? this.levelArr[list.old_level] : list.old_level) + '->' + (this.levelArr[list.new_level] ? this.levelArr[list.new_level] : list.new_level)
              //list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
              list.created_at = this.dataFormat(list.created_at)

            })

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>