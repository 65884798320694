<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <!-- <div class="com_item_search">
        <div class="com_item_tx">新錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div> -->
      <!-- <div class="com_item_search">
        <div class="com_item_tx">哈希值</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">狀態</div>
        <div class="com_item_input">
          <el-select v-model="selectVal1" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in typeObj" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </div> -->
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
        <el-button type="default" size="small" @click="resetFn">重置</el-button>
      </div>

    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
    </div>
    <div class="com_search_box mt_40">
      <div class="com_item_search">
        <el-button type="primary" @click="showAlertFn('', 'add')">新增</el-button>
      </div>
    </div>

    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>錢包ID</th>
          <th>舊上級錢包ID</th>
          <th>新上級錢包ID</th>
          <th>申請時間</th>
          <th>狀態</th>
          <th>備註</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ list.old_parent_id }}</td>
            <td>{{ list.new_parent_id }}</td>
            <!-- <td>
              <p v-if="list.tx_id != ''">
                <a :href="'https://bscscan.com/tx/' + list.tx_id + '?langId=cn'" target="_blank">{{ list.tx_id }}</a>
              </p>
              <p v-else>-</p>
            </td> -->
            <td>
              <!-- <p>{{ parseFloat(list.updated_at) > 0 ? dataFormat(list.updated_at) : '-' }}</p> -->
              <p>{{ list.updated_at.length > 0 ? list.updated_at : '-' }}</p>
            </td>
            <!-- <td>
              <p>{{ parseFloat(list.updated_at) > 0 ? dataFormat(list.updated_at) : '-' }}</p>
            </td> -->
            <td>
              <p v-if="list.status == 2" style=" color: #F90000;">失敗</p>
              <p v-else style="color: #409EFF">{{ list.status == 0 ? '置换中' : '成功' }}</p>
            </td>
            <td>
              <!-- <p v-if="list.status == 0" style="color: #139F0B">{{ list.note }}</p> -->
              <p>{{ list.note }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>


    <el-dialog v-model="showUpdate" title="更換上級" width="600px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">用户ID</div>
          <div class="com_from_right">
            <el-input v-model="user_id" type="text" placeholder="请输入" size="small"></el-input>
          </div>
          <el-button @click="searchUserID" type="primary" size="small" style="margin-left: 1rem">确认</el-button>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">绑定上级ID</div>
          <div class="com_from_right">
            <el-input v-model="bind_superior_id" type="text" placeholder="" size="small" disabled></el-input>
          </div>
        </div>

        <!-- <div class="com_from_item" v-show="edit_type == 2">
          <div class="com_from_left">直邀下级列表</div>
          <div class="com_from_right">
            <el-button type="primary" @click="searchDirectUserFn">
              查询
            </el-button>
          </div>
        </div> -->

        <div class="com_from_item">
          <div class="com_from_left">新上级ID</div>
          <div class="com_from_right">
            <el-input v-model="new_parent_id" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showUpdate = false">取消</el-button>
          <el-button type="primary" @click="submitEditFn">提交</el-button>
        </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "activityDinnerReport",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      options: [],
      resData: [],
      // balanceRangeInfo: "",
      // balanceRangeStatus: "",
      inputVal1: "",
      // inputVal2: "",
      // inputVal3: "",
      // selectVal1: "",
      // coinInfo: [],
      // tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      expenses: '',
      selectFail: '',
      min: '',
      max: '',
      isShow: false,
      passShow: false,
      typeObj: {
        0: { label: '置换中', value: '0' },
        1: { label: '成功', value: '1' },
        2: { label: '失败', value: '2' },
      },
      // isAlert: false,
      // content: '',
      filterVal: [
        "user_id",
        "old_parent_id",
        "new_parent_id",
        "updated_at",
        "note",
      ],
      finalData: [],
      isValid: true,
      showUpdate: false,
      user_id: '',
      bind_superior_id: '',
      new_parent_id: '',
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    searchUserID() {
      const req = {
        user_id: this.user_id
      }
      this.$ajax(`${this.Utils.URL.goURL}user_parent`, req, 'get')
        .then((res) => {
          this.bind_superior_id = res.parent_id
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    resetFn() {
      this.inputVal1 = ''
      this.startTime = ''
      this.endTime = ''
      this.resInit()
    },
    checkContent() {
      if (this.new_parent_id == '') {
        this.$message.error('请输入新上级ID');
        this.isValid = false
        return
      }
    },
    submitEditFn() {
      if (this.user_id == '') {
        this.$message.error('请输入用户ID');
        return
      }
      if (this.new_parent_id == '') {
        this.$message.error('请输入新上级ID');
        return
      }
      if (this.two_auth_code == '') {
        this.$message.error('请输入谷歌验证码');
        return
      }
      const req = {
        new_parent_id: this.new_parent_id,
        user_id: this.user_id,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}parent_replace_management`, req, "post", 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.showUpdate = false
            this.resetFn()
            this.user_id = ''
            this.new_parent_id = ''
            this.two_auth_code = ''
            this.bind_superior_id = ''
            this.$message.success('成功');
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });

    },
    showAlertFn(index, type) {
      this.showUpdate = true
      this.user_id = ''
      this.new_parent_id = ''
      this.two_auth_code = ''
      this.bind_superior_id = ''
    },
    // showFn(index, type) {
    //   this.curIndex = index
    //   this.curType = type
    //   this.two_auth_code = ''
    //   this.options = []
    //   this.resData[index].new_wallet_id_list.forEach((val, index) => {
    //     this.options.push(index + 1)
    //   })
    //   this.expenses = this.options.length
    //   this.isShow = true
    // },

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.user_id = this.inputVal1;
      }

      if (this.startTime) {
        req.start_date = this.startTime
      }
      if (this.endTime) {
        req.end_date = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}parent_replace_management`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.resData.forEach((list) => {
            list.updated_at = this.dataFormat(list.updated_at)

          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}

.full-width {
  width: 100%;
}

.group-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.com_from_right {
  width: 60%
}
</style>