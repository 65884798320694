<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          终端
        </div>
        <div class="com_from_right">
          <el-checkbox-group v-model="port_id">
            <el-checkbox v-for="list in options1" :label="list.value">{{ list.label }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          是否推荐位
        </div>
        <div class="com_from_right">
          <el-radio v-model="is_first" label="1">是</el-radio>
          <el-radio v-model="is_first" label="2">否</el-radio>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          一级分类
        </div>
        <div class="com_from_right">
          <el-select
            v-model="one_type"
            clearable
            filterable
            placeholder="请选择"
            size="small"
            @change="selectFn"
          >
            <el-option v-for="item in options2"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          二级分类
        </div>
        <div class="com_from_right">
          <el-select
            v-model="two_type"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option v-for="item in options3"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          链接地址
        </div>
        <div class="com_from_right">
          <el-input
            v-model="link_address"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          英文链接地址
        </div>
        <div class="com_from_right">
          <el-input
            v-model="link_address_en"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          中文标题
        </div>
        <div class="com_from_right">
          <el-input
            v-model="title"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          英文标题
        </div>
        <div class="com_from_right">
          <el-input
            v-model="title_en"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          排序
        </div>
        <div class="com_from_right">
          <el-input
            v-model="sort"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          中文图片(160*90)
        </div>
        <div class="com_from_right">
          <el-upload
            class="avatar-uploader"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            data="cn"
            :on-success="handleAvatarSuccess"
            :on-change="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrls" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          英文图片(160*90)
        </div>
        <div class="com_from_right">
          <el-upload
            class="avatar-uploader"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-change="beforeAvatarUploadEn"
          >
            <img v-if="imageUrlEN" :src="imageUrlENs" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          中文简介
        </div>
        <div class="com_from_right">
          <el-input type="textarea"
                    rows="5"
                    v-model="introduce"
                    size="small">

          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          英文简介
        </div>
        <div class="com_from_right">
          <el-input type="textarea"
                    rows="5"
                    v-model="introduce_en"
                    size="small">

          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="sumbitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "videoListEdit",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      is_first: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      startTime: '',
      endTime: '',
      type: '',
      id: '',
      port_id: [],
      one_type: '',
      two_type: '',
      title: '',
      sort: '',
      title_en: '',
      link_address: '',
      introduce: '',
      introduce_en: '',
      link_address_en: '',
      infoData: {},
      imageUrls: '',
      imageUrl: '',
      imageUrlENs: '',
      imageUrlEN: '',
    }
  },
  created() {
    this.type = this.$route.query.type
    this.options1 = JSON.parse(window.sessionStorage.getItem('portList'))
    this.options2 = JSON.parse(window.sessionStorage.getItem('oneVideoClass'))
    if (this.type == 'edit') {
      this.infoData = JSON.parse(window.localStorage.getItem('listDataInfo'))

      this.id = this.infoData.id
      this.port_id = this.infoData.port_id.split(',')
      this.one_type = this.infoData.one_type
      this.selectFn(this.infoData.one_type)
      this.two_type = this.infoData.two_type
      this.title = this.infoData.title_zh
      this.title_en = this.infoData.title_en
      this.imageUrls = this.Utils.URL.goURL + this.infoData.img_addr
      this.imageUrl = this.infoData.img_addr
      this.imageUrlENs = this.Utils.URL.goURL + this.infoData.img_addr_en
      this.imageUrlEN = this.infoData.img_addr_en
      this.link_address = this.infoData.link_address
      this.link_address_en = this.infoData.link_address_en
      this.introduce = this.infoData.introduce_zh
      this.is_first = this.infoData.is_first
      this.introduce_en = this.infoData.introduce_en
      this.sort = this.infoData.sort
    }
  },
  methods: {
    sumbitFn() {
      if (!this.port_id.length) {
        this.$message.error('请选择终端')
        return false
      }

      if (this.one_type === '') {
        this.$message.error('请选择一级分类')
        return false
      }

      if (this.two_type === '') {
        this.$message.error('请选择二级分类')
        return false
      }

      if (this.title === '') {
        this.$message.error('请输入中文标题')
        return false
      }

      if (this.title_en === '') {
        this.$message.error('请输入英文标题')
        return false
      }

      if (this.link_address === '') {
        this.$message.error('请输入中文视频地址')
        return false
      }

      if (this.link_address_en === '') {
        this.$message.error('请输入英文视频地址')
        return false
      }

      if (this.sort === '') {
        this.$message.error('请输入排序')
        return false
      }

      if (this.introduce === '') {
        this.$message.error('请输入中文简介')
        return false
      }
      if (this.introduce_en === '') {
        this.$message.error('请输入英文简介')
        return false
      }
      if (this.is_first === '') {
        this.$message.error('请选择是否推荐位')
        return false
      }
      if (this.imageUrl === '') {
        this.$message.error('请上传中文图片')
        return false
      }
      if (this.imageUrlEN === '') {
        this.$message.error('请上传英文图片')
        return false
      }
      let url = this.type == 'edit' ? 'update_video' : 'created_video'
      let req = {
        port_id: this.port_id.join(','),
        one_type: this.one_type,
        two_type: this.two_type,
        title_zh: this.title,
        title_en: this.title_en,
        img_addr: this.imageUrl,
        img_addr_en: this.imageUrlEN,
        link_address: this.link_address,
        link_address_en: this.link_address_en,
        sort: this.sort,
        introduce_zh: this.introduce,
        introduce_en: this.introduce_en,
        is_first: this.is_first,
      }
      if (this.type == 'edit') {
        req.id = this.id
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
        .then(res => {
          this.$message.success('操作成功,两秒后自动跳转')
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    selectFn(vals, type = 2) {
      if (!vals) {
        return false
      }
      let req = {
        type_content_id: '3'
      }
      let url = 'type_one_list'
      if (type == 2) {
        url = 'type_two_list'
        req.type_id = this.one_type
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
        .then(res => {
          let arr = type == 1 ? res ? res : [] : res.list ? res.list : []
          let arr2 = []
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.type_zh
            }
          })
          if (type == 1) {
            this.options2 = arr2
          } else {
            this.options3 = arr2

          }
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      this.uploadFn(file, 'cn')
    },
    beforeAvatarUploadEn(file) {
      this.uploadFn(file, 'en')

    },
    uploadFn(file, type) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      const formData = new FormData()
      let str = file.name.split('.')
      let last = str[str.length - 1].toLowerCase()
      if (!/png|jpg|jpeg/.test(last)) {
        this.$message.error('上传错误,文件格式必须为：png/jpg/jpeg');
        return;
      }
      let time = parseInt(new Date().getTime())
      str = 'image_upload' + time + '.' + last
      formData.append('files', file.raw, str)
      formData.append('dir', 'videoImg')
      this.$ajax(`${this.Utils.URL.goURL}upload`, formData, 'post', 2500000, true)
        .then(res => {
          if (type == 'en') {
            this.imageUrlENs = this.Utils.URL.goURL + res.url
            this.imageUrlEN = res.url
          } else {
            this.imageUrls = this.Utils.URL.goURL + res.url
            this.imageUrl = res.url
          }

        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>