<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">投资地址</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal1"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>


      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>投资地址</th>
        <th>投资数量</th>
        <th>投资累计收益</th>
        <th>投资状态</th>
        <th>投资开始时间</th>
        <th>投资到期时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.invest_addr }}</td>
          <td>{{ list.invest_amount }}</td>
          <td>{{ list.profit_amount }}</td>
          <td>{{ statusObj[list.status] }}</td>
          <td>{{ dataFormat(list.begin_at) }}</td>
          <td>{{ dataFormat(list.end_at) }}</td>
          <td class="tab_btn">
            <span class="btn" @click="showDetailsFn(list.id)">查看</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="detailsShow" title="收益明细" width="500px">
      <section class="">
        <div class="tab_box">
          <table class="com-table">
            <thead class="com_thead">
            <th>序号</th>
            <th>收益</th>
            <th>每日收益率</th>
            <th>发放时间</th>
            </thead>
            <tbody class="com_tbody">
            <tr v-for="(list, index) in inviteData" :key="list.id">
              <td>{{ (page - 1) * size + 1 + index }}</td>
              <td>{{list.profit_amount}}</td>
              <td>{{list.rate}}</td>
              <td>{{ dataFormat(list.created_at) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="com_page">
          <el-pagination
              @size-change="handleSizeChangeDetails"
              @current-change="pageFnDetails"
              :current-page="pageDetails"
              :page-sizes="[10, 20, 50, 100, 1000]"
              :page-size="sizeDetails"
              layout="total, prev, pager, next, sizes"
              :total="totalDetails"
          >
          </el-pagination>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="detailsShow = false">关闭</el-button>
                </span>
      </template>
    </el-dialog>


  </article>
</template>

<script>
export default {
  name: "institutionLoanFundraising",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      pageDetails: 1,
      sizeDetails: 10,
      totalDetails: 0,
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      inputVal2: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      two_auth_code: '',
      detailsShow: false,
      inviteData: [],
      selectArr: [{value: '钱包', label: '钱包'}],
      statusObj: {
        1: '确认中',
        2: '成功',
        3: '失败',
        4: '已返还',
        5: '已复投',
      },
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    showDetailsFn (id) {
      this.detailsShow = true
      this.curIndex = id
      this.detailsInit(id)
    },
    detailsInit(id) {
      let req = {
        id: id,
        page: this.pageDetails,
        size: this.sizeDetails
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/invest_profit_list`, req, 'get')
          .then(res => {
            this.inviteData = res.list ? res.list : []
            this.total = parseFloat(res.totalDetails)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    resInit() {
      let req = {
        id: this.$route.query.id,
        page: this.page,
        size: this.size
      }
      if (this.inputVal1) {
        req.invest_addr = this.inputVal1
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/admin_invest_details_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
    handleSizeChangeDetails(val) {
      this.pageDetails = 1;
      this.sizeDetails = val;
      this.detailsInit(this.curIndex);
    },
    pageFnDetails(index) {
      this.pageDetails = index
      this.detailsInit(this.curIndex)
    },
  }

}
</script>

<style scoped>

</style>