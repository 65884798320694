import { createApp, h, resolveComponent, defineCustomElement } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import "./style/style.scss";
import utils from './utils'
import axios from 'axios'
import qs from 'qs'
import routerPath from "./router-path";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import BigNumber from 'bignumber.js'
window.windowBigNumber = BigNumber
window.routerPath = routerPath
window.token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : ''
window.userName = window.localStorage.getItem('userName') ? window.localStorage.getItem('userName') : ''
import router from './router'



NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: true })

window.ruleRouter = window.localStorage.getItem('ruleRouter') ? JSON.parse(window.localStorage.getItem('ruleRouter')) : []

if (window.token && window.ruleRouter.length >= 1) {
    let arr = window.routerPath['/home']

    window.ruleRouter.forEach(val => {
        if (window.routerPath[val.path] && val.path != '/home') {
            window.routerPath[val.path].meta = val
            arr.children.push(window.routerPath[val.path])
        }
    })

    router.addRoute(arr)
}

const app = createApp({

    render() {
        return h(resolveComponent('router-view'))
    }

})
app.config.globalProperties.$axios = axios
app.config.globalProperties.Utils = utils
app.mixin({
    created() {
        this.hidePageLoad()
        this.isShowButton()
    },
    data() {
        return {
            isExcelAllButton: false,
            isExcelButton: false,
            isDetailButton: false,
            isEditButton: false,
            isAddButton: false,
            isOpenButton: false,
            isLockButton: false,
            isResetPassButton: false,
            isRevokeButton: false,
            isDelButton: false,
            isExamineButton: false,
            isBankerButton: false,
            isExtractButton: false,
            isGenerateButton: false,
            ajaxAxiosArray: [],
            devModulo: 100,
            exchangePriceDefaulDecimal: 8, // 默认价格小数位数
            chainTypeArr:[
                {label: 'TRX', value: 'tron'},
                {label: 'BSC', value: 'bsc'},
                {label: 'ETH', value: 'eth'},
                {label: 'FTC', value: 'ftc'},
                {label: 'FTC-M', value: 'ftcmain'},
            ],
            langList: [{ label: '中文繁体', value: 'zh' }, { label: '英文', value: 'en' }, { label: '西班牙', value: 'es' }, { label: '法语', value: 'fr' }, { label: '韩语', value: 'ko' }, { label: '日语', value: 'ja' }, { label: '越南', value: 'vi' }, { label: '马来西亚', value: 'ms' }, { label: '泰国', value: 'th' }, { label: '印尼', value: 'id' }, { label: '葡萄牙', value: 'pt' }, { label: '葡萄牙语(巴西)', value: 'pt_br' }],
        }
    },
    methods: {
        showPageLoad() {
            NProgress.start()
        },
        hidePageLoad() {
            NProgress.done()
        },
        isShowButton() {
            let routes = this.$route ? this.$route : {}
            let route = routes.meta ? routes.meta : {}
            let routeMeta = route.children ? route.children : []
            let metaLen = routeMeta.length
            let metaIdx = 0
            if (routes.path != route.path) {
                return false
            }
            for (metaIdx; metaIdx < metaLen; metaIdx++) {
                switch (routeMeta[metaIdx].check == 1) {
                    case routeMeta[metaIdx].name == 'excelAll':
                        this.isExcelAllButton = true
                        break
                    case routeMeta[metaIdx].name == 'excel':
                        this.isExcelButton = true
                        break
                    case routeMeta[metaIdx].name == 'detail':
                        this.isDetailButton = true
                        break
                    case routeMeta[metaIdx].name == 'edit':
                        this.isEditButton = true
                        break
                    case routeMeta[metaIdx].name == 'add':
                        this.isAddButton = true
                        break
                    case routeMeta[metaIdx].name == 'open':
                        this.isOpenButton = true
                        break
                    case routeMeta[metaIdx].name == 'lock':
                        this.isLockButton = true
                        break
                    case routeMeta[metaIdx].name == 'resetPass':
                        this.isResetPassButton = true
                        break
                    case routeMeta[metaIdx].name == 'revoke':
                        this.isRevokeButton = true
                        break
                    case routeMeta[metaIdx].name == 'del':
                        this.isDelButton = true
                        break
                    case routeMeta[metaIdx].name == 'examine':
                        this.isExamineButton = true
                        break
                    case routeMeta[metaIdx].name == 'banker':
                        this.isBankerButton = true
                        break
                    case routeMeta[metaIdx].name == 'extract':
                        this.isExtractButton = true
                        break
                    case routeMeta[metaIdx].name == 'generate':
                        this.isGenerateButton = true
                        break
                }
            }
        },
        goBack() {
            this.$router.go(-1)
        },
        dataFormat(time, type = 'yyyy-MM-dd hh:mm:ss') {
            if (!time || time == 0) {
                return '-'
            }
            time = parseFloat(time * 1000)
            return this.Utils.tool.date.dateFormat(type, time)
        },
        scientificNotationToString(param) {
            if (!param) {
                return param
            }
            let strParam = String(param)
            let flag = /e/.test(strParam)
            if (!flag) return param

            //
            let sysbol = true
            if (/e-/.test(strParam)) {
                sysbol = false
            }
            //
            let index = Number(strParam.match(/\d+$/)[0])
            //
            let basis = strParam.match(/^[\d\.]+/)[0].replace(/\./, '')

            if (sysbol) {
                return basis.padEnd(index + 1, 0)
            } else {
                return basis.padStart(index + basis.length, 0).replace(/^0/, '0.')
            }
        },
        padRight(val, lens) {
            if (lens == 0) {
                return parseInt(val)
            }

            if (lens == 2) {
                if (parseFloat(val) > 0.0001) {
                    lens = parseFloat(val) < 0.01 ? 4 : 2
                }
            }

            let str = val ? val : 0
            str = this.scientificNotationToString(str)
            str = (str ? (str + '') : '0').split('.')
            let lena = parseInt(lens ? lens : this.exchangePriceDefaulDecimal)
            let len = parseInt(lens ? lens : this.exchangePriceDefaulDecimal)
            if (str.length > 1) {
                len = str[1].length >= len ? len : len - str[1].length
                if (len < 0) {
                    return str[0] + '.' + str[1]
                }
                if (str[1].length >= lena) {
                    return (str[0] + '.' + str[1].substring(0, len))
                }
                return (str[0] + '.' + str[1] + (new Array(len + 1)).join('0'))
            } else {
                if (str[0] == 0) {
                    return ('0.' + (new Array(len + 1)).join('0'))
                }
                return (str + '.' + (new Array(len + 1)).join('0'))
            }
        },
        copyText(str) {
            let input = document.createElement('SPAN')
            input.textContent = str
            input.style.position = 'absolute'
            input.style.left = '-999px'
            input.style.top = '-999px'
            input.style.zIndex = '-999'
            document.body.appendChild(input)
            if (document.selection) {
                let range = document.body.createTextRange();
                range.moveToElementText(input);
                range.select();
            } else {
                let range = document.createRange();
                range.selectNode(input);
                let selection = window.getSelection();
                if (selection.rangeCount > 0) selection.removeAllRanges();
                selection.addRange(range);
            }
            document.execCommand('copy');
            // this.showToastFn(this.$t('indexView.copy_success'))
            this.$message.success('复制成功')
            document.body.removeChild(input)
        },
        goRouter(path, query = {}, replace = false) {

            if (path.trim() == '') {
                return false
            }
            /*if (replace) {
              this.$router.push({path: path, query: query})
              setTimeout(() => {window.location.reload()}, 0)
              return
            }
            if (this.$route.path != path && this.$route.path != '/') {
                let routeUrl = this.$router.resolve({
                    path: path,
                    query: query
                });
                window.open(routeUrl .href, '_blank');
                return false
            }*/
            this.$router.push({ path: path, query: query })
        },
        $ajax(url, json, method = 'post', timeout = 9000000, isFormData = false, jsonHead = false) {
            const promise = new Promise((resolve, reject) => {
                if (!url || !json) {
                    reject({
                        status: 0,
                        msg: `url or josn is null`
                    })
                }
                let req = {
                    url: url,
                    method: method,
                    timeout: timeout,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'lang': 'cn',
                        'XXX-DOMAIN': window.location.origin
                    },
                    cancelToken: new this.$axios.CancelToken(c => { // 设置 cancel token
                        this.ajaxAxiosArray.push(c)
                    }),
                    transformResponse: [function (data) {
                        if (data) {
                            try {
                                let str = data.replace(/\:(\d+)(\,|\})/g, (a, b, c, d, e) => {
                                    return (':"' + b + '"' + c)
                                });
                                const s = JSON.parse(str)
                                return s
                            } catch (e) {
                                return JSON.parse(data)
                            }
                        }
                        return data
                    }]
                }

                if (json.start) {
                    json.start =  this.Utils.tool.date.dateUTCFormat('yyyy-MM-dd hh:mm:ss', json.start)
                }
                if (json.end) {
                    json.end =  this.Utils.tool.date.dateUTCFormat('yyyy-MM-dd hh:mm:ss', json.end)
                }
                if (json.begin) {
                    json.begin =  this.Utils.tool.date.dateUTCFormat('yyyy-MM-dd hh:mm:ss', json.begin)
                }

                if (json.begin_time) {
                    json.begin_time =  this.Utils.tool.date.dateUTCFormat('yyyy-MM-dd hh:mm:ss', json.begin_time)

                }
                if (json.end_time) {
                    json.end_time =  this.Utils.tool.date.dateUTCFormat('yyyy-MM-dd hh:mm:ss', json.end_time)
                }
                // end_date_start,end_date_end

                if (json.end_date_start) {
                    json.end_date_start =  this.Utils.tool.date.dateUTCFormat('yyyy-MM-dd hh:mm:ss', json.end_date_start)
                }
                if (json.end_date_end) {
                    json.end_date_end =  this.Utils.tool.date.dateUTCFormat('yyyy-MM-dd hh:mm:ss', json.end_date_end)
                }
                
                req.headers.token = window.token
                // req.headers.token = this.storeLang == 'zh' ? 'en' : 'en'

                if (!isFormData) {
                    if (method === 'get') {
                        req.params = json
                    } else {
                        if (jsonHead) {
                            req.headers['Content-Type'] = 'application/json'
                            req.data = json
                        } else {
                            req.data = qs.stringify(json)
                        }
                        // req.data = json
                    }
                } else {
                    req.headers['Content-Type'] = 'multipart/form-data'
                    req.data = json
                }
                this.showPageLoad()
                this.$axios(req).then((response) => {
                    this.hidePageLoad()
                    if (response.data.code == '0') {
                        resolve(response.data.data)
                    } else if (response.data.code == '-1') {
                        reject(response.data)
                        this.goRouter('/login')
                    } else {
                        reject(response.data)
                    }
                }).catch((error) => {

                    this.hidePageLoad()
                    if (error.msg) {
                        reject(error)
                    } else {
                        if (error.message == 'webExit') {
                            return false
                        }
                        reject({ msg: 'Request failed' })
                    }
                    console.error(`ajax error: ${url} ### ${error}`)
                })
            })
            return promise
        },

        // 导出excel
        exportExcel(tHeader, filterVal, resData, name) {
            let tableData = JSON.parse(JSON.stringify(resData))
            let data_test = /\d{4}(\-|\/|.)\d{1,2}\1\d{1,2}/
            tableData.forEach((item, i) => {
                if (item.created_at) {
                    if (!data_test.test(item.created_at)) {
                        item.created_at = this.dataFormat(item.created_at)
                    }
                }
                if (item.time) {
                    item.time = this.dataFormat(item.time)
                }
                item.index = i + 1
            })
            require.ensure([], () => {
                const {
                    export_json_to_excel,
                } = require("./utils/excel/Export2Excel.js"); //Export2Excel路径
                // const tHeader = ["序号", "昵称", "账号"]; // 上面设置Excel的表格第一行的标题
                // const filterVal = ["index", "nick", "account_number"]; // 上面的index、nickName、name是tableData里对象的属性key值
                const data = this.formatJson(filterVal, tableData);//tableData; //把要导出的数据tableData存到list
                export_json_to_excel(tHeader, data, name + "列表"); //最后一个是表名字
            });
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map((v) => filterVal.map((j) => v[j]));
        },
        // get 6 digits after the decimal point
        getSixDecimal(val) {
            if (val == 0) {
                return 0
            }
            if (val.indexOf('.') == -1) {
                return val
            } else {
                return val.split('.')[1].length > 6 ? val.split('.')[0] + '.' + val.split('.')[1].substring(0, 6) : val
            }
        }
    },
    beforeUnmount() {
        this.ajaxAxiosArray.forEach((ele, index) => {
            ele('webExit')
            delete this.ajaxAxiosArray[index]
        })
    },


})

app.use(ElementPlus)
app.use(router)
app.mount('#app')
