<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">撮合池地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">目标地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>



      <div class="com_item_search" >
        <div class="com_item_tx">变更方向</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option value="1" label="转出"></el-option>
            <el-option value="2" label="转入"></el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">变更类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search" v-if="false">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>撮合池地址</th>
        <th>目标地址</th>
        <th>变更方向</th>
        <th>币种</th>
        <th>变更数量</th>

        <th>类型</th>
        <th>时间</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.pool_addr}}
          </td>
          <td>
            {{list.target_addr}}
          </td>
          <td>
            {{list.direction}}
          </td>
          <td>
            {{list.coin_symbol}}
          </td>
          <td>
            {{list.amount}}
          </td>

          <td>
            {{list.tx_type}}
          </td>
          <td>
            {{(list.confirm_at)}}
          </td>

        </tr>
        <tr v-show="resData.length && selectVal1 && selectVal4">
          <td>小计</td>
          <td></td>
          <td></td>
          <td>数量: {{scientificNotationToString(subtotal.amount)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length && selectVal1 && selectVal4">
          <td>总计</td>
          <td></td>
          <td></td>
          <td>数量: {{scientificNotationToString(sum.amount)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "makeDetails",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      typeObj: {
        1: {label: '借贷质押', value: '1'},
        2: {label: '投资', value: '2'},
        3: {label: '创建合伙人', value: '3'},
        4: {label: '认购合伙人', value: '4'},
        5: {label: '认购FTH', value: '5'},
        6: {label: '创建超级节点', value: '6'},
        7: {label: '认购超级节点', value: '7'},
        1001: {label: '提取投资本金', value: '1001'},
        1002: {label: '返还保证金', value: '1002'},
        1003: {label: '返还认购合伙人资金', value: '1003'},
        1004: {label: '返还创建合伙人资金', value: '1004'},
        1005: {label: '合伙人邀请奖励', value: '1005'},
        1007: {label: '发放收益', value: '1007'},
        1008: {label: '提取FTH', value: '1008'},
        1009: {label: '释放认购合伙人资金', value: '1009'},
        1010: {label: '管理后台转出撮合池资产', value: '1010'},
        1012: {label: 'fth兑换usdt交易', value: '1012'},
        1013: {label: '监管节点发放fth', value: '1013'},
        1014: {label: '撮合池转至FTH合约地址', value: '1014'},
      },
      filterVal: [
        "pool_addr",
        "target_addr",
        "direction",
        "coin_symbol",
        "amount",
        "tx_type",
        "confirm_at",
      ],
    };
  },
  created() {
    if (this.$route.query.startTime) {
      this.startTime = this.$route.query.startTime
      this.endTime = this.dataFormat(((new Date(this.startTime).getTime() ) + 86400000)/1000, 'yyyy-MM-dd')
    }
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.addr = this.inputVal1;
      }
      if (this.inputVal2) {
        req.signAddr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1 !== '') {
        req.direction = this.selectVal1;
      }
      if (this.selectVal2) {
        req.type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.coin = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}pool_tx_details`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label: list.tx_type
              list.direction =  list.direction ? '转入' : '转出'
              list.confirm_at = this.dataFormat(list.confirm_at)

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>