<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">认购地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">节点名称</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">创建者地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">节点收款地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">超级节点状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option value="1" label="确认中"></el-option>
            <el-option value="2" label="成功"></el-option>
            <el-option value="3" label="失败"></el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">认购地址类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option value="1" label="用户地址"></el-option>
            <el-option value="2" label="系统地址"></el-option>
          </el-select>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>


    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>认购地址</th>
        <th>USDT认购金额</th>
        <th>现金券认购金额</th>
        <th>认购总金额</th>
        <th>认购地址类型</th>
        <th>节点名称</th>
        <th>链类型</th>
        <th>创建者地址</th>
        <th>节点收款地址</th>
        <th>认购状态</th>
        <th>认购时间</th>
        <th>交易hash</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.addr}}
          </td>
          <td>
            {{scientificNotationToString(list.amount)}} USDT
          </td>
          <td>
            {{scientificNotationToString(list.ticket_num)}} USDT
          </td>
          <td>
            {{scientificNotationToString(list.total_num)}} USDT
          </td>
          <td>
            {{list.addr_type == 1 ? '用户地址' : '系统地址'}}
          </td>

          <td>
            {{list.node_title}}
          </td>
          <td>
            {{list.chain_type}}
          </td>
          <td>
            {{list.creator_addr}}
          </td>
          <td>
            {{list.node_addr}}
          </td>
          <td>
            {{statusTx(list.status)}}
          </td>
          <td>{{ dataFormat(list.time) }}</td>
          <td>
            {{list.hash}}
          </td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td>
            <p style="white-space: nowrap;" >bsc: {{scientificNotationToString(subtotal.amount_bsc)}} USDT</p>
            <p style="white-space: nowrap;" >tron: {{scientificNotationToString(subtotal.amount_tron)}} USDT</p>
            <p style="white-space: nowrap;" >eth: {{scientificNotationToString(subtotal.amount_eth)}} USDT</p>
          </td>
          <td> {{scientificNotationToString(subtotal.ticket_num)}} USDT</td>
          <td> {{scientificNotationToString(subtotal.total_num)}} USDT</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td>
            <p style="white-space: nowrap;" >bsc: {{scientificNotationToString(sum.amount_bsc)}} USDT</p>
            <p style="white-space: nowrap;" >tron: {{scientificNotationToString(sum.amount_tron)}} USDT</p>
            <p style="white-space: nowrap;" >eth: {{scientificNotationToString(sum.amount_eth)}} USDT</p>
          </td>
          <td> {{scientificNotationToString(sum.ticket_num)}} USDT</td>
          <td> {{scientificNotationToString(sum.total_num)}} USDT</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "super_partner_record",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      selectVal1: "",
      selectVal2: "",
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        'addr',
        'hash',
        'amount',
        'ticket_num',
        'status',
        'time',
        'note',
      ],
    };
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    statusTx (type) {
      if (type == 1) {
        return '认购中'
      }
      if (type == 2) {
        return '成功'
      }
      return '失败'
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {

      let req = {
        page: this.page,
        size: this.size,
      };

      if (this.inputVal1) {
        req.addr = this.inputVal1
      }
      if (this.inputVal2) {
        req.node_title = this.inputVal2
      }
      if (this.inputVal3) {
        req.creator_addr = this.inputVal3
      }
      if (this.inputVal4) {
        req.node_addr = this.inputVal4
      }
      if (this.selectVal1) {
        req.status = this.selectVal1
      }
      if (this.selectVal2) {
        req.addr_type = this.selectVal2
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}super/details`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>