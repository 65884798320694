<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>使用Token</th>
        <th>兑换Token</th>
        <th>预计使用数量</th>
        <th>预计兑换数量</th>
        <th>已使用数量</th>
        <th>已兑换数量</th>
        <th>剩余可用数量</th>
        <th>消耗矿工费</th>
        <th>挂单时间</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.borrow_addr }}</td>
          <td>{{ list.public_addr }}</td>
          <td>{{ list.contract_number }}</td>
          <td>{{ list.mechanism_name }}</td>
          <td>{{ list.chain_type }}</td>
          <td>{{ list.symbol }}</td>
          <td>{{ statusObj[list.status] }}</td>
          <td>{{ list.borrow_amount }}</td>
          <td>{{ list.curr_borrow }}</td>
          <td>{{ list.pledge_amount }}</td>
          <td>{{ list.surplus_pledge }}</td>
          <td>{{ list.duration_day }}</td>
          <td>{{ list.daily_borrow_rate }}</td>
          <td>{{ list.interest_paid }}</td>
          <td>{{ dataFormat(list.created_at) }}</td>
          <td>{{ dataFormat(list.expiration_at) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "institutionLoanLiquidityOrder",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      inputVal2: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      selectArr: [{value: '钱包', label: '钱包'}],
      payShow: false,
      rateShow: false,
      two_auth_code: '',
      rateVal: '',
      statusObj: {
        1: '申请中',
        2: '申请成功募集中',
        3: '募集成功还款中',
        4: '放款成功还款中',
        5: '还款中',
        6: '已还款成功',
        7: '申请失败',
        8: '募集失败',
        9: '放款失败',
        10: '还款失败',
      },
      transactionShow: false,
      liquidityShow: false,
      option2: [],
      coin1: '',
      coin2: '',
      coinVal1: '',
      coinVal2: '',
      curCoin1: {},
      curCoin2: {},
      coin3: '',
      coin4: '',
      coinVal3: '',
      coinVal4: '',
      curCoin3: {},
      curCoin4: {},
      pairCoinData: {},
      payType: '',
      coinBalance1: '',
      coinBalance2: '',
      coinPriceTx: '',
      transactionType: '',
    }
  },
  created() {
    if (this.$route.query.contract_number) {
      this.resInit()
    }
  },
  methods: {
    hideRateFn () {
      this.liquidityShow = false
      this.transactionShow = false
    },
    submitLiquidityFn () {
      if (!this.coinVal3) {
        this.$message.error('请输入币种1数量')
        return false
      }
      if (!this.coinVal4) {
        this.$message.error('请输入币种2数量')
        return false
      }
      if (this.coin3 === '') {
        this.$message.error('请选择币种1')
        return false
      }
      if (this.coin4 === '') {
        this.$message.error('请选择币种2')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        ad_id: this.$route.query.contract_number,
        coin1_id: this.curCoin3.coin_id,
        coin2_id: this.curCoin4.coin_id,
        coin1_num: this.coinVal3,
        coin2_num: this.coinVal4,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/add_liquidity`, req, 'post')
          .then(res => {
            this.$message.success('交易已经广播')
            this.coin3 = ''
            this.coin4 = ''
            this.two_auth_code = ''
            this.coinVal3 = ''
            this.coinVal4 = ''
            this.curCoin3 = {}
            this.curCoin4 = {}
            this.pairCoinData = {}

            this.liquidityShow = false
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    searchLiquidityCoin () {
      if (this.coin3 !== '' && this.coin4 !== '') {
        this.coinVal3 = ''
        this.coinVal4 = ''
        this.curCoin3 = this.option2[this.coin3]
        this.curCoin4 = this.option2[this.coin4]
        this.eachPairBalance()
      }

    },
    eachPairBalance () {
      let req = {
        ad_id: this.$route.query.contract_number,
        coin1_id: this.curCoin3.coin_id,
        coin2_id: this.curCoin4.coin_id,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/pair_info`, req, 'get')
          .then(res => {
            this.pairCoinData = res ? res : {}
          })
          .catch(res => {
            this.pairCoinData = {}
            this.$message.error(res.msg)
          })
    },
    showTransactionFn (type, index) {
      if (type == 'liquidity') {
        this.coin3 = ''
        this.coin4 = ''
        this.coinVal3 = ''
        this.coinVal4 = ''
        this.curCoin3 = {}
        this.curCoin4 = {}
        this.pairCoinData = {}
        this.liquidityShow = true
      } else {
        this.coin1 = ''
        this.coin2 = ''
        this.coinVal1 = ''
        this.coinVal2 = ''
        this.curIndex = ''
        this.payType = ''
        this.coinBalance1 = ''
        this.coinBalance2 = ''
        this.curCoin1 = {}
        this.curCoin2 = {}
        this.coinPriceTx = ''
        this.transactionShow = true
      }
      this.transactionType = type
      this.curIndex = index
      this.two_auth_code = ''
      this.coinListFn()

    },
    coinListFn() {
      let req = {
        chain_type: this.resData[this.curIndex].chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_type_coin_list`, req, 'get')
          .then(res => {
            if (this.transactionType == 'liquidity') {
              this.coin3 = ''
              this.coin4 = ''
            } else {
              this.coin1 = ''
              this.coin2 = ''
            }

            this.option2 = res.list ? res.list : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    showRateFn (index) {
      this.curIndex = index
      this.rateShow = true
    },
    editRateFn () {

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'organ/remove_liquidity'
      let req = {
        ad_id: this.$route.query.contract_number,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否移除流动池', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('移除成功')
              this.two_auth_code = ''
              this.rateShow = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
        ad_id: this.$route.query.contract_number,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/liquidity`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>