<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <div class="com_search_box">


      <div class="com_item_search">
        <div class="com_item_tx">投资钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>



      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>


    <section class="com_search_box" >

      <div class="com_item_search">
        <el-button type="primary" @click="singFn('')" v-if="isGenerateButton"  size="small" >新增自动投资地址</el-button>
        <el-button type="primary" @click="showLoanFn" v-if="false"  size="small" >自动闪兑设置</el-button>
      </div>
    </section>

    <div>

      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>投资钱包管理</th>
        <th>钱包资产</th>
        <th>链类型</th>
        <th>币种</th>
        <th>私钥状态</th>
        <th>地址状态</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData">
          <td>{{list.addr}}</td>
          <td>
            <p v-for="fin in list.fins">
              {{fin.symbol}}: {{fin.amount}}
            </p>

          </td>
          <td>{{list.chain_type}}</td>
          <td>{{list.coin_symbol}}</td>
          <td>{{list.pri_enabled ? '正常' : '未录入'}}</td>
          <td>{{list.addr_enabled ? '启用' : '禁用'}}</td>
          <td class="tab_btn">
            <span class="btn" @click="singFn(index)" v-if="isGenerateButton">重传私钥</span>
            <span class="btn" @click="showStatusFn(index)" v-if="isEditButton">状态管理</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curIndex === '' ? '上传投资钱包地址私钥' : '重传私钥'" :width="750">
      <div class="com_search_box">

        <div class="com_item_search">
          <div class="com_item_tx">链类型</div>
          <div class="com_item_input mr_10" style="width: 550px">
            <el-select
                v-model="chain_type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
                :disabled="curIndex !== ''"
                @change="coinListFN"
            >
              <el-option
                  v-for="item in chainTypeArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">币种</div>
          <div class="com_item_input mr_10" style="width: 550px">
            <el-select
                v-model="coinVal"
                clearable
                filterable
                placeholder="请选择"
                :disabled="curIndex !== ''"
                size="small"
            >
              <el-option v-for="item in options2"
                         :key="item.id"
                         :label="item.symbol"
                         :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">公钥</div>
          <div class="com_item_input mr_10" style="width: 550px">
            <el-input
                v-model="keyR.pub_key"
                placeholder=""
                :readonly="true"
                rows="7"
                type="textarea"
                size="small">
            </el-input>
          </div>
          <span class="color_T1 cur_po" @click="copyText(keyR.pub_key)">复制</span>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">秘钥密文</div>
          <div class="com_item_input" style="width: 550px">
            <el-input
                v-model="toAddr"
                placeholder="请输入"
                rows="7"
                type="textarea"
                size="small">
            </el-input>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">谷歌验证码</div>
          <div class="com_item_input">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary"  @click="submitKeyFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isLoan" title="自动闪兑设置" :width="750">
      <section class="com_from_box">

        <div class="com_from_item">
          <div class="com_from_left">
            开关
          </div>
          <div class="com_from_right">
            <el-radio-group v-model="orderData.open" class="ml-4">
              <el-radio :label="true" size="large">开启</el-radio>
              <el-radio :label="false" size="large">关闭</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">BSC钱包地址</div>
          <div class="com_from_right">
            {{ orderData.bsc.addr }}
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">
            闪兑方式
          </div>
          <div class="com_from_right">
            <el-radio-group v-model="orderData.bsc.swap_type" class="ml-4">
<!--              <el-radio label="0" size="large">同链闪兑</el-radio>-->
              <el-radio label="1" size="large">跨链闪兑</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">USDT自动闪兑阈值</div>
          <div class="com_from_right">
            <el-input
                v-model="orderData.bsc.usdt_threshold"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">钱包余额：{{ orderData.bsc.usdt_bal }} USDT</span>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">USDT自动闪兑数量</div>
          <div class="com_from_right">
            <el-input
                v-model="orderData.bsc.usdt_amt"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">USDT</span>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">BNB自动闪兑阈值</div>
          <div class="com_from_right">
            <el-input
                v-model="orderData.bsc.main_threshold"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">钱包余额：{{ orderData.bsc.main_bal }} BNB</span>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">BNB自动闪兑数量</div>
          <div class="com_from_right">
            <el-input
                v-model="orderData.bsc.main_amt"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">BNB</span>
        </div>
        <p style="height: 2px; background: #eee; margin-bottom: 20px" ></p>

        <div class="com_from_item">
          <div class="com_from_left">TRON钱包地址</div>
          <div class="com_from_right">
            {{ orderData.tron.addr }}
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">
            闪兑方式
          </div>
          <div class="com_from_right">
            <el-radio-group v-model="orderData.tron.swap_type" class="ml-4">
<!--              <el-radio label="0" size="large">同链闪兑</el-radio>-->
              <el-radio label="1" size="large">跨链闪兑</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">USDT自动闪兑阈值</div>
          <div class="com_from_right">
            <el-input
                v-model="orderData.tron.usdt_threshold"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">钱包余额：{{ orderData.tron.usdt_bal }} USDT</span>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">USDT自动闪兑数量</div>
          <div class="com_from_right">
            <el-input
                v-model="orderData.tron.usdt_amt"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">USDT</span>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">TRX自动闪兑阈值</div>
          <div class="com_from_right">
            <el-input
                v-model="orderData.tron.main_threshold"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">钱包余额：{{ orderData.tron.main_bal }} TRX</span>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">TRX自动闪兑数量</div>
          <div class="com_from_right">
            <el-input
                v-model="orderData.tron.main_amt"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">TRX</span>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>


          <div class="color_T5">提示:
            当钱包地址内币种余额低于以上设置的阈值时，系统会自动向钱包地址闪兑代币，由于单次跨链闪

            兑最低金额需≥ 30 USDT，请设置自动闪兑数量时输入价值 ≥ 30  USDT的代币数量，以免自动闪兑失败。
          </div>

      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isLoan = false">取消</el-button>
                    <el-button @click="subOrderFn" type="primary">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isTransctionAlert" title="状态管理" :width="550">
      <div class="com_search_box">
        <div class="com_item_search">
          <div class="com_item_tx">选择地址状态</div>
          <div class="com_item_input mr_10" >
            <el-select
                v-model="is_open"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  label="开启"
                  :value="true"
              ></el-option>
              <el-option
                  label="关闭"
                  :value="false"
              ></el-option>
            </el-select>
          </div>

        </div>

        <div class="com_item_search">
          <div class="com_item_tx">谷歌验证码</div>
          <div class="com_item_input">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>


      </div>
      <div class="com_item_search">
        <p>温馨提示：</p>
        <p>1.同一条链只能同时有一个自动投资地址生效</p>
        <p>2.关闭自动投资地址后，将不会自动投资借贷订单</p>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isTransctionAlert = false">关闭</el-button>
                <el-button type="primary"  @click="submitTransctionFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "addInvestAddress",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      two_auth_code: '',
      resData: [],
      keyR: {},
      curIndex: '',
      isAlert: false,
      isLoan: false,
      orderData: {
        bsc:{},
        tron:{},
        open: false
      },
      gasPriceData: {},
      toAddr: '',
      is_open: '',
      chain_type: '',
      options2: '',
      coinVal: '',
      inputVal1: '',
      selectVal1: '',
      selectVal2: '',
      coinInfo: [],
      isTransctionAlert: false
    }
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.resInit()
  },
  methods: {
    coinListFN() {
      let req = {
        chain_type: this.chain_type,
        size: 10000,
        is_white: true,
      }
      this.$ajax(`${this.Utils.URL.goURL}coin/list`, req, 'get')
          .then(res => {
            this.options2 = res ? res.list : []

            if (this.curIndex === '') {

              this.coinVal = ''
            }
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    showStatusFn (index) {
      this.isTransctionAlert = true
      this.curIndex = index
      this.two_auth_code = ''
      this.is_open = this.resData[index].addr_enabled
    },
    submitTransctionFn () {
      let index = this.curIndex

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'sign/set_addr_status'
      let req = {

        is_bool : this.is_open,
        two_auth_code: this.two_auth_code,
        usage: 'invest',
        coin_id: this.resData[index] ? this.resData[index].coin_id : '',
        chain_type: this.resData[index] ? this.resData[index].chain_type : '',
        id: this.resData[index] ? this.resData[index].id : '',
      }

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.$message.success('成功')
            this.isTransctionAlert = false
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    subOrderFn () {
      let obj = {
        tron: {},
        bsc: {}
      }
      obj.open = this.orderData.open
      obj.bsc.chain_type = this.orderData.bsc.chain_type
      obj.bsc.main_amt = parseFloat(this.orderData.bsc.main_amt)
      obj.bsc.main_threshold = parseFloat(this.orderData.bsc.main_threshold)
      obj.bsc.usdt_amt = parseFloat(this.orderData.bsc.usdt_amt)
      obj.bsc.swap_type = parseFloat(this.orderData.bsc.swap_type)
      obj.bsc.usdt_threshold = parseFloat(this.orderData.bsc.usdt_threshold)
      obj.tron.chain_type = this.orderData.tron.chain_type
      obj.tron.main_amt = parseFloat(this.orderData.tron.main_amt)
      obj.tron.main_threshold = parseFloat(this.orderData.tron.main_threshold)
      obj.tron.usdt_amt = parseFloat(this.orderData.tron.usdt_amt)
      obj.tron.swap_type = parseFloat(this.orderData.tron.swap_type)
      obj.tron.usdt_threshold = parseFloat(this.orderData.tron.usdt_threshold)
      if (obj.bsc.main_amt < 0) {
        this.$message.error('请输入正确的BNB数量')
        return false
      }
      if (obj.bsc.main_threshold < 0) {
        this.$message.error('请输入正确的BNB阈值')
        return false
      }
      if (obj.bsc.usdt_amt < 0) {
        this.$message.error('请输入正确的BSC钱包的USDT数量')
        return false
      }
      if (obj.bsc.usdt_threshold < 0) {
        this.$message.error('请输入正确的BSC钱包的USDT阈值')
        return false
      }

      if (obj.tron.main_amt < 0) {
        this.$message.error('请输入正确的TRX数量')
        return false
      }
      if (obj.tron.main_threshold < 0) {
        this.$message.error('请输入正确的TRX阈值')
        return false
      }
      if (obj.tron.usdt_amt < 0) {
        this.$message.error('请输入正确的TRON钱包的USDT数量')
        return false
      }
      if (obj.tron.usdt_threshold < 0) {
        this.$message.error('请输入正确的TRON钱包的USDT阈值')
        return false
      }

      let url = 'trade/edit_auto_flash'
      let req = obj
      this.$confirm(`是否提交自动闪兑配置`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post', 2500000, false, true)
            .then(res => {
              this.$message.success('已提交')
              this.isLoan = false

            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    showLoanFn () {
      this.isLoan = true

      this.two_auth_code = ''
      this.$ajax(`${this.Utils.URL.goURL}trade/auto_flash`, {

      }, 'get')
          .then(res => {
            this.orderData = res ? res : {
              bsc:{
                swap_type: '1'
              },
              tron:{
                swap_type: '1'
              },
              open: false
            }
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },
    resInit () {
      let url = 'sign/auto_invest_addr_list'

      let req = {
        usage: 'invest',
        page: this.page,
        size: this.size,
      }
      if (this.inputVal1) {
        req.addr = this.inputVal1
      }
      if (this.selectVal1) {
        req.chain_type = this.selectVal1
      }
      if (this.selectVal2) {
        req.symbol = this.selectVal2
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total);
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    singFn (index) {
      this.isAlert = true
      this.toAddr = ''
      this.chain_type = ''
      this.coinVal = ''
      this.two_auth_code = ''
      this.curIndex = index
      if (index !== '') {

        this.coinVal = this.resData[index].coin_id
        this.chain_type = this.resData[index].chain_type
        this.coinListFN()
      }
      let req = {
        usage: 'invest'
      }
      this.$ajax(`${this.Utils.URL.goURL}sign/public_key`, req, 'get')
          .then(res => {
            this.keyR = res ? res : {}

          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitKeyFn() {
      let index = this.curIndex
      if (index === '' && this.chain_type === '') {
        this.$message.error('请选择链类型')
        return false
      }
      if (index === '' && this.coinVal === '') {
        this.$message.error('请选择币种')
        return false
      }
      if (this.toAddr === '') {
        this.$message.error('请填写私钥')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'sign/add_hot_key'
      let req = {
        id: this.keyR.id,
        key: this.toAddr,
        two_auth_code: this.two_auth_code,
        usage: 'invest',
        coin_id: this.resData[index] ? this.resData[index].coin_id : this.coinVal,
        chain_type: this.resData[index] ? this.resData[index].chain_type : this.chain_type,
        addr: this.resData[index] ? this.resData[index].addr : '',
      }

      this.$confirm(`${this.curIndex === '' ? '是否上传投资钱包地址私钥' : '是否重传私钥'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('成功')
              this.isAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
  },
}
</script>

<style scoped>

</style>