<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="user_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">質押週期</div>
        <div class="com_item_input">
          <el-select v-model="duration_day" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in typeObj" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">狀態</div>
        <div class="com_item_input">
          <el-select v-model="status" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="begin_time" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="end_time" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>

    </div>

    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>钱包ID</th>
          <th>FUSD數量</th>
          <th>質押週期</th>
          <th>LP數量</th>
          <th>LP價格</th>
          <th>復押次數</th>
          <th>預計利息FUSD</th>
          <th>預計利息SCF</th>
          <th>狀態</th>
          <th>開始時間</th>
          <th>結束時間</th>
          <th>交易hash</th>
          <th>操作人</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ scientificNotationToString(list.usdt_amt) }}</td>
            <td>{{ list.duration_day }}</td>
            <td>{{ list.real_amt }}</td>
            <td>{{ list.lp_price }}</td>
            <td>{{ list.invest_time }}</td>
            <td>{{ scientificNotationToString(list.fusd_amt) }}</td>
            <td>{{ scientificNotationToString(list.ftc_amt) }}</td>
            <td>{{ list.status }}</td>
            <td>{{ list.start_at }}</td>
            <td>{{ list.end_at }}</td>
            <td>
              <a :href="`https://mainchain.ftcchain.org/#/tx/${list.tx_id}`" target="_blank" class="link">{{ list.tx_id }}</a>
            </td>
            <td>{{ list.operator }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td> {{scientificNotationToString(subtotal.usdt_amt)}}</td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.real_amt)}}</td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.fusd_amt)}}</td>
            <td>{{scientificNotationToString(subtotal.ftc_amt)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td> {{scientificNotationToString(sum.usdt_amt)}}</td>
            <td></td>
            <td>{{scientificNotationToString(sum.real_amt)}}</td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(sum.fusd_amt)}}</td>
            <td>{{scientificNotationToString(sum.ftc_amt)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>
  </article>
</template>

<script>
export default {
  name: "octstakingrecord",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      user_id: '',
      begin_time: '',
      end_time: '',
      user_wallet: '',
      amount: '',
      two_auth_code: '',
      isShow: false,
      duration_day: '',
      typeObj: {
        1: { label: '7天', value: '1' },
        2: { label: '15天', value: '2' },
        3: { label: '30天', value: '3' },
      },
      status: '',
      statusList: {
        1: { label: '初始狀態，交易確認中', value: '1' },
        2: { label: '下單成功，計息中', value: '2' },
        3: { label: '下單失敗', value: '3' },
        4: { label: '訂單到期，本金待返回', value: '4' },
        5: { label: '訂單到期，本金已返還', value: '5' },
      },
      subtotal: {},
      sum: {},
    };
  },
  created() {
    this.resInit();
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.user_id) {
        req.user_id = this.user_id;
      }
      if (this.begin_time) {
        req.begin_time = this.begin_time;
      }
      if (this.end_time) {
        req.end_time = this.end_time;
      }
      if (this.duration_day) {
        req.duration_day = this.duration_day;
      }
      if (this.status) {
        req.status = this.status;
      }
      this.$ajax(`${this.Utils.URL.goURL}otcLp_order_list`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {
            list.start_at = list.start_at.length > 0 ? this.dataFormat(list.start_at) : '-'
            list.end_at = list.end_at.length > 0 ? this.dataFormat(list.end_at) : '-'
            list.status = this.statusList[list.status].label
          })
          
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.radio_group {
  &_head {
    display: flex;
    p {
      flex-basis: 50%;
      margin-bottom: 5px;
    }
  }
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .el-radio {
      flex-basis: 50%;
      margin: 5px 0;
    }
}
}

</style>