<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">From地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">To地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">交易hash</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">交易方向</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>钱包ID</th>
        <th>From地址</th>
        <th>To地址</th>
        <th>交易hash</th>
        <th>区块高度</th>
        <th>链类型</th>
        <th>币种</th>
        <th>交易方向</th>
        <th>交易类型</th>
        <th>交易数量</th>
        <th>链上交易数量</th>
        <th>差额</th>
        <th>审计结果</th>
        <th>交易时间</th>
        <th>备注</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.wallet_id}}</td>
          <td>{{list.form_addr}}</td>
          <td>{{list.to_addr}}</td>
          <td>{{list.tx_id}}</td>
          <td>{{list.block_height}}</td>
          <td>{{list.chain_type}}</td>
          <td>{{list.coin_symbol}}</td>
          <td>{{list.direction}}</td>
          <td>{{list.tx_type}}</td>
          <td>{{scientificNotationToString(list.record_amount)}}</td>
          <td>{{scientificNotationToString(list.public_amount)}}</td>
          <td>{{scientificNotationToString(list.transfer_diff)}}</td>
          <td>{{list.risk_result}}</td>
          <td>{{(list.time)}}</td>
          <td>{{list.note}}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.record_amount)}}</td>
          <td> {{scientificNotationToString(subtotal.public_amount)}}</td>
          <td> {{scientificNotationToString(subtotal.transfer_diff)}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.record_amount)}}</td>
          <td> {{scientificNotationToString(sum.public_amount)}}</td>
          <td> {{scientificNotationToString(sum.transfer_diff)}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "alarmTransferAbnormal",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      typeObj: {
        1: {label: '借贷质押', value: '1'},
        2: {label: '投资', value: '2'},
        3: {label: '创建合伙人', value: '3'},
        4: {label: '认购合伙人', value: '4'},
        5: {label: '认购FTH', value: '5'},
        6: {label: '创建超级节点', value: '6'},
        7: {label: '认购超级节点', value: '7'},
        1001: {label: '返还投资金额', value: '1001'},
        1002: {label: '返还用户质押资金', value: '1002'},
        1003: {label: '返还认购合伙人资金', value: '1003'},
        1004: {label: '返还认购合伙人资金', value: '1004'},
        1005: {label: '返还创建合伙人资金', value: '1005'},
        1006: {label: '合伙人有上下级关系', value: '1006'},
        1007: {label: '投资收益', value: '1007'},
      },
      typeObj1: {
        1: {label: '充值', value: '1'},
        2: {label: '提币', value: '2'},
      },
      filterVal: [
        "wallet_id",
        "form_addr",
        "to_addr",
        "tx_id",
        "block_height",
        "chain_type",
        "coin_symbol",
        "direction",
        "tx_type",
        "record_amount",
        "public_amount",
        "transfer_diff",
        "time",
        "risk_result",
        "note",
      ],
    };
  },
  created() {

    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.form = this.inputVal2;
      }
      if (this.inputVal3) {
        req.to = this.inputVal3;
      }
      if (this.inputVal4) {
        req.hash = this.inputVal4;
      }
      if (this.selectVal1) {
        req.status = this.selectVal1;
      }
      if (this.selectVal2) {
        req.dire = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.coin = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}alarm_transfer_abnormal`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label: list.tx_type
              list.direction = this.typeObj1[list.direction] ? this.typeObj1[list.direction].label: list.direction
              list.risk_result =  list.risk_result == 1 ? '正常' : '异常'
              list.time = this.dataFormat(list.time)

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>