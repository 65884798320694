<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="user_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>

      </div>

    </div>
    <div v-show="resData.length">
      <div class="box-title  mt_40">
        <span style="width: 4%;">&nbsp;</span>
        <span style="width: 30%;">&nbsp;</span>
        <span style="width: 42%;" class="tx">份額=每個區塊產生獎勵後，用戶可獲取的獎勵數額</span>
        <span style="width: 12%;">&nbsp;</span>
        <span style="width: 12%;">&nbsp;</span>
        <span style="width: 12%;">&nbsp;</span>
      </div>
      <table class="com-table">
        <thead class="com_thead">
          <th style="width: 4%;">序號</th>
          <th style="width: 30%;">錢包ID</th>
          <th style="width: 15%;">超級節點份額</th>
          <th style="width: 15%;">Fin-Chain份額</th>
          <th style="width: 12%;">個人總挖礦</th>
          <th style="width: 12%;">已釋放挖礦</th>
          <th style="width: 12%;">未釋放挖礦</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ list.super_amount }}</td>
            <td>{{ list.fin_chain_amount }}</td>
            <td>{{ list.total_amount }}</td>
            <td>{{ list.total_release_amount }}</td>
            <td>{{ list.total_unrelease_amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page" v-show="resData.length">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

    
  </article>
</template>

<script>
export default {
  name: "miningList",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      user_id: '',
    };
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn() {
      if (this.user_id != '') {
        this.page = 1;
        this.resInit();
        return;
      } else {
        this.resData = [];
      }
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.user_id) {
        req.user_id = this.user_id;
      }
      this.$ajax(`${this.Utils.URL.goURL}mining_list`, req, "get")
        .then((res) => {
          if (res.list == null) {
            this.$message.error("暫無數據");
            return;
          }
          this.resData = res.list ? res.list : []
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.box-title {
  display: flex;
}
.box-title .tx {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  white-space: nowrap;
}
</style>