<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">委托購買錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">販賣錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">鏈類別</div>
        <div class="com_item_input">
          <el-select
            v-model="chainType"
            clearable
            filterable
            placeholder="請選擇鏈類別"
            size="small">
            <el-option v-for="item in chainTypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

    </div>
    <div class="com_search_box">
      <!-- <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button> -->
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>

    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
        <th>序號</th>
        <th>委托購買錢包ID</th>
        <th>販賣錢包ID</th>
        <th>販賣數量</th>
        <th>販賣單價</th>
        <th>販賣hash(FSU)</th>
        <th>收款金額(USDT)</th>
        <th>付款HASH</th>
        <th>付款完成時間</th>
        <th>到帳hash</th>
        <th>到帳時間</th>
        <th>備註</th>
        
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.purchase_wallet_address }}</td>
          <td>{{ list.wallet_address }}</td>
          <td>{{ list.amount }}</td>
          <td>{{ list.price }}</td>
          <td>{{ list.from_tx_id }}</td>
          <td>{{ list.rec_amount }}</td>
          <td>{{ list.to_tx_id }}</td>
          <td>{{ list.to_at }}</td>
          <td>{{ list.rec_tx_id }}</td>
          <td>{{ list.rec_at }}</td>
          <td>{{ list?.remake }}</td>
        </tr>

        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(subtotal.amount) }}</td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(subtotal.rec_amount) }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(sum.amount) }}</td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(sum.rec_amount) }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>
  </article>
</template>

<script>
export default {
  name: "otc-sale-order",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      inputVal1: "",
      inputVal2: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      chainType: '',
      chainTypeList: [
        {
          value: 'bsc',
          text: 'BSC',
        },
        {
          value: 'eth',
          text: 'ETH',
        },
        {
          value: 'tron',
          text: 'TRON',
        }
      ],
      filterVal: [
        "purchase_wallet_address",
        "wallet_address",
        "amount",
        "price",
        "from_tx_id",
        "rec_amount",
        "to_tx_id",
        "to_at",
        "rec_tx_id",
        "rec_at",
        "remake",
      ],
      order_id: ''
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    if (this.$route.query.order_id) {
      this.order_id = this.$route.query.order_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/otc_order_item_list`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.rec_at = list.rec_at > 0 ? this.dataFormat(list.rec_at) : '-'
            list.to_at = list.to_at > 0 ? this.dataFormat(list.to_at) : '-'
          })
          if (this.resDataFullForExport.length > 0) {
            this.exportExcel(this.tHeader, this.filterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.purchase_wallet_address = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_address = this.inputVal2;
      }
      if (this.chainType) {
        req.chan_type = this.chainType;
      }
      if (this.startTime) {
        req.begin = this.startTime
      }
      if (this.endTime) {
        req.end= this.endTime
      }
      if (this.order_id) {
        req.order_id = this.order_id
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/otc_order_item_list`, req, "get")
          .then((res) => {
            if (res.list === null || res.list.length === 0) {
              this.$message.error("暂无数据");
              this.total = 0;
              this.resData = [];
              return;
            }

            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              list.rec_at = +list.rec_at > 0 ? this.dataFormat(+list.rec_at) : '-'
              list.to_at = +list.to_at > 0 ? this.dataFormat(+list.to_at) : '-'
            })
            
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>