<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          认购节点
        </div>
        <div class="com_from_right">
          {{ name }}
          <!-- <el-select
              v-model="node_id"
              clearable
              filterable
              placeholder="请选择"
              size="small">
            <el-option v-for="item in resData"
                       :key="item.node_id"
                       :label="item.node_id + '--' + item.title"
                       :value="item.node_id">
            </el-option>
          </el-select> -->
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          剩余额度
        </div>
        <div class="com_from_right">
          {{ surplus }}
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          认购数量
        </div>
        <div class="com_from_right">
          <el-input
            v-model="node_num"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          谷歌认证码
        </div>
        <div class="com_from_right">
          <el-input
            v-model="two_auth_code"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "buySuperNode",
  data() {
    return {
      node_id: '',
      node_num: '',
      two_auth_code: '',
      type: 1,
      resData: [],
      page: 1,
      size: 100,
      surplus: '',
      name: ''
    }
  },
  created() {
    this.surplus = this.$route.query.amount
    this.name = this.$route.query.name
    this.node_id = this.$route.query.node_id
    this.resInit()
  },
  methods: {
    
    resInit () {
      let req = {
        status: 2
      }
      this.$ajax(`${this.Utils.URL.goURL}super/super_node_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn() {
      // if (this.node_id === "") {
      //   this.$message.error("请选择认购节点");
      //   return false;
      // }
      if (this.node_num === "") {
        this.$message.error("请输入认购数量");
        return false;
      }
      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {

        node_id: +this.node_id,
        num: +this.node_num,
        two_auth_code: this.two_auth_code,
      }
      this.$ajax(`${this.Utils.URL.goURL}super/subscribe`, req, 'post')
        .then(res => {
          // this.node_num = ''
          this.$message.success('认购成功')
          this.$router.push("/superPartnerRecord");
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },

  }
}
</script>

<style scoped>

</style>