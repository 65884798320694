<template>
  <article class="h5_login_view">
    <div class="login_box">
      <h2 class="title_h2">登陆</h2>
      <p class="item">
        <span>账号:</span><input class="input_tx" v-model="user_name" type="text"/>
      </p>
      <p class="item">
        <span>密码:</span>
        <input class="input_pass" v-model="pass" type="password"/>
      </p>
      <p class="item">
        <span>谷歌验证码:</span>
        <input class="input_code" v-model="two_auth_code" type="text"/>
      </p>
      <p class="item" v-if="false">
        <span>验证码</span><input class="input_tx" type="text"/>
      </p>
      <p class="item tx_cen">
        <input class="input_btn" type="button" @click="submitFn" value="登陆"/>
      </p>
    </div>
  </article>
</template>

<script>
import md5 from "blueimp-md5";

export default {
  name: "login",
  data() {
    return {
      user_name: "",
      pass: "",
      two_auth_code: "",
    };
  },
  methods: {
    submitFn() {
      let req = {
        user_name: this.user_name,
        two_auth_code: this.two_auth_code,
        pass: md5(this.pass),
      };
      this.$ajax(`${this.Utils.URL.goURL}login_admin`, req, "post")
        .then((res) => {
          if (res.is_reset != 2) {
            window.sessionStorage.setItem("user_id", res.user_id);
            this.$router.replace("/loginReset");
            return false;
          }
          let ruleLists = res.power_list ? res.power_list : [];
          ruleLists = ruleLists.sort((a, b) => {
            return a.id - b.id;
          });
          ruleLists = ruleLists.sort((a, b) => {
            return a.sort - b.sort;
          });
          let arr = [];
          let arr1 = [];
          ruleLists.forEach((val, index) => {
            if (val.type == "1") {
              val.children = [];
              arr.push(val);
            }
            if ((val.check == 1 || val.expansion == 1) && val.path) {

              arr1.push(val);
            }
          });

          let ruleFormat = (obj) => {
            ruleLists.forEach((val) => {
              if (obj.children) {
                if (obj.id == val.parent_id && obj.id != val.id) {
                  val.children = [];
                  obj.children.push(val);
                  ruleFormat(val);
                }
              }
            });
          };
          arr.forEach((val) => {
            ruleFormat(val);
          });
          window.localStorage.setItem("ruleMenu", JSON.stringify(arr));
          window.localStorage.setItem("ruleRouter", JSON.stringify(arr1));
          window.token = res.token;
          window.userName = this.user_name;
          window.localStorage.setItem("userName", this.user_name);
          window.localStorage.setItem("mechanism", JSON.stringify(res.mechanism ? res.mechanism : {}));
          window.localStorage.setItem("token", window.token);
          this.$router.push("/home");
          setTimeout(() => {
            window.location.reload();
          }, 300);
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>


<style scoped lang="scss">
.h5_login_view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .title_h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .login_box {
    background: $FFF;
    border-radius: 8px;
    padding: 20px;
    width: 420px;

    .item {
      margin-bottom: 20px;
    }

    .input_tx {
      width: 250px;
      line-height: 40px;
      margin-left: 20px;
      border-radius: 8px;
      border: 1px solid $BR;
      padding: 0 20px;
    }

    .input_code {
      width: 178px;
      line-height: 40px;
      margin-left: 20px;
      border-radius: 8px;
      border: 1px solid $BR;
      padding: 0 20px;
    }

    .input_pass {
      width: 250px;
      line-height: 40px;
      margin-left: 20px;
      border-radius: 8px;
      border: 1px solid $BR;
      padding: 0 20px;
    }

    .input_btn {
      width: 200px;
      background: $T4;
      line-height: 40px;
      text-align: center;
      border-radius: 8px;
      margin: 0 auto;
      color: $FFF;
      cursor: pointer;
    }
  }
}
</style>

