<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">


      <div class="com_item_search">
        <div class="com_item_tx">钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">社区名称</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">报销类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

    </div>


    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>报销时间</th>
        <th>报销类型</th>
        <th>数量(USDT)</th>
        <th>bsc钱包地址</th>
        <th>hash</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.created_at}}</td>
          <td>{{list.reason_tx}}</td>
          <td>{{list.amount}}</td>
          <td>{{list.bsc_addr}}</td>
          <td>{{list.hash}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>



  </article>
</template>

<script>
export default {
  name: "communityReimburseDetails",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      options: [],
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      expenses: '',
      reason: '',
      min: '',
      max: '',
      isShow: false,
      passShow: false,
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      typeObj: {
        1: {label: '工作室', value: '1'},
        2: {label: '俱乐部', value: '2'},
        3: {label: '体系', value: '3'},
        4: {label: '分公司', value: '4'},
      },
      typeObj1: {
        1: {label: '申请中', value: '1'},
        2: {label: '已通过', value: '2'},
        3: {label: '已拒绝', value: '3'},
      },
      typeObj2: {
        1: {label: '组织社员活动经费', value: '1'},
        2: {label: '社区空间装修', value: '2'},
        3: {label: '申请备用金', value: '3'},
      },
      filterVal: [
        "created_at",
        "reason_tx",
        "amount",
        "bsc_addr",
        "hash",
      ],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.inputVal1 = this.$route.query.id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showFn (index, type) {
      this.curIndex = index
      this.curType = type
      this.two_auth_code = ''
      this.reason = ''

      this.isShow = true
    },
    editRateFn () {
      let index = this.curIndex

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'submit_community_activity_operation'
      let req = {
        id: this.resData[index].id,
        status: this.curType,
        two_auth_code: this.two_auth_code,
      }

      if (this.reason == '') {

        this.$message.error('请填写备注')
        return  false
      }

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.$message.success('操作成功')
            this.resInit()
            this.isShow = false
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.user_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.community_name = this.inputVal2;
      }
      if (this.inputVal3) {
        req.name = this.inputVal3;
      }
      if (this.inputVal4) {
        req.bsc_addr = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.community_level = this.selectVal2;
      }
      if (this.selectVal3 !== '') {
        req.status = this.selectVal3;
      }
      if (this.selectVal4) {
        req.fail_status = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}reimburse_detail`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :

              list.reason_tx = this.typeObj2[list.reason_id] ? this.typeObj2[list.reason_id].label: list.reason_id
              list.created_at = this.dataFormat(list.created_at)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>