<template>
    <article class="">
      <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
      <div class="com_search_box">
        <div class="com_item_search">
          <div class="com_item_tx">錢包ID</div>
          <div class="com_item_input">
            <el-input v-model="user_id" placeholder="请输入" size="small">
            </el-input>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">提取數字大於</div>
          <div class="com_item_input">
            <el-input type="number" v-model="amount" placeholder="请输入" size="small">
            </el-input>
          </div>
        </div>
        <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
        <div class="com_item_search">
          <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
  
        </div>
  
      </div>
      <div>
        <table class="com-table  mt_40">
          <thead class="com_thead">
            <th>序號</th>
            <th>錢包ID</th>
            <th>提取類型</th>
            <th>提取數量(FTC)</th>
            <th>提取數量(USDT)</th>
            <th>提取數量(FUSD)</th>
            <th>提取時間</th>
            <th>備註</th>
          </thead>
          <tbody class="com_tbody">
            <tr v-for="(list, index) in resData" :key="list.id">
              <td>{{ (page - 1) * size + 1 + index }}</td>
              <td>{{ list.user_id }}</td>
              <td>{{ list.op_type }}</td>
              <td>{{ list.symbol === 'SCF' ? list.total_amount : '-' }}</td>
              <td>{{ list.symbol === 'UDT' || list.symbol === 'USDT' ? list.total_amount : '-' }}</td>
              <td>{{ list.symbol === 'FUSD' ? list.total_amount : '-' }}</td>
              <td>
                  <p>{{ list.created_at }}</p>
              </td>
              <td>
                <p>{{ list.remark != '' ?  list.remark : '-'}}</p>
              </td>
            </tr>
            
          
          </tbody>
        </table>
      </div>
  
      <div class="com_page">
        <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
        </el-pagination>
      </div>
  
    </article>
  </template>
  
  <script>
  export default {
    name: "ftcRunningTask",
    data() {
      return {
        page: 1,
        size: 10,
        total: 0,
        resData: [],
        user_id: '',
        amount: '',
        startTime: '',
        endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      };
    },
    created() {
      this.resInit();
    },
    methods: {
      handleSizeChange(val) {
        this.page = 1;
        this.size = val;
        this.resInit();
      },
      pageFn(index) {
        this.page = index;
        this.resInit();
      },
      resInit() {
        let req = {
          page: this.page,
          size: this.size,
          type: ''
        };
        if (this.user_id) {
          req.user_id = this.user_id;
        }
        if (this.amount) {
          req.amount = +this.amount;
        }
        if (this.startTime) {
          req.start = this.startTime
        }
        if (this.endTime) {
          req.end = this.endTime
        }
        this.$ajax(`${this.Utils.URL.goURL}pick_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : []
            this.resData.forEach((list) => {
              list.created_at = this.dataFormat(list.created_at)
              list.op_type = list.op_type == 37 ? 'FTC節點獎勵發放' : list.op_type == 38 ? 'FTC團隊獎勵發放' : list.op_type == 55 ? 'FSP團隊獎勵發放' : '-';
            })
            this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>