<template>
  <article class="">
    <div class="com_h2_title has-button">
      <h2>{{ $route.meta.title }}</h2>
      
      <div class="wrap-button">
        <el-button :class="{'el-button--primary' : isNewToken}" @click="handleLoadOldToken">FTC</el-button>
        <el-button :class="{'el-button--primary' : !isNewToken}" @click="handleLoadOldToken">OLD-FTC</el-button>
      </div>
      
    </div>
    <div class="list-button">
      <div>
        <el-button type="primary" v-for="(item, index) in listButtons" :key="index" @click="showModal(item)">{{ item }}</el-button>
      </div>
      <div style="margin-top: 10px">
        <!-- <el-button type="primary" @click="showModalInstant">瞬間拉/砸盤</el-button> -->
        <el-button type="primary" @click="showModalTransferFTC">新增FTC持倉地址</el-button>
        <!-- <el-button type="primary" @click="showModalBNB()">BNB燃料費工具</el-button> -->
      </div>
      
    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="callAnotherResData()">更新所有地址资产</el-button>
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>

    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>地址編號</th>
          <th>地址</th>
          <th>BNB數量</th>
          <th>FTC數量</th>
          <th>USDT數量</th>
          <th>LP數量<br />(FTC-USDT)</th>
          <th>最後更新時間</th>
          <th>備註</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td class="left">{{ list.id }}</td>
            <td class="left">{{ list.address }}</td>
            <td class="left">{{ list.bnb_balance }}</td>
            <td class="left">{{ list.ftc_balance }}</td>
            <td class="left">{{ list.usdt_balance }}</td>
            <td class="left">{{ list.lp_balance }}</td>
            <td>
              <p>{{ list.updated_at.length > 0 ? list.updated_at : '-' }}</p>
            </td>
            <td>
              {{ list.remark }}
            </td>
          </tr>
          <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td> {{subtotal?.bnb_balance ? scientificNotationToString(subtotal.bnb_balance) : 0}} BNB</td>
          <td> {{subtotal?.ftc_balance ? scientificNotationToString(subtotal.ftc_balance) : 0}} FTC</td>
          <td> {{subtotal?.usdt_balance ? scientificNotationToString(subtotal.usdt_balance) : 0}} USDT</td>
          <td> {{subtotal?.lp_balance ? scientificNotationToString(subtotal.lp_balance) : 0}} LP</td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>

          <td> {{sum?.bnb_balance ? scientificNotationToString(sum.bnb_balance) : 0}} BNB</td>
          <td> {{sum?.ftc_balance ? scientificNotationToString(sum.ftc_balance) : 0}} FTC</td>
          <td> {{sum?.usdt_balance ? scientificNotationToString(sum.usdt_balance) : 0}} USDT</td>
          <td> {{sum?.lp_balance ? scientificNotationToString(sum.lp_balance) : 0}} LP</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
        
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[5, 10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>


    <el-dialog v-model="isShow" :title="titleModal" :width="600">
      <div class="com_from_box">
        <div class="com_from_item" v-show="typeButton == '跨鏈轉入FTC'" style="align-items: flex-end;">
          <div class="com_from_left">剩余可跨链余额:</div>
          <div class="com_from_right">{{ amountFTC }}</div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">
            地址編號:
          </div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="content" placeholder="请输入" type="text" size="small">
            </el-input>
            <span @click="content = 'All'" class="link">全部</span>
            <p class="note">多選地址：使用,隔開<br />指定地址编号：輸入1-1000</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">{{ titleForm }}:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="range1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="range2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
          </div>
        </div>
        <div v-show="typeButton == '添加LP'" class="com_from_item">
          <div class="com_from_left">USDT數量:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="amount1" placeholder="输入最小值" size="small" class="input-range" disabled>
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="amount2" placeholder="输入最大值" size="small" class="input-range" disabled>
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">間隔時間:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="time1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="time2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
            <span style="margin-left: 10px;">亳秒</span>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShow = false">关闭</el-button>
          <el-button type="primary" @click="submitFn">提交</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 跨鏈轉入FTC -->
    <el-dialog v-model="isShowTransferFTC" title="新增FTC持倉地址" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">
            新增地址數量:
          </div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="content" placeholder="请输入" type="number" size="small" min="1">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">增加數量:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="range1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="range2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">間隔時間:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="time1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="time2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
            <span style="margin-left: 10px;">亳秒</span>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowTransferFTC = false">关闭</el-button>
          <el-button type="primary" @click="submitFnTransferFTC">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 瞬間拉/砸盤 -->
    <el-dialog v-model="isShowInstant" title="瞬間拉/砸盤" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">
            當前價格:
          </div>
          <div class="com_from_right">
            <p class="instant_value">value</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">目標價格:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="price_instant" placeholder="" size="small">
            </el-input>
          </div>
        </div>
        
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowInstant = false">关闭</el-button>
          <el-button type="primary" @click="submitFnInstant">提交</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <el-dialog v-model="isShowBNB" title="BNB燃料費工具" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">轉出地址編號:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="contentBnB" placeholder="请输入" type="text" size="small">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">
            <p>轉出數量:</p>
            <p>(等於該保留數量)</p>
          </div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="rangeBNB1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="rangeBNB2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">間隔時間:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="timeBNB1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="timeBNB2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
            <span style="margin-left: 10px;">亳秒</span>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code_bnb" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowBNB = false">关闭</el-button>
          <el-button type="primary" @click="submitFnBNB">提交</el-button>
        </span>
      </template>
    </el-dialog> -->

  </article>
</template>

<script>
export default {
  name: "activityDinnerReport",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      resDataFullForExport: [],
      tHeader: [],
      two_auth_code: '',
      content: '',
      range1: null,
      range2: null,
      // amount1: null,
      // amount2: null,
      time1: null,
      time2: null,
      contentBnB: '',
      rangeBNB1: null,
      rangeBNB2: null,
      timeBNB1: null,
      timeBNB2: null,
      two_auth_code_bnb: '',
      filterVal: [
        "id",
        "address",
        "bnb_balance",
        "ftc_balance",
        "usdt_balance",
        "lp_balance",
        "updated_at",
        "remark",
      ],
      finalData: [],
      isValid: true,
      isShow: false,
      isShowBNB: false,
      listButtons: ['跨鏈轉入FTC', '賣出FTC', '賣出USDT', '添加LP', '減少LP'],
      // listButtons: ['跨鏈轉入FTC', '賣出FTC', '賣出USDT', '添加LP'],
      titleModal: '',
      titleForm: '',
      typeButton: '',
      subtotal: {
      },
      sum: {
      },
      amountFTC: null,
      rate: '',
      isShowTransferFTC: false,
      isShowInstant: false,
      price_instant: null,
      isNewToken: true,
      is_old_ftc: false
    };
  },
  computed: {
    amount1: {
      get() {
        return this.range1 * this.rate
      },
    },
    amount2: {
      get() {
        return this.range2 * this.rate
      },
    }
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    
    getResRate() {
      const req = {
        is_old_ftc: this.is_old_ftc
      }
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/getFtcUsdtRate`, req, "get")
        .then((res) => {
          this.rate = res.rate
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
        is_old_ftc: this.is_old_ftc
      }
      
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/getWalletAddressList`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.updated_at = this.dataFormat(list.updated_at)
          })
          if (this.resDataFullForExport.length > 0) {
            this.exportExcel(this.tHeader, this.filterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    // submitFnBNB() {
    //   // check contentBNB
    //   if (!this.contentBnB) {
    //     this.$message.error('請輸入轉出地址編號');
    //     return
    //   }
    //   if (this.rangeBNB1 == null || this.rangeBNB1 == '' || this.rangeBNB1 < 0) {
    //     this.$message.error('最小值不得為空');
    //     return
    //   }
    //   if (this.rangeBNB2 == null || this.rangeBNB2 == '' || this.rangeBNB2 < 0) {
    //     this.$message.error('最大值不得為空');
    //     return
    //   }
    //   if (this.rangeBNB1 > this.rangeBNB2) {
    //     this.$message.error('最小值不得大於最大值');
    //     return
    //   }
    //   if (this.rangeBNB1 == this.rangeBNB2) {
    //     this.$message.error('最小值可跟最大值一樣');
    //     return
    //   }
    //   if (+this.timeBNB1 < 0) {
    //     this.$message.error('开始时间不能小于0');
    //     return
    //   }
    //   if (+this.timeBNB2 < 0) {
    //     this.$message.error('结束时间不能小于0');
    //     return
    //   }
    //   if (+this.timeBNB1 > +this.timeBNB2) {
    //     this.$message.error('開始時間不得大於結束時間');
    //     return
    //   }
    //   if (this.two_auth_code_bnb == '') {
    //     this.$message.error('请输入谷歌验证码');
    //     return
    //   }

    //   let req = {
    //     address_id: this.contentBnB,
    //     two_auth_code: this.two_auth_code_bnb,
    //     min_amount: this.rangeBNB1,
    //     max_amount: this.rangeBNB2,
    //   }
    //   // this.$ajax(`${this.Utils.URL.goURL}ftc-market/liquidityAdd`, req, 'post', 2500000, false, true)
    //   //   .then((res) => {
    //   //     if (res.result) {
    //   //       this.isShow = false
    //   //       this.$message.success('成功');
    //   //       this.resInit()
    //   //     } else {
    //   //       this.$message.error(res.msg);
    //   //     }
    //   //   })
    //   //   .catch((res) => {
    //   //     this.isShow = false
    //   //     this.$message.error(res.msg);
    //   //   });
    // },
    // showModalBNB() {
    //   this.isShowBNB = true
    // },
    callAnotherResData(){
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/updateFtcMarketBalance`, {}, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },
    showModal(item) {
      this.resetForm()
      this.isShow = true
      this.checkTitleModal(item)
      this.typeButton = item
    },
    checkTitleModal(type) {
      switch (type) {
        case '跨鏈轉入FTC':
          this.titleModal = '跨鏈轉入FTC'
          this.titleForm = '增加數量'
          this.getBallanceFTC()
          break;
        case '賣出FTC':
          this.titleModal = '賣出FTC'
          this.titleForm = '賣出數量'
          break;
        case '賣出USDT':
          this.titleModal = '賣出USDT'
          this.titleForm = '賣出數量'
          break;
        case '添加LP':
          this.titleModal = '添加FTC-USDT LP'
          this.titleForm = '添加FTC數量'
          this.getResRate()
          break;
        case '減少LP':
          this.titleModal = '移除FTC-USDT LP'
          this.titleForm = '移除LP數量'
          break;
      }
    },
    checkContent(content) {
      if (content == '') {
        this.isValid = false
        this.$message.error('地址不得為空');
        return
      }

      if (content.includes(',') && content.includes('-')) {
        this.isValid = false
        this.$message.error('間隔符號僅可使用,');
        return
      }

      if (content == 'All') {
        this.isValid = true
        return
      } else {
        const regex = /^[0-9,-]*$/g
        if (!regex.test(content)) {
          this.isValid = false
          this.$message.error('間隔符號僅可使用,');
          return
        }
      }

      if (content.includes(',')) {
        const arr = content.split(',')
        const last = arr[arr.length - 1]
        if (last == '') {
          arr.pop()
        }

        // if (arr.length > 1) {
        //   this.isValid = true
        //   this.finalData = arr
        //   return
        // }
        this.isValid = true
        // remove duplicate
        const set = new Set(arr)
        const interactor = [...set]
        this.finalData = interactor.join(',')
      } else if (content.includes('-')) {

        const arr2 = content.split('-')
        const last = arr2[arr2.length - 1]

        if (last == '') {
          this.isValid = false
          this.$message.error('請輸入最大值');
          return
        }

        if (arr2.length > 1) {
          this.isValid = true
          const start = parseInt(arr2[0])
          const end = parseInt(arr2[1])

          if (start > end) {
            this.isValid = false
            this.$message.error('最小值不得大於最的值');
            return
          }

          const arr3 = []
          for (let i = start; i <= end; i++) {
            arr3.push(i)
          }
          // convert arr3 to string
          this.finalData = arr3.join(',')
          return
        }


      } else {
        // single value
        this.isValid = true
        this.finalData = content
      }
    },
    getBallanceFTC() {
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/remaining_ftc_contract_balance`, {}, 'get')
        .then((res) => {
          this.amountFTC = res.balance
          
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    // instant pull / smash
    showModalInstant() {
      this.isShowInstant = true
      this.resetForm()
    },
    submitFnInstant () {
      if (this.price_instant == null || this.price_instant == '') {
        this.$message.error('價格不得為空');
        return
      }
      if (this.two_auth_code == '') {
        this.$message.error('请输入谷歌验证码');
        return
      }
      let req = {}
    },
    showModalTransferFTC() {
      this.isShowTransferFTC = true
      this.resetForm()
    },
    submitFnTransferFTC() { // new update 7.8
      if (this.content == null || this.content == '') {
        this.$message.error('地址不得為空');
        return
      }
      // not less than 0
      if (+this.content <= 0) {
        this.$message.error('數量不得小於0');
        return
      }
      // must be integer
      if (!Number.isInteger(+this.content)) {
        this.$message.error('數量必須為整數');
        return
      }

      if (this.range1 == null || this.range1 == '' || +this.range1 < 0) {
        this.$message.error('最小值不能为空或小于 0');
        return
      }
      if (this.range2 == null || this.range2 == '' || +this.range2 < 0) {
        this.$message.error('最大值不得為空或小于 0');
        return
      }
      if (+this.range1 > +this.range2) {
        this.$message.error('最小值不得大於最大值');
        return
      }
      if (this.range1 == this.range2) {
        this.$message.error('最小值可跟最大值一樣');
        return
      }
      if (this.time1 == null || this.time1 == '') {
        this.$message.error('開始時間不得為空');
        return
      }
      if (this.time2 == null || this.time2 == '') {
        this.$message.error('結束時間不得為空');
        return
      }
      if (this.time1 <= 0) {
        this.$message.error('开始时间必须大过于0');
        return
      }
      if (this.time2 <= 0) {
        this.$message.error('结束时间必须大过于0');
        return
      }
      if (this.time1.includes('.')) {
        this.$message.error('時間不得為小數');
        return
      }
      if (this.time2.includes('.')) {
        this.$message.error('時間不得為小數');
        return
      }
      if (+this.time1 > +this.time2) {
        this.$message.error('開始時間不得大於結束時間');
        return
      }
      if (this.two_auth_code == '') {
        this.$message.error('请输入谷歌验证码');
        return
      }

      const req = {
        address_count: +this.content,
        min_amount: +this.range1,
        max_amount: +this.range2,
        min_milli_second: +this.time1,
        max_milli_second: +this.time2,
        two_auth_code: this.two_auth_code,
        is_old_ftc: this.is_old_ftc
      }
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/create_addr`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShowTransferFTC = false
            this.$message.success('成功');
            this.resInit();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    submitFn() {
      this.checkContent(this.content)
      if (this.isValid) {
        if (this.range1 == null || this.range1 == '' || this.range1 < 0) {
          this.$message.error('最小值不得為空');
          return
        }
        if (this.range2 == null || this.range2 == '' || this.range2 < 0) {
          this.$message.error('最大值不得為空');
          return
        }
        if (+this.range1 > +this.range2) {
          this.$message.error('最小值不得大於最大值');
          return
        }
        if (this.range1 == this.range2) {
          this.$message.error('最小值可跟最大值一樣');
          return
        }
        if (this.time1 == null || this.time1 == '') {
          this.$message.error('開始時間不得為空');
          return
        }
        if (this.time2 == null || this.time2 == '') {
          this.$message.error('結束時間不得為空');
          return
        }
        if (this.time1 <= 0) {
          this.$message.error('开始时间必须大过于0');
          return
        }
        if (this.time2 <= 0) {
          this.$message.error('结束时间必须大过于0');
          return
        }
        if (this.time1.includes('.')) {
          this.$message.error('時間不得為小數');
          return
        }
        if (this.time2.includes('.')) {
          this.$message.error('時間不得為小數');
          return
        }
        if (+this.time1 > +this.time2) {
          this.$message.error('開始時間不得大於結束時間');
          return
        }
        if (this.two_auth_code == '') {
          this.$message.error('请输入谷歌验证码');
          return
        }
        let req = {}

        if (this.content == 'All') {
          req = {
            two_auth_code: this.two_auth_code,
            min_amount: this.range1,
            max_amount: this.range2,
            min_milli_second: +this.time1,
            max_milli_second: +this.time2,
            is_old_ftc: this.is_old_ftc
          }
        } else {
          req = {
            two_auth_code: this.two_auth_code,
            address_id: this.finalData,
            min_amount: this.range1,
            max_amount: this.range2,
            min_milli_second: +this.time1,
            max_milli_second: +this.time2,
            is_old_ftc: this.is_old_ftc
          }
        }

        switch(this.typeButton) {
          case '跨鏈轉入FTC':
            this.callCrossInFtc(req)
            break;
          case '賣出FTC':
          case '賣出USDT':
            req.coin = this.typeButton == '賣出FTC' ? 'FTC' : 'USDT'
            this.callLiquidtySwap(req)
            break;
          case '添加LP':
            this.callAddLP(req)
            break;
          // case '減少LP':
          //   req.type = 5
          //   break;
        }
        

      }

    },
    callAddLP(req) {
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/liquidityAdd`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },
    callCrossInFtc(req) {
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/crossInFTC`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },
    callLiquidtySwap(req) {
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/liquiditySwap`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },
    resetForm() {
      this.content = ''
      this.range1 = ''
      this.range2 = ''
      this.time1 = null
      this.time2 = null
      this.two_auth_code = ''
      this.isValid = false
      this.finalData = ''
      this.price_instant = ''
      // this.price_error = ''
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      this.resData = [];
      // let loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      let req = {
        page: this.page,
        size: this.size,
        is_old_ftc: this.is_old_ftc
      };

      this.$ajax(`${this.Utils.URL.goURL}ftc-market/getWalletAddressList`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {
            list.updated_at = this.dataFormat(list.updated_at)
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
          // loading.close();
        })
        .catch((res) => {
          this.$message.error(res.msg);
          // loading.close();
        });
    },
    handleLoadOldToken() {
      this.isNewToken = !this.isNewToken
      this.is_old_ftc = !this.is_old_ftc
      this.resInit()
    },
  },
};
</script>

<style scoped>
.list-button {
  margin-bottom: 20px;
}

.list-button button {
  min-width: 125px;
}

.com_from_item_group {
  display: flex;
  place-items: center;
  flex-wrap: wrap;
}

.com_from_item_group .link {
  white-space: nowrap;
  margin-left: 10px;
  color: #409eff;
  cursor: pointer;
}

.com_from_item_group .note {
  color: #409eff;
  margin-top: 5px;
}

.com_from_item_group .dash {
  margin: 0 10px;
}

.com_from_item_group .el-input {
  width: 230px;
}

.com_from_item_group .input-range {
  width: 100px;
}

.com_from_box .com_from_item {
  align-items: flex-start;
}

.com_from_box .com_from_item .com_from_left {
  padding-top: 8px;
}
.com_from_item_group :deep(.el-input.is-disabled .el-input__inner) {
  color: #606266;
}
.instant_value {
  font-size: 20px;
  font-weight: bold;
}
.has-button {
  display: flex;
  align-items: center;
}
.has-button .wrap-button {
  margin-left: 3rem;
}
.wrap-button .el-button {
  min-width: 10rem;
}
</style>