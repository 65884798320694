<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>地址</th>
          <th>淨增加數量</th>
          <th>5%數值</th>
          <th>交易時間</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ list.net_value }}</td>
            <td>{{ list.net_value_5_percent }}</td>
            <td>{{ list.time }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td> {{ scientificNotationToString(subtotal.net_value) }} USDT</td>
            <td> {{ scientificNotationToString(subtotal.net_value_5_percent) }} USDT</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>


  </article>
</template>

<script>
export default {
  name: "fspNetIncreaseRecord",
  data() {
    return {
      page: 1,
      size: 100,
      total: 0,
      resData: [],
      wallet_id: "",
      startTime: "",
      endTime: "",
      tHeader: [],
      subtotal: {},
      filterVal: [
        "user_id",
        "net_value",
        "net_value_5_percent",
        "time",
      ],
      isAlert: false,

    };
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {

      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id) {
        req.wallet_id = this.wallet_id;
      }
      if (this.startTime) {
        req.start = this.startTime;
      }
      if (this.endTime) {
        req.end = this.endTime;
      }
      this.$ajax(`${this.Utils.URL.goURL}liquidity/fsp_net_record`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.resData.forEach((list) => {

            list.time = this.dataFormat(list.time)
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>