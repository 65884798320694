<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>


    <section class="com_from_box mt_40">
      <div class="com_from_item">
        <div class="com_from_left">
          链类型
        </div>
        <div class="com_from_right">
          <el-select
              v-model="chainVal"
              clearable
              filterable
              placeholder="请选择"
              size="small"
              @change="resInit"
          >
            <el-option label="BSC" value="bsc">BSC</el-option>
          </el-select>
        </div>
      </div>





      <div class="com_from_item">
        <div class="com_from_left">
          代币全称
        </div>
        <div class="com_from_right d_flex">
          <el-input
              v-model="name"
              placeholder="请输入代币全称,如(Binance-Peg BSC-USD)"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          代币简称(英文大写)
        </div>
        <div class="com_from_right d_flex">
          <el-input
              v-model="symbol"
              placeholder="请输入代币简称,如(USDT)"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          代币精度
        </div>
        <div class="com_from_right d_flex">
          <el-input
              v-model="decimals"
              placeholder="请输入1-18内整数"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          合约名称
        </div>
        <div class="com_from_right d_flex">
          <el-input
              v-model="contract"
              placeholder="请输入合约名称,使用协议名+代币简称,如(BEP20USDT)"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          发行数量
        </div>
        <div class="com_from_right d_flex">
          <el-input
              v-model="total_supply"
              placeholder="请输入发行数量"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          添加流动性USDT数量
        </div>
        <div class="com_from_right d_flex">
          <el-input
              v-model="init_usdt"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          添加流动性代币数量
        </div>
        <div class="com_from_right d_flex">
          <el-input
              v-model="init_token"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          流动池代币初始价格
        </div>
        <div class="com_from_right d_flex">
          1代币 = {{init_token && init_usdt ? init_usdt / init_token : 0}} USDT
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          预计闪兑总额(发行代币手续费+添加流动性)
        </div>
        <div class="com_from_right d_flex">
          {{ (resData.contract_fee - 0) +  (init_usdt - 0)}} USDT
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          闪兑地址
        </div>
        <div class="com_from_right d_flex">
          {{ resData.addr }}
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          闪兑地址余额
        </div>
        <div class="com_from_right d_flex">
          {{ resData.usdt_bal }} USDT
        </div>
      </div>


      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>


      <div class="com_from_item" >
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>
<p>发币及添加流动性流程：</p>
<p>1、提交后自动创建发币地址；</p>
<p>2、发币地址创建成功后自动闪兑转入创建代币合约费用和添加流动性所需费用；</p>
<p>3、闪兑成功后自动执行发币操作 ；</p>
<p>4、发币成功后自动执行添加流动性操作；</p>
<p>5、发币成功后，请前往币种列表设置代币图标和代币介绍；</p>
<p>6、由于发币执行流程较多，预计等待时间较长，请耐心等待，同时请保证闪兑地址（自动投资地址）内余额充足。</p>







  </article>
</template>

<script>
export default {
  name: "tokenListEdit",
  data() {
    return {
      two_auth_code: '',
      chainVal: 'bsc',
      init_token: '',
      contract: '',
      decimals: '',
      init_usdt: '',
      name: '',
      symbol: '',
      total_supply: '',
      resData: {}
    }
  },
  created() {
    this.resInit()
  },
  methods: {

    resInit () {
      this.$ajax(`${this.Utils.URL.goURL}contract/new_info`, {
        chain_type: this.chainVal
      }, 'get')
          .then(res => {
            this.resData = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn() {


      if (this.init_token === '') {
        this.$message.error('请输入流动性代币数量')
        return false
      }

      if (this.contract === '') {
        this.$message.error('请输入合约名称')
        return false
      }

      if (this.init_usdt === '') {
        this.$message.error('请输入流动性USDT数量')
        return false
      }

      if (this.name === '') {
        this.$message.error('请输入代币全称')
        return false
      }

      if (this.symbol === '') {
        this.$message.error('请输入代币简称')
        return false
      }

      if (this.total_supply === '') {
        this.$message.error('请输入发行量')
        return false
      }

      if (this.decimals === '') {
        this.$message.error('请输入代币精度')
        return false
      }

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      let req = {
        two_auth_code: this.two_auth_code,
        chain_type: this.chainVal,
        init_token: this.init_token,
        contract: this.contract,
        decimals: this.decimals,
        init_usdt: this.init_usdt,
        name: this.name,
        symbol: this.symbol,
        total_supply: this.total_supply,
      }


      this.$confirm('是否提交发行代币', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$ajax(`${this.Utils.URL.goURL}contract/new`, req, 'post', )
            .then(res => {
              this.resInit()
              this.$message.success('成功')
            })
            .catch(res => {
              this.$message.error(res.msg)
            })

      }).catch(() => {

      });


    },
  }
}
</script>

<style scoped>
.com_from_right{
  width: 400px;
  text-align: left;
}
.d_flex{
  justify-content: left;
}

</style>