<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="user_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">交易類別</div>
        <div class="com_item_input">
          <el-select
            v-model="type"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in transactionTypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="summary">
      <p class="title">收入列表</p>
      <div class="content">
        <p class="title">收入總計：{{ total_fee_sum }}</p>
        <p class="fee_sum">
          
          <span v-for="item in currencyList" :key="item.toString()">{{ item }}收入 : {{ fee_sum[item] }}</span>
        </p>
      </div>
    </div>
    <div class="summary">
      <p class="title">入金</p>
      <div class="content">
        <p class="title">入金總計: {{ total_in_fee_sum }}</p>
        <p class="fee_sum">
          <span v-for="item in currencyListIn" :key="item.toString()">{{ item }}入金 : {{ in_fee_sum[item] }}</span>
        </p>
      </div>
    </div>
    <div class="summary">
      <p class="title">出金</p>
      <div class="content">
        <p class="title">出金總計: {{ total_out_fee_sum }}</p>
        <p class="fee_sum">
          
          <span v-for="item in currencyListOut" :key="item.toString()">{{ item }}出金 : {{ out_fee_sum[item] }}</span>
        </p>
      </div>
    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>
    <div>
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>錢包ID</th>
          <th>交易類別</th>
          <th>交易幣種</th>
          <th>數量</th>
          <th>獲得幣種</th>
          <th>數量</th>
          <th>手續費幣種</th>
          <th>手續費</th>
          <th>兌換時間</th>
          <th>交易哈希</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ list.type }}</td>
            <td>{{ list.deposit_coin_code }}</td>
            <td>{{ list.deposit_coin_amt }}</td>
            <td>{{ list.receive_coin_code }}</td>
            <td>{{ list.receive_coin_amt }}</td>
            <td>{{ list.deposit_coin_code }}</td>
            <td>{{ list.fee }}</td>
            <td>
              <p>{{ list.created_at_at.length > 0 ? list.created_at_at : '-' }}</p>
            </td>
            <td>
              <a :href="`https://mainchain.ftcchain.org/#/tx/${list.transaction_id}`" target="_blank" class="link">{{ list.transaction_id }}</a>
            </td>
          </tr>
          <!-- <tr v-show="resData.length == 0">
            <td colspan="10">目前没有执行中任务</td>
          </tr> -->
          <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.deposit_coin_amt)}}</td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.receive_coin_amt)}}</td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.fee)}}</td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.deposit_coin_amt)}}</td>
          <td></td>
          <td> {{scientificNotationToString(sum.receive_coin_amt)}}</td>
          <td></td>
          <td> {{scientificNotationToString(sum.fee)}}</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

    
  </article>
</template>

<script>
export default {
  name: "fusdExchangeRecord",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      subtotal: {},
      sum: {},
      startTime: '',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      user_id: '',
      type: '',
      transactionTypeList: [
        {
          value: 1,
          text: '入金',
        },
        {
          value: 2,
          text: '出金',
        }
      ],
      tHeader: [],
      filterVal: [
        'user_id',
        'type', // new update
        'deposit_coin_code',
        'deposit_coin_amt',
        'receive_coin_code',
        'receive_coin_amt',
        'deposit_coin_code',
        'fee',
        'created_at_at',
        'transaction_id',
      ],
      fee_sum: {},
      in_fee_sum: {},
      out_fee_sum: {},
      currencyList: [],
      currencyListIn: [],
      currencyListOut: [],
      resDataFullForExport: [],
    };
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  computed: {
    total_fee_sum() {
      return Object.values(this.fee_sum).reduce((total, num) => {
        let totalNum = Math.abs(parseFloat(total) + parseFloat(num))
        // if the decimal is over > 8 digits
        if (totalNum.toString().indexOf('.') > -1) {
          let decimal = totalNum.toString().split('.')[1]
          if (decimal.length > 8) {
            totalNum = totalNum.toFixed(8)
          }
        }
        return totalNum;
      }, 0);
    },
    total_in_fee_sum() {
      return Object.values(this.in_fee_sum).reduce((total, num) => {
        let totalNum = Math.abs(+total + parseFloat(num))
        if (totalNum.toString().indexOf('.') > -1) {
          let decimal = totalNum.toString().split('.')[1]
          if (decimal.length > 8) {
            totalNum = totalNum.toFixed(8)
          }
        }
        return totalNum;
      }, 0);
    },
    total_out_fee_sum() {
      return Object.values(this.out_fee_sum).reduce((total, num) => {
        let totalNum = Math.abs(+total + parseFloat(num))
        if (totalNum.toString().indexOf('.') > -1) {
          let decimal = totalNum.toString().split('.')[1]
          if (decimal.length > 8) {
            totalNum = totalNum.toFixed(8)
          }
        }
        return totalNum;
      }, 0);
    }
  },
  created() {
    this.resInit();
  },
  methods: {
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
      }
      this.$ajax(`${this.Utils.URL.goURL}flash_record_list`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.created_at_at = this.dataFormat(list.created_at_at)
            list.type = list.type == 1 ? '入金' : list.type == 2 ? '出金' : '-'
          })
          if (this.resDataFullForExport.length > 0) {
            this.exportExcel(this.tHeader, this.filterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.user_id) {
        req.user_id = this.user_id;
      }
      if (this.startTime) {
        req.start = this.startTime;
      }
      if (this.endTime) {
        req.end = this.endTime;
      }
      if (this.type) {
        req.type = this.type;
      }
      this.$ajax(`${this.Utils.URL.goURL}flash_record_list`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.fee_sum = res.fee_sum ? res.fee_sum : {}
          this.in_fee_sum = res.in_fee_sum ? res.in_fee_sum : {}
          this.out_fee_sum = res.out_fee_sum ? res.out_fee_sum : {}
          this.currencyList = Object.keys(this.fee_sum)
          this.currencyListIn = Object.keys(this.in_fee_sum)
          this.currencyListOut = Object.keys(this.out_fee_sum)
          this.resData.forEach((list) => {
            
            list.created_at_at = this.dataFormat(list.created_at_at)
            list.type = list.type == 1 ? '入金' : list.type == 2 ? '出金' : '-'
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.summary {
  display: flex;
  place-items: center;
  margin-bottom: 20px;
}
.summary .title {
  font-weight: bold;
  font-size: 24px;
  margin-right: 25px;
  flex-basis: 8%;
}
.summary .content .title {
  font-weight: bold;
  font-size: 18px;
}
.link {
  text-decoration: underline;
}
.fee_sum span {
  margin-right: 10px;
}
</style>