<template>
    <article class="">
        <section class="com_from_box">

            <div class="com_from_item">
                <div class="com_from_left">
                    角色昵称
                </div>
                <div class="com_from_right">
                    <el-input
                            v-model="nick_name"
                            placeholder="请输入"
                            size="small">
                    </el-input>
                </div>
            </div>
            <div class="com_from_item">
                <el-tree
                        ref="roleTree"
                        :data="treeData"
                        show-checkbox
                        node-key="id"
                        :default-expand-all="true"
                        :default-checked-keys="checkedArr"
                        :props="defaultProps"
                        @check-change="treeChangeFn"
                />
            </div>

          <div class="com_from_item">
            <div class="com_from_left">谷歌验证码</div>
            <div class="com_from_right">
              <el-input v-model="two_auth_code" placeholder="请输入" size="small">
              </el-input>
            </div>
          </div>

            <div class="com_from_item">
                <div class="com_from_left">

                </div>
                <div class="com_from_right">
                    <span class="btn" @click="submitFn">提交</span>
                </div>

            </div>
        </section>

    </article>
</template>

<script>
    // import ruleList from '../mock-rule.json'
    export default {
        name: "adminRoleEdit",
        data() {
            return {
                pass: '',
                dataObj: {},
                nick_name: '',
              two_auth_code: '',
                treeData: [

                ],
                checkedArr: [],
                defaultProps: {
                    children: 'children',
                    label: 'title',
                },
            }
        },
        created () {

            /*let ruleLists = ruleList.list
            ruleLists = ruleLists.sort((a,b) => {
                return a.id - b.id
            })
            console.log(ruleLists)
            let arr = []
            let arr1 = []
            let checkedArr = []
            ruleLists.forEach((val, index) => {
                if (val.type == '1') {
                    val.children = []
                    arr.push(val)
                }
                if (val.check == 1) {
                    checkedArr.push(val.id)
                }
                if ((val.check == 1 || val.expansion == 1) && val.path) {
                    arr1.push(val)
                }
            })


            let ruleFormat = (obj => {
                ruleLists.forEach(val => {
                    if (obj.children) {
                        if (obj.id == val.parent_id && obj.id != val.id) {
                            val.children = []
                            obj.children.push(val)
                            ruleFormat(val)
                        }
                    }
                })
            })
            arr.forEach(val => {
                ruleFormat(val)
            })
            console.log(arr1, 'arr1')
            console.log(ruleLists)
            console.log(arr)
            this.treeData = arr
            this.checkedArr = checkedArr
            window.localStorage.setItem('ruleTree', JSON.stringify(arr))
            window.localStorage.setItem('ruleRouter', JSON.stringify(arr1))*/
            this.dataObj = window.sessionStorage.getItem('listDataInfo') ? JSON.parse(window.sessionStorage.getItem('listDataInfo')) : {}

            if (window.localStorage.getItem('ruleTree')) {
                this.treeData = JSON.parse(window.localStorage.getItem('ruleTree'))
            } else {
                this.resInit()
            }
            if (this.dataObj.role_id) {
                this.nick_name = this.dataObj.role_name
                this.checkedArr = this.dataObj.power_id.split(',')
            }
        },
        methods: {
            treeChangeFn (val, node) {
                // console.log(val, node)
            },
            resInit () {
                let req = {}
                this.$ajax(`${this.Utils.URL.goURL}power_list`, req, 'get')
                    .then(res => {
                        let ruleLists = res ? res.list : []
                        ruleLists = ruleLists.sort((a,b) => {
                            return a.id - b.id
                        })
                        let arr = []
                        ruleLists.forEach((val, index) => {
                            if (val.type == '1') {
                                val.children = []
                                arr.push(val)
                            }
                        })


                        let ruleFormat = (obj => {
                            ruleLists.forEach(val => {
                                if (obj.children) {
                                    if (obj.id == val.parent_id && obj.id != val.id) {
                                        val.children = []
                                        obj.children.push(val)
                                        ruleFormat(val)
                                    }
                                }
                            })
                        })
                        arr.forEach(val => {
                            ruleFormat(val)
                        })
                        this.treeData = arr
                        window.localStorage.setItem('ruleTree', JSON.stringify(arr))
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },
            submitFn() {
                let arr = this.$refs.roleTree.getCheckedKeys()
                let expansionArr = this.$refs.roleTree.getHalfCheckedKeys()

                if (arr && !arr.length) {
                    this.$message.error('请勾选权限')
                    return false
                }
                if (this.two_auth_code == '') {
                  this.$message.error('请输入谷歌验证码')
                  return false
                }
                let role_power_id = arr.join(',')
                let expansion_id = expansionArr.join(',')
                let url = 'created_role'
                let req = {
                    user_name: this.nick_name,
                    role_power_id: role_power_id,
                    role_expansion_id: expansion_id,
                    two_auth_code: this.two_auth_code,
                }
                if (this.dataObj.role_id) {
                    url = 'update_role'
                    req.role_id = this.dataObj.role_id
                }
                this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
                    .then(res => {
                        if (res.status == 1) {
                            this.$message.success('操作成功,两秒后自动跳转')
                            setTimeout(()=> {
                                this.goBack()
                            }, 1500)
                        } else {
                            this.$message.error('操作失败')
                        }

                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },

        }
    }
</script>

<style scoped>
    .el-tree{
        background: none;
    }
</style>