<template>
  <article class="">
    <div class="com_h2_title has-button">
      <h2>{{ $route.meta.title }}</h2>
      
      <div class="wrap-button">
        <el-button type="" :class="{'el-button--primary' : isNewToken}" @click="handleLoadOldToken">FSP</el-button>
        <!-- <el-button type="" :class="{'el-button--primary' : !isNewToken}" @click="handleLoadOldToken">OLD-FTC</el-button> -->
      </div>
      
    </div>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">地址</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      
      <div class="com_item_search">
        <div class="com_item_tx">請輸入任務編號</div>
        <div class="com_item_input">
          <el-input v-model="task_number" placeholder="請输入任務編號" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">狀態搜尋</div>
        <div class="com_item_input">
          <el-select
            v-model="status"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in listStatus"
                       :key="item.value"
                       :label="item.text"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>

      </div>

    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
      <el-button type="primary" size="small" @click="isShow = true">取消任務</el-button>
    </div>
    <div>
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>任务编号</th>
          <th>地址</th>
          <th>CAKE-LP數量</th>
          <th>FSP數量</th>
          <th>FUSD/USDT數量</th>
          <th>执行时间</th>
          <th>建立時間</th>
          <th>完成時間</th>
          <th>類別</th>
          <th>状态</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td class="left">{{ list.task_id }}</td>
            <td class="left">{{ list.address }}</td>
            <td>{{ list.lp_amount }}</td>
            <td class="left">{{ list.fsp_amount }}</td>
            <td class="left">{{ list.usdt_amount }}</td>
            <td>
              <p>{{ list.execute_time.length > 0 ? list.execute_time : '-' }}</p>
            </td>
            <td>
              <p>{{ list.create_time.length > 0 ? list.create_time : '-' }}</p>
            </td>
            <td>
              <p>{{ list.updated_time.length > 0 ? list.updated_time : '-' }}</p>
            </td>
            <td>
                <p>{{ list.task_type }}</p>
            </td>
            <td>
              <p>{{ list.status }}</p>
            </td>
          </tr>
          <tr v-show="resData.length == 0">
            <td colspan="11">目前没有执行中任务</td>
          </tr>
          <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.lp_amount)}}</td>
          <td> {{scientificNotationToString(subtotal.fsp_amount)}}</td>
          <td> {{scientificNotationToString(subtotal.usdt_amount)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.lp_amount)}}</td>
          <td> {{scientificNotationToString(sum.fsp_amount)}}</td>
          <td> {{scientificNotationToString(sum.usdt_amount)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes, jumper" :total="total">
      </el-pagination>
    </div>

    <el-dialog v-model="isShow" title="取消任務" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">任務編號</div>
          <div class="com_from_right">
            <el-input v-model="task_number_id" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷哥驗證</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShow = false">取消</el-button>
          <el-button type="primary" @click="submitFn">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "fspRunningTask",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      subtotal: {},
      sum: {},
      task_number: '',
      task_number_id: '',
      isShow: false,
      two_auth_code: '',
      wallet_id: '',
      tHeader: [],
      filterVal: [
        'task_id',
        'address',
        'lp_amount',
        'fsp_amount',
        'usdt_amount',
        'execute_time',
        'create_time',
        'updated_time',
        'task_type',
        'status',
      ],
      status: '',
      listStatus: [
        {
          text: '待處理',
          value: 1
        },
        {
          text: '處理中',
          value: 2
        },
        {
          text: '失敗',
          value: 3
        },
        {
          text: '完成',
          value: 4
        },
        {
          text: '已取消',
          value: 5
        },
        {
          text: '待確認',
          value: 6
        }
      ],
      isNewToken: true,
      is_old_ftc: false
    };
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  created() {
    this.resInit();
  },
  methods: {
    submitFn() {
      if (this.task_number_id.length == 0) {
        this.$message.error('請輸入任務編號');
        return false;
      }
      if (this.two_auth_code.length == 0) {
        this.$message.error('請輸入谷哥驗證');
        return false;
      }
      let req = {
        task_id: +this.task_number_id,
        two_auth_code: this.two_auth_code,
        is_fsp: true
      };
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/cancel_task`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
            this.task_number = ''
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      this.resData = [];
      let req = {
        page: this.page,
        size: this.size,
        is_old_ftc: this.is_old_ftc,
        is_fsp: true
      };
      if (this.wallet_id.length > 0) {
        req.address = this.wallet_id;
      }
      if (this.task_number.length > 0) {
        req.task_id = +this.task_number;
      }
      if (this.status) {
        req.status = +this.status;
      }
      this.$ajax(`${this.Utils.URL.goURL}ftc-market/getFTCMarketTaskList`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {
            
            list.create_time = this.dataFormat(list.create_time)
            list.updated_time = this.dataFormat(list.updated_time)
            list.execute_time = this.dataFormat(list.execute_time)
            list.task_type = list.task_type == 1 ? '跨鏈添加FSP' : list.task_type == 2 ? '賣出FSP' : list.task_type == 3 ? '賣出USDT' : list.task_type == 4 ? '添加lp' : list.task_type == 5 ? '建立FSP持倉' : '-';
            // list.status = list.status == 1 ? '待處理' : list.status == 2 ? '處理中' : list.status == 3 ? '失敗' : list.status == 4 ? '完成' : list.status == 5 ? '已取消' : '-'
            list.status = list.status == 1 ? '待處理' : list.status == 2 ? '處理中' : list.status == 3 ? '失敗' : list.status == 4 ? '完成' : list.status == 5 ? '已取消' : list.status == 6 ? '待確認' : '-'
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleLoadOldToken() {
      this.isNewToken = !this.isNewToken
      this.is_old_ftc = !this.is_old_ftc
      this.resInit()
    },
  },
};
</script>

<style scoped>
.has-button {
  display: flex;
  align-items: center;
}
.has-button .wrap-button {
  margin-left: 3rem;
}
.wrap-button .el-button {
  min-width: 10rem;
}
</style>