<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">认购地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

      <div class="balance">
        <div class="balance_item">
          <p>訂單總量 :</p>
          <p style="margin-left: 10px;">{{ curPurchase }}</p>
        </div>
        <div class="balance_item">
          <p>認購單價 :</p>
          <p style="margin-left: 10px;"> {{ curPrice }} $</p>
        </div>
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>出售用戶錢包ID</th>
        <th>交易hash數量</th>
        <th>交易完成時間</th>
        <th>出售數量</th>
        <th>出售總價</th>
        <th>收款hash</th>
        <th>狀態</th>
        <th>收款完成時間</th>
        <th>认购地址</th>
        <th>备注</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.sell_user_id }}</td>
          <td>{{ list.sell_hash }}</td>
          <td>{{ list.sell_confirm_at }}</td>
          <td>{{ scientificNotationToString(list.sell_amount) }}</td>
          <td>{{ scientificNotationToString(list.sell_amount_usdt) }}</td>
          <td>{{ list.rec_hash }}</td>
          <td>{{ list.status }}</td>
          <td>{{ list.rec_confirm_at }}</td>
          <td>{{ list.sell_address }}</td>
          <td>{{ list?.remake }}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.sub_purchase_amount)}}</td>
          <td>{{scientificNotationToString(subtotal.sub_purchase_amount_usdt)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(sum.purchase_amount)}}</td>
          <td>{{scientificNotationToString(sum.purchase_amount_usdt)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "otc-commission-order-list-detail",
  data() {
    return {
      page: 1,
      size: 100,
      total: 0,
      resData: [],
      inputVal1: "",
      inputVal2: "",
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        'sell_user_id',
        'sell_hash',
        'sell_confirm_at',
        'sell_amount',
        'sell_amount_usdt',
        'rec_hash',
        'status',
        'rec_confirm_at',
        'sell_address',
        'remake'
      ],
      curPurchase: '',
      curPrice: '',
    };
  },
  created() {
    this.resInit();
    if (this.$route.query.purchase_amount) {
      this.curPurchase = this.$route.query.purchase_amount
    }
    if (this.$route.query.price) {
      this.curPrice = this.$route.query.price
    }
    // if query order_id is empty, return to page otcCommissionOrderList
    if (!this.$route.query.order_id) {
      this.goRouter('otcCommissionOrderList')
    }
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {

      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.$route.query.order_id) {
        req.order_id = +this.$route.query.order_id
      }
      if (this.inputVal1) {
        req.sell_address = this.inputVal1
      }
      if (this.inputVal2) {
        req.sell_user_id = this.inputVal2
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/system_order_detail_list`, req, "get")
          .then((res) => {
            if (res.list === null || res.list.length === 0) {
              this.$message.error("暂无数据");
              this.total = 0;
              this.resData = [];
              return;
            }
            
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {

              list.sell_confirm_at = list.sell_confirm_at > 0 ? this.dataFormat(list.sell_confirm_at) : '-'
              list.rec_confirm_at = list.rec_confirm_at > 0 ? this.dataFormat(list.rec_confirm_at) : '-'
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
.balance {
  display: flex;

}
.balance_item {
  margin-right: 30px;
  display: flex;
  font-size: 1.6rem;
}
.balance_item p {
  line-height: 1.4;
}
</style>