import { createRouter, createWebHistory } from 'vue-router'
const routerHistory = createWebHistory()
import Login from './login'
import loginReset from './login_reset'

const router = createRouter({
    history: routerHistory,
    routes: [
        {
            path: '/login',
            component: Login
        },
        {
            path: '/loginReset',
            component: loginReset
        }
        /*{
            path: '/home',
            component: Home,
            children: [
                {
                    path: '/articleList',
                    component: articleList
                },

            ]
        },*/
    ],
    scrollBehavior() {
        // 始终滚动到顶部
        return { top: 0 }
    },
})

export default router