<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <!-- <div class="com_item_search">
        <div class="com_item_tx">新錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div> -->
      <div class="com_item_search">
        <div class="com_item_tx">哈希值</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">狀態</div>
        <div class="com_item_input">
          <el-select v-model="selectVal1" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in typeObj" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
        <el-button type="default" size="small" @click="resetFn">重置</el-button>
      </div>

    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
    </div>
    <div class="com_search_box mt_40">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="showAlertFn('', 'add')">添加任务</el-button>
      </div>
    </div>

    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>舊錢包ID</th>
          <th>新錢包ID</th>
          <th>哈希值</th>
          <th>申請時間</th>
          <th>完成時間</th>
          <th>狀態</th>
          <th>備註</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td class="left">{{ list.old_user_id }}</td>
            <td class="left">{{ list.new_user_id }}</td>
            <td class="left">
              <p v-if="list.tx_id != ''">
                <a :href="'https://bscscan.com/tx/' + list.tx_id + '?langId=cn'"
                  target="_blank">{{ list.tx_id }}</a>
              </p>
              <p v-else>-</p>
            </td>
            <td>
              <p>{{ parseFloat(list.created_at) > 0 ? dataFormat(list.created_at) : '-' }}</p>
            </td>
            <td>
              <p>{{ parseFloat(list.updated_at) > 0 ? dataFormat(list.updated_at) : '-' }}</p>
            </td>
            <td>
              <p v-if="list.status == 2" style=" color: #F90000;">失敗</p>
              <p v-else-if="list.status == 3" style=" color: #F90000;">已失效</p>
              <p v-else style="color: #409EFF">{{ list.status == 0 ? '置换中' : '成功' }}</p>
            </td>
            <td>
              <p v-if="list.status == 0" style="color: #139F0B">{{ list.note }}</p>
              <p v-else>{{ list.note }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" title="被盜置換-新增" :width="600">
      <div class="com_from_box">
        <p>輸入框輸入內容按照以下範例填寫，支援同時接受多條記錄輸入</p>
        <p>舊錢包ID,新錢包ID,哈希值</p>
        <p>*多条数据输入时需要换行</p>
        <div class="com_from_item" style="margin-top: 20px;">
          <div class="com_from_right full-width">
            <el-input type="textarea" rows="10" v-model="content" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" placeholder="请输入" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAlert = false">关闭</el-button>
          <el-button type="primary" @click="submitFn">提交</el-button>
        </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "activityDinnerReport",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      options: [],
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      // inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      expenses: '',
      selectFail: '',
      min: '',
      max: '',
      isShow: false,
      passShow: false,
      typeObj: {
        0: { label: '置换中', value: '0' },
        1: { label: '成功', value: '1' },
        2: { label: '失败', value: '2' },
        3: { label: '已失效', value: '3' },
      },
      isAlert: false,
      content: '',
      filterVal: [
        "old_user_id",
        "new_user_id",
        "tx_id",
        "created_at",
        "note",
      ],
      finalData: [],
      isValid: true,
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    resetFn() {
      this.inputVal1 = ''
      // this.inputVal2 = ''
      this.inputVal3 = ''
      this.selectVal1 = ''
      this.startTime = ''
      this.endTime = ''
      this.resInit()
    },
    checkContent(content) {
      const arr = content.split('\n')
      const newArr = []
      arr.forEach((val, index) => {
        const obj = {}
        const item = val.split(',')
        if (item.length != 3) {
          this.isValid = false
          this.$message.error(`第${index + 1}行格式錯誤`)
          return
        } else {
          this.isValid = true
        }

        if (!item[0] || !item[1]) {
          this.isValid = false
          this.$message.error(`第${index + 1}行格式錯誤`)
          return
        } else {
          this.isValid = true
        }
        obj.old_user_id = item[0]
        obj.new_user_id = item[1]
        obj.tx_id = item[2]
        newArr.push(obj)
      })
      this.finalData = newArr
    },
    submitFn() {
      this.checkContent(this.content)
      if (this.isValid) {
        if (this.two_auth_code == '') {
          this.$message.error('请输入谷歌验证码');
          return
        }
        const req = {
          two_auth_code: this.two_auth_code,
          data: this.finalData
        }
        this.$ajax(`${this.Utils.URL.goURL}wallet_stolen_management`, req, 'post', 2500000, false, true)
          .then((res) => {
            if (res.result) {
              this.isAlert = false
              this.resInit()
              this.$message.success('成功');
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
      }

    },
    showAlertFn(index, type) {
      this.isAlert = true
      // reset all fields
      this.content = ''
      this.two_auth_code = ''
    },
    showFn(index, type) {
      this.curIndex = index
      this.curType = type
      this.two_auth_code = ''
      this.options = []
      this.resData[index].new_wallet_id_list.forEach((val, index) => {
        this.options.push(index + 1)
      })
      this.expenses = this.options.length
      this.isShow = true
    },

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.user_id = this.inputVal1;
      }
      // if (this.inputVal2) {
      //   req.new_user_id = this.inputVal2;
      // }
      if (this.inputVal3) {
        req.tx_id = this.inputVal3;
      }
      if (this.selectVal1) {
        req.status = this.selectVal1;
      }

      if (this.startTime) {
        req.start_date = this.startTime
      }
      if (this.endTime) {
        req.end_date = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}wallet_stolen_management`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}

.full-width {
  width: 100%;
}
.com-table td.left {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}
</style>