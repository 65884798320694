<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">投资钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">投资钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">借款钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">借款钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">借款共管地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal5" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">交易hash</div>
        <div class="com_item_input">
          <el-input v-model="inputVal6" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">贷款类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in loanTypeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">投资类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div style="width: 2800px">
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>合同编号</th>
        <th>贷款类型</th>
        <th>贷款人信息</th>
        <th>链类型</th>
        <th>投资币种</th>
        <th>投资数量</th>
        <th>预计利息</th>
        <th>收益率</th>
        <th>放贷周期(天)</th>
        <th>投资类型</th>
        <th>投资钱包ID</th>
        <th>投资钱包地址</th>
        <th>借款钱包ID</th>
        <th>借款钱包地址</th>
        <th>借贷共管地址</th>
        <th>交易Hash</th>
        <th>投资时间</th>
        <th>投资生效时间</th>
        <th>投资到期时间</th>
        <th>备注</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.contract_number}}</td>
          <td>{{ list.borrow_type }}</td>
          <td >{{ list.nick }}</td>
          <td >{{ list.chain_type }}</td>
          <td >{{ list.symbol }}</td>
          <td>{{scientificNotationToString(list.lending_numb)}}</td>
          <td>{{scientificNotationToString(list.interest)}}</td>
          <td >{{ list.lending_rate }}</td>
          <td >{{ list.duration }}</td>
          <td >{{ list.invest_type }}</td>
          <td >{{ list.wallet_id }}</td>
          <td >{{ list.lending_addr }}</td>
          <td >{{ list.borrow_wallet_id }}</td>
          <td >{{ list.borrow_wallet_addr }}</td>
          <td >{{ list.multi_addr }}</td>
          <td >{{ list.hash }}</td>
          <td>{{ (list.created_at) }}</td>
          <td>{{ (list.start_at) }}</td>
          <td>{{ (list.end_at) }}</td>
          <td >{{ list.remarks }}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.lending_numb)}}</td>
          <td> {{scientificNotationToString(subtotal.interest)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.lending_numb)}}</td>
          <td> {{scientificNotationToString(sum.interest)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>U资产统计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.lending_numb)}} USDT</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>非U资产统计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <p style="white-space: nowrap;"  v-for="list in other_total.lending_numb">{{list.amount}} {{list.code}}</p>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "personalLoanDetails",
  data() {
    return {
      page: 1,
      size: 50,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      inputVal5: "",
      inputVal6: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      other_total: {},
      usd_total: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      typeObj: {
        1: {label: '钱包资产投资', value: '1'},
        2: {label: '撮合池余额自动复投', value: '2'},
        4: {label: '系统匹配复投（1天）', value: '4'},
        5: {label: '撮合池余额手动复投', value: '5'},
      },
      typeObj1: {
        1: {label: '钱包投资', value: '1'},
        2: {label: '撮合池余额复投', value: '2'},
      },
      loanTypeObj: {
        1: {label: '用户借贷', value: '1'},
        2: {label: '机构借贷', value: '2'},
        3: {label: '机构基金', value: '3'},
      },
      filterVal: [
        "contract_number",
        "borrow_type",
        "nick",
        "chain_type",
        "symbol",
        "lending_numb",
        "interest",
        "lending_rate",
        "duration",
        "invest_type",
        "wallet_id",
        "lending_addr",
        "borrow_wallet_id",
        "borrow_wallet_addr",
        "multi_addr",
        "hash",
        "created_at",
        "start_at",
        "end_at",
        "remarks",
      ],
    };
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    if (this.$route.query.captain_id) {
      this.inputVal1 = this.$route.query.captain_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };

      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.lending_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.borrow_wallet_id = this.inputVal3;
      }
      if (this.inputVal4) {
        req.borrow_wallet_addr = this.inputVal4;
      }
      if (this.inputVal5) {
        req.public_addr = this.inputVal5;
      }
      if (this.inputVal6) {
        req.tx_id = this.inputVal6;
      }
      if (this.selectVal1) {
        req.borrow_type = this.selectVal1;
      }
      if (this.selectVal2) {
        req.invest_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.coin_id = this.selectVal4;
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}user_lending_details`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.other_total = res.other_total ? res.other_total : {}
            this.usd_total = res.usd_total ? res.usd_total : {}
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              list.borrow_type = this.loanTypeObj[list.borrow_type] ? this.loanTypeObj[list.borrow_type].label : list.borrow_type
              list.invest_type = this.typeObj[list.invest_type] ? this.typeObj[list.invest_type].label : list.typeObj
              list.created_at = this.dataFormat(list.created_at)
              list.start_at = this.dataFormat(list.start_at)
              list.end_at = this.dataFormat(list.end_at)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>