<template>
    <article class="">
        <div class="com_search_box"  v-if="isEditButton">
            <div class="com_item_search">
                <el-button type="primary" size="small" @click="goDetails">新增角色</el-button>
            </div>

        </div>
        <div class="tab_box">
            <table class="com-table">
                <thead class="com_thead">
                <th>序号</th>
                <th>id</th>
                <th>角色</th>
                <th>操作</th>

                </thead>
                <tbody class="com_tbody">
                <tr v-for="(list, index) in resData" :key="list.id">
                    <td>{{page == 1 ? index + 1 : index == 0 ? ((page - 1)* 10) + 1 : ((page - 1) * 10) + index + 1}}</td>
                    <td>{{list.role_id}}</td>
                    <td>{{list.role_name}}</td>
                    <td class="tab_btn">
                        <span class="btn" v-if="isEditButton" @click="goDetails(list)">编辑</span>
                        <span class="btn" v-if="isEditButton" @click="delFn(index)">删除</span>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
        <div class="com_page">
            <el-pagination
                    layout="pager"
                    :total="total"
                    :current-page="page"
                    @current-change="pageFn"
            >
            </el-pagination>
        </div>

    </article>
</template>

<script>
    export default {
        name: "adminRoleList",
        data() {
            return {
                page: 1,
                size: 1000,
                total: '',
                resData: {}
            }
        },
        created () {
            this.resInit()
        },
        methods: {
            goDetails (node) {
                let obj
                if (node) {
                    obj = node
                } else {
                    obj = {}
                }
                window.sessionStorage.setItem('listDataInfo', JSON.stringify(obj))
                this.goRouter('/adminRoleEdit')
            },
            delFn (index) {
                this.curIndex = index
                this.$confirm('是否删除', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ajax(`${this.Utils.URL.goURL}delete_role`, {
                        role_id: this.resData[index].role_id
                    }, 'post')
                        .then(res => {
                            if (res.status == 1) {
                                this.$message.success('操作成功')
                                this.resData.splice(index, 1)
                            } else {
                                this.$message.error('操作失败')
                            }
                        })
                        .catch(res => {
                            this.$message.error(res.msg)
                        })
                }).catch(() => {

                });
            },
            resInit () {
                let req = {
                    page: this.page,
                    size: this.size
                }

                this.$ajax(`${this.Utils.URL.goURL}role_list`, req, 'get')
                    .then(res => {
                        this.resData = res.list ? res.list : []
                        window.sessionStorage.setItem('roleData', JSON.stringify(this.resData))
                        this.total = parseFloat(res.total)
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },
            pageFn (index) {
                this.page = index
                this.resInit()
            }
        }
    }
</script>

<style scoped>

</style>