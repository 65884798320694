<template>
  <article class="">
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
            v-model="startTime"
            type="datetime"
            size="small"
            format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
            v-model="endTime"
            type="datetime"
            size="small"
            format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>用户名</th>
        <th>IP</th>
        <th>描述</th>
        <th>日期</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ page == 1 ? index + 1 : index == 0 ? ((page - 1) * 10) + 1 : ((page - 1) * 10) + index + 1 }}</td>
          <td>{{ list.account_number }}</td>
          <td>{{ list.ip_addr }}</td>
          <td>{{ list.operation_summary }}</td>
          <td>{{ dataFormat(list.created_at) }}</td>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
        layout="pager"
        :total="total"
        :current-page="page"
        @current-change="pageFn"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "systemLog",
  data() {
    return {
      selectVal1: '',
      selectVal2: '',
      options1: [{value: '0', label: '不需要'}, {value: '1', label: '否'}, {value: '2', label: '强制'}],
      options2: [{value: '1', label: 'Android'}, {value: '2', label: 'iOS'}],
      startTime: '',
      endTime: '',
      page: 1,
      size: 10,
      total: '',
      resData: {}
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}log_file_list`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    }
  }
}
</script>

<style scoped>

</style>