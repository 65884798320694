<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">


      <div class="com_item_search">
        <div class="com_item_tx">用户钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">订单编号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">商户收款人</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">收款账号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">商户通道</div>
        <div class="com_item_input">
          <el-input v-model="inputVal5" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">订单状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">付款方式</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">付款币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal5"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option :label="list.currency_name + '(' + list.currency  + ')'" :value="list.currency" v-for="list in currencyList"></el-option>

          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>

    <div class="scroll_x">
      <div class="tab_box mt_40 w2100">
        <table class="com-table">
          <thead class="com_thead">
          <th>序号</th>
          <th>订单编号</th>
          <th>用户钱包ID</th>
          <th>卖币合约地址</th>
          <th>币种</th>
          <th>链类型</th>
          <th>购买数量</th>
          <th>单价</th>
          <th>付款金额</th>
          <th>付款币种</th>
          <th>订单状态</th>
          <th>支付方式</th>
          <th>付款用户姓名</th>
          <th>用户支付账号</th>
          <th>商户通道</th>
          <th>收款渠道</th>
          <th>商户收款人</th>
          <th>商家收款账号</th>
          <th>订单创建时间</th>
          <th>订单完成时间</th>
          <th>操作</th>
          <th>备注信息</th>
          <th>付款截图</th>
          </thead>
          <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="index">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{list.order_id}}</td>
            <td>{{list.wallet_id}}</td>
            <td>{{ list.sell_contract_addr }}</td>
            <td>{{ list.symbol }}</td>
            <td>{{ list.chain_type }}</td>
            <td>{{ list.amount }}</td>
            <td>{{ list.price }}</td>
            <td>{{ list.total }}</td>
            <td>{{ list.currency }}</td>
            <td>{{ list.status_tx }}</td>
            <td>{{ list.pay_way_tx }}</td>
            <td>{{ list.buyer_bank_name }}</td>
            <td>{{ list.buyer_account }}</td>
            <td>{{ list.channel }}</td>
            <td>{{ list.bank_name ? list.bank_name : list.pay_way_tx }}</td>
            <td>{{ list.sell_name }}</td>
            <td>{{ list.sell_account }}</td>
            <td>{{ list.created_at }}</td>
            <td>{{ list.complete_at }}</td>
            <td class="tab_btn">
              <span class="btn" v-show="(list.status == 2 ) && isEditButton"  @click="showLevelFn(index)">放币</span>
              <span class="btn" v-show="list.status == 6 || list.operate_status == 1"  @click="goDetailsFn(list)">申诉详情</span>
            </td>
            <td>{{ list.remark }}</td>
            <td>
              <a v-if="list.transfer_pic" :href="Utils.URL.imgURL + list.transfer_pic" target="_blank"><img :src="Utils.URL.imgURL + list.transfer_pic" width="100"/></a>
            </td>
          </tr>

          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.amount)}}</td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.total)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(sum.amount)}}</td>
            <td></td>
            <td>{{scientificNotationToString(sum.total)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>



          </tbody>
        </table>
      </div>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="'放币操作'" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">购买币种</div>
          <div class="com_from_right">
            {{alertData.symbol}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">链类型</div>
          <div class="com_from_right">
            {{alertData.chain_type}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">购买数量</div>
          <div class="com_from_right">
            {{alertData.amount}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">付款货币</div>
          <div class="com_from_right">
            {{alertData.currency}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">单价</div>
          <div class="com_from_right">
            {{alertData.price}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">付款金额</div>
          <div class="com_from_right">
            {{alertData.total}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">所属银行</div>
          <div class="com_from_right">
            {{alertData.buyer_bank_name}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">付款账号</div>
          <div class="com_from_right">
            {{alertData.buyer_account}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">用户钱包ID</div>
          <div class="com_from_right">
            {{alertData.wallet_id}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left"></div>
          <div class="com_from_right">
            请确认买币付款到账并且金额无误后再进行放币操作，以免造成不必要损失！
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="submitFn">提交</el-button>
                </span>
      </template>
    </el-dialog>




  </article>
</template>

<script>
export default {
  name: "c2cSellOrder",
  data() {
    return {
      resData: [],
      selectVal1: '2',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      selectVal5: 'CNY',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      inputVal4: '',
      inputVal5: '',
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      curIndex: 0,
      curType: '',
      page: 1,
      size: 10,
      total: '',
      startTime: '',
      endTime: '',
      tHeader: [],
      alertData: {},
      isAlert: false,
      two_auth_code: '',
      typeObj: {
        'BankCard': {value: 'BankCard', label: '银行卡'},
        'Alipay': {value: 'Alipay', label: '支付宝'},
        'WeChat': {value: 'WeChat', label: '微信'},
      },
      typeObj1: {
        '1': {value: '1', label: '待付款'},
        '2': {value: '2', label: '待放币'},
        '3': {value: '3', label: '待投资'},
        '4': {value: '4', label: '已投资'},
        '5': {value: '5', label: '已取消'},
        '6': {value: '6', label: '申诉中'},
      },
      coinInfo: [],
      currencyList: [],
      sum: {},
      subtotal: {},
      filterVal: [
        "order_id",
        "wallet_id",
        "sell_contract_addr",
        "symbol",
        "chain_type",
        "amount",
        "price",
        "total",
        "currency",
        "status_tx",
        "pay_way_tx",
        "buyer_account",
        "channel",
        "bank_name",
        "sell_name",
        "sell_account",
        "created_at",
        "complete_at",
        "remark",
      ],
    }
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.$ajax(`${this.Utils.URL.goURL}buy/admin_select_currency`, {}, 'get')
        .then(res => {
          this.currencyList = res.list ? res.list : []
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    this.resInit()
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    submitFn () {

      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }

      let req = {
        order_id: this.alertData.order_id,
        two_auth_code: this.two_auth_code,

      };
      let url = 'buy/put_money'
      if (this.alertData.status == 2) {
        url = 'buy/put_money'
      }


      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isAlert = false;
            this.$message.success("放币成功");
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    showLevelFn (index) {
      this.curIndex = index
      this.two_auth_code = ''
      this.alertData = this.resData[index]
      this.isAlert = true
    },
    goDetailsFn(obj) {
      window.sessionStorage.setItem('listDataInfo', JSON.stringify(obj))
      this.goRouter('/c2cAppealSellDetails')
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },

    resInit() {
      let req = {
        page: this.page,
        size: this.size,
        op_type: 3,
        order_type: 2,
      }
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1
      }
      if (this.inputVal2) {
        req.order_id = this.inputVal2
      }
      if (this.inputVal3) {
        req.sell_name = this.inputVal3
      }
      if (this.inputVal4) {
        req.card_no = this.inputVal4
      }
      if (this.inputVal5) {
        req.channel = this.inputVal5
      }
      if (this.selectVal1) {
        req.status = this.selectVal1
      }
      if (this.selectVal2) {
        req.pay_type = this.selectVal2
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4
      }
      if (this.selectVal5) {
        req.currency = this.selectVal5
      }

      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}buy/order_admin_list`, req, 'get')
          .then(res => {
            this.resData= res.list ? res.list : []
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              list.status_tx = this.typeObj1[list.status] ? this.typeObj1[list.status].label: list.status
              list.pay_way_tx = this.typeObj[list.pay_way] ? this.typeObj[list.pay_way].label: list.pay_way
              if (list.operate_status == 1 ) {
                if (list.status == 5) {
                  list.status_tx = '卖家胜诉'
                }
                if (list.status == 3 || list.status == 4) {
                  list.status_tx = '买家胜诉'
                }
              }

              list.evidence_tx = ''
              if (list.appeal_status != 0) {
                list.appeal_status_tx = list.appeal_status == 1 ? '买家起诉' : '卖家起诉'
                if (list.appeal_status == 1 && list.sell_evidence_data.appeal_type != 0) {
                  list.evidence_tx = '卖家提交'
                }
                if (list.appeal_status == 2 && list.buy_evidence_data.appeal_type != 0) {
                  list.evidence_tx = '买家提交'
                }
              } else {
                list.appeal_status_tx = ''
              }

              list.created_at = this.dataFormat(list.created_at)
              list.complete_at = this.dataFormat(list.complete_at)
            })
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    }
  },
}
</script>

<style scoped>

</style>