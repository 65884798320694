<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">组织者钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">组织者钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">新用户钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <!-- <div class="com_item_search">
        <div class="com_item_tx">是否羊毛党</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                :label="'普通用户'"
                :value="0"
            >
            </el-option>
            <el-option
                :label="'羊毛党'"
                :value="1"
            >
            </el-option>
          </el-select>

        </div>
      </div> -->
      <div class="com_item_search">
        <div class="com_item_tx">拒绝原因</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj1"
                :key="item.status"
                :label="item.remark"
                :value="item.status"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">業績狀態</div>
        <div class="com_item_input">
          <el-select
              v-model="assets_status"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
          <el-option
                v-for="item in typeObj2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

    </div>

    <div v-show="false">
      <div class="com_item_search">
        <el-button
            type="primary"
            size="small"
            @click="passShow = true"
        >审核阈值设置</el-button
        >
      </div>
    </div>

    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>组织者钱包ID</th>
        <!-- <th>组织者钱包地址</th>
        <th>用户钱包ID</th>
        <th>已发放钱包ID</th> -->
        <!-- <th>组织者数据统计</th>
        <th>撸羊毛金额(USDT)</th>
        <th>是否是羊毛党</th> -->
        <th>真实ip地址</th>
        <th>用户ip</th>
        <th>新增團隊流動性增額度(拆合USDT)</th>
        <th>国家/地区</th>
        <th>社区名称</th>
        <th>视频资料</th>
        <th>截图资料</th>
        <th>审核状态</th>
        <th>當前狀態</th> <!-- add new 08/20-->
        <th>聚餐桌數(用戶添寫)</th> <!-- add new 08/20-->
        <th>審核桌數(1桌150)</th> <!-- change 08/20-->
        <th>提交资料时间</th>
        <th>操作时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.parent_id}}</td>
          <!-- <td>{{ list.parent_addr}}</td>
          <td>
            <p v-for="add in list.new_wallet_id_list" class="tx_left d_flex mb_5" >
              <span>{{add.status == 1 ? '新用户' : add.status ==  2 ? '旧用户' : add.status == 3 ? '未绑定' :  add.status == 4 ?'重复设备' : '已发放奖励'}}:</span> <span>{{ add.new_wallet_id}}</span>
            </p>
          </td>
          <td>
            <p v-for="news in list.reward_wallet_id" class="tx_left d_flex mb_5" >
              <span>{{news.status == 1 ? '新用户' : news.status ==  2 ? '旧用户' : news.status == 3 ? '未绑定' :  news.status == 4 ?'重复设备' : news.status == 5 ? '已发放' : '已发放奖励'}}:</span> <span>{{ news.new_wallet_id}}</span>
            </p>
          </td> -->
          <!-- <td class="tab_btn">
            <div v-show="list.isClick">
              <p style="white-space: nowrap;" >报销次数:{{list.times}}</p>
              <p style="white-space: nowrap;" >报销总金额:{{list.total_amount}}</p>
              <p style="white-space: nowrap;" >团队投资总金额:{{list.invest_amount}} USDT</p>
              <p style="white-space: nowrap;" >团队净入金总金额:{{list.net_income}} USDT</p>
            </div>
            <p class="btn"  @click="searchIncomeFn(list, index)">{{list.isClick ? '刷新' : '查询'}}</p>

          </td>
          <td>{{list.woolen_wool}}</td>
          <td>{{list.horse_type != 0 ? '是' : '否'}}</td> -->
          <td>{{list.real_area}}</td>
          <td>{{list.client_ip}}</td>
          <td>{{list.incr_amt}}</td>
          <td>{{ list.country}}/{{ list.city}}</td>
          <td>{{ list.community}}</td>
          <td><a :href="Utils.URL.imgURL + list.video_link" target="_blank">{{list.video_link}}</a></td>
          <td>
            <p v-for="img in list.pic_list">
              <a :href="Utils.URL.imgURL + img.pic_link" target="_blank"><img :src="Utils.URL.imgURL + img.pic_link" width="100"/></a>
            </p>
          </td>

          <td>{{ list.type}}</td>
          <td>{{ list.assets_status}}</td>
          <td>{{ list.apply_num}}</td>
          <td>{{ list.expenses}}</td>
          <td>{{ list.created_at}}</td>
          <td>{{ list.operate_at}}</td>
          <td class="tab_btn">
            <span class="btn" v-if="list.status == 1" @click="showFn(index, 2)">通过</span>
            <span class="btn" v-if="list.status == 1" @click="showFn(index, 3)">拒绝</span>

          </td>
        </tr>

        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <!-- <td></td>
          <td></td>
          <td></td> -->
          <!-- <td></td>
          <td></td>
          <td></td> -->
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.incr_amt)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <!-- <td></td>
          <td></td>
          <td></td> -->
          <!-- <td></td>
          <td></td>
          <td></td> -->
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.incr_amt)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        </tbody>
      </table>
    </div>
    <el-dialog v-model="isShow" :title="curType == 2 ? '审核通过' : '审核拒绝'" width="500px">
      <section class="com_from_box">

        <div class="com_from_item" v-show="curType == 3">
          <div class="com_from_left">拒绝原因</div>
          <div class="com_from_right">
            <el-select
                v-model="selectFail"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in typeObj1"
                  :key="item.status"
                  :label="item.remark"
                  :value="item.status"
              >
              </el-option>
            </el-select>

          </div>
        </div>

        <div class="com_from_item" v-show="curType == 2">
          <div class="com_from_left">桌數</div>
          <div class="com_from_right">
            <el-input
                v-model="expenses"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
            <!-- <el-select
                v-model="expenses"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in options"
                  :key="item"
                  :label="item + '个'"
                  :value="item"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">温馨提示</div>
          <div class="com_from_right">
            {{curType == 2 ? '审核通过后自动投资地址会向组织者钱包地址发起转账' : '确定拒绝本次审核？'}}
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isShow = false">取消</el-button>
                    <el-button type="primary" @click="editRateFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="passShow" title="审核阈值设置" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">撸羊毛审核最小值</div>
          <div class="com_from_right">
            <el-input v-model="min" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">撸羊毛审核最大值</div>
          <div class="com_from_right">
            <el-input v-model="max" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="passShow = false">取消</el-button>
                    <el-button type="primary" @click="editPassFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "activityDinnerReport",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      options: [],
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      expenses: '',
      selectFail: '',
      min: '',
      max: '',
      isShow: false,
      passShow: false,
      typeObj: {
        1: {label: '待审核', value: '1'},
        2: {label: '审核通过', value: '2'},
        3: {label: '审核拒绝', value: '3'},
        4: {label: '已发放', value: '4'},
        5: {label: '已发放', value: '5'},
      },
      typeObj1: [],
      typeObj2: {
        1: {label: '业绩达标', value: '1'},
        2: {label: '业绩未达标，72小时以内', value: '2'},
        3: {label: '业绩未达标，超过72小时', value: '3'},
      },
      filterVal: [
        "wallet_id",
        "name",
        "card_id",
        "pic_link",
        "type",
        "created_at",
        "updated_at",
      ],
      assets_status: ''
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.resInit();
    this.statusList();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showFn (index, type) {
      this.curIndex = index
      this.curType = type
      this.two_auth_code = ''
      this.options = []
      this.resData[index].new_wallet_id_list.forEach((val, index) => {
        this.options.push(index + 1)
      })
      this.expenses = this.options.length
      this.isShow = true
    },
    editPassFn () {

      if (this.min === '') {
        this.$message.error('请输入最小值')
        return false
      }
      if (this.max === '') {
        this.$message.error('请输入最大值')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'horse/submit_horse_operation'
      let req = {
        min: this.min,
        max: this.max,
        two_auth_code: this.two_auth_code,
      }

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.$message.success('操作成功')
            this.two_auth_code = ''
            this.passShow = false
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    editRateFn () {
      let index = this.curIndex

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'horse/submit_horse_operation'
      let req = {
        id: this.resData[index].id,
        op_type: this.curType,
        two_auth_code: this.two_auth_code,
      }
      if (this.curType == 2 && (this.expenses == '' || this.expenses == 0)) {
        this.$message.error('请输入报销人数')
        return  false
      }
      if (this.curType == 3 && this.selectFail == '') {

        this.$message.error('请选择拒绝原因')
        return  false
      }
      if (this.curType == 2) {
        req.expenses = this.expenses
      }
      if (this.curType == 3) {
        req.fail_status = this.selectFail
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.$message.success('操作成功')
            this.resData[index].status = this.curType
            this.resData[index].type = this.typeObj[this.curType].label
            this.isShow = false
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    statusList() {
      this.$ajax(`${this.Utils.URL.goURL}horse/fail_list`, {}, "get")
          .then((res) => {
            this.typeObj1 = res.list ? res.list : [];

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.parent_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.parent_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.new_wallet_id = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.status = this.selectVal2;
      }
      // if (this.selectVal3 !== '') {
      //   req.horse_type = this.selectVal3;
      // }
      if (this.selectVal4) {
        req.fail_status = this.selectVal4;
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      if (this.assets_status) {
        req.assets_status = this.assets_status;
      }
      this.$ajax(`${this.Utils.URL.goURL}horse/horse_apply_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.isClick = false
              list.times = 0
              list.total_amount = 0
              list.invest_amount = 0
              list.net_income = 0
              list.type = this.typeObj[list.status] ? this.typeObj[list.status].label: list.status
              list.operate_at = this.dataFormat(list.operate_at)
              list.created_at = this.dataFormat(list.created_at)
              list.assets_status = this.typeObj2[list.assets_status] ? this.typeObj2[list.assets_status].label : '-'
            })
            
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
    searchIncomeFn (list, index) {
      let req = {
        user_id: list.parent_id
      }
      this.$ajax(`${this.Utils.URL.goURL}horse/flush`, req, "get")
          .then((res) => {
            let list = res ? res : {};

            this.resData[index].isClick = true
            this.resData[index].times = list.times
            this.resData[index].total_amount = list.total_amount
            this.resData[index].invest_amount = list.invest_amount
            this.resData[index].net_income = list.net_income

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>