<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="goRouter('/terminalListEdit')" >新增终端</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>ID</th>
        <th>终端</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ list.id }}</td>
          <td>{{ list.port }}</td>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="com_page" v-if="false">
      <el-pagination
        layout="pager"
        :total="total"
        :current-page="page"
        @current-change="pageFn"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "terminalList",
  data() {
    return {
      options2: [],
      page: 1,
      size: 10,
      total: '',
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      startTime: '',
      endTime: '',
      resData: [],
      selectArr: [{value: '钱包', label: '钱包'}]
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    resInit() {
      let req = {
        type_content_id: 1
      }
      this.$ajax(`${this.Utils.URL.goURL}port_list`, req, 'get')
        .then(res => {
          this.resData = res ? res : []
          this.total = parseFloat(res.total)
          let arr = res ? res : []
          let arr2 = []
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.port
            }
          })
          window.sessionStorage.setItem('portList', JSON.stringify(arr2))
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    }
  }
}
</script>

<style scoped>

</style>