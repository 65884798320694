<template>
  <article class="h5_login_reset_view">
    <div class="login_box">
      <h2 class="title_h2">重置密码</h2>
      <p class="item">
        <span class="name">原密码:</span>
        <input class="input_pass" v-model="oldPass" type="password" placeholder="请输入原密码"/>
      </p>
      <p class="item">
        <span class="name">新密码:</span>
        <input class="input_pass" v-model="pass" type="password" placeholder="请输入8-20字母跟数字组合"/>
      </p>
      <p class="item">
        <span class="name">确认密码:</span>
        <input class="input_pass" v-model="repass" type="password" placeholder="请输入8-20字母跟数字组合"/>
      </p>
      <p class="item">
        <span class="name">生成谷歌Key</span>
        <input class="input_tx" type="text" readonly disabled placeholder="请生成" :value="googleKey"/>
        <input type="button" value="生成Key" class="code_btn" @click="createGoogleFn">
        <input type="button" value="复制" class="code_btn" @click="copyText(googleKey)">
      </p>
      <p class="item">
        <span class="name">谷歌验证码</span>
        <input class="input_tx" placeholder="请输入" type="text" v-model="keyCode"/>
      </p>
      <p class="item tx_cen"><input class="input_btn" type="button" @click="submitFn" value="提交">
      </p>
      <p class="">
        <span class="color_T5 fz12">温馨提示：</span>
      </p>
      <p class="item">
        <span class="color_T3 fz12">用户首次进入后台时，为了保证您的账号和财产安全，请自行设置登录密码并妥善保管，请勿将账户信息泄露给他人，以免给您的财产造成不必要的损失！</span>
      </p>
    </div>
  </article>
</template>

<script>
import md5 from 'blueimp-md5'

export default {
  name: 'loginReset',
  data() {
    return {
      googleKey: '',
      googleUid: '',
      keyCode: '',
      oldPass: '',
      repass: '',
      pass: '',
      isDisabled: false,
      time: 60,
      timeFn: null,
      btnsmsVal: '验证码',
      user_id: ''
    }
  },
  created() {
    this.user_id = window.sessionStorage.getItem('user_id')
  },
  methods: {

    createGoogleFn() {
      this.$ajax(`${this.Utils.URL.goURL}gen_two_authkey`, {}, 'get')
        .then(res => {
          this.googleKey = res.two_auth_key
          this.googleUid = res.auth_key_uuid
          this.$message.success('生成成功,请务必保管好谷歌Key')
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    submitFn() {
      if (!this.user_id) {
        this.$message.error('非法操作')
        return false
      }
      if (this.oldPass === '') {
        this.$message.error('请输入原密码')
        return false
      }
      if (this.pass != this.repass) {
        this.$message.error('两次密码不一致')
        return false
      }
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(this.pass) || !this.pass) {
        this.$message.error('密码必须包含字母和数字,且在8-20位之间')
        return false
      }
      /*if (!this.emailCode) {
        this.$message.error('请输入邮箱验证码')
        return false
      }*/
      if (!this.googleKey) {
        this.$message.error('请生成谷歌key')
        return false
      }
      if (!this.keyCode) {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        user_id: this.user_id,
        old_passwd: md5(this.oldPass),
        new_passwd: md5(this.pass),
        confirm_passwd: md5(this.repass),
        authkey_uuid: this.googleUid,
        two_auth_code: this.keyCode,
      }
      this.$ajax(`${this.Utils.URL.goURL}reset_user_pass`, req, 'post')
        .then(res => {
          this.$message.success('重置成功,两秒后自动跳转登录页面')
          setTimeout(() => {
            this.$router.push('/login')
          }, 2000)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.h5_login_reset_view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .title_h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .login_box {
    background: $FFF;
    border-radius: 8px;
    padding: 20px;
    width: 560px;
    border: 1px solid $BR;

    .item {
      margin-bottom: 20px;

    }

    .name {
      display: inline-block;
      width: 90px;
    }

    .input_tx {
      width: 250px;
      line-height: 40px;
      margin-left: 20px;
      border-radius: 8px;
      border: 1px solid $BR;
      padding: 0 20px;
    }

    .input_pass {
      width: 250px;
      line-height: 40px;
      margin-left: 20px;
      border-radius: 8px;
      border: 1px solid $BR;
      padding: 0 20px;
    }

    .input_btn {
      width: 200px;
      background: $T4;
      line-height: 40px;
      text-align: center;
      border-radius: 8px;
      margin: 0 auto;
      color: $FFF;
      cursor: pointer;
    }

    .code_btn {
      padding: 0px 10px;
      line-height: 35px;
      cursor: pointer;
      color: $FFF;
      margin-left: 10px;
      text-align: center;
      border-radius: 4px;
      background: $T4;
    }
  }
}

</style>
