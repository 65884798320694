<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <section class="com_search_box">

      <div class="com_item_search">
        <el-button type="primary"  size="small" @click="singFn('')">上传Ftc-main钱包地址私钥</el-button>
      </div>
    </section>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>Ftc-main钱包地址</th>
        <th>私钥状态</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr>
          <td>{{resData[0]?.addr}}</td>
          <td>{{resData[0]?.pri_enabled ? '正常' : '未录入'}}</td>
          <td class="tab_btn">
            <span class="btn" @click="singFn(1)">重传私钥</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curIndex === '' ? '上传Ftc-main钱包地址私钥' : '重传私钥'" :width="750">
      <div class="com_search_box">

        <div class="com_item_search">
          <div class="com_item_tx">公钥</div>
          <div class="com_item_input mr_10" style="width: 550px">
            <el-input
                v-model="keyR.pub_key"
                placeholder=""
                :readonly="true"
                rows="7"
                type="textarea"
                size="small">
            </el-input>
          </div>
          <span class="color_T1 cur_po" @click="copyText(keyR.pub_key)">复制</span>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">秘钥密文</div>
          <div class="com_item_input" style="width: 550px">
            <el-input
                v-model="toAddr"
                placeholder="请输入"
                rows="7"
                type="textarea"
                size="small">
            </el-input>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">谷歌验证码</div>
          <div class="com_item_input">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary"  @click="submitKeyFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "addFthAddress",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      two_auth_code: '',
      resData: {},
      keyR: {},
      curIndex: '',
      isAlert: false,
      gasPriceData: {},
      toAddr: '',
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },
    resInit () {
      let url = 'sign/hot_addr'
      let req = {
        usage: 'ftcmain'
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
          .then(res => {
            this.resData = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    singFn () {
      this.isAlert = true
      this.toAddr = ''
      this.two_auth_code = ''

      let req = {
      }
      this.$ajax(`${this.Utils.URL.goURL}sign/public_key`, req, 'get')
          .then(res => {
            this.keyR = res ? res : {}

          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitKeyFn() {
      let index= this.curIndex
      if (this.toAddr === '') {
        this.$message.error('请填写私钥')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'sign/upload_hot_key'
      let req = {
        id: this.keyR.id,
        key: this.toAddr,
        two_auth_code: this.two_auth_code,
        usage: 'ftcmain',
        chain_type: this.resData[0] ? this.resData[0].chain_type : '',
        addr: this.resData[0] ? this.resData[0].addr : '',
      }
      this.$confirm(`${this.curIndex === '' ? '是否上传Ftc-main钱包地址私钥' : '是否重传私钥'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('成功')
              this.isAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
  },
}
</script>

<style scoped>

</style>