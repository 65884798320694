<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item" >
        <div class="com_from_left">
          节点地址
        </div>
        <div class="com_from_right">
          <el-input
              v-model="address"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          节点名称
        </div>
        <div class="com_from_right">
          <el-input
            v-model="node_name"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>


      <div class="com_from_item">
        <div class="com_from_left">
          所在地区
        </div>
        <div class="com_from_right">
          <el-input
              v-model="region"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          节点介绍
        </div>
        <div class="com_from_right">
          <el-input type="textarea"
                    rows="5"
                    v-model="introduce"
                    size="small">

          </el-input>
        </div>
      </div>


      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
              v-model="code"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="sumbitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "nodeListEdit",
  data() {
    return {
      address: '',
      region: '',
      node_name: '',
      introduce: '',
      code: '',
    }
  },
  created() {
    this.type = this.$route.query.type

    if (this.type == 'edit') {
      this.infoData = JSON.parse(window.localStorage.getItem('listDataInfo'))

      this.address = this.infoData.address
      this.region = this.infoData.region
      this.node_name = this.infoData.node_name
      this.introduce = this.infoData.introduce


    }
  },
  methods: {
    sumbitFn() {


      if (this.region === '') {
        this.$message.error('请输入节点地区')
        return false
      }


      if (this.node_name === '') {
        this.$message.error('请输入节点名称')
        return false
      }


      if (this.introduce === '') {
        this.$message.error('请输入节点介绍')
        return false
      }

      if (this.code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }



      let url = this.type == 'edit' ? 'update_node' : 'add_node'
      let req = {
        address: this.address,
        region: this.region,
        node_name: this.node_name,
        introduce: this.introduce,
        code: this.code,
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post', 25000, false, )
        .then(res => {
          this.$message.success('操作成功,两秒后自动跳转')
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>