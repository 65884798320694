<template>
  <article class="">
    <h2 style="color:red">{{$route.query.id ? '修改当前权限' : '新增下级权限'}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">权限名称</div>
        <div class="com_from_right">
          <el-input v-model="title" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">权限URL</div>
        <div class="com_from_right">
          <el-input v-model="path" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">接口URL</div>
        <div class="com_from_right" style="width: 1000px">
          <el-input v-model="sign" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">权限类型</div>
        <div class="com_from_right">
          <el-select
            v-model="type"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option label="头部菜单" value="1"> </el-option>
            <el-option label="左侧菜单" value="2"> </el-option>
            <el-option label="详情页" value="3"> </el-option>
            <el-option label="按钮" value="4"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">按钮类型</div>
        <div class="com_from_right">
          <el-select
            v-model="name"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option label="编辑按钮" value="edit"> </el-option>
            <el-option label="删除按钮" value="del"> </el-option>
            <el-option label="冻结按钮" value="lock"> </el-option>
            <el-option label="详情按钮" value="detail"> </el-option>
            <el-option label="添加按钮" value="add"> </el-option>
            <el-option label="打开按钮" value="open"> </el-option>
            <el-option label="重置按钮" value="resetPass"> </el-option>
            <el-option label="生成" value="generate"> </el-option>
            <el-option label="提取" value="extract"> </el-option>
            <el-option label="撤销" value="revoke"> </el-option>
            <el-option label="导出" value="excel"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">父级ID</div>
        <div class="com_from_right">
          <el-input v-model="parent_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">排序</div>
        <div class="com_from_right">
          <el-input v-model="sort" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left"></div>
        <div class="com_from_right">
          <span class="btn mr_10" @click="submitFn">提交</span>
          <span class="btn mr_10" @click="submitFn('edit')">修改</span>
          <span class="btn" @click="deleteFn">删除</span>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
// import ruleList from '../mock-rule.json'
export default {
  name: "adminRuleEdit",
  data() {
    return {
      path: "",
      title: "",
      type: "",
      parent_id: "",
      sign: "",
      name: "",
      sort: "",
      id: "",
    };
  },
  created() {
    let obj = window.sessionStorage.getItem("listDataInfo")
      ? JSON.parse(window.sessionStorage.getItem("listDataInfo"))
      : {};
      if(this.$route.query.id){
    this.path = obj.path;
    this.title = obj.title;
    this.type = obj.type;
    this.parent_id =  obj.parent_id;
    this.name = obj.name;
    this.sort = obj.sort;
    this.id = obj.id;
    this.sign = obj.sign;
      }else{
        this.parent_id =  obj.id;
      }

    /*let ruleLists = ruleList.list
            ruleLists = ruleLists.sort((a,b) => {
                return a.id - b.id
            })
            ruleLists.forEach((val, index) => {

                setTimeout(() => {
                    console.log(val.id)
                    // this.submitFn(val)
                }, 1000 + index * 400)
            })*/
  },
  methods: {
    treeChangeFn(val, node) {
      console.log(val, node);
    },
    deleteFn() {
      let url = "delete_power";
      if (!this.id) {
        this.$message.error("权限id为空");
        return false;
      }
      let req = {
        power_id: this.id,
      };

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
        .then((res) => {
          if (res.status == 1) {
            this.$message.success("操作成功,两秒后自动跳转");
            setTimeout(() => {
              this.goBack();
            }, 1500);
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    submitFn(obj) {
      let url = "created_power";
      let req = {
        path: this.path,
        title: this.title,
        type: this.type,
        parent_id: this.parent_id,
        name: this.name,
        sign: this.sign,
        sort: this.sort,
      };
      if (this.id) {
        req.power_id = this.id;
        url = "update_power";
      }
      if (obj == "edit") {
        req.power_id = this.$route.query.id;
        url = "update_power";
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
        .then((res) => {
          if (res.status == 1) {
            this.$message.success("操作成功");
            /*setTimeout(()=> {
                                this.goBack()
                            }, 1500)*/
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.el-tree {
  background: none;
}
</style>