<template>
    <article class="">
      <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
      <div class="com_search_box">
        <div class="com_item_search">
          <div class="com_item_tx">錢包ID</div>
          <div class="com_item_input">
            <el-input v-model="user_id" placeholder="请输入" size="small">
            </el-input>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">发放类型</div>
          <div class="com_item_input">
            <el-select v-model="category" clearable filterable placeholder="全部发放类型" size="small">
              <el-option v-for="(item, key) in typeList" :key="key" :label="item.label" :value="key">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
        <div class="com_item_search">
          <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
  
        </div>
  
      </div>
      <div class="com_search_box">
        <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
          导出excel（本页）
        </el-button>
      </div>
      <div>
        <table class="com-table  mt_40">
          <thead class="com_thead">
            <tr>
              <th>序號</th>
              <th>錢包ID</th>
              <th>发放类型</th>
              <th>FSP发放数量</th>
              <th>发放时间</th>
            </tr>
          </thead>
          <tbody class="com_tbody">
            <tr v-for="(list, index) in resData" :key="list.id">
              <td>{{ (page - 1) * size + 1 + index }}</td>
              <td>{{ list.user_id }}</td>
              <td>{{ list.type }}</td>
              <td>{{ scientificNotationToString(list.amount) }}</td>
              <td>{{ list.released_at }}</td>
            </tr>
            <tr v-show="resData.length">
              <td>总计</td>
              <td></td>
              <td></td>
              <td> {{ scientificNotationToString(subtotal) }} FSP</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="com_page">
        <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
        </el-pagination>
      </div>
  
    </article>
  </template>
  
  <script>
  export default {
    name: "fsp-issuance-record",
    data() {
      return {
        page: 1,
        size: 10,
        total: 0,
        resData: [],
        subtotal: '',
        user_id: '',
        category: '',
        tHeader: [],
        filterVal: [
          '2',
          '3',
          '4',
          '5',
        ],
        typeList: {
          1: { label: '普通释放', value: '1' },
          2: { label: '加速释放', value: '2' },
          3: { label: 'KOL加速', value: '3' },
          4: { label: '挖矿奖励', value: '4' },
        },
        startTime: '',
        endTime: '',
      };
    },
    created() {
      this.resInit();
    },
    methods: {
      handleSizeChange(val) {
        this.page = 1;
        this.size = val;
        this.resInit();
      },
      pageFn(index) {
        this.page = index;
        this.resInit();
      },
      resInit() {
        let req = {
          page: this.page,
          size: this.size,
          type: ''
        };
        if (this.user_id) {
          req.user_id = this.user_id;
        }
        if (this.category) {
          req.type = +this.category;
        }
        if (this.startTime) {
          req.start = this.startTime
        }
        if (this.endTime) {
          req.end = this.endTime
        }
        this.$ajax(`${this.Utils.URL.goURL}liquidity/fsp_release_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : []
            this.resData.forEach((list) => {
              list.released_at = this.dataFormat(list.released_at)
              // list.op_type = list.op_type == 37 ? 'FTC節點獎勵發放' : list.op_type == 38 ? 'FTC團隊獎勵發放' : list.op_type == 55 ? 'FSP團隊獎勵發放' : '-';
              list.type = this.typeList[list.type] ? this.typeList[list.type].label : list.type
            })
            this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
            this.subtotal = parseFloat(res.subtotal) ? parseFloat(res.subtotal) : 0
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>