<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">錢包地址</div>
        <div class="com_item_input">
          <el-input v-model="addr" placeholder="请输入" size="small" >
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <!-- <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div> -->

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>錢包ID</th>
          <th>鏈類型</th>
          <th>地址</th>
          <th>黑名單狀態</th>
          <th>時間</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{list.wallet_id}}</td>
            <td>{{list.chain_type}}</td>
            <td>{{list.addr}}</td>
            <td>
              <p v-if="list.score === '100'">否</p>
              <p v-else style="color: red">是</p>
            </td>
            <td>{{ time }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div v-show="isHasData" style="text-align: center;">
      <p><img src="../../assets/img/no_data.png" alt=""></p>
    </div>
    <!-- <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div> -->

  </article>
</template>

<script>
export default {
  name: "operationalDeport",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      addr: '',
      tHeader: [],
      filterVal: [
        "invest_profit",
        "pool_profit",
        "coupons_profit",
        "md_average_profit",
        "team_profit",
        "direct_profit",
        "indirect_profit",
        "partner_profit",
        "order_amount",
        "spread_amount",
      ],
      isHasData: false,
      time: this.dataFormat(new Date().getTime() / 1000),
    };
  },
  created() {
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.resData = [];
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.addr) {
        req.addr = this.addr;
      }
      this.$ajax(`${this.Utils.URL.goURL}black/swft_ban`, req, 'get')
          .then((res) => {
            if (res.list.length === 0 || res.list === null) {
              this.isHasData = true
              this.$message.error('晢无数据');
              return
            } else {
              this.isHasData = false
              this.resData = res.list ? res.list : [];
            }
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
</style>