<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>链类型</th>
        <th>币种</th>
        <th>充值数量</th>
        <th>提币数量</th>
        <th>留存数量</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.chain_type}}
          </td>
          <td>
            {{list.symbol}}
          </td>
          <td>
            {{scientificNotationToString(list.recharge_numb)}}
          </td>
          <td>
            {{scientificNotationToString(list.extract_numb)}}
          </td>
          <td>
            {{scientificNotationToString(list.remaining)}}
          </td>

        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>

          <td> {{scientificNotationToString(subtotal.recharge_numb)}}</td>
          <td> {{scientificNotationToString(subtotal.extract_numb)}}</td>
          <td> {{scientificNotationToString(subtotal.remaining)}}</td>

        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "billCount",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      typeObj: {
        1: {label: '返还投资金额', value: '1'},
        2: {label: '返还用户质押资金', value: '2'},
        3: {label: '返还认购合伙人资金', value: '3'},
        4: {label: '返还创建合伙人资金', value: '4'},
        5: {label: '合伙人有上下级关系', value: '5'},
      },
      filterVal: [
        "chain_type",
        "symbol",
        "recharge_numb",
        "extract_numb",
        "remaining",
      ],
    };
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.addr = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.status = this.selectVal1;
      }
      if (this.selectVal2) {
        req.tx_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}asset_summary`, req, "get")
          .then((res) => {
            this.resData = res ? res : [];
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.subtotal.recharge_numb = 0
            this.subtotal.extract_numb = 0
            this.subtotal.remaining = 0
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach(val => {
              this.subtotal.recharge_numb = this.subtotal.recharge_numb + parseFloat(val.recharge_numb)
              this.subtotal.extract_numb = this.subtotal.extract_numb + parseFloat(val.extract_numb)
              this.subtotal.remaining = this.subtotal.remaining + parseFloat(val.remaining)
            })

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>