
let app = 'appfast.'
let website = 'website.'
let www = 'www.'
let admin = 'adminbuild.'
if (process.env.NODE_ENV == 'production') {
  website = 'website.'
  app = 'app.'
  www = 'www.'
  admin = 'adminbuild.'
}

let goUrl = window.location.origin.replace(/\w+\./, app)
let h5Url = window.location.origin.replace(/\w+\./, app)
let websiteUrl = window.location.origin.replace(/\w+\./, website)
let wsUrl = window.location.host.replace(/\w+\./, app)
let wwwUrl = window.location.origin.replace(/\w+\./, www)
let adminUrl = window.location.origin.replace(/\w+\./, admin)


if (process.env.NODE_ENV == 'production') {
  goUrl = window.location.origin
  wsUrl = window.location.host
  if (!(goUrl.indexOf('pette') > 0) && !(goUrl.indexOf('uatfi') > 0)) {
    wwwUrl = 'https://www.ftcchain.org'
    websiteUrl = 'https://website.ftcchain.org'
    h5Url = 'https://appfast.ftcchain.org'
    adminUrl = 'https://adminbuild.ftcchain.org'
  }
}


export default process.env.NODE_ENV === 'production' ? {
  goURL: goUrl + '/admin_api/',
  imgURL: goUrl + '/img_api/',
  downloadURL: websiteUrl + '/img_api/',
} : {
  goURL: 'https://www.jhgsddev.com/admin_api/',
  imgURL: 'https://www.jhgsddev.com/img_api/',
  downloadURL: 'https://www.jhgsddev.com/img_api/',
}

