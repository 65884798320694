<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div style="display: flex; place-items:center;">
      <p>跟隨策略：
        <el-button @click="handleSwitch()" class="btn-switch" :disabled="!followState?.switch"
          :class="followState?.switch == true ? 'open' : 'close'">{{ followState?.switch == true ?
            followState?.follow_type == 1 ? '策略一 已开启' : '策略二 已开启' : '已关闭' }}</el-button>
      </p>
      <p>Finswap 賣了多少FTC, Pancake就會賣多少FTC：
        <el-button @click="handleSwitchPanCake()" class="btn-switch"
          :class="followState?.follow_ftc_sell_switch == true ? 'open' : 'close'"
          :disabled="!followState?.follow_ftc_sell_switch">{{ followState?.follow_ftc_sell_switch == true ? '已开启' : '已关闭'
          }}</el-button>
      </p>
    </div>
    <div class="list-button">
      <div>
        <el-button type="primary" v-for="(item, index) in listButtons" :key="index" @click="showModal(item)">{{ item
        }}</el-button>
      </div>

      <div style="margin-top: 10px">
        <el-button type="primary" @click="showModalInstant">瞬間拉/砸盤</el-button>
        <el-button type="primary" @click="showModalTransferFTC">新增FTC持倉地址</el-button>
        <el-button type="primary" @click="isShowFTCSwitchOpen = true">fin賣多少pan賣多少</el-button>
      </div>
    </div>

    <div class="com_search_box">
      <el-button type="primary" @click="callUpdateData()">更新所有地址</el-button>
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>

    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>地址編號</th>
          <th>地址</th>
          <th>FTC數量</th>
          <th>FUSD數量</th>
          <th>LP數量<br />(FTC-FUSD)</th>
          <th>最後更新時間</th>
          <th>備註</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td class="left">{{ list.id }}</td>
            <td class="left">{{ list.address }}</td>
            <td class="left">{{ list.ftc_amount }}</td>
            <td class="left">{{ list.fusd_amount }}</td>
            <td class="left">{{ list.lp_amount }}</td>
            <td>
              <p>{{ list.updated_at.length > 0 ? list.updated_at : '-' }}</p>
            </td>
            <td>
              {{ list.remark }}
            </td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td> {{scientificNotationToString(subtotal.ftc_amount)}}</td>
            <td> {{scientificNotationToString(subtotal.fusd_amount)}}</td>
            <td> {{scientificNotationToString(subtotal.lp_amount)}}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td> {{scientificNotationToString(sum.ftc_amount)}}</td>
            <td> {{scientificNotationToString(sum.fusd_amount)}}</td>
            <td> {{scientificNotationToString(sum.lp_amount)}}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>

      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[5, 10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes"
        :total="total">
      </el-pagination>
    </div>


    <el-dialog v-model="isShow" :title="titleModal" :width="600">
      <div class="com_from_box">
        <div class="com_from_item" v-show="typeButton == '跨鏈轉入FTC'" style="align-items: flex-end;">
          <div class="com_from_left">剩余可跨链余额:</div>
          <div class="com_from_right">{{ amountFTC }}</div>
        </div>
        <div class="com_from_item" v-show="typeButton == '跟隨價格策略'">
          <div class="com_from_left">策略選擇:</div>
          <div class="com_from_right com_from_item_group">
            <el-select v-model="strategy" placeholder="请选择" size="small">
              <el-option label="策略一 跟隨 pancake" value="1"></el-option>
              <el-option label="策略二 那邊高賣那邊" value="2"></el-option>
            </el-select>
          </div>
        </div>
        <div class="com_from_item" v-show="typeButton == '跟隨價格策略'">
          <div class="com_from_left">跟隨價格誤差值:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="price_error" placeholder="输入數值" type="number" size="small">
            </el-input>
            <span style="margin-left: 10px;">%</span>
          </div>
        </div>
        <div class="com_from_item" v-show="strategy == '2'">
          <div class="com_from_left">pancake地址編號:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="content1" placeholder="请输入" type="text" size="small">
            </el-input>
            <span @click="content1 = 'All'" class="link">全部</span>
            <p class="note">多選地址：使用,隔開<br />指定地址序號：輸入51-100就是指包含51到100的地址</p>
          </div>
        </div>
        <!-- <div class="com_from_item" v-show="strategy == '2'">
          <div class="com_from_left">Finswap地址編號:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="content2" placeholder="请输入" type="text" size="small">
            </el-input>
            <span @click="content2 = 'All'" class="link">全部</span>
            <p class="note">多選地址：使用,隔開<br />指定地址序號：輸入51-100就是指包含51到100的地址</p>
          </div>
        </div> -->
        <div class="com_from_item">
          <div class="com_from_left">{{ strategy == '2' ? 'Finswap' : '' }} 地址編號:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="content" placeholder="请输入" type="text" size="small">
            </el-input>
            <span @click="content = 'All'" class="link">全部</span>
            <p class="note">多選地址：使用,隔開<br />指定地址序號：輸入51-100就是指包含51到100的地址</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">{{ titleForm }}:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="range1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="range2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
          </div>
        </div>
        <div class="com_from_item" v-show="typeButton == '跟隨價格策略'">
          <div class="com_from_left">FUSD賣出數量:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="increase1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="increase2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
          </div>
        </div>
        <div v-show="typeButton == '添加LP'" class="com_from_item">
          <div class="com_from_left">FUSD數量:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="amount1" placeholder="输入最小值" size="small" class="input-range" disabled>
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="amount2" placeholder="输入最大值" size="small" class="input-range" disabled>
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">間隔時間:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="time1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="time2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
            <span style="margin-left: 10px;">亳秒</span>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShow = false">关闭</el-button>
          <el-button type="primary" @click="submitFn">{{ typeButton == '跟隨價格策略' ? '开启策略' : '提交' }}</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isShowFuelCoseTool" :title="titleModal" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">轉出地址編號:</div>
          <div class="com_from_right">
            <el-input v-model="address_id" placeholder="請輸入地址ID" type="number" size="small">
            </el-input>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">轉出數量：<br />(等於該保留數量):</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="range1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="range2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">間隔時間:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="time1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="time2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
            <span style="margin-left: 10px;">亳秒</span>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowFuelCoseTool = false">关闭</el-button>
          <el-button type="primary" @click="submitFnFuelCostTool">提交</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- show modal switch -->
    <el-dialog v-model="isShowSwitch" title="你确定吗？" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="this.isShowSwitch = false">关闭</el-button>
          <el-button type="primary" @click="submitShowSwitch">关闭策略</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- show modal follow ftc sell switch -->
    <el-dialog v-model="isShowFTCSwitch" title="賣出FTC" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="this.isShowFTCSwitch = false">关闭</el-button>
          <el-button type="primary" @click="submitShowFTCSwitch">關閉</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isShowFTCSwitchOpen" title="賣出FTC" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">Pancake address 地址編號:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="content" placeholder="请输入" type="text" size="small">
            </el-input>
            <!-- <span @click="content = 'All'" class="link">全部</span> -->
            <p class="note">多選地址：使用,隔開<br />指定地址序號：輸入51-100就是指包含51到100的地址</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="this.isShowFTCSwitchOpen = false">关闭</el-button>
          <el-button type="primary" @click="submitShowFTCSwitchOpen">開啟</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 跨鏈轉入FTC -->
    <el-dialog v-model="isShowTransferFTC" title="新增FTC持倉地址" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">
            新增地址數量:
          </div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="content" placeholder="请输入" type="number" size="small" min="1">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">增加數量:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="range1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="range2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">間隔時間:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="time1" placeholder="输入最小值" size="small" class="input-range">
            </el-input>
            <span class="dash">-</span>
            <el-input type="number" v-model="time2" placeholder="输入最大值" size="small" class="input-range">
            </el-input>
            <span style="margin-left: 10px;">亳秒</span>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowTransferFTC = false">关闭</el-button>
          <el-button type="primary" @click="submitFnTransferFTC">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 瞬間拉/砸盤 -->
    <el-dialog v-model="isShowInstant" title="瞬間拉/砸盤" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">
            當前價格:
          </div>
          <div class="com_from_right">
            <p class="instant_value">{{ rate }}</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">目標價格:</div>
          <div class="com_from_right com_from_item_group">
            <el-input type="number" v-model="price_instant" placeholder="" size="small">
            </el-input>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證:</div>
          <div class="com_from_right com_from_item_group">
            <el-input v-model="two_auth_code" placeholder="输入内容" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowInstant = false">关闭</el-button>
          <el-button type="primary" @click="submitFnInstant">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "finswapMarketMaker",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      resDataFullForExport: [],
      tHeader: [],
      two_auth_code: '',
      content: '', // address_id
      range1: null,
      range2: null,
      time1: null,
      time2: null,
      filterVal: [
        "id",
        "address",
        "ftc_amount",
        "fusd_amount",
        "lp_amount",
        "lp_balance",
        "updated_at",
        "remark",
      ],
      finalData: [],
      finalDataPanCake: [],
      isValid: true,
      isShow: false,
      listButtons: ['跨鏈轉入FTC', '跟隨價格策略', '賣出FTC', '賣出FUSD', '添加LP', '減少LP'],
      titleModal: '',
      titleForm: '',
      typeButton: '',
      subtotal: {
      },
      sum: {
      },
      amountFTC: null,
      rate: '',
      price_error: '',
      increase1: null,
      increase2: null,
      isShowFuelCoseTool: false,
      followState: null,
      isShowSwitch: false,
      isShowTransferFTC: false,
      isShowInstant: false,
      price_instant: null,
      strategy: '1',
      content1: '',
      isShowFTCSwitch: false,
      isShowFTCSwitchOpen: false,
    };
  },
  computed: {
    amount1: {
      get() {
        return this.range1 * this.rate
      },
    },
    amount2: {
      get() {
        return this.range2 * this.rate
      },
    }
  },
  created() {
    this.resInit();
    this.getStatusPancake();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSwitchPanCake() {
      this.isShowFTCSwitch = true
    },
    submitShowFTCSwitchOpen() {
      this.checkContent(this.content)
      if (this.isValid) {
        if (this.two_auth_code == '') {
          this.$message.error('谷歌驗證碼不能為空');
          return
        }
        const req = {
          two_auth_code: this.two_auth_code,
          switch: true,
          address_id: this.finalData,
        }
        this.$ajax(`${this.Utils.URL.goURL}finswap-market/follow_ftc_sell_switch`, req, 'post', 2500000, false, true)
          .then((res) => {
            if (res.result) {
              this.isShowFTCSwitchOpen = false
              this.two_auth_code = ''
              this.$message.success('成功');
              this.getStatusPancake()
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
      }
    },
    submitShowFTCSwitch() {
      if (this.two_auth_code == '') {
        this.$message.error('谷歌驗證碼不能為空');
        return
      }
      const req = {
        two_auth_code: this.two_auth_code,
        switch: false,
      }
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/follow_ftc_sell_switch`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShowFTCSwitch = false
            this.two_auth_code = ''
            this.$message.success('成功');
            this.getStatusPancake()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    getBallanceFTC() {
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/get_ftc_balance`, {}, 'get')
        .then((res) => {
          this.amountFTC = res.balance

        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    showModalTransferFTC() {
      this.isShowTransferFTC = true
      this.resetForm()
    },
    submitFnTransferFTC() { // new update 7.8
      if (this.content == null || this.content == '') {
        this.$message.error('地址不得為空');
        return
      }
      // not less than 0
      if (+this.content <= 0) {
        this.$message.error('數量不得小於0');
        return
      }
      // must be integer
      if (!Number.isInteger(+this.content)) {
        this.$message.error('數量必須為整數');
        return
      }

      if (this.range1 == null || this.range1 == '' || +this.range1 < 0) {
        this.$message.error('最小值不能为空或小于 0');
        return
      }
      if (this.range2 == null || this.range2 == '' || +this.range2 < 0) {
        this.$message.error('最大值不得為空或小于 0');
        return
      }
      if (+this.range1 > +this.range2) {
        this.$message.error('最小值不得大於最大值');
        return
      }
      if (this.range1 == this.range2) {
        this.$message.error('最小值可跟最大值一樣');
        return
      }
      if (this.time1 == null || this.time1 == '') {
        this.$message.error('開始時間不得為空');
        return
      }
      if (this.time2 == null || this.time2 == '') {
        this.$message.error('結束時間不得為空');
        return
      }
      if (this.time1 <= 0) {
        this.$message.error('开始时间必须大过于0');
        return
      }
      if (this.time2 <= 0) {
        this.$message.error('结束时间必须大过于0');
        return
      }
      if (this.time1.includes('.')) {
        this.$message.error('時間不得為小數');
        return
      }
      if (this.time2.includes('.')) {
        this.$message.error('時間不得為小數');
        return
      }
      if (+this.time1 > +this.time2) {
        this.$message.error('開始時間不得大於結束時間');
        return
      }
      if (this.two_auth_code == '') {
        this.$message.error('请输入谷歌验证码');
        return
      }

      const req = {
        address_count: +this.content,
        min_amount: +this.range1,
        max_amount: +this.range2,
        min_milli_second: +this.time1,
        max_milli_second: +this.time2,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/create_addr`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShowTransferFTC = false
            this.$message.success('成功');
            this.resInit();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    // instant pull / smash
    showModalInstant() {
      this.isShowInstant = true
      this.resetForm()
      this.getResRate()
    },
    submitFnInstant() {
      if (this.price_instant == null || this.price_instant == '') {
        this.$message.error('價格不得為空');
        return
      }
      // can not be less than 0
      if (+this.price_instant <= 0) {
        this.$message.error('價格不得小於0');
        return
      }
      if (this.two_auth_code == '') {
        this.$message.error('请输入谷歌验证码');
        return
      }
      let req = {
        target_price: +this.price_instant,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/pump_dump`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShowInstant = false
            this.$message.success('成功');
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSwitch() {
      this.isShowSwitch = true
    },
    submitShowSwitch() {
      if (this.two_auth_code == '') {
        this.$message.error('谷歌驗證碼不能為空');
        return
      }
      const req = {
        two_auth_code: this.two_auth_code,
        switch: false,
        // follow_type: this.strategy
      }
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/follow_switch`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShowSwitch = false
            this.two_auth_code = ''
            this.$message.success('成功');
            this.getStatusPancake()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    getStatusPancake() {
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/get_follow_pancake_status`, {}, "get")
        .then((res) => {
          this.followState = res
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },

    // add LP
    getResRate() {
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/get_ftc_fusd_rate`, {}, "get")
        .then((res) => {
          this.rate = res.rate
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    // sumbit form fule cost tool
    submitFnFuelCostTool() {
      // 
    },


    checkTitleModal(type) {
      switch (type) {
        case '跨鏈轉入FTC':
          this.titleModal = '跨鏈轉入FTC'
          this.titleForm = '增加數量'
          this.getBallanceFTC()
          break;
        case '跟隨價格策略':
          this.titleModal = '跟隨價格策略'
          this.titleForm = 'FTC賣出數量'
          break;
        case '賣出FTC':
          this.titleModal = '賣出FTC'
          this.titleForm = '賣出數量'
          break;
        case '賣出FUSD':
          this.titleModal = '賣出FUSD'
          this.titleForm = '賣出數量'
          break;
        case '添加LP':
          this.titleModal = '添加FTC-FUSD LP'
          this.titleForm = '添加FTC數量'
          this.getResRate()
          break;
        case '減少LP':
          this.titleModal = '移除FTC-FUSD LP'
          this.titleForm = '移除LP數量'
          break;
        case 'FTC燃料費工具':
          this.titleModal = '燃料費工具'
          this.titleForm = '轉出數量'
          break;
      }
    },


    checkContent(content) {
      if (content == '') {
        this.isValid = false
        this.$message.error('地址不得為空');
        return
      }

      if (content.includes(',') && content.includes('-')) {
        this.isValid = false
        this.$message.error('間隔符號僅可使用,');
        return
      }

      if (content == 'All') {
        this.isValid = true
        return
      } else {
        const regex = /^[0-9,-]*$/g
        if (!regex.test(content)) {
          this.isValid = false
          this.$message.error('間隔符號僅可使用,');
          return
        }
      }

      if (content.includes(',')) {
        const arr = content.split(',')
        const last = arr[arr.length - 1]
        if (last == '') {
          arr.pop()
        }

        this.isValid = true
        // remove duplicate
        const set = new Set(arr)
        const interactor = [...set]
        this.finalData = interactor.join(',')
      } else if (content.includes('-')) {

        const arr2 = content.split('-')
        const last = arr2[arr2.length - 1]

        if (last == '') {
          this.isValid = false
          this.$message.error('請輸入最大值');
          return
        }

        if (arr2.length > 1) {
          this.isValid = true
          const start = parseInt(arr2[0])
          const end = parseInt(arr2[1])

          if (start > end) {
            this.isValid = false
            this.$message.error('最小值不得大於最的值');
            return
          }

          const arr3 = []
          for (let i = start; i <= end; i++) {
            arr3.push(i)
          }
          // convert arr3 to string
          this.finalData = arr3.join(',')
          return
        }


      } else {
        // single value
        this.isValid = true
        this.finalData = content
      }
    },

    checkContentPanCake(content) {
      if (content == '') {
        // this.isValid = false
        this.$message.error('地址不得為空');
        return
      }

      if (content.includes(',') && content.includes('-')) {
        // this.isValid = false
        this.$message.error('間隔符號僅可使用,');
        return
      }

      if (content == 'All') {
        // this.isValid = true
        return
      } else {
        const regex = /^[0-9,-]*$/g
        if (!regex.test(content)) {
          // this.isValid = false
          this.$message.error('間隔符號僅可使用,');
          return
        }
      }

      if (content.includes(',')) {
        const arr = content.split(',')
        const last = arr[arr.length - 1]
        if (last == '') {
          arr.pop()
        }

        // this.isValid = true
        // remove duplicate
        const set = new Set(arr)
        const interactor = [...set]
        this.finalDataPanCake = interactor.join(',')
      } else if (content.includes('-')) {

        const arr2 = content.split('-')
        const last = arr2[arr2.length - 1]

        if (last == '') {
          // this.isValid = false
          this.$message.error('請輸入最大值');
          return
        }

        if (arr2.length > 1) {
          // this.isValid = true
          const start = parseInt(arr2[0])
          const end = parseInt(arr2[1])

          if (start > end) {
            // this.isValid = false
            this.$message.error('最小值不得大於最的值');
            return
          }

          const arr3 = []
          for (let i = start; i <= end; i++) {
            arr3.push(i)
          }
          // convert arr3 to string
          this.finalDataPanCake = arr3.join(',')
          return
        }


      } else {
        // single value
        // this.isValid = true
        this.finalDataPanCake = content
      }
    },
    submitFn() {
      if (this.typeButton == '跟隨價格策略') {
        if (this.price_error == '') {
          this.$message.error('请输入跟隨價格誤差值');
          return
        }
      }
      this.checkContent(this.content)

      if (this.isValid) {
        if (this.range1 == null || this.range1 == '' || +this.range1 < 0) {
          this.$message.error('最小值不得為空');
          return
        }
        if (this.range2 == null || this.range2 == '' || +this.range2 < 0) {
          this.$message.error('最大值不得為空');
          return
        }
        if (+this.range1 > +this.range2) {
          this.$message.error('最小值不得大於最大值');
          return
        }
        if (this.range1 == this.range2) {
          this.$message.error('最小值可跟最大值一樣');
          return
        }
        // check inrease1, increase2
        if (this.typeButton == '跟隨價格策略') {
          if (this.increase1 == null || this.increase1 == '') {
            this.$message.error('請輸入增加數量');
            return
          }
          if (this.increase2 == null || this.increase2 == '') {
            this.$message.error('請輸入增加數量');
            return
          }
        }

        if (this.time1 == null || this.time1 == '') {
          this.$message.error('開始時間不得為空');
          return
        }
        if (this.time2 == null || this.time2 == '') {
          this.$message.error('結束時間不得為空');
          return
        }
        if (this.time1 <= 0) {
          this.$message.error('开始时间必须大过于0');
          return
        }
        if (this.time2 <= 0) {
          this.$message.error('结束时间必须大过于0');
          return
        }
        if (this.time1.includes('.')) {
          this.$message.error('時間不得為小數');
          return
        }
        if (this.time2.includes('.')) {
          this.$message.error('時間不得為小數');
          return
        }
        if (+this.time1 > +this.time2) {
          this.$message.error('開始時間不得大於結束時間');
          return
        }
        if (this.two_auth_code == '') {
          this.$message.error('请输入谷歌验证码');
          return
        }
        let req = {}

        if (this.content == 'All') {
          req = {
            two_auth_code: this.two_auth_code,
            min_amount: this.range1,
            max_amount: this.range2,
            min_milli_second: +this.time1,
            max_milli_second: +this.time2,
          }
        } else {
          req = {
            two_auth_code: this.two_auth_code,
            address_id: this.finalData,
            min_amount: this.range1,
            max_amount: this.range2,
            min_milli_second: +this.time1,
            max_milli_second: +this.time2,
          }
        }

        switch (this.typeButton) {
          case '跨鏈轉入FTC':
            this.callCrossInFtc(req)
            break;
          case '跟隨價格策略':
            this.followPrice(req)
            break;
          case '賣出FTC':
          case '賣出FUSD':
            req.coin = this.typeButton == '賣出FTC' ? 'FTC' : 'FUSD'
            this.callLiquidtySwap(req)
            break;
          case '添加LP':
            this.callAddLP(req)
            break;
          case '減少LP':
            this.callRemoveLP(req)
            break;
        }
      }
    },
    callCrossInFtc(req) {
      req.max_amount = +req.max_amount
      req.min_amount = +req.min_amount
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/cross_in_ftc`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },

    callAddLP(req) {
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/liquidity_add`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },
    callRemoveLP(req) {
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/liquidity_remove`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },

    // 跟隨價格策略
    followPrice(req) {
      if (this.strategy == '2') {
        this.checkContentPanCake(this.content1)
      }
      let req2 = {}
      if (this.strategy == '1') {
        req2 = {
          address_id: this.content == 'All' ? '' : req.address_id,
          different_percentage: this.price_error,
          ftc_add_max_amount: this.range2,
          ftc_add_min_amount: this.range1,
          fusd_add_max_amount: this.increase2,
          fusd_add_min_amount: this.increase1,
          max_milli_second: req.max_milli_second,
          min_milli_second: req.min_milli_second,
          switch: true,
          two_auth_code: req.two_auth_code,
          follow_type: +this.strategy,
        }
      } else {
        req2 = {
          address_id: this.content == 'All' ? '' : req.address_id,
          different_percentage: this.price_error,
          ftc_add_max_amount: this.range2,
          ftc_add_min_amount: this.range1,
          fusd_add_max_amount: this.increase2,
          fusd_add_min_amount: this.increase1,
          max_milli_second: req.max_milli_second,
          min_milli_second: req.min_milli_second,
          switch: true,
          two_auth_code: req.two_auth_code,
          follow_type: +this.strategy,
          pancake_address_id: this.content1 == 'All' ? '' : this.finalDataPanCake
        }
      }
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/follow_switch`, req2, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
            this.getStatusPancake()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },

    // 賣出FTC, 賣出FUSD
    callLiquidtySwap(req) {
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/create_swap`, req, 'post', 2500000, false, true)
        .then((res) => {
          if (res.result) {
            this.isShow = false
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },

    resetForm() {
      this.content = ''
      this.range1 = ''
      this.range2 = ''
      this.time1 = null
      this.time2 = null
      this.two_auth_code = ''
      this.isValid = false
      this.finalData = ''
      this.price_error = ''
      this.price_instant = ''
      this.increase1 = ''
      this.increase2 = ''
      this.strategy = '1'
    },

    showModal(item) {
      this.resetForm()
      item != 'FTC燃料費工具' ? this.isShow = true : this.isShowFuelCoseTool = true
      this.checkTitleModal(item)
      this.typeButton = item
    },

    // for excel
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
      }
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/get_ftcmain_address_list`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.updated_at = this.dataFormat(list.updated_at)
          })
          if (this.resDataFullForExport.length > 0) {
            this.exportExcel(this.tHeader, this.filterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };

      this.$ajax(`${this.Utils.URL.goURL}finswap-market/get_ftcmain_address_list`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {
            list.updated_at = this.dataFormat(list.updated_at)
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    callUpdateData() {
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/refresh_finswap_market`, {}, 'get')
        .then((res) => {
          if (res.result) {
            this.$message.success('成功');
            this.resInit()
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.isShow = false
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.list-button {
  margin: 20px 0;
}

.list-button button {
  min-width: 125px;
}

.com_from_item_group {
  display: flex;
  place-items: center;
  flex-wrap: wrap;
}

.com_from_item_group .link {
  white-space: nowrap;
  margin-left: 10px;
  color: #409eff;
  cursor: pointer;
}

.com_from_item_group .note {
  color: #409eff;
  margin-top: 5px;
}

.com_from_item_group .dash {
  margin: 0 10px;
}

.com_from_item_group .el-input,
.com_from_item_group .el-select {
  width: 230px;
}

.com_from_item_group .input-range {
  width: 100px;
}

.com_from_box .com_from_item {
  align-items: flex-start;
}

.com_from_box .com_from_item .com_from_left {
  padding-top: 8px;
}

.com_from_item_group :deep(.el-input.is-disabled .el-input__inner) {
  color: #606266;
}

.btn-switch {
  border: 0;
}

.close {
  color: red;
}

.open {
  color: green;
}

.instant_value {
  font-size: 20px;
  font-weight: bold;
}

.com_from_box .com_from_item .com_from_left {
  min-width: 140px;
}</style>