<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          终端
        </div>
        <div class="com_from_right">
          <el-checkbox-group v-model="port_id">
            <el-checkbox v-for="(list, index) in options1" :label="list.value" :key="index">{{ list.label }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          一级分类
        </div>
        <div class="com_from_right">
          <el-select
            v-model="one_type"
            clearable
            filterable
            placeholder="请选择"
            size="small"
            @change="selectFn"
          >
            <el-option v-for="item in options2"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          二级分类
        </div>
        <div class="com_from_right">
          <el-select
            v-model="two_type"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option v-for="item in options3"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          文章标签
        </div>
        <div class="com_from_right">
          <el-select
              v-model="tag_id"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option label="无" value="0">无</el-option>
            <el-option label="原创" value="1">原创</el-option>
            <el-option label="转载" value="2">转载</el-option>

          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          是否置顶
        </div>
        <div class="com_from_right">
          <div class="com_from_right">
            <el-radio v-model="is_top" :label="true">是</el-radio>
            <el-radio v-model="is_top" :label="false">否</el-radio>
          </div>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          发布人
        </div>
        <div class="com_from_right">
          <el-input
              v-model="new_creator"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          链接地址
        </div>
        <div class="com_from_right">
          <el-input
            v-model="link_address"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item" v-for="(list, index) in title_list" :key="index">
        <div class="com_from_left">
          {{ list.type_title }}标题
        </div>
        <div class="com_from_right">
          <el-input
              v-model="list.value"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item" v-for="(list, index) in img_list" :key="index">
        <div class="com_from_left">
          {{ list.type_title }}图片
        </div>
        <div class="com_from_right">
          <el-upload
              class="avatar-uploader"
              action="''"
              :auto-upload="false"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-change="(val => beforeAvatarUpload(list.type, index, val))"
          >
            <img v-if="list.value" :src="Utils.URL.goURL + list.value" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>

      <div class="com_from_item" v-for="(list, index) in introduce_list" :key="index">
        <div class="com_from_left">
          {{ list.type_title }}简介
        </div>
        <div class="com_from_right">

          <el-input type="textarea"
                    rows="5"
                    v-model="list.value"
                    size="small">

          </el-input>
        </div>
      </div>


      <div class="com_from_item" v-for="(list, index) in article_content_list" :key="index">
        <div class="com_from_left">
          {{ list.type_title }}描述
        </div>
        <div class="com_from_right">
          <tinymces :value="list.value" @input="(msg) => {tinyFN(list.type, index, msg)}"></tinymces>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          排序
        </div>
        <div class="com_from_right">
          <el-input
            v-model="sort"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="sumbitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
import tinymces from "../../components/tinymce";

export default {
  name: "articleListEdit",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      startTime: '',
      endTime: '',
      type: '',
      id: '',
      port_id: [],
      one_type: '',
      two_type: '',
      title_zh: '',
      title_en: '',
      article_content_zh: '',
      article_content_en: '',
      introduce_zh: '',
      introduce_en: '',
      link_address: '',
      tinyValCN: '',
      tinyValEN: '',
      sort: '',
      imageUrls: '',
      imageUrl: '',
      imageUrlENs: '',
      imageUrlEN: '',
      is_top: '',
      tag_id: '',
      new_creator: '',
      infoData: {},
      article_content_list: [],
      title_list: [],
      introduce_list: [],
      img_list: [],
    }
  },
  created() {
    this.type = this.$route.query.type
    this.options1 = JSON.parse(window.sessionStorage.getItem('portList'))
    this.options2 = JSON.parse(window.sessionStorage.getItem('oneArticleClass'))
    if (this.type == 'edit') {
      this.infoData = JSON.parse(window.sessionStorage.getItem('listDataInfo'))

      this.id = this.infoData.id
      this.port_id = this.infoData.port_id.split(',')
      this.one_type = this.infoData.one_type
      this.selectFn(this.infoData.one_type)
      this.two_type = this.infoData.two_type
      this.title_zh = this.infoData.title_zh
      this.title_en = this.infoData.title_en
      this.article_content_zh = this.infoData.article_content_zh
      this.article_content_en = this.infoData.article_content_en
      this.tinyValCN = this.infoData.article_content_zh
      this.tinyValEN = this.infoData.article_content_en
      this.introduce_zh = this.infoData.introduce_zh
      this.introduce_en = this.infoData.introduce_en
      this.link_address = this.infoData.link_address
      this.sort = this.infoData.sort
      this.is_top = this.infoData.is_top
      this.tag_id = this.infoData.tag_id
      this.new_creator = this.infoData.new_creator
      this.imageUrls = this.Utils.URL.goURL + this.infoData.cover_link
      this.imageUrl = this.infoData.cover_link
      this.imageUrlENs = this.Utils.URL.goURL + this.infoData.cover_link_en
      this.imageUrlEN = this.infoData.cover_link_en
      this.img_list = this.infoData.img_list ? this.infoData.img_list : []
      this.title_list = this.infoData.title_list ? this.infoData.title_list : []
      this.article_content_list = this.infoData.article_content_list ? this.infoData.article_content_list : []
      this.introduce_list = this.infoData.introduce_list ? this.infoData.introduce_list : []
      let obj = {}
      let obj1 = {}
      let arr = []
      let arr1 = []
      let arr2 = []
      let arr3 = []

      if (!this.article_content_list.length) {
        this.article_content_list.push({
          type_title: '中文',
          type: 'cn',
          value: ''
        })
        this.article_content_list.push({
          type_title: '英文',
          type: 'en',
          value: ''
        })
      }
      if (!this.title_list.length) {
        this.title_list.push({
          type_title: '中文',
          type: 'cn',
          value: ''
        })
        this.title_list.push({
          type_title: '英文',
          type: 'en',
          value: ''
        })
      }
      if (!this.introduce_list.length) {
        this.introduce_list.push({
          type_title: '中文',
          type: 'cn',
          value: ''
        })
        this.introduce_list.push({
          type_title: '英文',
          type: 'en',
          value: ''
        })
      }
      if (!this.img_list.length) {
        this.img_list.push({
          type_title: '中文',
          type: 'cn',
          value: ''
        })
        this.img_list.push({
          type_title: '英文',
          type: 'en',
          value: ''
        })
      }

      this.langList.forEach((val, index) => {
        obj[val.value] = val.label
      })
      this.title_list.forEach((val, index) => {
        if (val.type == 'cn' && val.value == '') {
          this.title_list[index].value = this.infoData.title_zh
        }
        if (val.type == 'en' && val.value == '') {
          this.title_list[index].value = this.infoData.title_en
        }
      })
      this.article_content_list.forEach((val, index) => {
        if (val.type == 'cn' && val.value == '') {
          this.article_content_list[index].value = this.infoData.article_content_zh
        }
        if (val.type == 'en' && val.value == '') {
          this.article_content_list[index].value = this.infoData.article_content_en
        }
      })
      this.introduce_list.forEach((val, index) => {
        if (val.type == 'cn' && val.value == '') {
          this.introduce_list[index].value = this.infoData.introduce_zh
        }
        if (val.type == 'en' && val.value == '') {
          this.introduce_list[index].value = this.infoData.introduce_en
        }
      })
      this.img_list.forEach((val, index) => {
        obj1[val.type] = val.type_title
        if (val.type == 'cn' && val.value == '') {
          this.img_list[index].value = this.infoData.cover_link
        }
        if (val.type == 'en' && val.value == '') {
          this.img_list[index].value = this.infoData.cover_link_en
        }
      })
      for (let i in obj) {
        if (!obj1[i]) {
          arr.push({
            type_title: obj[i],
            type: i,
            value: ''
          })
          arr1.push({
            type_title: obj[i],
            type: i,
            value: ''
          })
          arr2.push({
            type_title: obj[i],
            type: i,
            value: ''
          })
          arr3.push({
            type_title: obj[i],
            type: i,
            value: ''
          })
        }
      }
      this.img_list = this.img_list.concat(arr3)
      this.article_content_list = this.article_content_list.concat(arr)
      this.title_list = this.title_list.concat(arr1)
      this.introduce_list = this.introduce_list.concat(arr2)

      // remove old config
      this.title_list = this.title_list.filter(val => {
        return val.type != 'cn' && val.type != 'pt_pt' && val.type != 'pt-pt' && val.type != 'pt-br'
      })
      this.article_content_list = this.article_content_list.filter(val => {
        return val.type != 'cn' && val.type != 'pt_pt' && val.type != 'pt-pt' && val.type != 'pt-br'
      })
      this.introduce_list = this.introduce_list.filter(val => {
        return val.type != 'cn' && val.type != 'pt_pt' && val.type != 'pt-pt' && val.type != 'pt-br'
      })
      this.img_list = this.img_list.filter(val => {
        return val.type != 'cn' && val.type != 'pt_pt' && val.type != 'pt-pt' && val.type != 'pt-br'
      
      })
      // add pt_br 
      // this.title_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: ''
      // })
      // this.article_content_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: ''
      // })
      // this.introduce_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: ''
      // })
      // this.img_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: ''
      // })
    } else {
      this.initImgList()
      this.initArticleList()
      this.initIntroduceList()
      this.initTypeList()
    }
  },
  components: {
    tinymces
  },
  methods: {
    initArticleList() {
      this.langList.forEach((val, index) => {
        this.article_content_list[index] = {}
        this.article_content_list[index].type_title = val.label
        this.article_content_list[index].type = val.value
        this.article_content_list[index].value = ''
      })
    },
    initIntroduceList() {
      this.langList.forEach((val, index) => {
        this.introduce_list[index] = {}
        this.introduce_list[index].type_title = val.label
        this.introduce_list[index].type = val.value
        this.introduce_list[index].value = ''
      })
    },
    initTypeList() {
      this.langList.forEach((val, index) => {
        this.title_list[index] = {}
        this.title_list[index].type_title = val.label
        this.title_list[index].type = val.value
        this.title_list[index].value = ''
      })
    },
    initImgList() {
      this.langList.forEach((val, index) => {
        this.img_list[index] = {}
        this.img_list[index].type_title = val.label
        this.img_list[index].type = val.value
        this.img_list[index].value = ''
      })
    },
    tinyFN(type, index, msg) {
      this.article_content_list[index].value = msg

    },
    sumbitFn() {
      let isTitle = false
      if (!this.port_id.length) {
        this.$message.error('请选择终端')
        return false
      }

      if (this.one_type === '') {
        this.$message.error('请选择一级分类')
        return false
      }

      if (this.two_type === '') {
        this.$message.error('请选择二级分类')
        return false
      }
      this.title_list.forEach(val => {
        if (!val.value) {
          isTitle = true
          this.$message.error(`请填写${val.type_title}标题`)
          return false
        }
      })
      this.introduce_list.forEach(val => {
        if (!val.value) {
          isTitle = true
          this.$message.error(`请填写${val.type_title}简介`)
          return false
        }
      })
      this.article_content_list.forEach(val => {
        if (!val.value) {
          isTitle = true
          this.$message.error(`请填写${val.type_title}描述`)
          return false
        }
      })

      if (this.link_address === '') {
        this.$message.error('请输入链接地址')
        return false
      }

      if (isTitle) {
        return false
      }
      let url = this.type == 'edit' ? 'update_article' : 'created_article'
      let titleZh = this.title_list.find(val => val.type == 'zh').value
      let req = {
        port_id: this.port_id.join(','),
        one_type: parseFloat(this.one_type),
        two_type: parseFloat(this.two_type),
        img_list: this.img_list,
        title_list: this.title_list,
        introduce_list: this.introduce_list,
        article_content_list: this.article_content_list,
        link_address: this.link_address,
        new_creator: this.new_creator,
        tag_id: parseFloat(this.tag_id),
        is_top: this.is_top == 1 ? true : false,
        sort: parseFloat(this.sort),
        // title_zh: this.title_zh,
        title_zh: titleZh,
        title_en: this.title_en,
        article_content_zh: this.tinyValCN,
        article_content_en: this.tinyValEN,
        introduce_zh: this.introduce_zh,
        introduce_en: this.introduce_en,
        cover_link: this.imageUrl,
        cover_link_en: this.imageUrlEN,
      }
      if (this.type == 'edit') {
        req.id = parseFloat(this.id)
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post', 2500000, false, true)
        .then(res => {
          this.$message.success('操作成功,两秒后自动跳转')
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    selectFn(vals, type = 2) {
      if (!vals) {
        return false
      }
      let req = {
        type_content_id: '1'
      }
      let url = 'type_one_list'
      if (type == 2) {
        url = 'type_two_list'
        req.type_id = this.one_type
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
        .then(res => {
          let arr = type == 1 ? res ? res : [] : res.list ? res.list : []
          let arr2 = []
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.type_zh
            }
          })
          if (type == 1) {
            this.options2 = arr2
          } else {
            this.options3 = arr2

          }
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(lang, index, file) {
      this.uploadFn(lang, index, file)
    },
    uploadFn(type, index, file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      const formData = new FormData()
      let str = file.name.split('.')
      let last = str[str.length - 1].toLowerCase()
      if (!/png|jpg|jpeg/.test(last)) {
        this.$message.error('上传错误,文件格式必须为：png/jpg/jpeg');
        return;
      }
      let time = parseInt(new Date().getTime())
      str = 'image_upload' + time + '.' + last
      formData.append('files', file.raw, str)
      formData.append('dir', 'bannerImg')
      this.$ajax(`${this.Utils.URL.goURL}upload`, formData, 'post', 2500000, true)
          .then(res => {
            this.img_list[index].value = res.url

          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
