<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">轉出地址</div>
        <div class="com_item_input">
          <el-input v-model="addr_out" placeholder="请输入" size="small">	
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">接收地址</div>
        <div class="com_item_input">
          <el-input v-model="addr_in" placeholder="请输入" size="small">	
          </el-input>
        </div>
      </div>
      <!-- <div class="com_item_search">
        <div class="com_item_tx">转出状态</div>
        <div class="com_item_input">
          <el-select v-model="status_out" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in statusObj1" :key="key" :label="item" :value="key">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>
    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>地址編號</th>
          <th>轉出地址</th>
          <th>幣種</th>
          <th>發送數量</th>
          <th>轉出狀態</th>
          <th>轉出時間</th>
          <th>轉出HASH</th>
          <th>燃料費(BNB)</th>
          <th>收款地址</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list) in resData" :key="list.id">
            <td>{{ list.id }}</td>
            <td>{{ list.from_addr }}</td>
            <td>{{ list.symbol }}</td>
            <td>{{ list.amount }}</td>
            <td>{{ list.status }}</td>
            <td>{{ list.tx_time }}</td>
            <td><a :href="`https://mainchain.ftcchain.org/#/tx/${list.hash}`" target="_blank" class="link">{{ list.hash }}</a></td>
            <td>{{ list.fee }}</td>
            <td>{{ list.to_addr }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.amount)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.fee)}}</td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(sum.amount)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(sum.fee)}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page" v-show="resData.length">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>


  </article>
</template>
  
<script>
export default {
  name: "mix-token-record",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      startTime: '',
      endTime: '',
      addr_out: '',
      addr_in: '',
      subtotal: {},
      sum: {},
      resDataFullForExport: [],
      tHeader: [],
      filterVal: [
        'id',
        'from_addr',
        'symbol',
        'amount',
        'status',
        'tx_time',
        'hash',
        'fee',
        'to_addr',
      ],
    };
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  created() {
    this.resInit();
  },
  methods: {
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
      }
      if (this.addr_out) {
        req.from_addr = this.addr_out;
      }
      if (this.addr_in) {
        req.to_addr = this.addr_in;
      }
      if (this.startTime) {
        req.start_at = this.startTime;
      }
      if (this.endTime) {
        req.end_at = this.endTime;
      }
      
      this.$ajax(`${this.Utils.URL.goURL}organ/transfer_records`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.status = '成功'
            list.tx_time =  parseFloat(list.tx_time) > 0 ? this.dataFormat(list.tx_time) : '-'
          })
          if (this.resDataFullForExport.length > 0) {
            this.exportExcel(this.tHeader, this.filterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.addr_out) {
        req.from_addr = this.addr_out;
      }
      if (this.addr_in) {
        req.to_addr = this.addr_in;
      }
      if (this.startTime) {
        req.start_at = this.startTime;
      }
      if (this.endTime) {
        req.end_at = this.endTime;
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/transfer_records`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.total = parseFloat(res.total)
          this.resData.forEach((list) => {
            list.status = '成功'
            list.tx_time =  parseFloat(list.tx_time) > 0 ? this.dataFormat(list.tx_time) : '-'
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>
