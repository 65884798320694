<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">


      <div class="com_item_search">
        <div class="com_item_tx">黑名单团队长钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="showAlertFn({})"
      >
        新增黑名单团队
      </el-button>

    </div>

      <div class="tab_box mt_40 ">
        <table class="com-table">
          <thead class="com_thead">
          <th>序号</th>
          <th>黑名单团队长钱包ID</th>
          <th>添加黑名单时间</th>
          <th>操作</th>

          </thead>
          <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="index">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{list.user_id}}</td>
            <td>{{list.created_at}}</td>
            <td class="tab_btn">
              <span class="btn" @click="showAlertFn(list)">移出黑名单</span>
            </td>

          </tr>


          </tbody>
        </table>
      </div>


    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curData.user_id ? '移出黑名单' : '新增黑名单团队'" width="500px">
      <section class="com_from_box">
        <h3 v-show="curData.user_id" style="margin-bottom: 20px">移除黑名单后团队成员将可以正常提币，确定将该钱包ID移除提币黑名单？</h3>
        <div class="com_from_item" v-show="!curData.user_id">
          <div class="com_from_left">黑名单钱包ID</div>
          <div class="com_from_right">
            <el-input
                v-model="userId"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item" v-show="!curData.user_id">
          提示：添加黑名单后，该钱包ID下的 团队成员将无法进行提币操作！
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="submitFn">提交</el-button>
                </span>
      </template>
    </el-dialog>




  </article>
</template>

<script>
export default {
  name: "backWithdraw",
  data() {
    return {
      resData: [],
      selectVal1: '2',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      inputVal2: '',
      curIndex: 0,
      curData: {},
      curType: '',
      page: 1,
      size: 10,
      total: '',
      startTime: '',
      endTime: '',
      tHeader: [],
      alertData: {},
      isAlert: false,
      two_auth_code: '',
      userId: '',
      coinInfo: [],
      currencyList: [],
      sum: {},
      subtotal: {},
      filterVal: [
        "order_id",
        "wallet_id",
        "sell_contract_addr",
        "symbol",
        "chain_type",
        "amount",
        "price",
        "total",
        "currency",
        "status_tx",
        "pay_way_tx",
        "buyer_account",
        "buyer_bank_name",
        "channel",
        "sell_name",
        "sell_account",
        "bank_name",
        "created_at",
        "complete_at",
        "remark",
      ],
    }
  },
  created() {

    this.resInit()
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showAlertFn (data) {
      this.isAlert = true
      this.userId = ''
      this.two_auth_code = ''
      this.curData = data
    },
    submitFn () {
      if (!this.curData.user_id && this.userId === '') {
        this.$message.error("请输入黑名单钱包ID");
        return false;
      }

      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }

      let req = {
        two_auth_code: this.two_auth_code,

      }
      if (this.curData.user_id) {
        req.type = 2
        req.user_id = this.curData.user_id
      } else {
        req.type = 1
        req.user_id = this.userId
      }
      let url = 'black/edit_black'



      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isAlert = false;
            this.$message.success("操作成功");
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },

    resInit() {
      let req = {
        page: this.page,
        size: this.size,

      }
      if (this.inputVal1) {
        req.user_id = this.inputVal1
      }


      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}black/list`, req, 'get')
          .then(res => {
            this.resData= res.list ? res.list : []
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {

              list.created_at = this.dataFormat(list.created_at)
            })
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    }
  },
}
</script>

<style scoped>

</style>