<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">用户ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">操作者账号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>


    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>用户ID</th>
        <th>设置前级别</th>
        <th>设置后级别</th>
        <th>操作者账号</th>
        <th>操作时间</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.user_id}}</td>
          <td>{{ levelArr[list.old_level]}}</td>
          <td>{{ levelArr[list.new_level]}}</td>
          <td>{{ list.admin_account}}</td>
          <td>{{ list.created_at}}</td>

        </tr>



        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "levelHistory",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',

      filterVal: [
        "wallet_id",
        "name",
        "card_id",
        "pic_link",
        "type",
        "created_at",
        "updated_at",
      ],
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.admin_account = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.status = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}level_record`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.created_at = this.dataFormat(list.created_at)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>