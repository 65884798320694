<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">


      <div class="com_item_search">
        <div class="com_item_tx">变更类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>


      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>保证金变更数量</th>
        <th>保证金剩余数量</th>
        <th>变更类型</th>
        <th>变更时间</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ scientificNotationToString(list.margin) }}</td>
          <td>{{ scientificNotationToString(list.remaining_margin) }}</td>
          <td>{{typeObj[list.type] ? typeObj[list.type].label : list.type}}</td>
          <td>{{ dataFormat(list.time) }}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td> {{scientificNotationToString(subtotal.margin)}}</td>
          <td> {{scientificNotationToString(subtotal.remaining_margin)}}</td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td> {{scientificNotationToString(sum.margin)}}</td>
          <td> {{scientificNotationToString(sum.remaining_margin)}}</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>




  </article>
</template>

<script>
export default {
  name: "institutionLoanDeposit",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      inputVal2: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      two_auth_code: '',
      selectArr: [{value: '钱包', label: '钱包'}],
      subtotal: {},
      sum: {},
      typeObj: {
        1000: {label: '退还保证金', value: '1000'},
        1008: {label: '支付违约金', value: '1008'},
        1009: {label: '减少质押虚拟资产', value: '1009'},
        1010: {label: '派发收益减少质押金', value: '1010'},
        1012: {label: '保证金抵扣投资本金', value: '1012'},
        1: {label: '创建借币订单充入保证金', value: '1'},
        10: {label: '发放平台收益', value: '10'},
        17: {label: '返还虚拟资产', value: '17'},
      },
      statusObj: {
        1: '确认中',
        2: '成功',
        3: '失败',
        4: '已返还',
        5: '已复投',
      },
    }
  },
  created() {
    if (this.$route.query.id) {
      this.inputVal1 = this.$route.query.id
    }
    this.resInit()
  },
  methods: {

    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.inputVal1) {
        req.contract_id = this.inputVal1
      } else {
        return false
      }
      if (this.selectVal2) {
        req.op_type = this.selectVal2
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}contract_margin_details`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>