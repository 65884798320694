<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">计划任务编号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

    </div>


    <div class="com_search_box mt_40" >
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="showAlertFn('', 'add')">添加任务</el-button>
      </div>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>计划任务编号</th>
        <th>团队标签</th>
        <th>数据类型</th>
        <th>数据开始时间</th>
        <th>数据结束时间</th>
        <th>剔除类型</th>
        <th>任务状态</th>
        <th>任务创建时间</th>
        <th>任务完成时间</th>
        <th>任务执行总耗时</th>
        <th>任务说明</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ (list.number_id) }}</td>
          <td>{{ (list.team_type_tx) }}</td>
          <td>{{ (list.data_type_tx) }}</td>
          <td>{{ (list.start_at) }}</td>
          <td>{{ (list.end_at) }}</td>
          <td>{{ (list.cull_type_tx) }}</td>
          <td>{{ (list.status_tx) }}</td>
          <td>{{ (list.created_at) }}</td>
          <td>{{ (list.over_at) }}</td>
          <td>{{ (list.times_tx) }}</td>
          <td>{{ (list.remark) }}</td>
          <td class="tab_btn">
            <span class="btn"  @click="goRouter('/zlbDataReport', {id: list.number_id})">查看</span>

          </td>

        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curType == 'edit' ? '添加任务' : '添加任务'" :width="600">
      <div class="com_from_box">
        <div class="com_from_item" >
          <div class="com_from_left">团队标签</div>
          <div class="com_from_right" >
            <el-select
                v-model="team_type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option v-for="item in typeObj"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>

          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">数据类型</div>
          <div class="com_from_right" >
            <el-select
                v-model="data_type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option v-for="item in data_typeObj"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>

          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">剔除标记</div>
          <div class="com_from_right" >
            <el-select
                v-model="cull_type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option v-for="item in cull_typeObj"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>

          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">数据开始时间</div>
          <div class="com_from_right" >
            <el-date-picker
                v-model="start_at"
                type="datetime"
                size="small"
                format="YYYY-M-D H:mm:ss"
                value-format="YYYY-M-D H:mm:ss"
                placeholder="选择日期时间">
            </el-date-picker>

          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">数据结束时间</div>
          <div class="com_from_right" >
            <el-date-picker
                v-model="end_at"
                type="datetime"
                size="small"
                format="YYYY-M-D H:mm:ss"
                value-format="YYYY-M-D H:mm:ss"
                placeholder="选择日期时间">
            </el-date-picker>

          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">任务备注</div>
          <div class="com_from_right" >
            <el-input type="textarea"
                      rows="5"
                      v-model="remark"
                      size="small">

            </el-input>

          </div>
        </div>

      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary" @click="submitFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "zlb-assignment-report",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      selectVal1: "",
      startTime: "",
      endTime: "",
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "number_id",
        "team_type_tx",
        "data_type_tx",
        "start_at",
        "end_at",
        "cull_type_tx",
        "status_tx",
        "created_at",
        "over_at",
        "times_tx",
        "remark",
      ],
      isAlert: false,
      isRemove: false,
      curIndex: 0,
      curType: '',
      option1: [{label: '全额创建', value: 1}],
      title: '',
      two_auth_code : '',
      wallet_id: '',
      team_type: '',
      data_type: '',
      cull_type: '',
      end_at: '',
      remark: '',
      start_at: '',
      typeObj: {
        1: {label: '俱乐部', value: '1'},
        2: {label: '工作室', value: '2'},
        3: {label: '普通用户', value: '3'},
        4: {label: '临时标签', value: '4'},
      },
      statusObj: {
        1: {label: '等待执行', value: '1'},
        2: {label: '执行中', value: '2'},
        3: {label: '执行成功', value: '3'},
        4: {label: '执行失败', value: '4'},
      },
      data_typeObj: {
        1: {label: '出入金数据', value: '1'},
        2: {label: '团队当前投资', value: '2'},
        3: {label: 'otc买币', value: '3'},
      },
      cull_typeObj: {
        1: {label: '剔除团队下级', value: '1'},
        2: {label: '不剔除团队下级', value: '2'},
      },
    };
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
    title (val) {
      this.title = val.replace(/[^\a-\z\A-\Z\s]/g,'')
    },
  },
  methods: {
    showRemove (index) {
      this.curIndex = index
      this.two_auth_code = ''
      this.isRemove = true
    },
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.two_auth_code = ''
      this.team_type = ''
      this.data_type = ''
      this.cull_type = ''
      this.end_at = ''
      this.remark = ''
      this.start_at = ''

    },
    submitFn() {


      if (this.team_type === '') {
        this.$message.error('请选择团队标签')
        return false
      }
      if (this.data_type === '') {
        this.$message.error('请选择数据类型')
        return false
      }
      if (this.cull_type === '') {
        this.$message.error('请选择剔除类型')
        return false
      }
      if (this.end_at === '') {
        this.$message.error('请选择数据结束时间')
        return false
      }
      if (this.start_at === '') {
        this.$message.error('请选择数据开始时间')
        return false
      }
      let req = {
        two_auth_code: this.two_auth_code,
        team_type: this.team_type,
        data_type: this.data_type,
        cull_type: this.cull_type,
        end: this.end_at,
        remark: this.remark,
        start: this.start_at,
      }
      let url
      if (this.curType == 'edit') {
        url = 'add_club_task'
      } else {
        url = 'add_club_task'
      }

      /*this.$confirm('是否新增/编辑机构用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {*/
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {

            this.isAlert = false
            this.resInit()
            this.$message.success('提交成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      /*}).catch(() => {

      });*/

    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {

      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.number_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.addr = this.inputVal2;
      }
      if (this.selectVal1) {
        req.user_type = this.selectVal1;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}club_task_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {

              list.team_type_tx = this.typeObj[list.team_type] ? this.typeObj[list.team_type].label : list.team_type
              list.status_tx = this.statusObj[list.status] ? this.statusObj[list.status].label : list.status
              list.data_type_tx = this.data_typeObj[list.data_type] ? this.data_typeObj[list.data_type].label : list.data_type
              list.cull_type_tx = this.cull_typeObj[list.cull_type] ? this.cull_typeObj[list.cull_type].label : list.cull_type
              //list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
              list.start_at = this.dataFormat(list.start_at)
              list.end_at = this.dataFormat(list.end_at)
              list.over_at = this.dataFormat(list.over_at)
              list.created_at = this.dataFormat(list.created_at)

              if (list.times >= 0) {
                let day = '0'
                let hours = '0'
                let minute = '0'
                let second = '0'
                let date = (list.times / 3600) / 24
                if (date >= 0) {
                  day = date >= 10 ? parseInt(date) : '0' + parseInt(date)
                }
                date = (list.times / 3600) % 24
                if (list.times >= 0) {
                  hours = date >= 10 ? parseInt(date) : '0' + parseInt(date)
                }
                date = list.times / 60 % 60
                if (date >= 0) {
                  minute = date >= 10 ? parseInt(date, 10) : '0' + parseInt(date)
                }
                date = list.times % 60
                if (date >= 0) {
                  second = date >= 10 ? parseInt(date) : '0' + parseInt(date)
                }
                list.times_tx = day + '天' + hours + '时' + minute + '分' + second + '秒'
              }

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>