<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>个人钱包ID</th>
        <th>所属上级</th>
        <th>管理级别</th>
        <th>直接下级人数</th>
        <th>团队总人数</th>
        <th>团队当前認購总额</th>
        <th>个人認購总额</th>
        <th>绑定上级时间</th>
        <!-- <th>详情</th> -->
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('personaNextlLendingDetails', {team_wallet_id: list.user_id, start: startTime, end: endTime})">{{list.user_id}}</span>
          </td>
          <td class="color_T1" @click="goRouter('personaNextlLendingReport', {team_wallet_id: list.parent_id, start: startTime, end: endTime})">{{list.parent_id}}</td>
          <td>{{list.level}}</td>
          <td>{{list.next_numb}}</td>
          <td>{{list.team_numb}}</td>
          <td>{{scientificNotationToString(list.team_fsp)}}</td>
          <td>{{scientificNotationToString(list.self_fsp)}}</td>
          <td>{{(list.bind_time)}}</td>
          <!-- <td class="tab_btn">
            <span class="btn" @click="goRouter('personalLendingDetails', {wallet_id: list.user_id})">借款明细</span>
            <span class="btn" @click="goRouter('personalLoanDetails', {captain_id: list.user_id})">投资明细</span>
            <span class="btn" @click="goRouter('addressList', {wallet_id: list.user_id})">钱包地址列表</span>
          </td> -->
        </tr>
        <!-- <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.team_real_amount)}} USDT</td>
          <td>{{scientificNotationToString(subtotal.lending_real_amount)}} USDT</td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(sum.team_real_amount)}} USDT</td>
          <td>{{scientificNotationToString(sum.lending_real_amount)}} USDT</td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>U资产统计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(usd_total.team_real_amount)}} USDT</td>
          <td>{{scientificNotationToString(usd_total.lending_real_amount)}} USDT</td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>非U资产统计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <p style="white-space: nowrap;"  v-for="(list, idx) in other_total.team_real_amount" :key="idx">{{list.amount}} {{list.code}}</p>
          </td>
          <td>
            <p style="white-space: nowrap;"  v-for="(list, idx) in other_total.lending_real_amount" :key="idx">{{list.amount}} {{list.code}}</p>
          </td>
          <td></td>
          <td></td>
        </tr> -->
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "personaNextlLendingReport",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      other_total: {},
      usd_total: {},
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      filterVal: [
        "user_id",
        "parent_id",
        "level",
        "next_numb",
        "team_numb",
        // "team_invest_amount",
        // "team_invest_profit",
        // "lending_real_amount",
        "team_fsp",
        // "team_profit",
        // "pool_profit",
        // "md_profit",
        // "coupons_profit",
        // "self_recharge",
        // "self_withdraw",
        // "borrowing_amount",
        // "borrowing_interest",
        // "partner_subscribe",
        // "partner_profit",
        "self_fsp",
        "bind_time",
      ],
    };
  },
  created() {
    this.page = this.$route.query.page ? parseFloat(this.$route.query.page) : this.page
    this.size = this.$route.query.size ? parseFloat(this.$route.query.size) : this.size
    this.startTime = this.$route.query.start || this.startTime ? this.$route.query.start || this.startTime : ''
    this.endTime = this.$route.query.end || this.endTime ? this.$route.query.end || this.endTime : ''
    if (this.$route.query.team_wallet_id ) {
      this.inputVal1 = this.$route.query.team_wallet_id
      this.resInit();
    }


    // this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;

      this.resInit();

    },
    pageFn(index, type) {
      this.page = index;
      this.resInit();

    },
    resInit () {
      let req = {
        page: this.page,
        size: this.size,
      };

      if (this.inputVal1) {
        req.team_wallet_id = this.inputVal1;
      }

      if (!req.team_wallet_id) {
        this.$message.error('请输入钱包ID');
        return false
      }

      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.tx_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      let query = Object.assign({}, req)
      this.$ajax(`${this.Utils.URL.goURL}members`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.other_total = res.other_total ? res.other_total : {}
            this.usd_total = res.usd_total ? res.usd_total : {}
            this.resData.forEach((list) => {
              list.level = this.levelArr[list.level]
              list.bind_time = this.dataFormat(list.bind_time)
            })
            this.$router.replace({path: 'personaNextlLendingReport',query: query})
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },

  },
  watch: {
    $route(next,old) {
      if (next.path == old.path) {
        window.scrollTo(0, 0)
        if (this.inputVal1 != this.$route.query.team_wallet_id ) {
          this.inputVal1 = this.$route.query.team_wallet_id
          this.resInit()
        }
      }

    }
  }
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>