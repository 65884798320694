<template>
  <article class>
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="d_flex_2 mt_10" v-for="(list, index) in resList" :key="index">
      <div>收款币种{{index}}汇率</div>
      <section class="com_from_box ml_20">
        <div class="com_from_item">
          <div class="com_from_left">收款币种</div>
          <div class="com_from_right">
            <el-input
                v-model="list.currency"

                disabled="disabled"
                placeholder="收款币种"
                size="small"
            ></el-input>
          </div>
          <p class="ml_10">{{list.currency_name}}</p>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">汇率设置</div>
          <div class="com_from_right">
            <el-input
                v-model="list.price"
                placeholder="请输入汇率,大于0"
                size="small"
            ></el-input>
          </div>
          <p class="ml_10">参考价格 {{list.visit_price}} {{list.currency}} = 1 USDT</p>
        </div>
      </section>
    </section>


    <section class="com_from_box mt_40">
      <div class="com_from_item">
        <div class="com_from_left">谷歌验证码*</div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入谷歌验证码"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left"></div>
        <div class="com_from_right">
          <span class="btn" v-if="isEditButton" @click="sumbitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>
      </div>
    </section>
  </article>
</template>

<script>


export default {
  name: "merchantExchangeRate",
  data() {
    return {
      two_auth_code: '',
      resList: []
    };
  },
  created() {
    this.initFn()
  },
  methods: {
    pricesFn (val, index) {
      this.resList[index].price = this.padRight(val, 3)
    },
    initFn () {
      this.$ajax(`${this.Utils.URL.goURL}buy/price_list`, {
        coin_id: this.coin_id
      }, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            this.resList = arr
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },

    sumbitFn() {
      /*if (this.token === '' && this.chain_type != 'tl') {
              this.$message.error('请输入公链合约')
              return false
          }*/
      let isTitle = false
      this.resList.forEach(val => {
        if (!(val.price > 0)) {
          isTitle = true
          this.$message.error(`收款币种${val.currency},汇率设置小于0`)
          return false
        }
      })
      if (this.two_auth_code === '') {
        this.$message.error("请输入谷歌验证码");
        return false;
      }

      if (isTitle) {
        return false
      }


      let url = 'buy/set_price'
      let req = {
        currency_list: this.resList,
        two_auth_code: this.two_auth_code,

      };

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post", 2500000, false, true)
          .then(res => {
            this.$message.success("设置成功");

          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },

  }
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>