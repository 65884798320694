<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          终端
        </div>
        <div class="com_from_right">
          <el-checkbox-group v-model="port_id">
            <el-checkbox v-for="list in options1" :label="list.value">{{ list.label }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          一级分类
        </div>
        <div class="com_from_right">
          <el-select v-model="one_type" clearable filterable placeholder="请选择" size="small" @change="selectFn">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          二级分类
        </div>
        <div class="com_from_right">
          <el-select v-model="two_type" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <!-- <div class="com_from_item">
        <div class="com_from_left">
          链接地址
        </div>
        <div class="com_from_right">
          <el-input v-model="link_address" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div> -->
      <!-- add new 0826 -->
      <div v-for="(item, idx) in link_address_list" :key="idx">
        <div class="com_from_item">
          <div class="com_from_left">
            {{ item.type_title }}鏈結地址
          </div>
          <div class="com_from_right">
            <el-input v-model="item.value" placeholder="请输入" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <!-- end add new 0826 -->
      <div class="com_from_item" v-for="(list, idx) in title_list" :key="idx">
        <div class="com_from_left">
          {{ list.type_title }}标题
        </div>
        <div class="com_from_right">
          <el-input v-model="list.value" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item" v-for="(list, index) in img_list">
        <div class="com_from_left">
          {{ list.type_title }}图片
        </div>
        <div class="com_from_right">
          <el-upload class="avatar-uploader" action="''" :auto-upload="false" :show-file-list="false"
            :on-success="handleAvatarSuccess" :on-change="(val => beforeAvatarUpload(list.type, index, val))">
            <img v-if="list.value" :src="Utils.URL.goURL + list.value" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          排序
        </div>
        <div class="com_from_right">
          <el-input v-model="sort" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="sumbitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "bannerListEdit",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      startTime: '',
      endTime: '',
      type: '',
      id: '',
      port_id: [],
      one_type: '',
      two_type: '',
      title_zh: '',
      title_en: '',
      // link_address: '',
      infoData: {},
      img_list: [],
      title_list: [],
      imageUrls: '',
      imageUrl: '',
      imageUrlENs: '',
      imageUrlEN: '',
      sort: '',
      link_address_list: [],
    }
  },
  created() {
    this.type = this.$route.query.type
    this.options1 = JSON.parse(window.sessionStorage.getItem('portList'))
    this.options2 = JSON.parse(window.sessionStorage.getItem('oneImgClass'))

    if (this.type == 'edit') {
      this.infoData = JSON.parse(window.localStorage.getItem('listDataInfo'))

      this.id = this.infoData.id
      this.port_id = this.infoData.port_id.split(',')
      this.one_type = this.infoData.one_type
      this.selectFn(this.infoData.one_type)
      this.two_type = this.infoData.two_type
      this.sort = this.infoData.sort

      this.img_list = this.infoData.img_list ? this.infoData.img_list : []
      this.title_list = this.infoData.title_list ? this.infoData.title_list : []
      this.link_address_list = this.infoData.link_address_list ? this.infoData.link_address_list : []

      let obj = {}
      let obj1 = {}
      let obj2 = {}
      let arr = []
      let arr1 = []
      let arr2 = []
      this.langList.forEach((val, index) => {
        obj[val.value] = val.label
      })
      this.img_list.forEach((val, index) => {
        obj1[val.type] = val.type_titles
      })
      if (this.link_address_list.length > 0) {
        this.link_address_list.forEach((val, index) => {
          obj2[val.type] = val.type_title
        })
      }
      for (let i in obj) {
        if (!obj1[i]) {
          arr.push({
            type_title: obj[i],
            type: i,
            value: ''
          })
          arr1.push({
            type_title: obj[i],
            type: i,
            value: ''
          })
          arr2.push({
            type_title: obj[i],
            type: i,
            value: ''
          })
        }
      }
      this.img_list = this.img_list.concat(arr)
      // remove duplicate img_list
      this.img_list = this.img_list.filter((item, index, self) => {
        return self.findIndex(v => v.type === item.type) === index
      })
      this.title_list = this.title_list.concat(arr1)
      this.link_address_list = this.link_address_list.concat(arr2)
      // remove duplicate title_list
      this.title_list = this.title_list.filter((item, index, self) => {
        return self.findIndex(v => v.type === item.type) === index
      })
      // remove duplicate link_address_list
      this.link_address_list = this.link_address_list.filter((item, index, self) => {
        return self.findIndex(v => v.type === item.type) === index
      })

      // remove old config
      this.img_list = this.img_list.filter((item) => {
        return item.type !== 'cn' && item.type !== 'pt_pt' && item.type !== 'pt-pt' && item.type !== 'pt-br' 
      })
      this.title_list = this.title_list.filter((item) => {
        return item.type !== 'cn' && item.type !== 'pt_pt' && item.type !== 'pt-pt' && item.type !== 'pt-br' 
      })
      this.link_address_list = this.link_address_list.filter((item) => {
        return item.type !== 'cn' && item.type !== 'pt_pt' && item.type !== 'pt-pt' && item.type !== 'pt-br' 
      })
      // add pt_br
      // this.img_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: ''
      // })
      // this.title_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: ''
      // })
      // this.link_address_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: ''
      // })
    } else {
      this.initImgList()
      this.initTypeList()
      this.initLinkList()
    }
  },
  methods: {
    initImgList() {
      this.langList.forEach((val, index) => {
        this.img_list[index] = {}
        this.img_list[index].type_title = val.label
        this.img_list[index].type = val.value
        this.img_list[index].value = ''
      })
    },
    initTypeList() {
      this.langList.forEach((val, index) => {
        this.title_list[index] = {}
        this.title_list[index].type_title = val.label
        this.title_list[index].type = val.value
        this.title_list[index].value = ''
      })
    },
    initLinkList() {
      this.langList.forEach((val, index) => {
        this.link_address_list[index] = {}
        this.link_address_list[index].type_title = val.label
        this.link_address_list[index].type = val.value
        this.link_address_list[index].value = ''
      })
    },
    sumbitFn() {
      let isTitle = false
      let isImg = false
      if (!this.port_id.length) {
        this.$message.error('请选择终端')
        return false
      }

      if (this.one_type === '') {
        this.$message.error('请选择一级分类')
        return false
      }

      if (this.two_type === '') {
        this.$message.error('请选择二级分类')
        return false
      }

      if (!this.title_list.length) {
        this.$message.error('请填写标题')
        return false
      }

      if (!this.img_list.length) {
        this.$message.error('请上传图片')
        return false
      }
      this.img_list.forEach(val => {
        if (!val.value) {
          isImg = true
          this.$message.error(`请上传${val.type_title}图片`)
          return false
        }
      })

      this.title_list.forEach(val => {
        if (!val.value) {
          isImg = true
          this.$message.error(`请填写${val.type_title}标题`)
          return false
        }
      })

      if (isImg || isTitle) {
        return false
      }

      let url = this.type == 'edit' ? 'update_poster' : 'created_poster'
      let req = {
        port_id: this.port_id.join(','),
        one_type_id: parseFloat(this.one_type),
        two_type_id: parseFloat(this.two_type),
        img_list: this.img_list,
        title_list: this.title_list,
        link_address_list: this.link_address_list,
        sort: parseFloat(this.sort),
      }
      if (this.type == 'edit') {
        req.id = parseFloat(this.id)
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post', 2500000, false, true)
        .then(res => {
          this.$message.success('操作成功,两秒后自动跳转')
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    selectFn(vals, type = 2) {
      if (!vals) {
        return false
      }
      let req = {
        type_content_id: '2'
      }
      let url = 'type_one_list'
      if (type == 2) {
        url = 'type_two_list'
        req.type_id = this.one_type
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
        .then(res => {
          let arr = type == 1 ? res ? res : [] : res.list ? res.list : []
          let arr2 = []
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.type_zh
            }
          })
          if (type == 1) {
            this.options2 = arr2
          } else {
            this.options3 = arr2

          }
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(lang, index, file) {
      this.uploadFn(lang, index, file)
    },
    uploadFn(type, index, file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      const formData = new FormData()
      let str = file.name.split('.')
      let last = str[str.length - 1].toLowerCase()
      if (!/png|jpg|jpeg/.test(last)) {
        this.$message.error('上传错误,文件格式必须为：png/jpg/jpeg');
        return;
      }
      let time = parseInt(new Date().getTime())
      str = 'image_upload' + time + '.' + last
      formData.append('files', file.raw, str)
      formData.append('dir', 'bannerImg')
      this.$ajax(`${this.Utils.URL.goURL}upload`, formData, 'post', 2500000, true)
        .then(res => {
          this.img_list[index].value = res.url

        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>