import Home from './home'

import coinList from './view/coin-list'
import coinEditList from './view/coin-list-edit'
import coinLoanList from './view/coin-loan-list'
import coinLoanEditList from './view/coin-loan-list-edit'
import pledgeCoinList from './view/pledge-coin-list'
import tokenList from './view/token-list'
import tokenEditList from './view/token-list-edit'
import assetsAdjust from './view/assets-adjust'
import systemLog from './view/system-log'
import ipWhite from './view/ip-white'



import articleList from './view/article/article-list'
import videoList from './view/article/video-list'
import videoListEdit from './view/article/video-list-edit'
import hotArticleList from './view/article/hot-article-list'
import hotArticleListEdit from './view/article/hot-article-list-edit'
import appIconList from './view/article/app-icon-list'
import appIconListEdit from './view/article/app-icon-list-edit'
import bannerList from './view/article/banner-list'
import articleListEdit from './view/article/article-list-edit'
import bannerListEdit from './view/article/banner-list-edit'
import classList from './view/article/class-list'
import classListEdit from './view/article/class-list-edit'
import terminalList from './view/article/terminal-list'
import terminalListEdit from './view/article/terminal-list-edit'




import adminList from './view/admin/admin-list'
import adminEdit from './view/admin/admin-edit'
import adminRoleList from './view/admin/admin-role-list'
import adminRoleEdit from './view/admin/admin-role-edit'
import adminRuleList from './view/admin/admin-rule-list'
import adminRuleEdit from './view/admin/admin-rule-edit'








import versionList from './view/setting/version-list'
import versionSetting from './view/setting/version-setting'
import versionSettingIOS from './view/setting/version-setting-ios'
import keyList from './view/setting/key-list'
import appidList from './view/setting/appid-list'
import androidUpload from './view/setting/android-upload'
import nodeList from './view/setting/node-list'
import nodeListEdit from './view/setting/node-list-edit'
import addMakeAddress from './view/setting/add-make-address'
import securityDepositAddress from './view/setting/security-deposit-address'
import addFthAddress from './view/setting/add-fth-address'
import addSwftAddress from './view/setting/add-swft-address'
import addInvestAddress from './view/setting/add-invest-address'
import addActivityAddress from './view/setting/add-activity-address'
import addCommunityAddress from './view/setting/add-community-address.vue'
import backWithdraw from './view/setting/back-withdraw'
import backWithdrawRecord from './view/setting/back-withdraw-record'
import addOtcAddress from './view/setting/add-otc-address'


import loanOrder from './view/finance/loan-order'
import batchTransferList from './view/finance/batch-transfer-list'
import swftAddress from './view/finance/swft-address'
import batchTransfer from './view/finance/batch-transfer'
import fundCoin from './view/finance/fund-coin'
import financeOtcContract from './view/finance/finance-otc-contract.vue'






import superNodeRewardSummaryList from './view/excel/cash-coupon-transfer-record.vue'
import superNodeRewardDetails from './view/excel/cash-coupon-transfer-record-detail.vue'

import superPartnerList from './view/excel/super-partner-list'
import buySuperNode from './view/excel/buy-super-node.vue'
import superPartnerDetails from './view/excel/super-partner-details'
import superPartnerRecord from './view/excel/super-partner-record.vue'
import partnerList from './view/excel/partner-list'
import partnerDetails from './view/excel/partner-details'
import c2cRechargeList from './view/excel/c2c-recharge-list'
import c2cWithdrawList from './view/excel/c2c-withdraw-list'
import c2cFineList from './view/excel/c2c-fine-list'
import rechargeList from './view/excel/recharge-list'
import gzsRechargeReport from './view/excel/gzs-recharge-report'
import zlbRechargeReport from './view/excel/zlb-recharge-report'
import zlbListReport from './view/excel/zlb-list-report'
import zlbAssignmentReport from './view/excel/zlb-assignment-report'
import zlbDataReport from './view/excel/zlb-data-report'
import withdrawList from './view/excel/withdraw-list'
import withdrawAuditList from './view/excel/withdraw-audit-list'
import billCount from './view/excel/bill-count'
import teamLendingReport from './view/excel/team-lending-report'
import teamLendingDetails from './view/excel/team-lending-details'
import teamLoanDetails from './view/excel/team-loan-details'
import personalLendingReport from './view/excel/personal-lending-report'
import personaNextlLendingReport from './view/excel/personal-next-lending-report'
import personaNextlLendingDetails from './view/excel/personal-next-lending-details'
import personalLendingDetails from './view/excel/personal-lending-details'
import personalLoanDetails from './view/excel/personal-loan-details'
import teamLoanFundraising from './view/excel/team-loan-fundraising'
import lendingDepositDetails from './view/excel/lending-deposit-details'
import addressList from './view/excel/address-list'
import nextDetails from './view/excel/next-details'
import stoTotalDeport from './view/excel/sto-total-report'
import stoDetailsDeport from './view/excel/sto-details-report'
import stoExchangeReport from './view/excel/sto-exchange-report'
import operationalReport from './view/excel/operational-report'
import alarmTransferAbnormal from './view/excel/alarm-transfer-abnormal'
import alarmStatusManagement from './view/excel/alarm-status-management'
import dayCompoundInterestCount from './view/excel/day-compound-interest-count'
import dayFinancialVoucherCount from './view/excel/day-financial-voucher-count'
import dayFundCount from './view/excel/day-fund-count'
import dayLoanCount from './view/excel/day-loan-count'
import financialVoucherDetails from './view/excel/financial-voucher-details'
import financialVoucherDetailsCopy from './view/excel/financial-voucher-details-copy'
import userList from './view/excel/user-list'
import levelHistory from './view/excel/level-history'
import userInfo from './view/excel/user-info'
import makeDetails from './view/excel/make-details'
import makeTotal from './view/excel/make-total'
import releaseIncomeReport from './view/excel/release-income-report'
import incomeReport from './view/excel/income-report'
import alipayAuthReport from './view/excel/alipay-auth-report'
import makeSpendEstimate from './view/excel/make-spend-estimate'
import realRechargeList from './view/excel/real-recharge-list'
import teamRechargeReport from './view/excel/team-recharge-report'
import teamRechargereport1 from './view/excel/team-recharge-report-1'
import teamProfitAudit from './view/excel/team-profit-audit'
import teamInvitationRelationship from './view/excel/team-invitation-relationship'
import teamLevelRecord from './view/excel/team-level-record'
import teamLevelUser from './view/excel/team-level-user'
import stoSubscribeDetailsDeport from './view/excel/sto-subscribe-details-report'
import activityDinnerReport from './view/excel/activity-dinner-report'
import otcAwardReport from './view/excel/otc-award-report.vue'
import mechanismProfitExcel from './view/excel/mechanism-profit-excel'
import fundCheck from './view/excel/fund-check'
import fundCheckh from './view/excel/fund-check-h'
import communityListReport from './view/excel/community-list-report'
import communityApplicationReport from './view/excel/community-application-report'
import communityReimburseReport from './view/excel/community-reimburse-report'
import communityReimburseDetails from './view/excel/community-reimburse-details.vue'
import communityAwardReport from './view/excel/community-award-report.vue'


import createdInstitutionFund from './view/institutional-management/created-institution-fund'
import createdInstitutionLoan from './view/institutional-management/created-institution-loan'
import institutionFundAsset from './view/institutional-management/institution-fund-asset'
import institutionFundFundraising from './view/institutional-management/institution-fund-fundraising'
import institutionFundIncome from './view/institutional-management/institution-fund-income'
import institutionFundOrder from './view/institutional-management/institution-fund-order'
import institutionLoanAsset from './view/institutional-management/institution-loan-asset'
import institutionLoanFundraising from './view/institutional-management/institution-loan-fundraising'
import institutionLoanOrder from './view/institutional-management/institution-loan-order'
import institutionLoanOrderCopy from './view/institutional-management/institution-loan-order-copy'
import institutionLoanTransactionOrder from './view/institutional-management/institution-loan-transaction-order'
import institutionLoanLiquidityCrossOrder from './view/institutional-management/institution-loan-transaction-cross-order'
import loanLiquidityCrossOrder from './view/excel/loan-transaction-cross-order'
import institutionLoanLiquidityOrder from './view/institutional-management/institution-loan-liquidity-order'
import institutionalAccountList from './view/institutional-management/institutional-account-list'
import institutionLoanDeposit from './view/institutional-management/institution-loan-deposit'
import institutionAccountAssetDetails from './view/institutional-management/institution-account-asset-details'
import institutionLoanSetting from './view/institutional-management/institution-loan-setting'
import oneDayInstitutionalSetting from './view/institutional-management/one-day-institutional-setting.vue'



import c2cAppealDetails from './view/c2c-management/c2c-appeal-details'
import c2cAppealSellDetails from './view/c2c-management/c2c-sell-appeal-details'
import c2cAppealOrder from './view/c2c-management/c2c-appeal-order'
import c2cOrder from './view/c2c-management/c2c-order'
import c2cSellOrder from './view/c2c-management/c2c-sell-order'
import merchantAmountList from './view/c2c-management/merchant-amount-list'
import merchantBankList from './view/c2c-management/merchant-bank-list'
import merchantExchangeRate from './view/c2c-management/merchant-exchange-rate'
import merchantList from './view/c2c-management/merchant-list'
import merchantDetails from './view/c2c-management/merchant-details'
import otcContractAddr from './view/c2c-management/otc-contract-addr.vue'



import ftcbscManage from './view/setting/ftcbsc-wallet-management.vue'
import ftcmainManage from './view/setting/ftc-main-wallet-management.vue'
import stolenWalletManage from './view/excel/stolen-wallet-manage'
import replaceParentManage from './view/excel/replace-parent-manage'
import ftcMarketMaker from './view/excel/ftc-market-maker'
import ftcRunningTask from './view/excel/ftc-running-task'
// import userRiskManage from './view/excel/user-risk-manage'
// import auditRecord from './view/excel/audit-record'
import miningList from './view/excel/mining-list'
import pickList from './view/excel/pick-list'
import fusdExchangeRecord from './view/excel/fusd-exchange-record'
import finswapMarketMaker from './view/excel/finswap-market-maker'
import finswapRunningTask from './view/excel/finswap-running-task'
import addFinswapAddress from './view/setting/add-finswap-address'

import ftcMarketRecord from './view/excel/ftc-market-record'

import liquidityFlashOrder from './view/excel/liquidity-flash-order'
import liquidStakeOrder from './view/excel/liquid-stake-order'
import liquidRecord from './view/excel/liquid-record'
import addFMAddress from './view/setting/add-fm-address'

import swiftBlackList from './view/excel/swift-black-list'
import stakingRecords from './view/excel/staking-records'
import incomerecord3 from './view/excel/income-record-3'

import worldvideo from './view/excel/world-video'

import addmortgageAddress from './view/setting/add_mortgage_address'

import otcstakingrecord from './view/excel/otc-staking-record'
import stakingopotc from './view/excel/staking-op-otc'
import mixtokenfunction from './view/excel/mix-token-function'
import mixtokenrecord from './view/excel/mix-token-record'
import mixtokentrecord from './view/excel/mix-token-t-record'

// import finswaprecord from './view/excel/finswap-record'
// import Settlementcheck from './view/excel/settlement-check'
import mortgageorderlist from './view/excel/mortgage-order-list'
import goldrecord from './view/excel/gold-record'

//1029
import newInvestmentReport from './view/excel/new-investment-report'

//0229
import castingOrder from './view/excel/casting-order'

// 0311
import otcPurchaseOrder from './view/excel/otc-purchase-order'
import otcSalesOrder from './view/excel/otc-sales-order'

// 0312
import otcCommissionOrderList from './view/excel/otc-commission-order-list'
import otcCommissionOrderListDetail from './view/excel/otc-commission-order-list-detail'
import otcFsuSales from './view/excel/otc-fsu-sales'
import otcFsuSalesRecord from './view/excel/otc-fsu-sales-record'

// 0320
import otcRechargeList from './view/excel/otc-recharge-list'
import otcWithdrawList from './view/excel/otc-withdraw-list'

// 0323
import rechargeListTotal from './view/excel/recharge-list-total'
import withdrawListTotal from './view/excel/withdraw-list-total'

// 0410
import fspFlowRecord from './view/excel/fsp-flow-record'

//0820
import fspNetIncreaseRecord from './view/excel/fsp-net-increase-record.vue'
import fspMarketMaker from './view/excel/fsp-market-maker.vue'
import fspRunningTask from './view/excel/fsp-running-task.vue'

import fspIssuanceRecord from './view/excel/fsp-issuance-record.vue'
import fspLiquidityRecord from './view/excel/fsp-liquidity-record.vue'
import fspExtractionRecord from './view/excel/fsp-extraction-record.vue'
let routerPath = {
    '/home': {
        path: '/home',
        component: Home,
        children: [
            {
                path: '/ipWhite',
                component: ipWhite
            },
        ]
    },
    '/articleList': {
        title: "文章列表",
        path: '/articleList',
        component: articleList
    },
    '/articleListEdit': {
        title: "文章编辑",
        path: '/articleListEdit',
        component: articleListEdit
    },
    '/videoList': {
        title: "视频列表",
        path: '/videoList',
        component: videoList
    },
    '/videoListEdit': {
        title: "视频编辑",
        path: '/videoListEdit',
        component: videoListEdit
    },
    '/hotArticleList': {
        title: "热搜列表",
        path: '/hotArticleList',
        component: hotArticleList
    },
    '/hotArticleListEdit': {
        title: "热搜编辑",
        path: '/hotArticleListEdit',
        component: hotArticleListEdit
    },
    '/appIconList': {
        title: "app首页图标",
        path: '/appIconList',
        component: appIconList
    },
    '/appIconListEdit': {
        title: "app首页图标编辑",
        path: '/appIconListEdit',
        component: appIconListEdit
    },
    '/bannerList': {
        title: "轮播图列表",
        path: '/bannerList',
        component: bannerList
    },
    '/bannerListEdit': {
        title: "轮播图编辑",
        path: '/bannerListEdit',
        component: bannerListEdit
    },
    '/classList': {
        title: "分类列表",
        path: '/classList',
        component: classList
    },
    '/classListEdit': {
        title: "分类编辑",
        path: '/classListEdit',
        component: classListEdit
    },
    '/terminalList': {
        title: "终端列表",
        path: '/terminalList',
        component: terminalList
    },
    '/terminalListEdit': {
        title: "终端编辑",
        path: '/terminalListEdit',
        component: terminalListEdit
    },
    '/versionList': {
        title: "版本列表",
        path: '/versionList',
        component: versionList
    },
    '/versionSetting': {
        title: "Android版本设置",
        path: '/versionSetting',
        component: versionSetting
    },
    '/nodeList': {
        title: "节点列表",
        path: '/nodeList',
        component: nodeList
    },
    '/nodeListEdit': {
        title: "节点列表编辑",
        path: '/nodeListEdit',
        component: nodeListEdit
    },
    '/addMakeAddress': {
        title: "撮合池钱包管理",
        path: '/addMakeAddress',
        component: addMakeAddress
    },
    '/securityDepositAddress': {
        title: "系统保证金管理",
        path: '/securityDepositAddress',
        component: securityDepositAddress
    },
    '/addFthAddress': {
        title: "FTH钱包管理",
        path: '/addFthAddress',
        component: addFthAddress
    },
    '/addSwftAddress': {
        title: "Swft转出地址管理",
        path: '/addSwftAddress',
        component: addSwftAddress
    },
    '/versionSettingIOS': {
        title: "iOS版本设置",
        path: '/versionSettingIOS',
        component: versionSettingIOS
    },
    '/appidList': {
        path: '/appidList',
        component: appidList
    },
    '/adminList': {
        title: "账号列表",
        path: '/adminList',
        component: adminList
    },
    '/adminEdit': {
        title: "账号编辑",
        path: '/adminEdit',
        component: adminEdit
    },
    '/adminRoleList': {
        title: "角色列表",
        path: '/adminRoleList',
        component: adminRoleList
    },
    '/adminRoleEdit': {
        title: "角色编辑",
        path: '/adminRoleEdit',
        component: adminRoleEdit
    },
    '/adminRuleList': {
        title: "权限列表",
        path: '/adminRuleList',
        name: 'adminRuleList',
        component: adminRuleList
    },
    '/adminRuleEdit': {
        title: "权限编辑",
        path: '/adminRuleEdit',
        component: adminRuleEdit
    },
    '/coinList': {
        title: "币种列表",
        path: '/coinList',
        component: coinList
    },
    '/coinListEdit': {
        title: "币种编辑",
        path: '/coinListEdit',
        component: coinEditList
    },
    '/coinLoanList': {
        title: "多币种借款配置",
        path: '/coinLoanList',
        component: coinLoanList
    },
    '/coinLoanEditList': {
        title: "多币种借款编辑",
        path: '/coinLoanEditList',
        component: coinLoanEditList
    },
    '/pledgeCoinList': {
        title: "质押币种设置",
        path: '/pledgeCoinList',
        component: pledgeCoinList
    },
    '/tokenList': {
        title: "代币列表",
        path: '/tokenList',
        component: tokenList
    },
    '/tokenEditList': {
        title: "代币编辑",
        path: '/tokenEditList',
        component: tokenEditList
    },
    '/androidUpload': {
        title: '安卓包上传',
        path: '/androidUpload',
        component: androidUpload
    },
    '/assetsAdjust': {
        title: '资产调整',
        path: '/assetsAdjust',
        component: assetsAdjust
    },
    '/systemLog': {
        title: '系统日志',
        path: '/systemLog',
        component: systemLog
    },
    '/superNodeRewardSummaryList': { // new
        title: '现金劵报表',
        path: '/superNodeRewardSummaryList',
        component: superNodeRewardSummaryList
    },
    '/superNodeRewardDetails': { // new
        title: '详情页',
        path: '/superNodeRewardDetails',
        component: superNodeRewardDetails
    },
    '/superPartnerList': {
        title: '超级节点认购列表',
        path: '/superPartnerList',
        component: superPartnerList
    },
    '/buySuperNode': {
        title: '认购超级节点',
        path: '/buySuperNode',
        component: buySuperNode
    },
    '/superPartnerDetails': {
        title: '超级节点认购详情',
        path: '/superPartnerDetails',
        component: superPartnerDetails
    },
    '/superPartnerRecord': {
        title: '超级节点认购记录',
        path: '/superPartnerRecord',
        component: superPartnerRecord
    },
    '/partnerList': {
        title: '合伙人认购列表',
        path: '/partnerList',
        component: partnerList
    },
    '/partnerDetails': {
        title: '认购详情',
        path: '/partnerDetails',
        component: partnerDetails
    },
    '/c2cRechargeList': {
        title: 'c2c充值列表',
        path: '/c2cRechargeList',
        component: c2cRechargeList
    },
    '/gzsRechargeReport': {
        title: 'GZS出入金报表',
        path: '/gzsRechargeReport',
        component: gzsRechargeReport
    },
    '/zlbRechargeReport': {
        title: 'ZLB出入金报表',
        path: '/zlbRechargeReport',
        component: zlbRechargeReport
    },
    '/zlbListReport': {
        title: 'ZLB列表',
        path: '/zlbListReport',
        component: zlbListReport
    },
    '/zlbAssignmentReport': {
        title: '批跑任务列表',
        path: '/zlbAssignmentReport',
        component: zlbAssignmentReport
    },
    '/zlbDataReport': {
        title: '批跑数据',
        path: '/zlbDataReport',
        component: zlbDataReport
    },
    '/c2cWithdrawList': {
        title: 'c2c提币列表',
        path: '/c2cWithdrawList',
        component: c2cWithdrawList
    },
    '/c2cFineList': {
        title: 'c2c罚金列表',
        path: '/c2cFineList',
        component: c2cFineList
    },
    '/rechargeList': {
        title: '充值列表',
        path: '/rechargeList',
        component: rechargeList
    },
    '/withdrawList': {
        title: '提币列表',
        path: '/withdrawList',
        component: withdrawList
    },
    '/withdrawAuditList': {
        title: '提币审核列表',
        path: '/withdrawAuditList',
        component: withdrawAuditList
    },
    '/billCount': {
        title: '充提汇总',
        path: '/billCount',
        component: billCount
    },
    '/keyList': {
        title: '热钱包签名',
        path: '/keyList',
        component: keyList
    },
    '/teamLendingReport': {
        title: '团队报表',
        path: '/teamLendingReport',
        component: teamLendingReport
    },
    '/teamLendingDetails': {
        title: '团队投资明细',
        path: '/teamLendingDetails',
        component: teamLendingDetails
    },
    '/teamLoanDetails': {
        title: '团队借贷明细',
        path: '/teamLoanDetails',
        component: teamLoanDetails
    },
    '/personalLendingReport': {
        title: '个人列表报表',
        path: '/personalLendingReport',
        component: personalLendingReport
    },
    '/personaNextlLendingReport': {
        title: '个人报表',
        path: '/personaNextlLendingReport',
        component: personaNextlLendingReport
    },
    '/personaNextlLendingDetails': {
        title: '下级个人报表',
        path: '/personaNextlLendingDetails',
        component: personaNextlLendingDetails
    },
    '/personalLendingDetails': {
        title: '借款订单',
        path: '/personalLendingDetails',
        component: personalLendingDetails
    },
    '/personalLoanDetails': {
        title: '投资订单',
        path: '/personalLoanDetails',
        component: personalLoanDetails
    },
    '/teamLoanFundraising': {
        title: '筹款详情',
        path: '/teamLoanFundraising',
        component: teamLoanFundraising
    },
    '/lendingDepositDetails': {
        title: '订单保证金详情',
        path: '/lendingDepositDetails',
        component: lendingDepositDetails
    },
    '/nextDetails': {
        title: '下级详情',
        path: '/nextDetails',
        component: nextDetails
    },
    '/addressList': {
        title: '公链钱包地址',
        path: '/addressList',
        component: addressList
    },
    '/stoTotalDeport': {
        title: 'FTH汇总',
        path: '/stoTotalDeport',
        component: stoTotalDeport
    },
    '/stoDetailsDeport': {
        title: 'FTH明细',
        path: '/stoDetailsDeport',
        component: stoDetailsDeport
    },
    '/stoExchangeReport': {
        title: 'FTH兑换明细',
        path: '/stoExchangeReport',
        component: stoExchangeReport
    },
    '/operationalReport': {
        title: '每日运营报表',
        path: '/operationalReport',
        component: operationalReport
    },
    '/alarmTransferAbnormal': {
        title: '风控异常记录',
        path: '/alarmTransferAbnormal',
        component: alarmTransferAbnormal
    },
    '/alarmStatusManagement': {
        title: '风控状态管理',
        path: '/alarmStatusManagement',
        component: alarmStatusManagement
    },
    '/dayCompoundInterestCount': {
        title: '每日复利汇总',
        path: '/dayCompoundInterestCount',
        component: dayCompoundInterestCount
    },
    '/dayFinancialVoucherCount': {
        title: '每日理财券汇总',
        path: '/dayFinancialVoucherCount',
        component: dayFinancialVoucherCount
    },
    '/dayFundCount': {
        title: '每日基金汇总',
        path: '/dayFundCount',
        component: dayFundCount
    },
    '/dayLoanCount': {
        title: '每日借贷汇总',
        path: '/dayLoanCount',
        component: dayLoanCount
    },
    '/financialVoucherDetailsCopy': {
        title: '财务理财券明细',
        path: '/financialVoucherDetailsCopy',
        component: financialVoucherDetailsCopy
    },

    '/financialVoucherDetails': {
        title: '理财券明细',
        path: '/financialVoucherDetails',
        component: financialVoucherDetails
    },
    '/userList': {
        title: '用户列表',
        path: '/userList',
        component: userList
    },
    '/levelHistory': {
        title: '操作记录',
        path: '/levelHistory',
        component: levelHistory
    },
    '/userInfo': {
        title: '用户信息',
        path: '/userInfo',
        component: userInfo
    },
    '/stolenWalletManage': {
        title: '被盗配置',
        path: '/stolenWalletManage',
        component: stolenWalletManage
    },
    '/replaceParentManage': {
        title: '更換邀請ID',
        path: '/replaceParentManage',
        component: replaceParentManage
    },
    '/incomeReport': {
        title: '每日收益汇总',
        path: '/incomeReport',
        component: incomeReport
    },
    '/alipayAuthReport': {
        title: '支付宝认证列表',
        path: '/alipayAuthReport',
        component: alipayAuthReport
    },
    '/makeSpendEstimate': {
        title: '撮合池支出预估',
        path: '/makeSpendEstimate',
        component: makeSpendEstimate
    },
    '/realRechargeList': {
        title: '真实用户充值列表',
        path: '/realRechargeList',
        component: realRechargeList
    },
    '/teamRechargeReport': {
        title: '二期团队净入金统计',
        path: '/teamRechargeReport',
        component: teamRechargeReport
    },
    '/teamRechargereport1': {
        title: '一期團隊淨入金統計',
        path: '/teamRechargereport1',
        component: teamRechargereport1
    },
    '/teamProfitAudit': {
        title: '团队收益稽核',
        path: '/teamProfitAudit',
        component: teamProfitAudit
    },
    '/teamLevelRecord': {
        title: '团队管理级别变更记录',
        path: '/teamLevelRecord',
        component: teamLevelRecord
    },
    '/teamLevelUser': {
        title: '管理级别用户列表',
        path: '/teamLevelUser',
        component: teamLevelUser
    },
    '/teamInvitationRelationship': {
        title: '团队邀请关系',
        path: '/teamInvitationRelationship',
        component: teamInvitationRelationship
    },
    '/stoSubscribeDetailsDeport': {
        title: '全球跑马圈地活动',
        path: '/stoSubscribeDetailsDeport',
        component: stoSubscribeDetailsDeport
    },
    '/activityDinnerReport': {
        title: 'sto认购明细',
        path: '/activityDinnerReport',
        component: activityDinnerReport
    },
    '/otcAwardReport': {
        title: 'otc审核奖励',
        path: '/otcAwardReport',
        component: otcAwardReport
    },
    '/mechanismProfitExcel': {
        title: '机构订单盈亏',
        path: '/mechanismProfitExcel',
        component: mechanismProfitExcel
    },
    '/fundCheck': {
        title: '撮合池对账',
        path: '/fundCheck',
        component: fundCheck
    },
    '/fundCheckh': {
        title: '撮合池对账',
        path: '/fundCheckh',
        component: fundCheckh
    },
    '/communityApplicationReport': {
        title: '申请列表',
        path: '/communityApplicationReport',
        component: communityApplicationReport
    },
    '/communityReimburseReport': {
        title: '报销列表',
        path: '/communityReimburseReport',
        component: communityReimburseReport
    },
    '/communityReimburseDetails': {
        title: '报销明细',
        path: '/communityReimburseDetails',
        component: communityReimburseDetails
    },
    '/communityAwardReport': {
        title: '奖励列表',
        path: '/communityAwardReport',
        component: communityAwardReport
    },
    '/communityListReport': {
        title: '社区列表',
        path: '/communityListReport',
        component: communityListReport
    },
    '/releaseIncomeReport': {
        title: '发放收益明细',
        path: '/releaseIncomeReport',
        component: releaseIncomeReport
    },
    '/makeTotal': {
        title: '撮合池汇总',
        path: '/makeTotal',
        component: makeTotal
    },
    '/makeDetails': {
        title: '撮合池详情',
        path: '/makeDetails',
        component: makeDetails
    },
    '/createdInstitutionFund': {
        title: '创建机构基金',
        path: '/createdInstitutionFund',
        component: createdInstitutionFund
    },
    '/createdInstitutionLoan': {
        title: '创建机构借贷',
        path: '/createdInstitutionLoan',
        component: createdInstitutionLoan
    },
    '/institutionFundAsset': {
        title: '机构基金地址资产',
        path: '/institutionFundAsset',
        component: institutionFundAsset
    },
    '/institutionFundFundraising': {
        title: '机构基金募资详情',
        path: '/institutionFundFundraising',
        component: institutionFundFundraising
    },
    '/institutionFundIncome': {
        title: '机构基金日收益率',
        path: '/institutionFundIncome',
        component: institutionFundIncome
    },
    '/institutionFundOrder': {
        title: '机构基金订单',
        path: '/institutionFundOrder',
        component: institutionFundOrder
    },
    '/institutionLoanAsset': {
        title: '机构借贷地址资产',
        path: '/institutionLoanAsset',
        component: institutionLoanAsset
    },
    '/institutionLoanFundraising': {
        title: '机构借贷募资详情',
        path: '/institutionLoanFundraising',
        component: institutionLoanFundraising
    },
    '/institutionLoanOrder': {
        title: '机构借贷订单',
        path: '/institutionLoanOrder',
        component: institutionLoanOrder
    },
    '/institutionLoanOrderCopy': {
        title: '机构借贷数据',
        path: '/institutionLoanOrderCopy',
        component: institutionLoanOrderCopy
    },
    '/institutionLoanTransactionOrder': {
        title: '兑换记录',
        path: '/institutionLoanTransactionOrder',
        component: institutionLoanTransactionOrder
    },
    '/institutionLoanLiquidityCrossOrder': {
        title: '跨链闪兑记录',
        path: '/institutionLoanLiquidityCrossOrder',
        component: institutionLoanLiquidityCrossOrder
    },
    '/loanLiquidityCrossOrder': {
        title: '跨链闪兑记录报表',
        path: '/loanLiquidityCrossOrder',
        component: loanLiquidityCrossOrder
    },
    '/institutionLoanLiquidityOrder': {
        title: '流动池列表',
        path: '/institutionLoanLiquidityOrder',
        component: institutionLoanLiquidityOrder
    },
    '/institutionalAccountList': {
        title: '机构账户',
        path: '/institutionalAccountList',
        component: institutionalAccountList
    },
    '/institutionLoanDeposit': {
        title: '保证金消耗明细',
        path: '/institutionLoanDeposit',
        component: institutionLoanDeposit
    },
    '/institutionAccountAssetDetails': {
        title: '虚拟额度变更详情',
        path: '/institutionAccountAssetDetails',
        component: institutionAccountAssetDetails
    },
    '/institutionLoanSetting': {
        title: '机构自动借贷',
        path: '/institutionLoanSetting',
        component: institutionLoanSetting
    },
    '/oneDayInstitutionalSetting': {
        title: '机构自动借贷1天订单',
        path: '/oneDayInstitutionalSetting',
        component: oneDayInstitutionalSetting
    },
    '/loanOrder': {
        title: '借贷订单列表',
        path: '/loanOrder',
        component: loanOrder
    },
    '/swftAddress': {
        title: '地址管理',
        path: '/swftAddress',
        component: swftAddress
    },
    '/addInvestAddress': {
        title: '投资钱包管理',
        path: '/addInvestAddress',
        component: addInvestAddress
    },
    '/addActivityAddress': {
        title: '活动奖励钱包管理',
        path: '/addActivityAddress',
        component: addActivityAddress
    },
    '/addCommunityAddress': {
        title: '社区活动奖励钱包管理',
        path: '/addCommunityAddress',
        component: addCommunityAddress
    },
    '/backWithdraw': {
        title: '提币黑名单团队',
        path: '/backWithdraw',
        component: backWithdraw
    },
    '/backWithdrawRecord': {
        title: '提币黑名单记录',
        path: '/backWithdrawRecord',
        component: backWithdrawRecord
    },
    '/batchTransfer': {
        title: '批量转账',
        path: '/batchTransfer',
        component: batchTransfer
    },
    '/batchTransferList': {
        title: '批量转账计划表',
        path: '/batchTransferList',
        component: batchTransferList
    },
    '/fundCoin': {
        title: '投资币种',
        path: '/fundCoin',
        component: fundCoin
    },
    '/financeOtcContract': {
        title: 'OTC合约替换',
        path: '/financeOtcContract',
        component: financeOtcContract
    },
    '/c2cAppealDetails': {
        title: '申诉详情',
        path: '/c2cAppealDetails',
        component: c2cAppealDetails
    },
    '/c2cAppealSellDetails': {
        title: '申诉详情',
        path: '/c2cAppealSellDetails',
        component: c2cAppealSellDetails
    },
    '/c2cAppealOrder': {
        title: '申诉订单',
        path: '/c2cAppealOrder',
        component: c2cAppealOrder
    },
    '/c2cOrder': {
        title: 'C2C订单',
        path: '/c2cOrder',
        component: c2cOrder
    },
    '/merchantList': {
        title: 'APP商户',
        path: '/merchantList',
        component: merchantList
    },
    '/merchantDetails': {
        title: 'APP商户详情',
        path: '/merchantDetails',
        component: merchantDetails
    },
    '/otcContractAddr': {
        title: 'OTC合约地址管理',
        path: '/otcContractAddr',
        component: otcContractAddr
    },
    '/c2cSellOrder': {
        title: '卖币订单',
        path: '/c2cSellOrder',
        component: c2cSellOrder
    },
    '/merchantAmountList': {
        title: '商户额度管理',
        path: '/merchantAmountList',
        component: merchantAmountList
    },
    '/merchantBankList': {
        title: '银行卡管理',
        path: '/merchantBankList',
        component: merchantBankList
    },
    '/merchantExchangeRate': {
        title: '汇率设置',
        path: '/merchantExchangeRate',
        component: merchantExchangeRate
    },
    '/ftcbscManage': {
        title: 'Ftcbsc錢包管理',
        path: '/ftcbscManage',
        component: ftcbscManage
    },
    '/ftcmainManage': {
        title: 'Ftcmain錢包管理',
        path: '/ftcmainManage',
        component: ftcmainManage
    },
    '/ftcMarketMaker': {
        title: 'FTC造市',
        path: '/ftcMarketMaker',
        component: ftcMarketMaker
    },
    '/ftcRunningTask': {
        title: '当前执行列表',
        path: '/ftcRunningTask',
        component: ftcRunningTask
    },
    // '/userRiskManage': {
    //     title: '用户风控列表',
    //     path: '/userRiskManage',
    //     component: userRiskManage
    // },
    // '/auditRecord': {
    //     title: '审计异常表',
    //     path: '/auditRecord',
    //     component: auditRecord
    // },
    '/miningList': {
        title: '收益提取表',
        path: '/miningList',
        component: miningList
    },
    '/pickList': {
        title: '挖礦報表',
        path: '/pickList',
        component: pickList
    },
    '/fusdExchangeRecord': {
        title: 'FUSD兑换记录',
        path: '/fusdExchangeRecord',
        component: fusdExchangeRecord
    },
    '/finswapMarketMaker': {
        title: 'Finswap造市',
        path: '/finswapMarketMaker',
        component: finswapMarketMaker
    },
    '/finswapRunningTask': {
        title: 'Finswap当前执行列表',
        path: '/finswapRunningTask',
        component: finswapRunningTask
    },
    '/addFinswapAddress': {
        title: "Finswap兑换钱包管理",
        path: '/addFinswapAddress',
        component: addFinswapAddress
    },
    '/ftcMarketRecord': {
        title: "策略交易記錄表",
        path: '/ftcMarketRecord',
        component: ftcMarketRecord
    },
    '/liquidityFlashOrder': {
        title: "跨链闪兑记录表",
        path: '/liquidityFlashOrder',
        component: liquidityFlashOrder
    },
    '/liquidStakeOrder': {
        title: "流动性质押订单",
        path: '/liquidStakeOrder',
        component: liquidStakeOrder
    },
    '/liquidrecord': {
        title: "流动性记录表",
        path: '/liquidrecord',
        component: liquidRecord
    },
    '/addFMAddress': {
        title: "LP质押合约钱包地址管理",
        path: '/addFMAddress',
        component: addFMAddress
    },
    '/swiftblacklist': {
        title: "SWIFT黑名單查詢",
        path: '/swiftblacklist',
        component: swiftBlackList
    },
    '/stakingrecords': {
        title: "質押收益發放提取記錄",
        path: '/stakingrecords',
        component: stakingRecords
    },
    '/incomerecord3': {
        title: "收益記錄表",
        path: '/incomerecord3',
        component: incomerecord3
    },
    '/worldvideo': {
        title: "全球視頻徵集",
        path: '/worldvideo',
        component: worldvideo
    },
    '/addmortgageAddress': {
        title: "抵押錢包管理",
        path: '/addmortgageAddress',
        component: addmortgageAddress
    },
    '/otcstakingrecord': {
        title: "OTC代快捷質押記錄表",
        path: '/otcstakingrecord',
        component: otcstakingrecord
    },
    '/stakingopotc': {
        title: "代快捷質押",
        path: '/stakingopotc',
        component: stakingopotc
    },
    '/mixtokenfunction': {
        title: "混幣功能",
        path: '/mixtokenfunction',
        component: mixtokenfunction
    },
    '/mixtokenrecord': {
        title: "混幣功能",
        path: '/mixtokenrecord',
        component: mixtokenrecord
    },
    '/mixtokentrecord': {
        title: "轉帳記錄",
        path: '/mixtokentrecord',
        component: mixtokentrecord
    },
    // '/finswaprecord': {
    //     title: "Finswap/pancake兌換SCF記錄",
    //     path: '/finswaprecord',
    //     component: finswaprecord
    // },
    // '/Settlementcheck': {
    //     title: "結算獎勵資格查詢",
    //     path: '/Settlementcheck',
    //     component: Settlementcheck
    // },
    '/mortgageorderlist': {
        title: "抵押訂單",
        path: '/mortgageorderlist',
        component: mortgageorderlist
    },
    '/goldrecord': {
        title: "用戶記錄",
        path: '/goldrecord',
        component: goldrecord
    },
    '/newInvestmentReport': {
        title: "新投资报表",
        path: '/newInvestmentReport',
        component: newInvestmentReport
    },
    '/castingOrder': {
        title: "鑄造訂單",
        path: '/castingOrder',
        component: castingOrder
    },
    '/otcPurchaseOrder': {
        title: "OTC委托購買訂單記錄",
        path: '/otcPurchaseOrder',
        component: otcPurchaseOrder
    },
    '/otcSalesOrder': {
        title: "OTC銷售訂單記錄",
        path: '/otcSalesOrder',
        component: otcSalesOrder
    },
    '/otcCommissionOrderList': {
        title: "OTC委托買單列表",
        path: '/otcCommissionOrderList',
        component: otcCommissionOrderList
    },
    '/otcCommissionOrderListDetail': {
        title: "详情页",
        path: '/otcCommissionOrderListDetail',
        component: otcCommissionOrderListDetail
    },
    '/otcFsuSales': {
        title: "OTC-FSU販賣",
        path: '/otcFsuSales',
        component: otcFsuSales
    },
    '/otcFsuSalesRecord': {
        title: "系統訂單記錄",
        path: '/otcFsuSalesRecord',
        component: otcFsuSalesRecord
    },
    // add 0320
    '/addOtcAddress': {
        title: "OTC熱錢包管理",
        path: '/addOtcAddress',
        component: addOtcAddress
    },
    '/otcRechargeList': {
        title: 'OTC充值',
        path: '/otcRechargeList',
        component: otcRechargeList
    },
    '/otcWithdrawList': {
        title: 'OTC提款',
        path: '/otcWithdrawList',
        component: otcWithdrawList
    },
    '/rechargeListTotal': {
        title: '總充值列表',
        path: '/rechargeListTotal',
        component: rechargeListTotal
    },
    '/withdrawListTotal': {
        title: '總提幣列表',
        path: '/withdrawListTotal',
        component: withdrawListTotal
    },
    '/fspFlowRecord': {
        title: 'FSP 流水記錄表',
        path: '/fspFlowRecord',
        component: fspFlowRecord
    },
    '/fspNetIncreaseRecord': {
        title: 'FSP 淨增加記錄表',
        path: '/fspNetIncreaseRecord',
        component: fspNetIncreaseRecord
    },
    '/fspMarketMaker': {
        title: 'FSP造市',
        path: '/fspMarketMaker',
        component: fspMarketMaker
    },
    '/fspRunningTask': {
        title: 'FSP当前执行列表',
        path: '/fspRunningTask',
        component: fspRunningTask
    },
    '/fspIssuanceRecord': {
        title: 'FSP发放记录表',
        path: '/fspIssuanceRecord',
        component: fspIssuanceRecord
    },
    '/fspLiquidityRecord': {
        title: 'FSP流动性记录表',
        path: '/fspLiquidityRecord',
        component: fspLiquidityRecord
    },
    '/fspExtractionRecord': {
        title: 'FSP提取记录表',
        path: '/fspExtractionRecord',
        component: fspExtractionRecord
    }
}
export default routerPath

