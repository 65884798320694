<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_address" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">鏈類別</div>
        <div class="com_item_input">
          <el-select
            v-model="chainType"
            clearable
            filterable
            placeholder="請選擇鏈類別"
            size="small">
            <el-option v-for="item in chainTypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <!-- <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button> -->
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>
    <div>
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>錢包ID</th>
          <th>支付數量(FSU)</th>
          <th>支付數量(FSP)</th>
          <th>鑄造幣種鏈別</th>
          <th>訂單數量</th>
          <th>手續費</th>
          <th>實際鑄造數量</th>
          <th>建立訂單時間</th>
          <th>完成時間</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.wallet_id }}</td>
            <td>{{ list.fsu_amount }}</td>
            <td>{{ list.fsp_amount }}</td>
            <td>{{ list.chan_type }}</td>
            <td>{{ list.casting_amount }}</td>
            <td>{{ list.casting_fee }}</td>
            <td>{{ list.casting_actual_amount }}</td>
            <td>{{ list.created_at }}</td>
            <td>{{ list.receive_at }}</td>
          </tr>
          <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td>{{ scientificNotationToString(subtotal.fsu_amount) }}</td>
          <td>{{ scientificNotationToString(subtotal.fsp_amount) }}</td>
          <td></td>
          <td>{{ scientificNotationToString(subtotal.casting_amount) }}</td>
          <td>{{ scientificNotationToString(subtotal.casting_fee) }}</td>
          <td>{{ scientificNotationToString(subtotal.casting_actual_amount) }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td>{{ scientificNotationToString(sum.fsu_amount) }}</td>
          <td>{{ scientificNotationToString(sum.fsp_amount) }}</td>
          <td></td>
          <td>{{ scientificNotationToString(sum.casting_amount) }}</td>
          <td>{{ scientificNotationToString(sum.casting_fee) }}</td>
          <td>{{ scientificNotationToString(sum.casting_actual_amount) }}</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

    
  </article>
</template>

<script>
export default {
  name: "castingOrder",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      subtotal: {},
      sum: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      wallet_address: '',
      chainType: '',
      chainTypeList: [
        {
          value: 'bsc',
          text: 'BSC',
        },
        {
          value: 'eth',
          text: 'ETH',
        },
        {
          value: 'tron',
          text: 'TRON',
        }
      ],
      tHeader: [],
      filterVal: [
        'wallet_id',
        'fsu_amount', 
        'fsp_amount',
        'chan_type',
        'casting_amount',
        'casting_fee',
        'casting_actual_amount',
        'created_at',
        'receive_at',
      ],
      resDataFullForExport: [],
    };
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  created() {
    this.resInit();
  },
  methods: {
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
      }
      this.$ajax(`${this.Utils.URL.goURL}casting/casting_order`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.created_at = list.created_at > 0 ? this.dataFormat(list.created_at) : '-'
            list.receive_at = list.receive_at > 0 ? this.dataFormat(list.receive_at) : '-'
          })
          if (this.resDataFullForExport.length > 0) {
            this.exportExcel(this.tHeader, this.filterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_address) {
        req.wallet_address = this.wallet_address;
      }
      if (this.startTime) {
        req.start = this.startTime;
      }
      if (this.endTime) {
        req.end = this.endTime;
      }
      if (this.chainType) {
        req.chan_type = this.chainType;
      }
      this.$ajax(`${this.Utils.URL.goURL}casting/casting_order`, req, "get")
      
        .then((res) => {
          if (res.list === null || res.list.length === 0) {
            this.$message.error("暂无数据");
            this.total = 0;
            this.resData = [];
            return;
          }
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {
            
            list.created_at = list.created_at > 0 ? this.dataFormat(list.created_at) : '-'
            list.receive_at = list.receive_at > 0 ? this.dataFormat(list.receive_at) : '-'
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.summary {
  display: flex;
  place-items: center;
  margin-bottom: 20px;
}
.summary .title {
  font-weight: bold;
  font-size: 24px;
  margin-right: 25px;
  flex-basis: 8%;
}
.summary .content .title {
  font-weight: bold;
  font-size: 18px;
}
.link {
  text-decoration: underline;
}
.fee_sum span {
  margin-right: 10px;
}
</style>