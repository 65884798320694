<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">合伙人名称</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">创建者地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">合伙人状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option value="1" label="确认中"></el-option>
            <el-option value="2" label="认购中"></el-option>
            <el-option value="3" label="失败"></el-option>
            <el-option value="4" label="失败-认购数量超出"></el-option>
            <el-option value="5" label="已完成"></el-option>
            <el-option value="6" label="失败-认购不足"></el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div class="com_search_box mt_40" >
      <div class="com_item_search">
        <el-button type="primary" v-if="isGenerateButton" size="small" @click="showAlertFn('', 'add')">创建合伙人</el-button>
      </div>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>合伙人名称</th>
        <th>合伙人级别</th>
        <th>创建者地址</th>
        <th>合伙人收款地址</th>
        <th>合伙人费用</th>
        <th>已认购数量</th>
        <th>参与人数</th>
        <th>合伙人状态</th>
        <th>申请时间</th>
        <th>详情</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.title}}
          </td>
          <td>
            {{list.level}}
          </td>
          <td>
            {{list.addr}}
          </td>
          <td>
            {{list.gathering_addr}}
          </td>
          <td>
            {{scientificNotationToString(list.cost)}} USDT
          </td>
          <td>
            {{scientificNotationToString(list.numb)}} USDT
          </td>
          <td>
            {{scientificNotationToString(list.participate_numb)}}
          </td>
          <td>
            {{(list.status)}}
          </td>
          <td>{{ (list.time) }}</td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('partnerDetails', {node_id: list.node_id})">查看</span>
            <span class="btn" v-if="isEditButton" @click="showAlertFn(index, 'edit')">编辑合伙人</span>
          </td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.cost)}} USDT</td>
          <td> {{scientificNotationToString(subtotal.numb)}} USDT</td>
          <td> {{scientificNotationToString(subtotal.participate_numb)}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.cost)}} USDT</td>
          <td> {{scientificNotationToString(sum.numb)}} USDT</td>
          <td> {{scientificNotationToString(sum.participate_numb)}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curType == 'edit' ? '编辑合伙人' : '新建合伙人'" :width="600">
      <div class="com_from_box">
        <div class="com_from_item" v-show="curType !== 'edit'">
          <div class="com_from_left">创建类型</div>
          <div class="com_from_right" >
            <el-select
                v-model="type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option v-for="item in option1"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>

          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">合伙人名称</div>
          <div class="com_from_right" >
            <el-input
                v-model="title"
                placeholder="请输入英文"
                type="text"
                size="small">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">合伙人介绍</div>
          <div class="com_from_right" style="width: 230px">
            <el-input
                v-model="introduction"
                placeholder="请输入"
                type="textarea"
                size="small">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right" >
            <el-input
                v-model="two_auth_code"
                placeholder="请输入"
                type="text"
                size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary" @click="submitFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "partner_list",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      selectVal1: "",
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "title",
        "level",
        "addr",
        "gathering_addr",
        "cost",
        "numb",
        "participate_numb",
        "status",
        "time",
      ],
      isAlert: false,
      curIndex: 0,
      curType: '',
      option1: [{label: '全额创建', value: 1}],
      title: '',
      two_auth_code : '',
      introduction: '',
      type: '',
      typeObj: {
        1: {label: '确认中', value: '1'},
        2: {label: '认购中', value: '2'},
        3: {label: '交易失败-无需退款', value: '3'},
        4: {label: '失败-认购数量超出需退款', value: '4'},
        5: {label: '已完成', value: '5'},
        6: {label: '失败-认购不足需退款', value: '6'},
      },
    };
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
    title (val) {
      this.title = val.replace(/[^\a-\z\A-\Z\s]/g,'')
    },
  },
  methods: {
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.title = ''
      this.two_auth_code = ''
      this.introduction = ''
      this.type = ''
      if (type == 'edit') {
        this.curIndex = index
        this.title = this.resData[index].title
        this.introduction = this.resData[index].introduction
        this.type = this.resData[index].type
      }
    },
    submitFn() {
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      if (this.title === '') {
        this.$message.error('请输入合伙人名称')
        return false
      }
      if (this.info === '') {
        this.$message.error('请输入合伙人介绍')
        return false
      }
      let req = {
        two_auth_code: this.two_auth_code,
        title: this.title,
        introduction: this.introduction,
      }
      let url
      if (this.curType == 'edit') {
        url = 'partner/edit'
        req.id = this.resData[this.curIndex].node_id
      } else {
        url = 'partner/add'
        if (this.type === '') {
          return false
        }
        req.type = this.type
      }

      /*this.$confirm('是否新增/编辑机构用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {*/
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.two_auth_code = ''
            this.introduction = ''
            this.title = ''
            this.type = ''
            this.isAlert = false
            this.resInit()
            this.$message.success('提交成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      /*}).catch(() => {

      });*/

    },
    statusTx (type) {
      if (type == 1) {
        return '确认中'
      }
      if (type == 2) {
        return '认购中'
      }
      if (type == 3) {
        return '交易失败-无需退款'
      }
      if (type == 4) {
        return '失败-认购数量超出需退款'
      }
      if (type == 5) {
        return '已完成'
      }
      if (type == 6) {
        return '失败-认购不足需退款'
      }
      return type
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {

      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.title = this.inputVal1;
      }
      if (this.inputVal2) {
        req.addr = this.inputVal2;
      }
      if (this.selectVal1) {
        req.status = this.selectVal1;
      }
      this.$ajax(`${this.Utils.URL.goURL}partner_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {

              list.status = this.typeObj[list.status] ? this.typeObj[list.status].label : list.status
              //list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
              list.level = this.levelArr[list.level]
              list.time = this.dataFormat(list.time)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>