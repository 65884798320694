<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">鏈類別</div>
        <div class="com_item_input">
          <el-select v-model="chain_type" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in chainTypeList" :key="key" :label="item.label" :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">狀態</div>
        <div class="com_item_input">
          <el-select v-model="status" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in statusList" :key="key" :label="item.label" :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <!-- <div class="com_search_box">
        <el-button
            type="primary"
            @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
        >
          导出excel（本页）
        </el-button>
      </div> -->

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>钱包ID</th>
          <th>抵押幣種</th>
          <th>抵押數量</th>
          <th>抵押交易哈希</th>
          <th>借款金額(FUSD)</th>
          <th>總利息(FUSD)</th>
          <th>借款資金哈希</th>
          <th>清算價</th>
          <th>抵押率</th>
          <th>當前保證金</th>
          <th>狀態</th>
          <th>借款時間</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ list.p_coin_symbol }}</td>
            <td>{{ scientificNotationToString(list.pledge_amt) }}</td>
            <td>{{ list.pledge_tx_id }}</td>
            <td>{{ scientificNotationToString(list.total_interest) }}</td>
            <td>{{ list.pledge_tx_id }}</td>
            <td>{{ list.loan_tx_id }}</td>
            <td>{{ scientificNotationToString(list.finished_amt) }}</td>
            <td>{{ list.borrow_rate }}</td>
            <td>{{ scientificNotationToString(list.margin_amt) }}</td>
            <td>{{ list.status }}</td>
            <td>{{ list.loan_at }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(subtotal.total_interest) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(subtotal.margin_amt) }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(sum.total_interest) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(sum.margin_amt) }}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

  </article>
</template>
  
<script>
export default {
  name: "rechargeList",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      wallet_id: '',
      startTime: '',
      endTime: '',
      chain_type: '',
      chainTypeList: {
        1: { label: 'SCF(MAIN)', value: '1' },
        2: { label: 'BTC', value: '2' },
        3: { label: 'ETH', value: '3' },
        4: { label: 'BNB', value: '4' },
      },
      status: '',
      statusList: {
        1: { label: '抵押中', value: '1' },
        2: { label: '抵押申請中', value: '2' },
        3: { label: '已完成', value: '3' },
        4: { label: '抵押廣播失敗', value: '4' },
        5: { label: '資金廣播失敗', value: '5' },
      },
      subtotal: {},
      sum: {},
      // tHeader: [],
      // filterVal: [
      //   "tx_time",
      //   "tx_type",
      //   "chain_type",
      //   "symbol",
      //   "numb",
      //   "user_id",
      //   "addr",
      //   "wallet_addr",
      //   "hash",
      // ],
    };
  },
  created() {
    this.resInit();
  },
  // mounted() {
  //   document.querySelector(".com_thead").childNodes.forEach((item) => {
  //     if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
  //     this.tHeader.push(item.innerText);
  //   });
  // },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id) {
        req.userID = this.wallet_id;
      }
      if (this.chain_type) {
        req.coinSymbol = this.chain_type;
      }
      if (this.status) {
        req.status = this.status;
      }
      if (this.startTime) {
        req.beginTime = this.startTime
      }
      if (this.endTime) {
        req.endTime = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}trade/collateral_record_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              list.status = this.statusList[list.status].label;
              list.loan_at = +list.loan_at > 0 ? this.dataFormat(list.loan_at): '-';

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>
  
<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>