<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">


      <div class="com_item_search">
        <div class="com_item_tx">借款钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">借款钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">借款共管地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">合同编号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">订单类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal6"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                label="资金借贷订单"
                :value="true"
            >
            </el-option>
            <el-option
                label="复投虚拟订单"
                :value="false"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">贷款类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">贷款状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in statusObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>

    </div>

    <div style="width: 2800px">
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>借款钱包ID</th>
        <th>借款钱包地址</th>
        <th>借款共管地址</th>
        <th>共管资产</th>
        <th>合同编号</th>
        <th>订单类型</th>
        <th>借款类型</th>
        <th>借款人信息</th>
        <th>链类型</th>
        <th>借款币种</th>
        <th>借款数量</th>
        <th>已借数量</th>
        <th>保证金数量</th>
        <th>借款状态</th>
        <th>借款周期(天)</th>
        <th>日利率</th>
        <th>预计利息总额</th>
        <th>违约金</th>
        <th>平台垫付金额</th>
        <th>发布借款时间</th>
        <th>借款生效时间</th>
        <th>借款到期时间</th>
        <th>详情</th>
        <th>备注</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.wallet_id}}</td>
          <td>{{list.borrowing_addr}}</td>
          <td>{{list.multi_addr}}</td>
          <td>
            <p>{{list.public_all_data.type}}-{{list.public_all_data.code}}: {{list.public_all_data.amount}}</p>
          </td>
          <td>{{list.numb}}</td>
          <td>{{ list.is_real_order_tx}}</td>
          <td>{{list.borrow_type}}</td>
          <td>{{list.borrow_name}}</td>
          <td>{{list.chain_type}}</td>
          <td>{{list.symbol}}</td>
          <td>{{scientificNotationToString(list.borrowing_num)}}</td>
          <td>{{scientificNotationToString(list.curr_borrow)}}</td>
          <td>{{scientificNotationToString(list.pledge_num)}}</td>
          <td>{{list.status}}</td>
          <td>{{list.invest_duration_day}}</td>
          <td>{{list.daily_interest_rate}}</td>
          <td>{{scientificNotationToString(list.interest)}}</td>
          <td>{{scientificNotationToString(list.breach_contract)}}</td>
          <td>{{scientificNotationToString(list.platform_pay)}}</td>
          <td>{{ (list.created_at) }}</td>
          <td>{{ (list.start_at) }}</td>
          <td>{{ (list.end_at) }}</td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('teamLoanFundraising', {code: list.numb})">筹款详情</span>
            <span class="btn" @click="goRouter('lendingDepositDetails', {code: list.numb})">保证金变更明细</span>
            <span class="btn" v-if="list.status == '放款成功还款中' && list.borrow_type == '用户借贷'" @click="showAlertFn(index)">手动清算订单</span>
            <span class="btn" v-if="list.is_can_manual_invest && isEditButton" @click="orderInit(list.numb)">手动投资</span>
          </td>
          <td>{{ list.remarks }}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <p style="white-space: nowrap;"  v-for="list in subtotal.public_all_amount">{{list.type}}-{{list.code}}: {{padRight(scientificNotationToString(list.amount), 6)}} </p>

          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.borrowing_num)}}</td>
          <td> {{scientificNotationToString(subtotal.curr_borrow)}}</td>
          <td> {{scientificNotationToString(subtotal.pledge_num)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.interest)}}</td>
          <td> {{scientificNotationToString(subtotal.breach_contract)}}</td>
          <td> {{scientificNotationToString(subtotal.platform_pay)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <p style="white-space: nowrap;"  v-for="list in sum.public_all_amount">{{list.type}}-{{list.code}}: {{padRight(scientificNotationToString(list.amount), 6)}} </p>

          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.borrowing_num)}}</td>
          <td> {{scientificNotationToString(sum.curr_borrow)}}</td>
          <td> {{scientificNotationToString(sum.pledge_num)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.interest)}}</td>
          <td> {{scientificNotationToString(sum.breach_contract)}}</td>
          <td> {{scientificNotationToString(sum.platform_pay)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" title="平仓清算" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="submitActiveAddrFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isLoan" title="手动投资" width="500px">
      <section class="com_from_box">


        <div class="com_from_item">
          <div class="com_from_left">合同编号</div>
          <div class="com_from_right">
            {{ orderData.numb }}
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">链类型</div>
          <div class="com_from_right">
            {{ orderData.chain_type }} 链
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">借款地址</div>
          <div class="com_from_right">
            {{ orderData.borrowing_addr }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">共管地址</div>
          <div class="com_from_right">
            {{ orderData.multi_addr }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">借款数量</div>
          <div class="com_from_right">
            {{ orderData.borrowing_num }} {{ orderData.symbol }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">已借数量</div>
          <div class="com_from_right">
            {{ orderData.curr_borrow }} USDT
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">可投数量</div>
          <div class="com_from_right">
            {{ orderData.can_invest_amount }} {{ orderData.symbol }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">筹资进度</div>
          <div class="com_from_right">
            {{ orderData.progress }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">投资地址</div>
          <div class="com_from_right">
            {{ orderData.invest_addr }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">钱包余额</div>
          <div class="com_from_right">
            {{ orderData.wallet_amount }} USDT
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">投资金额</div>
          <div class="com_from_right">
            <el-input
                v-model="orderNum"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
          <span class="ml_5">{{ orderData.invest_base }}的整倍数</span>
        </div>


        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isLoan = false">取消</el-button>
                    <el-button type="primary" @click="subOrderFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "personalLendingDetails",
  data() {
    return {
      page: 1,
      size: 50,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      selectVal6: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {public_all_amount: []},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      curIndex: '',
      two_auth_code: '',
      isAlert: false,
      isLoan: false,
      order_id: '',
      orderNum: '',
      orderData: {},
      other_total: {},
      usd_total: {},
      sum: {public_all_amount: []},
      typeObj: {
        1: {label: '用户借贷', value: '1'},
        2: {label: '机构借贷', value: '2'},
        3: {label: '机构基金', value: '3'},
      },
      statusObj: {
        1: {label: '申请中', value: '1'},
        2: {label: '筹款中', value: '2'},
        3: {label: '募集成功-还款中', value: '3'},
        4: {label: '放款成功还款中', value: '4'},
        5: {label: '提前还款', value: '5'},
        6: {label: '已还款成功', value: '6'},
        7: {label: '申请失败', value: '7'},
        8: {label: '募集失败', value: '8'},
        9: {label: '放款失败', value: '9'},
        10: {label: '还款失败', value: '10'},
        11: {label: '正常还款', value: '11'},
        12: {label: '交易亏损-系统强制平仓中', value: '12'},
        13: {label: '交易亏损-系统强制平仓已完成', value: '13'},
        14: {label: '管理后台强制平仓中', value: '14'},
        15: {label: '管理后台强制平仓完成', value: '15'},
      },
      filterVal: [
        "wallet_id",
        "borrowing_addr",
        "multi_addr",
        "public_all_amount",
        "numb",
        "is_real_order_tx",
        "borrow_type",
        "borrow_name",
        "chain_type",
        "symbol",
        "borrowing_num",
        "curr_borrow",
        "pledge_num",
        "status",
        "invest_duration_day",
        "daily_interest_rate",
        "interest",
        "breach_contract",
        "platform_pay",
        "created_at",
        "start_at",
        "end_at",
        "remarks",
      ],
    };
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );

    this.inputVal1 = this.$route.query.wallet_id ? this.$route.query.wallet_id : ''
    this.inputVal2 = this.$route.query.wallet_addr ? this.$route.query.wallet_addr : ''
    this.inputVal3 = this.$route.query.public_addr ? this.$route.query.public_addr : ''
    this.inputVal4 = this.$route.query.contract_number ? this.$route.query.contract_number : ''
    this.selectVal1 = this.$route.query.borrow_type ? this.$route.query.borrow_type : ''
    this.selectVal2 = this.$route.query.status ? this.$route.query.status : ''
    this.selectVal3 = this.$route.query.chain_type ? this.$route.query.chain_type : ''
    this.selectVal4 = this.$route.query.coin_id ? this.$route.query.coin_id : ''
    this.selectVal6 = this.$route.query.is_real_order ? this.$route.query.is_real_order == 'true' ? true : false : ''

    this.startTime = this.$route.query.begin_time || this.startTime ? this.$route.query.begin_time || this.startTime : ''
    this.endTime = this.$route.query.end_time || this.endTime ? this.$route.query.end_time || this.endTime : ''
    this.page = this.$route.query.page ? parseFloat(this.$route.query.page) : this.page
    this.size = this.$route.query.size ? parseFloat(this.$route.query.size) : this.size
    this.resInit();
  },
  mounted() {

    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    orderInit (order) {
      this.isLoan = true;
      this.order_id = ''
      this.orderNum = ''
      this.two_auth_code = ''
      this.$ajax(`${this.Utils.URL.goURL}organ/borrow_order_admin_detail`, {
        order_id: order
      }, 'get')
          .then(res => {
            this.orderData = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    showAlertFn (index) {
      this.curIndex = index
      this.two_auth_code = ''
      this.isAlert = true
    },
    subOrderFn () {
      let min = parseFloat(this.orderNum)
      let max = parseFloat(this.orderData.can_invest_amount)
      let balance = parseFloat(this.orderData.wallet_amount )

      if (min > max) {
        this.$message.error('投资金额大于可投数量')
        return false
      }
      if (min > balance) {
        this.$message.error('钱包余额不足')
        return false
      }
      if (this.orderNum === '') {
        this.$message.error('请输入投资金额')
        return false
      }
      /*if (this.orderNum % this.orderData.invest_base !== 0) {
        this.$message.error('请输入正确的倍数')
        return false
      }*/
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'organ/set_manual_invest_admin'
      let req = {
        two_auth_code: this.two_auth_code,
        amount : this.orderNum,
        order_id  : this.orderData.numb,
      }
      this.$confirm(`是否手动投资`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('交易已广播')
              this.loan = false

            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    submitActiveAddrFn () {
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'recovery_wallet'
      let req = {
        two_auth_code: this.two_auth_code,
        ad_id: this.resData[this.curIndex].numb,
      }
      this.$confirm(`是否手动清算`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('成功')
              this.isAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.public_addr = this.inputVal3;
      }
      if (this.inputVal4) {
        req.contract_number = this.inputVal4;
      }
      if (this.selectVal1) {
        req.borrow_type = this.selectVal1;
      }
      if (this.selectVal2) {
        req.status = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.coin_id = this.selectVal4;
      }
      if (this.selectVal6 !== '') {
        req.is_real_order = this.selectVal6
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      let query = Object.assign({}, req)
      this.$ajax(`${this.Utils.URL.goURL}user_borrowing_details`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {public_all_amount: []}
            this.sum = res.sum ? res.sum : {public_all_amount: []}
            this.other_total = res.other_total ? res.other_total : {}
            this.usd_total = res.usd_total ? res.usd_total : {}
            this.resData.forEach((list) => {
              list.borrow_type = this.typeObj[list.borrow_type] ? this.typeObj[list.borrow_type].label : list.borrow_type
              list.status = this.statusObj[list.status] ? this.statusObj[list.status].label: list.status
              list.is_real_order_tx = list.is_real_order ? '资金借贷订单' : '复投虚拟订单'
              list.created_at = this.dataFormat(list.created_at)
              list.start_at = this.dataFormat(list.start_at)
              list.end_at = this.dataFormat(list.end_at)
            })

            this.$router.replace({path: 'personalLendingDetails',query: query})
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>