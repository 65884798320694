<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">团队长钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <!-- <div class="com_item_search">
        <div class="com_item_tx">管理级别</div>
        <div class="com_item_input">
          <el-select v-model="selectVal1" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, index) in levelArr" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>

        </div>
      </div> -->

      <!-- <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div> -->


      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>個人錢包ID</th>
          <th>所屬上級</th>
          <th>管理級別</th>
          <th>個人業績</th>
          <th>直推人數</th>
          <th>團隊總人數</th>
          <th>團隊業績</th>
          <th>小區業績</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{list.user_id}}</td>
            <td>{{list.parent_id}}</td>
            <td>{{list.level}}</td>
            <td>{{list.self_amount}}</td>
            <td class="tab_btn">
              <span class="btn" @click="showDetail(1, list.user_id)" v-if="list.next_numb > 0">{{list.next_numb}}</span>
              <span v-else>0</span>
            </td>
            <td class="tab_btn">
              <span class="btn" @click="showDetail(2, list.user_id)" v-if="list.team_numb > 0">{{list.team_numb}}</span>
              <span v-else>0</span>
            </td>
            <td>{{list.team_amount}}</td>
            <td>{{ list.community_usdt }}</td>
          </tr>
          
        </tbody>
      </table>
    </div>
    <!-- <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div> -->

    <el-dialog v-model="isShow" :title="titleModal" :width="1000">
      <div class="com_from_box">
        <div>
          <table class="com-table mt_40">
            <thead class="com_thead">
              <th>序號</th>
              <th>個人錢包ID</th>
              <th>個人認購總額</th>
            </thead>
            <tbody class="com_tbody">
              <tr v-for="(list, index) in resDataDetail" :key="list.id">
                <td>{{ (page - 1) * size + 1 + index }}</td>
                <td>{{list.user_id}}</td>
                <td>{{list.self_amount}}</td>
              </tr>
              <tr v-show="resDataDetail.length">
                <td>總計</td>
                <td></td>
                <td>{{scientificNotationToString(sumDetail.self_amount)}}</td>
              </tr>
            </tbody>
          </table>
          <div class="com_page">
            <el-pagination @size-change="handleSizeChangeDetail" @current-change="pageFnDetail" :current-page="page_detail"
              :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size_detail" layout="total, prev, pager, next, sizes" :total="total_detail">
            </el-pagination>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="isShow = false">確認</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "personalLendingReport",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      // balanceRangeInfo: "",
      // balanceRangeStatus: "",
      inputVal1: "",
      // inputVal2: "",
      // inputVal3: "",
      // selectVal1: "",
      // selectVal2: "",
      // selectVal3: "",
      // selectVal4: "",
      // minNum: "",
      // maxNum: "",
      // coinInfo: [],
      // tx_id: "",
      tHeader: [],
      subtotal: {},
      // other_total: {},
      // usd_total: {},
      // startTime: '',
      // endTime: '',
      sum: {},
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      filterVal: [
        "user_id",
        "parent_id",
        "level",
        "self_amount",
        "next_numb",
        "team_numb",
        "team_amount",
        "community_usdt"
      ],
      titleModal: "",
      isShow: false,
      resDataDetail: [],
      typ: 0,
      page_detail: 1,
      size_detail: 100,
      sumDetail: {},
      total_detail: '',
    };
  },
  created() {
    this.page = this.$route.query.page ? parseFloat(this.$route.query.page) : this.page
    this.size = this.$route.query.size ? parseFloat(this.$route.query.size) : this.size
    this.startTime = this.$route.query.start || this.startTime ? this.$route.query.start || this.startTime : ''
    this.endTime = this.$route.query.end || this.endTime ? this.$route.query.end || this.endTime : ''
    if (this.$route.query.team_wallet_id || this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.team_wallet_id

      this.resInit();
    }
    // this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showDetail(type, id) {
      this.isShow = true;
      switch(type) {
        case 1:
          this.titleModal = `${id}直推人數列表`
          this.typ = 1
          break;
        case 2:
          this.titleModal = `${id}團隊列表`
          this.typ = 2
          break;
      }
      this.page_detail = 1
      this.size_detail = 100
      this.resFnDetail()
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      if (this.inputVal1) {
        this.resInit();
      }
    },
    pageFn(index) {
      this.page = index;
      if (this.inputVal1) {
        this.resInit();
      } else {
        this.$message.error("請輸入錢包ID");
      }
    },
    handleSizeChangeDetail(val) {
      this.page_detail = 1;
      this.size_detail = val;
      this.resFnDetail()
    },
    pageFnDetail(index) {
      this.page_detail = index;
      this.resFnDetail()
    },
    resFnDetail() {
      this.resDataDetail = [];
      let req = {
        page: this.page_detail,
        size: this.size_detail,
        typ: this.typ,
        wallet_id: this.inputVal1
      };
      this.$ajax(`${this.Utils.URL.goURL}members_detail`, req, "get")
        .then((res) => {
          this.resDataDetail = res.list ? res.list : [];
          this.total_detail = parseFloat(res.total);
          this.sumDetail = res.sum ? res.sum : {}
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }

      // if (this.inputVal3) {
      //   req.hash = this.inputVal3;
      // }
      // if (this.selectVal1) {
      //   req.level = this.selectVal1;
      // }
      // if (this.selectVal2) {
      //   req.tx_type = this.selectVal2;
      // }
      // if (this.selectVal3) {
      //   req.chain_type = this.selectVal3;
      // }
      // if (this.selectVal4) {
      //   req.symbol = this.selectVal4;
      // }
      // if (this.startTime) {
      //   req.start = this.startTime
      // }
      // if (this.endTime) {
      //   req.end = this.endTime
      // }
      // let query = Object.assign({}, req)
      this.$ajax(`${this.Utils.URL.goURL}members_detail`, req, "get")
        .then((res) => {
          

          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          // this.other_total = res.other_total ? res.other_total : {}
          // this.usd_total = res.usd_total ? res.usd_total : {}
          this.resData.forEach((list) => {
            list.level = this.levelArr[list.level]
          })
          // this.$router.replace({path: 'personalLendingReport',query: query})
        })
        .catch((res) => {
          if (!res.data.result) this.$message.error('錢包ID不存在');
          // this.$message.error(res.msg);
        });
    },
  },
  watch: {
    $route(next,old) {
      if (next.path == old.path) {
        window.scrollTo(0, 0)
        if (this.inputVal1 != this.$route.query.team_wallet_id) {
          this.inputVal1 = this.$route.query.team_wallet_id
          this.resInit()
        }
      }

    }
  }
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
.com-table .com_tbody .tab_btn .btn {
  margin-right: 0;
  text-decoration: underline;
}
</style>