<template>
  <article class="">
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          用户地址
        </div>
        <div class="com_from_right">
          <el-input
            v-model="userAddr"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          币种
        </div>
        <div class="com_from_right">
          <el-select
            v-model="coinVal"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in options2"
                       :key="item.addr"
                       :label="item.symbol"
                       :value="item.addr">
            </el-option>

          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          增加数量
        </div>
        <div class="com_from_right">
          <el-input
            v-model="amount"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
            v-model="authKey"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">用户地址</div>
        <div class="com_item_input">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
            v-model="selectVal1"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in options2"
                       :key="item.addr"
                       :label="item.symbol"
                       :value="item.symbol">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
            v-model="startTime"
            type="datetime"
            size="small"
            format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
            v-model="endTime"
            type="datetime"
            size="small"
            format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>地址</th>
        <th>币种</th>
        <th>数量</th>
        <th>状态</th>
        <th>创建时间</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ page == 1 ? index + 1 : index == 0 ? ((page - 1) * 10) + 1 : ((page - 1) * 10) + index + 1 }}</td>
          <td>{{ list.img_addr }}</td>
          <td>{{ list.symbol }}</td>
          <td>{{ list.amount }}</td>
          <td>{{ list.tl_tx_hash == 1 ? '未上账' : '已上账' }}</td>
          <td>{{ dataFormat(list.created_at) }}</td>
        </tr>

        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
        layout="pager"
        :total="total"
        :current-page="page"
        @current-change="pageFn"
      >
      </el-pagination>
    </div>


  </article>
</template>

<script>
export default {
  name: "assetsAdjust",
  data() {
    return {
      options2: [],
      options3: [
        {
          id: 1,
          label: '蜂鸟上报'
        },
        {
          id: 2,
          label: '余额符合要求'
        },
        {
          id: 3,
          label: '后台创建'
        }
      ],
      resData: [{}],
      selectArr: [],
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      isAjax: false,
      account: '',
      password: '',
      curIndex: 0,
      page: 1,
      size: 10,
      total: '',
      coinVal: '',
      amount: '',
      userAddr: '',
      authKey: '',
      startTime: '',
      endTime: '',
      option1: [{value: '1', label: '是'}, {value: '0', label: '否'}],
    }
  },
  created() {
    this.resInit()
    if (!window.sessionStorage.getItem('coinList')) {
      this.coinListFN()
    } else {
      this.options2 = JSON.parse(window.sessionStorage.getItem('coinList'))
    }
  },
  methods: {
    coinListFN() {

      let req = {}
      this.$ajax(`${this.Utils.URL.goURL}coin/list`, req, 'get')
        .then(res => {
          this.options2 = res ? res.list : []
          window.sessionStorage.setItem('coinList', JSON.stringify(this.options2))
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
    submitFn() {
      if (this.amount === '') {
        this.$message.error('请输入数量')
        return false
      }
      if (this.userAddr === '') {
        this.$message.error('请输入用户地址')
        return false
      }
      if (this.coinVal === '') {
        this.$message.error('请选择币种')
        return false
      }
      if (this.authKey === '') {
        this.$message.error('请输入验证码')
        return false
      }
      if (this.isAjax) {
        return false
      }
      let req = {
        addr: this.userAddr,
        contract_addr: this.coinVal,
        amount: this.amount,
        two_auth_code: this.authKey,
      }
      this.isAjax = true
      let url = 'add_addr_amount'
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
        .then(res => {
          this.userAddr = ''
          this.coinVal = ''
          this.amount = ''
          this.authKey = ''
          this.isAjax = false
          this.$message.success('增加成功')
          this.resInit()
        })
        .catch(res => {
          this.isAjax = false
          this.$message.error(res.msg)
        })
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.symbol = this.selectVal1
      }
      if (this.startTime) {
        req.begin = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      if (this.inputVal1) {
        req.addr = this.inputVal1
      }
      this.$ajax(`${this.Utils.URL.goURL}add_addr_amount_list`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped>

</style>