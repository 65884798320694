<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <section class="com_search_box">

      <div class="com_item_search">
        <el-button type="primary"  size="small" @click="singFn('')">创建系统保证金钱包地址</el-button>
      </div>
    </section>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>系统保证金合约地址</th>
        <th>关联多签地址</th>
        <th>链类型</th>
        <th>私钥状态</th>
        <th>合约状态</th>
        <th>资产</th>
        <th>生效时间</th>
        <th>创建时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{list.addr}}</td>
          <td>
            <p v-for="addr in list.signer_addrs">{{addr}}</p>
          </td>
          <td>{{list.chain_type}}</td>
          <td>{{list.pri_enabled ? '正常' : '未录入'}}</td>
          <td>{{typeObj[list.status] ? typeObj[list.status].label : list.status}}</td>
          <td>
            <p v-for="fin in list.fins">{{fin.symbol}}: {{fin.amount}}</p>
          </td>
          <td>{{list.publish_at}}</td>
          <td>{{list.created_at}}</td>
          <td class="tab_btn">
            <span class="btn" @click="activeAddrFn(index)">设为当前系统保证金地址</span>
            <span class="btn" v-show="list.status == 0" @click="addAddrFn(index)">发布合约</span>
            <span class="btn" @click="singFn(index)">重传私钥</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curIndex === '' ? '创建系统保证金钱包地址' : '重传私钥'" :width="750">
      <div class="com_search_box">
        <div class="com_item_search">
          <div class="com_item_tx">链类型</div>
          <div class="com_item_input mr_10" style="width: 550px">
            <el-select
                v-model="chain_type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in chainTypeArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"

              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">公钥</div>
          <div class="com_item_input mr_10" style="width: 550px">
            <el-input
                v-model="keyR.pub_key"
                placeholder=""
                :readonly="true"
                rows="7"
                type="textarea"
                size="small">
            </el-input>
          </div>
          <span class="color_T1 cur_po" @click="copyText(keyR.pub_key)">复制</span>
        </div>
        <div class="com_item_search" v-for="(list, key) in keysArr">
          <div class="com_item_tx">秘钥密文({{key + 1}})</div>
          <div class="com_item_input" style="width: 550px">
            <el-input
                v-model="list.val"
                placeholder="请输入"
                rows="7"
                type="textarea"
                size="small">
            </el-input>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">私钥</div>
          <div class="com_item_input mr_10" style="width: 550px">
            <el-button type="primary"  @click="addKeyFn">增加私钥</el-button>
          </div>

        </div>

        <div class="com_item_search">
          <div class="com_item_tx">谷歌验证码</div>
          <div class="com_item_input">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary"  @click="submitKeyFn">提交</el-button>
              </span>
      </template>
    </el-dialog>
    <el-dialog v-model="isActiveAlert" title="设置系统保证金合约地址" width="500px">
      <section class="com_from_box">
        <h2>确定将所选地址设置为该链最新系统保证金合约地址?</h2>
        <div class="com_from_item mt_40">
          <div class="com_from_left">是否启用</div>
          <div class="com_from_right">
            <el-select
                v-model="is_enable"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  label="是"
                  :value="true"
              ></el-option>
              <el-option
                  label="否"
                  :value="false"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="com_from_item mt_40">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">温馨提示:设置成功后现用地址将停用!并请同时设置其他链系统保证金合约地址,以保证不同链使用的私钥一致!</div>


        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isActiveAlert = false">取消</el-button>
                    <el-button type="primary" @click="submitActiveAddrFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
    <el-dialog v-model="isAddAlert" title="发布多签合约" width="500px">
      <section class="com_from_box">

        <div class="com_from_item">
          <div class="com_from_left">链类型</div>
          <div class="com_from_right">
            <el-select
                v-model="chain_type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
                :disabled="true"
            >
              <el-option
                  v-for="item in chainTypeArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"

              ></el-option>
            </el-select>
          </div>
        </div>


        <div class="com_from_item">
          <div class="com_from_left">钱包地址</div>
          <div class="com_from_right" style="width: 550px">
            {{gasPriceData.addr}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">钱包余额</div>
          <div class="com_from_right" style="width: 550px">
            {{gasPriceData.bal}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">预计矿工费</div>
          <div class="com_from_right" style="width: 550px">
            {{gasPriceData.create_pool}}
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">温馨提示:创建系统保证金合约需要1分钟左右的时间,提交信息后请耐心等待,稍后刷新页面查看,同时请保证地址内的矿工费充足!</div>
          <div class="com_from_right">

          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAddAlert = false">取消</el-button>
                    <el-button type="primary" @click="submitAddrFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "securityDepositAddress",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      address: '',
      chain_type: '',
      two_auth_code: '',
      resData: [],
      typeObj: {
        0: {label: '未创建合约', value: '0'},
        1: {label: '使用中', value: '1'},
        4: {label: '已失效', value: '4'},
        5: {label: '未启用', value: '5'},
      },
      keyR: {},
      curIndex: '',
      isActiveAlert: false,
      isAddAlert: false,
      isAlert: false,
      gasPriceData: {},
      keysArr: [],
      isTransctionAlert: false,
      toAddr: '',
      amount: '',
      coin: '',
      options2: [],
      coinArr: [],
      toAddrArr: [],
      is_enable: false,
      coinVal: '',
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },
    resInit () {
      let url = 'sign/pledge_list'
      let req = {
        page: this.page,
        size: this.size,
      }

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    activeAddrFn (index) {
      this.curIndex = index
      this.two_auth_code = ''
      this.is_enable = this.resData[this.curIndex].status == 1 ? true : false
      this.isActiveAlert = true
    },
    submitActiveAddrFn () {
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      if  (parseFloat(this.gasPriceData.create_pool) > parseFloat(this.gasPriceData.bal)) {
        this.$message.error('矿工费不足')
        return false
      }
      let url = 'sign/pledge_enable'
      let req = {
        is_enable: this.is_enable,
        two_auth_code: this.two_auth_code,
        wallet_id: this.resData[this.curIndex].id,
      }
      this.$confirm(`是否设置当前系统保证金地址`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('成功')
              this.isActiveAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    addAddrFn (index) {
      this.curIndex = index
      this.chain_type = this.resData[index].chain_type
      this.two_auth_code = ''
      this.gasPriceData = {}
      this.gasPoolPriceFn()
      this.isAddAlert = true
    },
    submitAddrFn () {

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      let url = 'sign/create_pledge'
      let req = {
        chain_type: this.chain_type,
        two_auth_code: this.two_auth_code,
        wallet_id: this.resData[this.curIndex].id,
      }
      this.$confirm(`是否发布多签合约`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('成功')
              this.isAddAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    gasPoolPriceFn() {
      let req = {
        chain_type: this.resData[this.curIndex].chain_type,
        create_sys_pledge: this.resData[this.curIndex].chain_type,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/pool_fee_info`, req, 'get')
          .then(res => {
            this.gasPriceData = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    addKeyFn () {
      this.keysArr.push({
        val: ''
      })
    },
    singFn (index) {
      this.isAlert = true
      this.curIndex = index
      this.keysArr = []
      this.two_auth_code = ''
      if (index !== '') {
        this.chain_type = this.resData[index].chain_type
      } else {
        this.chain_type = ''
      }
      let req = {
      }
      this.$ajax(`${this.Utils.URL.goURL}sign/public_key`, req, 'get')
          .then(res => {
            this.keyR = res ? res : {}

          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitKeyFn() {
      if (!this.keysArr.length) {
        this.$message.error('请填写私钥')
        return false
      }



      if (this.chain_type === '') {
        this.$message.error('请选择链类型')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let arr = []
      this.keysArr.forEach(val => {
        arr.push(val.val)
      })
      let url = 'sign/upload_key_pledge'
      let req = {
        id: this.keyR.id,
        keys: arr,
        two_auth_code: this.two_auth_code,
        chain_type: this.chain_type,
      }
      if (this.curIndex !== '') {
        req.wallet_id = parseFloat(this.resData[this.curIndex].id)
      }
      this.$confirm(`${this.curIndex === '' ? '是否创建系统保证金钱包地址' : '是否重传私钥'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post', 2500000, false, true)
            .then(res => {
              this.$message.success('成功')
              this.isAlert = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    submitFn() {
      if (this.chain_type === '') {
        this.$message.error('请选择链类型')
        return false
      }

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      let url = 'sign/create_pledge'
      let req = {
        chain_type: this.chain_type,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否创建系统保证金钱包', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('创建成功')
              this.chain_type = ''
              this.two_auth_code = ''
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    }
  },
}
</script>

<style scoped>

</style>