<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <!-- <div class="com_item_search">
        <div class="com_item_tx">鏈類別</div>
        <div class="com_item_input">
          <el-select v-model="chain_type" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in chainTypeList" :key="key" :label="item.label" :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">狀態</div>
        <div class="com_item_input">
          <el-select v-model="status" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="(item, key) in statusList" :key="key" :label="item.label" :value="key">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <!-- <div class="com_search_box">
        <el-button
            type="primary"
            @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
        >
          导出excel（本页）
        </el-button>
      </div> -->

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>錢包ID</th>
          <th>會員已領取元寶數量</th>
          <th>已申請兌換黃金克數</th>
          <th>已結算元寶數量</th>
          <th>操作</th>
          <th>結算時間(PST)</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <!-- user_id 1 ，ingots_num 2，GoldNum 3，settlement_at 5 -->
            <td>{{list.user_id}}</td>
            <td>{{list.ingots_num}}</td>
            <td>{{list.GoldNum}}</td>
            <td>{{ list.settlement_num }}</td>
            <td>
              <p @click="openDialog(list)" class="link">添加記錄</p>
            </td>
            <td>{{list.settlement_at}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

    <el-dialog v-model="isShow" :title="`添加${currentID} 兌換記錄`" :width="800">
      <div class="com_from_box">
        <p style="margin-bottom: 20px;"><strong>用戶當前可領取金元寶： {{ currentNum }}</strong></p>
        <div class="com_from_item">
          <div class="com_from_left">提領克數</div>
          <div class="com_from_right">
            <el-radio-group v-model="type_withdraw">
              <el-radio-button label="15" />
              <el-radio-button label="35" />
            </el-radio-group>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" placeholder="请输入" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShow = false">取消</el-button>
          <el-button type="primary" @click="submitFn">確認</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>
  
<script>
export default {
  name: "gold-record",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      wallet_id: '',
      startTime: '',
      endTime: '',
      // chain_type: '',
      // chainTypeList: {
      //   1: { label: 'SCF(MAIN)', value: '1' },
      //   2: { label: 'BTC', value: '2' },
      //   3: { label: 'ETH', value: '3' },
      //   4: { label: 'BNB', value: '4' },
      // },
      // status: '',
      // statusList: {
      //   1: { label: '抵押中', value: '1' },
      //   2: { label: '抵押申請中', value: '2' },
      //   3: { label: '已完成', value: '3' },
      //   4: { label: '抵押廣播失敗', value: '4' },
      //   5: { label: '資金廣播失敗', value: '5' },
      // },
      // subtotal: {},
      // sum: {},
      // tHeader: [],
      // filterVal: [
      //   "tx_time",
      //   "tx_type",
      //   "chain_type",
      //   "symbol",
      //   "numb",
      //   "user_id",
      //   "addr",
      //   "wallet_addr",
      //   "hash",
      // ],
      isShow: false,
      currentID: '',
      currentNum: '',
      type_withdraw: '15',
      two_auth_code: '',
    };
  },
  created() {
    this.resInit();
  },
  // mounted() {
  //   document.querySelector(".com_thead").childNodes.forEach((item) => {
  //     if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
  //     this.tHeader.push(item.innerText);
  //   });
  // },
  methods: {
    submitFn() {
      if (!this.two_auth_code) {
        this.$message.error("请输入谷歌验证码");
        return;
      }
      let req = {
        user_id: this.currentID,
        gold_num: +this.type_withdraw,
        two_auth_code: this.two_auth_code,
      };
      this.$ajax(`${this.Utils.URL.goURL}addExchangeRecord`, req, "post")
        .then((res) => {
          this.$message.success(res.msg);
          this.isShow = false;
          this.resInit();
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    openDialog(item) {
      this.two_auth_code = '';
      this.isShow = true;
      this.currentID = item.user_id;
      this.currentNum = item.ingots_num;
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id) {
        req.user_id = this.wallet_id;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}zqActivityList`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.resData.forEach((list) => {
              
              list.settlement_at = +list.settlement_at > 0 ? this.dataFormat(list.settlement_at) : '-';

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>
  
<style scoped>
.link {
  color: #1e98d7;
  cursor: pointer;
}
</style>