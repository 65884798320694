<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">商户钱包ID</div>
        <div class="com_item_input">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">OTC钱包地址</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal2"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">商户名</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal3"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">商户状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                label="正常"
                :value="true"
            >
            </el-option>
            <el-option
                label="禁用"
                :value="false"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">接单状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                label="开启"
                :value="true"
            >
            </el-option>
            <el-option
                label="关闭"
                :value="false"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>

    <div class="tab_box mt_40">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>商户钱包ID</th>
        <th>商户名</th>
        <th>链类型</th>
        <th>OTC钱包地址</th>
        <th>OTC钱包总额</th>
        <th>可用余额</th>
        <th>卖币订单</th>
        <th>保证金余额</th>
        <th>充值总额</th>
        <th>已卖出总额</th>
        <th>商户状态</th>
        <th>接单状态</th>
        <th>联系人</th>
        <th>电话</th>
        <th>开通OTC时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td :rowspan="list.row " v-show="list.isShow">{{ (page - 1) * size + 1 + list.index }}</td>
          <td :rowspan="list.row " v-show="list.isShow">{{ list.user_id }}</td>
          <td :rowspan="list.row " v-show="list.isShow">{{ list.merchant_nick }}</td>
          <td>{{list.chain_type}}</td>
          <td>{{list.sell_contract_addr}}</td>
          <td>{{list.otc_amount}} {{list.symbol}}</td>
          <td>{{list.curr_amount	}} {{list.symbol}}</td>
          <td>{{list.sell_order_amount	}} {{list.symbol}}</td>
          <td>{{list.bond_amount	}} {{list.symbol}}</td>
          <td>{{list.recharge_amount	}} {{list.symbol}}</td>
          <td>{{list.sell_amount	}} {{list.symbol}}</td>

          <td :rowspan="list.row " v-show="list.isShow">{{ list.merchant_status ? '正常' : '禁用' }}</td>
          <td :rowspan="list.row " v-show="list.isShow">{{ list.is_market ? '开启' : '关闭' }}</td>
          <td :rowspan="list.row " v-show="list.isShow">{{ list.name }}</td>
          <td :rowspan="list.row " v-show="list.isShow">+{{ list.area_code }}-{{list.phone}}</td>
          <td :rowspan="list.row " v-show="list.isShow">{{ list.created_at }}</td>
          <td class="tab_btn" :rowspan="list.row " v-show="list.isShow">
            <span class="btn"  @click="goRouter('merchantDetails', {id: list.id})">详情</span>

          </td>
        </tr>



        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>



  </article>
</template>

<script>
export default {
  name: "merchantList",
  data() {
    return {
      options2: [],
      resData: [],
      selectArr: [],
      selectVal1: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      curIndex: 0,
      curType: '',
      page: 1,
      size: 10,
      total: '',
      totalUser: '',
      selectVal2: '',
      startTime: '',
      endTime: '',
      option1: [],
      option2: [],
      tHeader: [],
      period_type: '',
      level: '',
      isAlert: false,
      showEdit: false,
      alertData: {},
      new_parent_id: '',
      childrenList: [],
      childrenArr: [],
      edit_type: '',
      two_auth_code: '',
      pageUser: 1,
      sizeUser: 10,
      filterVal: [
        "user_id",
        "level",
        "parent_id",
        "chain_type",
        "addr",
        "created_at",
      ],
    }
  },
  created() {

    this.resInit()
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {


    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },

    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1
      }
      if (this.inputVal2) {
        req.otc_addr = this.inputVal2
      }
      if (this.inputVal3) {
        req.merchant_name = this.inputVal3
      }
      if (this.selectVal1) {
        req.is_open = this.selectVal1
      }
      if (this.selectVal2) {
        req.is_market = this.selectVal2
      }

      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}buy/merchant_list`, req, 'get')
        .then(res => {
          let arr = res.list ? res.list : []
          let arrs = []
          arr.forEach((list, index) => {
            let ist = true
            let arrx = {}
            arrx = Object.assign({},list)
            arrx.area_code = list.area_code
            arrx.id = list.id
            arrx.is_market = list.is_market
            arrx.merchant_nick = list.merchant_nick
            arrx.merchant_status = list.merchant_status
            arrx.name = list.name
            arrx.phone = list.phone
            arrx.user_id = list.user_id
            arrx.created_at = this.dataFormat(list.created_at)
            arrx.bond_amount = ''
            arrx.chain_type = ''
            arrx.curr_amount = ''
            arrx.otc_amount = ''
            arrx.recharge_amount = ''
            arrx.sell_contract_addr = ''
            arrx.sell_order_amount = ''
            arrx.sell_amount = ''
            arrx.symbol = ''
            arrx.index = index
            arrx.row = list.quota_list.length ? list.quota_list.length : 1
            if (list.quota_list.length) {
              list.quota_list.forEach(val => {
                arrx = Object.assign({},arrx)
                if (ist) {
                  arrx.isShow = true
                  ist = false
                } else {
                  arrx.isShow = false

                }

                arrx.bond_amount = val.bond_amount
                arrx.chain_type = val.chain_type
                arrx.curr_amount = val.curr_amount
                arrx.otc_amount = val.otc_amount
                arrx.recharge_amount = val.recharge_amount
                arrx.sell_contract_addr = val.sell_contract_addr
                arrx.sell_order_amount = val.sell_order_amount
                arrx.sell_amount = val.sell_amount
                arrx.symbol = val.symbol
                arrs.push(arrx)
              })
            } else {
              arrx.isShow = true
              arrs.push(arrx)
            }

          })
          this.resData = arrs
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  },
}
</script>

<style scoped>

</style>