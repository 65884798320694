<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box" >
      <div class="com_item_search">
        <el-button type="primary" size="small" v-if="isEditButton" @click="goRouter('/appIconListEdit')">新增图标</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>链类型</th>
        <th>标题</th>
        <th>图标</th>
        <th>角标图标</th>
        <th>中文链接</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ page == 1 ? index + 1 : index == 0 ? ((page - 1) * 10) + 1 : ((page - 1) * 10) + index + 1 }}</td>
          <td>{{list.chain_type}}</td>
          <td>
            <!-- <p v-if="!list.title_list.length">{{ list.title_zh }}</p>
            <p v-if="list.title_list.length">{{ list.title_list[0].value }}</p> -->
            <p>{{ showZhContent(list.title_list)}}</p>
          </td>
          <td>
            <img  width="50" :src="Utils.URL.goURL + list.image" class="avatar">

          </td>
          <td>
            <img v-show="list.market_image" width="50" :src="Utils.URL.goURL + list.market_image" class="avatar">

          </td>
          <td>
            <!-- <p v-if="!list.title_list.length">{{ list.title_zh }}</p>
            <p v-if="list.title_list.length">{{ list.title_list[0].link }}</p> -->
            <p> {{ showZhLink(list.title_list) }}</p>
          </td>
          <td class="tab_btn">
            <span class="btn" v-if="isEditButton"  @click="goDetails(index)">编辑</span>
            <span class="btn" v-if="isDelButton" @click="delFn(index)">删除</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          layout="pager"
          :total="total"
          :current-page="page"
          @current-change="pageFn"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "bannerList",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      startTime: '',
      endTime: '',
      selectArr: [{value: '钱包', label: '钱包'}]
    }
  },
  created() {

    this.resInit()
  },
  methods: {
    showZhContent(val) {
      // filter zh
      let zh = val.filter(item => item.type === 'zh')
      return zh.length ? zh[0].value : ''
    },
    showZhLink(val) {
      // filter zh
      let zh = val.filter(item => item.type === 'zh')
      return zh.length ? zh[0].link : ''
    },
    goDetails(index) {
      window.localStorage.setItem('listDataInfo', JSON.stringify(this.resData[index]))
      this.goRouter('appIconListEdit', {type: 'edit'})
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.port_id = this.selectVal1
      }
      if (this.selectVal2) {
        req.one_type = this.selectVal2
      }
      if (this.selectVal3) {
        req.two_type = this.selectVal3
      }
      if (this.inputVal1) {
        req.title = this.inputVal1
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}app_home_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
            this.showZhContent(this.resData[0].title_list)
            this.showZhLink(this.resData[0].title_list)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    delFn(index) {
      this.curIndex = index
      this.$confirm('是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}delete_app_home`, {
          id: this.resData[index].id
        }, 'post')
            .then(res => {
              this.resData.splice(index, 1)
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },

  }

}
</script>

<style scoped>

</style>