<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">借款状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                :label="'是'"
                :value="true"
            >
            </el-option>
            <el-option
                :label="'否'"
                :value="false"
            >
            </el-option>
          </el-select>

        </div>
      </div>



      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>

    <section class="com_search_box" >

      <div class="com_item_search">
        <el-button type="primary" @click="showFn('')"  size="small" >新增借款订单</el-button>
      </div>
    </section>



    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>链类型</th>
        <th>借款币种</th>
        <th>自动借款状态</th>
        <th>借款金额范围</th>
        <th>借款金额系数</th>
        <th>日期</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.chain_type}}</td>
          <td>{{list.coin_symbol}}</td>
          <td>{{list.auto_borrow ? '开启' : '关闭'}}</td>
          <td>{{ list.rate}}</td>
          <td>{{list.coefficient}}</td>
          <td>{{(list.created_at)}}</td>
          <td class="tab_btn">
            <span class="btn" @click="showFn(index)" >设置</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isShow" :title="curIndex === '新建借款订单' ? '' : '编辑借款订单'" width="500px">
      <section class="com_from_box">

        <div class="com_from_item">
          <div class="com_from_left">链类型</div>
          <div class="com_from_right">
            <el-select
                v-model="chainVal"
                clearable
                filterable
                placeholder="请选择"
                size="small"
                @change="coinListFN"
            >
              <el-option label="bsc" value="bsc">bsc</el-option>
              <el-option label="tron" value="tron">tron</el-option>
              <el-option label="eth" value="eth">eth</el-option>
            </el-select>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">币种</div>
          <div class="com_from_right">
            <el-select
                v-model="coin_id"
                clearable
                filterable
                placeholder="请选择"
                @change="addrFn"
                size="small">
              <el-option v-for="item in options2"
                         :key="item.id"
                         :label="item.symbol"
                         :value="item.id">
              </el-option>

            </el-select>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">自动借款</div>
          <div class="com_from_right">
            <el-radio-group v-model="auto_borrow" class="ml-4">
              <el-radio :label="true" size="large">开启</el-radio>
              <el-radio :label="false" size="large">关闭</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">每笔借款金额</div>
          <div class="com_from_right">
            <div class="d_flex_1">
              <el-input
                  v-model="amount_min"
                  type="text"
                  placeholder="请输入"
                  size="small"
              ></el-input>
              <span>-</span>
              <el-input
                  v-model="amount_max"
                  type="text"
                  placeholder="请输入"
                  size="small"
              ></el-input>
            </div>
            <p>请输入{{base_amount}}倍数</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">借款金额系数</div>
          <div class="com_from_right">
            <div class="d_flex">
              <el-input
                  v-model="coefficient"
                  type="text"
                  placeholder="请输入"
                  size="small"
              ></el-input>
            </div>
            <p>请输入0-1范围值</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">借款周期</div>
          <div class="com_from_right">
            1天
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">借款地址</div>
          <div class="com_from_right">
            {{ borrow_addr }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isShow = false">取消</el-button>
                    <el-button type="primary" @click="submitFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "oneDayInstitutionalSetting",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      tx_id: "",
      tHeader: [],
      subtotal: {},
      isAlert: false,
      ticket_num: '',
      wallet_id: '',
      two_auth_code: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      typeObj: {
        1: {label: '合伙人赠送理财券', value: '1'},
        3: {label: '系统派发理财券', value: '3'},
        4: {label: '获得他人赠送理财券', value: '4'},
        5: {label: '使用理财券', value: '5'},
        6: {label: '赠给他人理财券', value: '6'},
        7: {label: '失效扣除理财券', value: '7'},
        8: {label: '活动赠送理财券', value: '8'},
        9: {label: '管理后台赠送理财券', value: '9'},
        10: {label: '活动获赠理财券并自动使用', value: '10'},
        11: {label: '后台移除理财券', value: '11'},
        12: {label: '新用户注册获得理财券', value: '12'},
      },
      filterVal: [
        "chain_type",
        "coin_symbol",
        "auto_borrow",
        "rate",
        "coefficient",
        "created_at",
      ],
      ticketInfo: [],
      coinInfo: [],
      options2: [],
      isShow: false,
      curIndex: '',
      amount_max: '',
      amount_min: '',
      auto_borrow: '',
      coefficient: '',
      coin_id: '',
      time_max: '',
      time_min: '',
      borrow_addr: '',
      chainVal: '',
      base_amount: '',
    };
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.resInit();

  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showFn (index) {
      this.curIndex = index
      this.two_auth_code = ''
      if (index === '') {
        this.chainVal = ''
        this.amount_max = ''
        this.amount_min = ''
        this.auto_borrow = ''
        this.coefficient = '0.001'
        this.coin_id = ''
        this.time_max = ''
        this.time_min = ''
        this.borrow_addr = ''

      } else {
        this.amount_max = this.resData[index].amount_max
        this.chainVal = this.resData[index].chain_type
        this.amount_min = this.resData[index].amount_min
        this.auto_borrow = this.resData[index].auto_borrow
        this.coefficient = this.resData[index].coefficient
        this.coin_id = this.resData[index].coin_id
        this.time_max = this.resData[index].time_max
        this.time_min = this.resData[index].time_min
        this.borrow_addr = this.resData[index].borrow_addr
        this.base_amount = this.resData[index].base_amount
        this.coinListFN()
      }
      this.isShow = true

    },
    addrFn () {
      this.$ajax(`${this.Utils.URL.goURL}organ/auto_borrow2_detail`, {
        coin_id: this.coin_id
      }, "get")
          .then((res) => {
            this.borrow_addr = res ? res.borrow_addr : ''
            this.base_amount = res ? res.base_amount : ''
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
    submitFn () {
      let amount_max = parseFloat(this.amount_max)
      let amount_min = parseFloat(this.amount_min)
      let time_max = parseFloat(this.time_max)
      let time_min = parseFloat(this.time_min)
      let base_amount = parseFloat(this.base_amount)
      if (amount_min > amount_max) {
        this.$message.error('请输入正常借款金额范围')
        return false
      }
      if (this.amount_max === '' ) {
        this.$message.error('请输入借款金额最大值')
        return false
      }
      if (this.amount_min === '' ) {
        this.$message.error('请输入借款金额最小值')
        return
      }
      if (this.auto_borrow === '' ) {
        this.$message.error('请选择借款状态')
        return false
      }
      if (this.coefficient === '' ) {
        this.$message.error('请输入金额系数')
        return false
      }
      if (this.coefficient >= 1 || this.coefficient < 0) {
        this.$message.error('请输入金额系数0-1范围值')
        return false
      }
      if (this.coin_id === '' ) {
        this.$message.error('请选择币种')
        return false
      }
      if (new window.windowBigNumber(amount_min).mod(base_amount).toString() != 0) {
        this.$message.error(`最小范围不是${base_amount}的倍数`)
        return false
      }
      if (new window.windowBigNumber(amount_max).mod(base_amount).toString() != 0) {
        this.$message.error(`最大范围不是${base_amount}的倍数`)
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

        let req = {
          amount_max: this.amount_max,
          amount_min: this.amount_min,
          auto_borrow: this.auto_borrow,
          coefficient: this.coefficient,
          coin_id: this.coin_id,
          two_auth_code: this.two_auth_code,
        }


        this.$confirm('是否提交自动借款配置', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          this.$ajax(`${this.Utils.URL.goURL}organ/edit_auto_borrow2`, req, 'post', )
              .then(res => {
                this.isShow = false
                this.resInit()
                this.$message.success('成功')
              })
              .catch(res => {
                this.$message.error(res.msg)
              })

        }).catch(() => {

        });

    },
    coinListFN() {
      let req = {
        chain_type: this.chainVal,
        is_white: true,
        size: 10000,
      }
      this.$ajax(`${this.Utils.URL.goURL}coin/list`, req, 'get')
          .then(res => {
            this.options2 = res ? res.list : []
            if (this.curIndex === '') {
              this.coin_id = ''
            }
            window.sessionStorage.setItem('coinList', JSON.stringify(this.options2))
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.chain_type = this.selectVal1;
      }
      if (this.selectVal2) {
        req.coin_symbol = this.selectVal2;
      }
      if (this.selectVal3 !== '') {
        req.auto_borrow = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/auto_borrow2`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.resData.forEach((list) => {

              list.rate = list.amount_min + '-' + list.amount_max
              list.created_at = this.dataFormat(list.created_at)

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>