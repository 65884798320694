<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">

      <div class="com_from_item">
        <div class="com_from_left">
          链类型
        </div>
        <div class="com_from_right">
          <el-select
              v-model="chain_type"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item" v-for="list in title_list">
        <div class="com_from_left">
          {{ list.type_title }}标题
        </div>
        <div class="com_from_right">
          <el-input
            v-model="list.value"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item" v-for="list in title_list">
        <div class="com_from_left">
          {{ list.type_title }}链接
        </div>
        <div class="com_from_right">
          <el-input
            v-model="list.link"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>


      <div class="com_from_item" >
        <div class="com_from_left">
          图标
        </div>
        <div class="com_from_right">
          <el-upload
            class="avatar-uploader"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-change="(val => beforeAvatarUpload('cn', index, val))"
          >
            <img v-if="imageUrl" :src="Utils.URL.goURL + imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          角标图标
        </div>
        <div class="com_from_right">
          <el-upload
              style="position: relative"
            class="avatar-uploader"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"

            :on-change="(val => beforeAvatarUpload('mar', index, val))"
          >
            <img v-if="imageUrls" :src="Utils.URL.goURL + imageUrls" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

            <i v-if="imageUrls" @click.stop="removeImgFn"  class="el-icon-plus del_img el-icon-error"></i>
          </el-upload>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          排序
        </div>
        <div class="com_from_right">
          <el-input
            v-model="sort"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
            v-model="two_auth_code"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="sumbitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "appIconListEdit",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      startTime: '',
      endTime: '',
      type: '',
      id: '',
      port_id: [],
      one_type: '',
      two_type: '',
      title_zh: '',
      title_en: '',
      link: '',
      infoData: {},
      img_list: [],
      title_list: [],
      imageUrls: '',
      imageUrl: '',
      imageUrlENs: '',
      imageUrlEN: '',
      two_auth_code: '',
      chain_type: '',
      sort: '',
    }
  },
  created() {
    this.type = this.$route.query.type
    this.options1 = JSON.parse(window.sessionStorage.getItem('portList'))
    this.options2 = JSON.parse(window.sessionStorage.getItem('oneImgClass'))

    if (this.type == 'edit') {
      this.infoData = JSON.parse(window.localStorage.getItem('listDataInfo'))

      this.id = this.infoData.id
      this.link = this.infoData.link
      this.imageUrl = this.infoData.image
      this.imageUrls = this.infoData.market_image
      this.sort = this.infoData.sort
      this.chain_type = this.infoData.chain_type

      this.img_list = this.infoData.img_list ? this.infoData.img_list : []
      this.title_list = this.infoData.title_list ? this.infoData.title_list : []
      let obj = {}
      let obj1 = {}
      let arr = []
      let arr1 = []
      this.langList.forEach((val, index) => {
        obj[val.value] = val.label
      })
      this.title_list.forEach((val, index) => {
        obj1[val.type] = val.type_title
      })
      for (let i in obj) {
        if (!obj1[i]) {
          arr.push({
            type_title: obj[i],
            type: i,
            value: ''
          })
          arr1.push({
            type_title: obj[i],
            type: i,
            value: '',
            link: '',
          })
        }
      }

      this.img_list = this.img_list.concat(arr)
      this.title_list = this.title_list.concat(arr1)

      // remove old config
      this.img_list = this.img_list.filter((item) => {
        return item.type !== 'cn' && item.type !== 'pt_pt' && item.type !== 'pt-pt' && item.type !== 'pt-br'
      })
      this.title_list = this.title_list.filter((item) => {
        return item.type !== 'cn' && item.type !== 'pt_pt' && item.type !== 'pt-pt' && item.type !== 'pt-br'
      })
      // add pt_br
      // this.img_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: ''
      // })
      // this.title_list.push({
      //   type_title: '葡萄牙语(巴西)',
      //   type: 'pt_br',
      //   value: '',
      //   link: '',
      // })

    } else {
      this.initImgList()
      this.initTypeList()
    }
  },
  methods: {
    initImgList() {
      this.langList.forEach((val, index) => {
        this.img_list[index] = {}
        this.img_list[index].type_title = val.label
        this.img_list[index].type = val.value
        this.img_list[index].value = ''
      })
    },
    initTypeList() {
      this.langList.forEach((val, index) => {
        this.title_list[index] = {}
        this.title_list[index].type_title = val.label
        this.title_list[index].type = val.value
        this.title_list[index].value = ''
        this.title_list[index].link = ''
      })
    },
    sumbitFn() {
      let isTitle = false
      let isImg = false


      if (!this.title_list.length) {
        this.$message.error('请填写标题')
        return false
      }

      /*if (this.chain_type == '') {
        this.$message.error('请选择链类型')
        return false
      }*/
      if (this.imageUrl == '') {
        this.$message.error('请上传图片')
        return false
      }
      if (this.two_auth_code == '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }


      this.title_list.forEach(val => {
        if (!val.value) {
          isImg = true
          this.$message.error(`请填写${val.type_title}标题`)
          return false
        }
        if (!val.link) {
          isImg = true
          this.$message.error(`请填写${val.type_title}链接`)
          return false
        }
      })

      if (isTitle) {
        return false
      }


      let url = this.type == 'edit' ? 'edit_home_data' : 'add_home_data'
      let req = {

        two_auth_code: this.two_auth_code,
        market_image: this.imageUrls,
        image: this.imageUrl,
        chain_type: this.chain_type,
        title_list: this.title_list,
        link: this.link,
        sort: parseFloat(this.sort),
      }
      if (this.type == 'edit') {
        req.id = parseFloat(this.id)
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post', 2500000, false, true)
        .then(res => {
          this.$message.success('操作成功,两秒后自动跳转')
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    selectFn(vals, type = 2) {
      if (!vals) {
        return false
      }
      let req = {
        type_content_id: '2'
      }
      let url = 'type_one_list'
      if (type == 2) {
        url = 'type_two_list'
        req.type_id = this.one_type
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
        .then(res => {
          let arr = type == 1 ? res ? res : [] : res.list ? res.list : []
          let arr2 = []
          arr.forEach((val, index) => {
            arr2[index] = {
              value: val.id,
              label: val.type_zh
            }
          })
          if (type == 1) {
            this.options2 = arr2
          } else {
            this.options3 = arr2

          }
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    removeImgFn () {
      this.imageUrls = ''
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(lang, index, file) {
      this.uploadFn(lang, index, file)
    },
    uploadFn(type, index, file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      const formData = new FormData()
      let str = file.name.split('.')
      let last = str[str.length - 1].toLowerCase()
      if (!/png|jpg|jpeg/.test(last)) {
        this.$message.error('上传错误,文件格式必须为：png/jpg/jpeg');
        return;
      }
      let time = parseInt(new Date().getTime())
      str = 'app_icon' + time + '.' + last
      formData.append('files', file.raw, str)
      formData.append('dir', 'appIcon')
      this.$ajax(`${this.Utils.URL.goURL}upload`, formData, 'post', 2500000, true)
        .then(res => {
          if (type == 'mar') {

            this.imageUrls = res.url

          } else {
            this.imageUrl = res.url
          }

        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
