<template>
  <article class="">
    <section class="com_from_box">

      <div class="com_from_item">
        <div class="com_from_left">
          ip白名单地址
        </div>
        <div class="com_from_right mr_10" style="width: 500px">
          <el-input
            v-model="inputVal1"
            placeholder="多个ip地址用英文逗号隔开"
            rows="7"
            type="textarea"
            size="small"
          >
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
            v-model="authKey"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

    <div class="com_search_box">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="alertShow = true">批量移除</el-button>
      </div>
    </div>

    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>勾选</th>
        <th>ip</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list">
          <td>{{ index + 1 }}</td>
          <td>
            <el-checkbox @change="checkFn(index)"></el-checkbox>
          </td>
          <td>{{ list }}</td>
        </tr>

        </tbody>
      </table>
    </div>


    <el-dialog
      v-model="alertShow"
      title="移除ip白名单"
      width="500px"
    >
      <section class="com_from_box">

        <div class="com_from_item">
          <div class="com_from_left">
            谷歌验证码
          </div>
          <div class="com_from_right">
            <el-input
              v-model="two_auth_code"
              type="text"
              placeholder="请输入"
              size="small">
            </el-input>
          </div>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="alertShow = false">取消</el-button>
          <el-button type="primary" @click="delFn"
          >提交</el-button
          >
        </span>
      </template>
    </el-dialog>


  </article>
</template>

<script>
export default {
  name: "ipWhite",
  data() {
    return {
      isAjax: false,
      alertShow: false,
      resData: [],
      postData: [],
      authKey: '',
      two_auth_code: '',
      inputVal1: '',
      endTime: '',
      option1: [{value: '1', label: '是'}, {value: '0', label: '否'}],
      checkArr: [],
    }
  },
  created() {
    this.resInit()

  },
  methods: {
    delFn(type, index) {
      this.$confirm('是否移除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        let str = ''

        this.checkArr.forEach(val => {
          if (val != null) {
            this.postData[val] = null
          }

        })
        str = (this.postData.filter(n => n)).join(',')

        this.$ajax(`${this.Utils.URL.goURL}update_ip_list`, {
          ip_list: str,
          two_auth_code: this.two_auth_code,
        }, 'post')
          .then(res => {
            this.two_auth_code = ''
            this.checkArr = []
            this.alertShow = false
            this.resInit()
            this.$message.success('移除成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      }).catch(() => {

      });

    },
    checkFn(index) {
      if (!this.checkArr[index]) {
        this.checkArr[index] = index
      } else {
        this.checkArr[index] = null
      }
    },
    submitFn() {
      if (this.inputVal1 === '') {
        this.$message.error('请输入IP地址')
        return false
      }
      if (this.authKey === '') {
        this.$message.error('请输入验证码')
        return false
      }
      if (this.isAjax) {
        return false
      }
      let str
      if (this.resData.length) {
        str = this.resData.join(',') + ','
      }
      str = str + this.inputVal1
      let req = {
        ip_list: str,
        two_auth_code: this.authKey,
      }
      this.isAjax = true
      let url = 'update_ip_list'
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
        .then(res => {
          this.isAjax = false
          this.$message.success('增加成功')
          this.resInit()
        })
        .catch(res => {
          this.isAjax = false
          this.$message.error(res.msg)
        })
    },
    resInit() {
      let req = {}

      this.$ajax(`${this.Utils.URL.goURL}get_ip_list`, req, 'get')
        .then(res => {
          this.resData = res ? res.split(',') : []
          this.postData = res ? res.split(',') : []
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped>

</style>