<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <p>卖币开关: <el-switch v-model="bankStatus" @change="showAlertFn"/> {{bankStatus ? '开启' : '关闭'}}</p>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>币种</th>
        <th>链类型</th>
        <th>当前可用余额</th>
        <th>已卖余额</th>
        <th>OTC热钱包地址</th>

        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" >
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.coin_data.code}}</td>
          <td>{{list.coin_data.type}}</td>
          <td>{{list.amount}}</td>
          <td>{{list.sales_quota}}</td>
          <td>{{list.addr}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <div class="mt_10 color_T4">
      <p>温馨提示：</p>
      <p>1、向商户的合约地址充值USDT后，会自动充值到商户的卖币可用余额中</p>
      <p>2、为避免余额不足导致无法进行卖币交易，请留意卖币合约地址内可用余额是否充足</p>
      <p>3、卖币开关关闭后，用户无法在app端下单购买代币</p>
    </div>

    <el-dialog v-model="isAlert" :show-close="false" :title="bankStatus  ? '开启卖币' : '关闭卖币'" width="500px">
      <section class="com_from_box">

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="() => {this.isAlert = false;this.bankStatus = !this.bankStatus}">取消</el-button>
                    <el-button type="primary" @click="submitFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "merchantAmountList",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      curIndex: '',
      two_auth_code: '',
      sum: {},
      bankStatus: false,
      isAlert: false,
    };
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}buy/bank_status`, {}, "get")
        .then((res) => {
          this.bankStatus = res.is_bool
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    this.resInit();
  },
  mounted() {


  },
  methods: {
    showAlertFn (index) {
      this.two_auth_code = ''
      this.isAlert = true
    },
    submitFn () {

      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }

      let req = {
        is_bool: this.bankStatus,
        two_auth_code: this.two_auth_code,
      };
      let url = 'buy/set_bank_status'

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isAlert = false;

            this.$message.success("设置成功");
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.public_addr = this.inputVal3;
      }
      if (this.inputVal4) {
        req.contract_number = this.inputVal4;
      }
      if (this.selectVal1) {
        req.borrow_type = this.selectVal1;
      }
      if (this.selectVal2) {
        req.status = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.coin_id = this.selectVal4;
      }
      if (this.selectVal6 !== '') {
        req.is_real_order = this.selectVal6
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}buy/quota_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}


          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>