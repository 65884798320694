<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">借币地址</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal1"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">合同编号</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal2"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">机构名称</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal3"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">跨链闪兑数量范围</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal4"
              placeholder="请输入最小值"
              size="small">
          </el-input>

        </div>
        <span>-</span>
        <div class="com_item_input">
          <el-input
              v-model="inputVal5"
              placeholder="请输入最大值"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">预计借贷数量</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal6"
              placeholder="请输入最小值"
              size="small">
          </el-input>

        </div>
        <span>-</span>
        <div class="com_item_input">
          <el-input
              v-model="inputVal7"
              placeholder="请输入最大值"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">投资周期</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal7"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option

                label="1天"
                value="1"
            >
            </el-option>
            <el-option

                label="7天"
                value="7"
            >
            </el-option>
            <el-option

                label="14天"
                value="14"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">订单类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal6"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                label="资金借贷订单"
                :value="true"
            >
            </el-option>
            <el-option
                label="复投虚拟订单"
                :value="false"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">合同状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal5"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in statusObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">清算</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option value="1" label="是">是</el-option>
            <el-option value="2" label="否">否</el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">swap交易</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option value="1" label="是">是</el-option>
            <el-option value="2" label="否">否</el-option>
          </el-select>
        </div>
      </div>


      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="scroll_x">
      <div class="tab_box w2100">
        <table class="com-table ">
          <thead class="com_thead">
          <th>序号</th>
          <th>借币地址</th>
          <th>共管多签地址</th>
          <th>共管资产</th>
          <th>合同编号</th>
          <th>订单类型</th>
          <th>机构名称</th>
          <th>链类型</th>
          <th>币种</th>
          <th>合同状态</th>
          <th>预计借贷数量</th>
          <th>已借贷数量</th>
          <th>保证金数量</th>
          <th>剩余保证金数量</th>
          <th>是否清算</th>
          <th>清算所得数量</th>
          <th>是否有swap交易</th>
          <th>跨链闪兑数量</th>
          <th>用户投资周期(天)</th>
          <th>日利率</th>
          <th>已付利息</th>
          <th>清算时间</th>
          <th>订单创建时间</th>
          <th>订单计息时间</th>
          <th>订单到期时间</th>
          <th>操作</th>
          </thead>
          <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="index">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.borrow_addr }}</td>
            <td>{{ list.public_addr }}</td>
            <td><p>{{list.amount_source_data.type}}-{{list.amount_source_data.code}}: {{list.amount_source_data.amount}}</p></td>
            <td>{{ list.contract_number }}</td>
            <td>{{ list.is_real_order ? '资金借贷订单' : '复投虚拟订单'}}</td>
            <td>{{ list.mechanism_name }}</td>
            <td>{{ list.chain_type }}</td>
            <td>{{ list.symbol }}</td>
            <td>{{ statusObj[list.status] ? statusObj[list.status].label : list.status }}</td>
            <td>{{ list.borrow_amount }}</td>
            <td>{{ list.curr_borrow }}</td>
            <td>{{ list.pledge_amount }}</td>
            <td>{{ list.surplus_pledge }}</td>
            <td>{{ list.is_clear ? '是' : '否' }}</td>
            <td>{{ list.clear_amount }}</td>
            <td>{{ list.is_swap ? '是' : '否' }}</td>
            <td>{{ list.flash_amount }}</td>
            <td>{{ list.duration_day }}</td>
            <td>{{ list.daily_borrow_rate }}</td>
            <td>{{ list.interest_paid }}</td>
            <td>{{ dataFormat(list.clear_at) }}</td>
            <td>{{ dataFormat(list.created_at) }}</td>
            <td>{{ dataFormat(list.start_at) }}</td>
            <td>{{ dataFormat(list.expiration_at) }}</td>
            <td class="tab_btn">
              <span class="btn" v-show="list.is_real_order" @click="goRouter('/institutionLoanAsset', {addr: list.public_addr, id: list.contract_number})">查询共管地址资产</span>
              <span class="btn" v-if="isEditButton && list.is_real_order" @click="showTransactionFn('transcation', index)">Swap兑换</span>
              <span class="btn" v-if="isEditButton && list.status == 4 && list.is_real_order || false" @click="showColdTransactionFn(index)">转账到冷钱包</span>
              <span class="btn" v-if="isEditButton && list.status == 4 && list.is_real_order " @click="showTransactionCrossFn('', index)">跨链闪兑</span>
              <span class="btn" v-show="list.is_cancel && list.is_real_order" v-if="isEditButton" @click="showDelLoaneFn(index)">取消借贷</span>
              <span class="btn" v-if="isEditButton && list.is_real_order" @click="showTransactionFn('liquidity', index)">添加流动池</span>
              <span class="btn" v-show="false">提前还款</span>
              <span class="btn" v-show="false" @click="showRateFn(index)">取消借贷</span>
              <span class="btn" @click="goRouter('/institutionLoanFundraising', {id: list.contract_number})">筹款记录</span>
              <span class="btn" @click="goRouter('/institutionLoanDeposit', {id: list.contract_number})">保证金消耗明细</span>
              <span class="btn" v-show="list.is_real_order" @click="goRouter('/institutionLoanLiquidityOrder', {contract_number: list.contract_number, chain_type: list.chain_type})">流动池列表</span>
              <span class="btn" v-show="list.is_real_order" @click="goRouter('/institutionLoanLiquidityCrossOrder', {contract_number: list.contract_number})">跨链闪兑记录</span>
              <span class="btn" v-show="false" @click="goRouter('/institutionLoanTransactionOrder', {contract_number: list.contract_number})">兑换列表</span>
            </td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td>
              <p style="white-space: nowrap;"  v-for="list in subtotal.public_all_amount">{{list.type}}-{{list.code}}: {{padRight(scientificNotationToString(list.amount), 6)}} </p>

            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(subtotal.borrow_amount)}}</td>
            <td>{{scientificNotationToString(subtotal.curr_borrow)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td>
              <p style="white-space: nowrap;"  v-for="list in other_total.public_all_amount">{{list.type}}-{{list.code}}: {{padRight(scientificNotationToString(list.amount), 6)}}</p>

            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{scientificNotationToString(sum.borrow_amount)}}</td>
            <td>{{scientificNotationToString(sum.curr_borrow)}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="payShow" title="提前还款" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">合同编号</div>
          <div class="com_from_right">
            xxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">保证金</div>
          <div class="com_from_right">
            xxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">借贷数量</div>
          <div class="com_from_right">
            xxxxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">日利率</div>
          <div class="com_from_right">
            xxxxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">借贷周期</div>
          <div class="com_from_right">
            xxxxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">总利息</div>
          <div class="com_from_right">
            xxxxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left"></div>
          <div class="com_from_right">
            <span class="color_T2">提前还款将收取借贷金额*5%的违约金，是否确定提前还款？</span>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="payShow = false">取消</el-button>
                    <el-button type="primary" >提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="coldShow" title="转账到冷钱包" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">转出地址</div>
          <div class="com_from_right">
            {{coldToAddr}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">转出金额</div>
          <div class="com_from_right">
            <el-input
                v-model="coldNum"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>

          </div>
          <span>USDT</span>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">收款地址</div>
          <div class="com_from_right">
            <el-select
                class="mr_5"
                v-model="coldAddress"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option v-for="(item, index) in coldAddressArr"
                         :key="item"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="coldShow = false">取消</el-button>
                    <el-button type="primary" @click="submitColdFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="delLoanShow" title="取消借贷" width="500px">
      <section class="com_from_box">

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="delLoanShow = false">取消</el-button>
                    <el-button type="primary" @click="submitDelLoanFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="rateShow" title="修改预计每日收益率" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">预计每日收益率</div>
          <div class="com_from_right">
            <el-input
                v-model="rateVal"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="rateShow = false">取消</el-button>
                    <el-button type="primary" @click="editRateFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
    <el-dialog v-model="transactionShow" title="兑换" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">Form{{payType == 2 ? '(预估)' : ''}}</div>
          <div class="com_from_right ">
            <div class="d_flex_1">
              <el-select
                  class="mr_5"
                  v-model="coin1"
                  clearable
                  filterable
                  placeholder="请选择"
                  size="small"
                  @change="searchCoin"
              >
                <el-option v-for="(item, index) in option2"
                           :key="item.coin_id"
                           :label="item.symbol"
                           :value="index">
                </el-option>
              </el-select>
              <el-input
                  v-model="coinVal1"
                  type="text"
                  @input="searchRateFn(1)"
                  placeholder="请输入"
                  size="small"
              ></el-input>
            </div>
            <p>余额: {{coinBalance1}} {{curCoin1.symbol}}</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">To{{payType == 1 ? '(预估)' : ''}}</div>
          <div class="com_from_right">
            <div class="d_flex_1">
              <el-select
                  class="mr_5"
                  v-model="coin2"
                  clearable
                  filterable
                  placeholder="请选择"
                  size="small"
                  @change="searchCoin"
              >
                <el-option v-for="(item, index) in option2"
                           :key="item.coin_id"
                           :label="item.symbol"
                           :value="index">
                </el-option>
              </el-select>
              <el-input
                  v-model="coinVal2"
                  type="text"
                  placeholder="请输入"
                  @input="searchRateFn(2)"
                  size="small"
              ></el-input>
            </div>
            <p>余额: {{coinBalance2}} {{curCoin2.symbol}}</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">兑换率</div>
          <div class="com_from_right">
            {{ coinPriceTx }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">预计矿工费</div>
          <div class="com_from_right">
            {{gasPriceData.swap_liquidity}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="hideRateFn">取消</el-button>
                    <el-button type="primary" @click="submitTransactionFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="transactionCrossShow" title="跨链闪兑" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">转出链类型</div>
          <div class="com_from_right">
            {{curCoin5.chain_type}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">转出地址</div>
          <div class="com_from_right">
            {{curCoin5.public_addr}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">转出范围(最小值-最大值)</div>
          <div class="com_from_right">
            {{curCoin6.deposit_min}} {{curCoin5.symbol}} - {{curCoin6.deposit_max}} {{curCoin5.symbol}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">转出数量</div>
          <div class="com_from_right">
            <el-input
                v-model="coinVal5"
                type="text"
                placeholder="请输入"
                @input="searchTranscationFn"
                size="small"
            ></el-input>
            <p>余额: {{coinBalance5}} {{curCoin5.symbol}}</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">接收币种</div>
          <div class="com_from_right">
            <el-select
                v-model="to_chain"
                clearable
                filterable
                placeholder="请选择"
                size="small"
                @change="(val) => {showTransactionCrossFn(val, curIndex)}"
            >
              <el-option
                  v-for="item in option3"
                  :label="item.chain_type + '-' + item.symbol"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">接收地址</div>
          <div class="com_from_right">
            <el-select
                v-model="destination_addr"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in curCoin6.dest_addrs"
                  :key="item.addr"
                  :label="item.addr + '-' + item.title"
                  :value="item.addr"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">发币手续费</div>
          <div class="com_from_right">
            {{curCoin6.chainFee}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">兑换手续费率</div>
          <div class="com_from_right">
            {{curCoin6.depositCoinFeeRate}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">即时汇率</div>
          <div class="com_from_right">
            {{curCoin6.instant_rate}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">预计到账数量</div>
          <div class="com_from_right">
            {{coinBalance6}}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="hideRateFn">取消</el-button>
                    <el-button type="primary" @click="submitTransacCrosstionFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="liquidityShow" title="添加流动池" width="500px">
      <section class="com_from_box">
        <div class="com_from_item" v-show="pairCoinData.address === ''">
          <div class="com_from_left"></div>
          <div class="com_from_right">
            你是该币对的第一个流动性提供者
            你添加代币的比例，将决定该流动池的初始价格。
            请合理设置流动池初始比例！
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">币对</div>
          <div class="com_from_right">
            <div class="d_flex_1">
              <el-select
                  class="mr_5"
                  v-model="coin3"
                  clearable
                  filterable
                  placeholder="请选择"
                  size="small"
                  @change="searchLiquidityCoin"
              >
                <el-option v-for="(item, index) in option2"
                           :key="item.coin_id"
                           :label="item.symbol"
                           :value="index">
                </el-option>
              </el-select>
              <span>-</span>
              <el-select
                  class="mr_5"
                  v-model="coin4"
                  clearable
                  filterable
                  placeholder="请选择"
                  size="small"
                  @change="searchLiquidityCoin"
              >
                <el-option v-for="(item, index) in option2"
                           :key="item.coin_id"
                           :label="item.symbol"
                           :value="index">
                </el-option>
              </el-select>

            </div>

          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">数量</div>
          <div class="com_from_right">
            <div class="d_flex_1">
              <div>
                <el-input
                    v-model="coinVal3"
                    type="text"
                    @input="searchLiquFn(1)"
                    placeholder="请输入"
                    size="small"
                ></el-input>
                <p>余额: {{coinBalance3}} {{curCoin3.symbol}}</p>
              </div>
              <span>-</span>
              <div>
                <el-input
                    v-model="coinVal4"
                    type="text"
                    placeholder="请输入"
                    @input="searchLiquFn(2)"
                    size="small"
                ></el-input>
                <p>余额: {{coinBalance4}} {{curCoin4.symbol}}</p>
              </div>

            </div>

          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">价格和流动性池份额</div>
          <div class="com_from_right">
            <table class="com-table">
              <thead class="com_thead">
              <tr>
                <th>{{curCoin3.symbol}} / {{curCoin4.symbol}}</th>
                <th>{{curCoin4.symbol}} / {{curCoin3.symbol}}</th>
                <th>流动池中的份额</th>
              </tr>
              </thead>
              <tbody class="com_tbody">
              <tr>
                <td>{{pairCoinData.coin2_bal > 0 ? pairCoinData.coin1_bal / pairCoinData.coin2_bal : ''}}</td>
                <td>{{pairCoinData.coin1_bal > 0 ? pairCoinData.coin2_bal / pairCoinData.coin1_bal : ''}}</td>
                <td>{{coinVal3 && pairCoinData.coin1_bal > 0 ? padRight((coinVal3 / parseFloat(pairCoinData.coin1_bal) + parseFloat(coinVal3)) * 100, 2) + '%' : ''}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">预计矿工费</div>
          <div class="com_from_right">
            {{gasPriceData.add_liquidity}}
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="hideRateFn">取消</el-button>
                    <el-button type="primary" @click="submitLiquidityFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "institutionLoanOrder",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      selectVal5: '',
      selectVal6: '',
      selectVal7: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      inputVal4: '',
      inputVal5: '',
      inputVal6: '',
      inputVal7: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      selectArr: [{value: '钱包', label: '钱包'}],
      payShow: false,
      rateShow: false,
      delLoanShow: false,
      two_auth_code: '',
      rateVal: '',
      subtotal: {
        public_all_amount: []
      },
      other_total: {
        public_all_amount: []
      },
      usd_total: {},
      sum: {},
      statusObj: {
        1: {label: '申请中', value: 1},
        2: {label: '申请成功募集中', value: 2},
        3: {label: '募集成功还款中', value: 3},
        4: {label: '放款成功还款中', value: 4},
        5: {label: '还款中', value: 5},
        6: {label: '已还款成功', value: 6},
        7: {label: '申请失败', value: 7},
        8: {label: '募集失败', value: 8},
        9: {label: '放款失败', value: 9},
        10: {label: '还款失败', value: 10},
        11: {label: '正常到期-还款中', value: 11},
        12: {label: '交易亏损-系统强制平仓中', value: 12},
        13: {label: '交易亏损-系统强制平仓完成', value: 13},
        14: {label: '管理后台强制平仓中', value: 14},
        15: {label: '管理后台强制平仓完成', value: 15},
      },
      transactionShow: false,
      liquidityShow: false,
      transactionCrossShow: false,
      coinInfo: [],
      option2: [],
      option3: [],
      coin1: '',
      coin2: '',
      coinVal1: '',
      coinVal2: '',
      curCoin1: {},
      curCoin2: {},
      coin3: '',
      coin4: '',
      coinVal3: '',
      coinVal4: '',
      curCoin3: {},
      curCoin4: {},
      coinBalance3: '',
      coinBalance4: '',
      coin5: '',
      coinVal5: '',
      curCoin5: {},
      curCoin6: {
        dest_addrs: [],
        destination_addrs: [],
        other_chains: [],
      },
      coinBalance5: '',
      coinBalance6: '',
      to_chain: '',
      destination_addr: '',
      pairCoinData: {},
      gasPriceData: {},
      payType: '',
      coinBalance1: '',
      coinBalance2: '',
      coinPriceTx: '',
      transactionType: '',
      coldAddress: '',
      coldAddressArr: [],
      coldNum: '',
      coldToAddr: '',
      coldShow: false,
    }
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.$ajax(`${this.Utils.URL.goURL}coin/list`, {
      size: 1000,
      bridger_cross: true
    }, 'get')
        .then(res => {

          this.option3 = res.list ? res.list : []
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    this.inputVal1 = this.$route.query.borrow_addr ? this.$route.query.borrow_addr : ''
    this.inputVal2 = this.$route.query.contract_number ? this.$route.query.contract_number : ''
    this.inputVal3 = this.$route.query.name ? this.$route.query.name : ''
    this.selectVal1 = this.$route.query.is_clear ? this.$route.query.is_clear : ''
    this.selectVal2 = this.$route.query.is_swap ? this.$route.query.is_swap : ''
    this.selectVal3 = this.$route.query.chain_type ? this.$route.query.chain_type : ''
    this.selectVal4 = this.$route.query.coin_symbol ? this.$route.query.coin_symbol : ''
    this.selectVal5 = this.$route.query.status ? this.$route.query.status : ''
    this.selectVal7 = this.$route.query.day ? this.$route.query.day : ''
    this.inputVal4 = this.$route.query.min_amount ? this.$route.query.min_amount : ''
    this.inputVal5 = this.$route.query.max_amount ? this.$route.query.max_amount : ''
    this.inputVal6 = this.$route.query.min_borrow_amount ? this.$route.query.min_borrow_amount : ''
    this.inputVal7 = this.$route.query.max_borrow_amount ? this.$route.query.max_borrow_amount : ''
    this.selectVal6 = this.$route.query.is_real_order ? this.$route.query.is_real_order == 'true' ? true : false : ''
    this.page = this.$route.query.page ? parseFloat(this.$route.query.page) : this.page
    this.size = this.$route.query.size ? parseFloat(this.$route.query.size) : this.size
    this.resInit()
  },
  methods: {
    hideRateFn () {
      this.transactionCrossShow = false
      this.liquidityShow = false
      this.transactionShow = false
    },
    submitLiquidityFn () {
      if (!this.coinVal3) {
        this.$message.error('请输入币种1数量')
        return false
      }
      if (parseFloat(this.coinVal3) > parseFloat(this.coinBalance3)) {
        this.$message.error('token1余额不足')
        return false
      }
      if (parseFloat(this.coinVal4) > parseFloat(this.coinBalance4)) {
        this.$message.error('token2余额不足')
        return false
      }
      if (!this.coinVal4) {
        this.$message.error('请输入币种2数量')
        return false
      }
      if (this.coin3 === '') {
        this.$message.error('请选择币种1')
        return false
      }
      if (this.coin4 === '') {
        this.$message.error('请选择币种2')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        ad_id: this.resData[this.curIndex].contract_number,
        coin1_id: this.curCoin3.coin_id,
        coin2_id: this.curCoin4.coin_id,
        coin1_num: this.coinVal3,
        coin2_num: this.coinVal4,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/add_liquidity`, req, 'post')
          .then(res => {
            this.$message.success('交易已经广播')
            this.coin3 = ''
            this.coin4 = ''
            this.two_auth_code = ''
            this.coinVal3 = ''
            this.coinVal4 = ''
            this.coinBalance3 = ''
            this.coinBalance4 = ''
            this.curCoin3 = {}
            this.curCoin4 = {}
            this.pairCoinData = {}

            this.liquidityShow = false
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitTransactionFn () {
      if (!this.coinVal1) {
        this.$message.error('请输入Form数量')
        return false
      }
      if (!this.coinVal2) {
        this.$message.error('请输入To数量')
        return false
      }
      if (this.coin1 === '') {
        this.$message.error('请选择Form币种')
        return false
      }
      if (this.coin2 === '') {
        this.$message.error('请选择To币种')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        ad_id: this.resData[this.curIndex].contract_number,
        pay_coin_id: this.curCoin1.coin_id,
        rec_coin_id: this.curCoin2.coin_id,
        pay_amount: this.coinVal1,
        rec_amount: this.coinVal2,
        typ: this.payType,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/swap`, req, 'post')
          .then(res => {
            this.$message.success('交易已经广播')
            this.coin1 = ''
            this.coin2 = ''
            this.two_auth_code = ''
            this.coinVal1 = ''
            this.coinVal2 = ''
            this.curIndex = ''
            this.payType = ''
            this.coinBalance1 = ''
            this.coinBalance2 = ''
            this.curCoin1 = {}
            this.curCoin2 = {}
            this.coinPriceTx = ''
            this.transactionShow = false
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitTransacCrosstionFn () {
      let coinVal5 = parseFloat(this.coinVal5)
      let coinBalance5 = parseFloat(this.coinBalance5)
      let deposit_min = parseFloat(this.curCoin6.deposit_min)
      let deposit_max = parseFloat(this.curCoin6.deposit_max)

      if (!coinVal5) {
        this.$message.error('请输入转出数量')
        return false
      }
      if (coinVal5 < deposit_min || coinVal5 > deposit_max) {
        this.$message.error('转出数量不在范围值内')
        return false
      }
      if (coinVal5 > coinBalance5) {
        this.$message.error('余额不足')
        return false
      }
      if (this.destination_addr  === '') {
        this.$message.error('请选择接收地址')
        return false
      }
      if (this.to_chain  === '') {
        this.$message.error('请选择接收币种')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        ad_id: this.resData[this.curIndex].contract_number,
        destination_addr: this.destination_addr,
        to_coin_id: this.to_chain,
        deposit_coin_amt : coinVal5,
        receive_coin_amt : this.coinBalance6,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/cross_chain`, req, 'post')
          .then(res => {
            this.$message.success('交易已经广播')
            this.transactionCrossShow = false
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    searchRateFn (type) {
      this.payType = type
      if (type == 1 && parseFloat(this.coinVal1) <= 0) {
        return false
      }
      if (type == 2 && parseFloat(this.coinVal2) <= 0) {
        return false
      }
      if (this.curCoin1.coin_id && this.curCoin2.coin_id) {

        this.coinPriceFn(this.curCoin1,this.curCoin2, type)

      }
    },
    searchTranscationFn () {

      if (parseFloat(this.coinVal5) <= 0) {
        return false
      }
      this.coinBalance6 = this.padRight((this.curCoin6.instant_rate * (parseFloat(this.coinVal5) * parseFloat(1 - this.curCoin6.depositCoinFeeRate))) - this.curCoin6.chainFee, 8)
    },
    searchLiquFn (type) {

      if (type == 1 && this.coinVal3) {

        this.coinVal4 = this.pairCoinData.coin1_bal > 0 ? this.padRight(this.coinVal3 / (this.pairCoinData.coin1_bal / this.pairCoinData.coin2_bal), this.curCoin3.decimals) : ''
        return false
      }
      if (type == 2 && this.coinVal4) {
        this.coinVal3 = this.pairCoinData.coin2_bal > 0 ? this.padRight(this.coinVal4 / (this.pairCoinData.coin2_bal / this.pairCoinData.coin1_bal), this.curCoin4.decimals) : ''

        return false
      }
    },
    coinPriceFn (obj1, obj2, type) {
      let req = {
        pay_coin_id: obj1.coin_id,
        rec_coin_id: obj2.coin_id,
        swap_type: this.resData[this.curIndex].swap_type,
      }
      if (type == 1) {
        req.pay_amount = this.coinVal1
      } else {
        req.rec_amount = this.coinVal2
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/pair_price`, req, 'get')
          .then(res => {
            if (type == 1) {
              this.coinVal2 = this.scientificNotationToString(res)
              this.coinPriceTx = `1 ${obj1.symbol} = ${parseFloat(res)/parseFloat(this.coinVal1)} ${obj2.symbol}`

            } else {
              this.coinVal1 = this.scientificNotationToString(res)
              this.coinPriceTx = `1 ${obj2.symbol} = ${parseFloat(res)/parseFloat(this.coinVal2)} ${obj1.symbol}`

            }
          })
          .catch(res => {
            if (type == 1) {
              this.coinVal2 = ''
            } else {
              this.coinVal1 = ''
            }
            this.$message.error(res.msg)
          })
    },
    searchCoin () {
      if (this.coin1 !== '') {
        this.coinVal1 = ''
        this.curCoin1 = this.option2[this.coin1]
        this.eachBalanceFn(1)
      }
      if (this.coin2 !== '') {
        this.coinVal2 = ''
        this.curCoin2 = this.option2[this.coin2]
        this.eachBalanceFn(2)
      }
    },
    searchLiquidityCoin () {
      if (this.coin3 !== '') {
        this.curCoin3 = this.option2[this.coin3]
        this.coinBalance3 = ''
        this.coinVal3 = ''
        this.eachBalanceOldFn(1)
      }
      if (this.coin4 !== '') {
        this.curCoin4 = this.option2[this.coin4]
        this.coinBalance4 = ''
        this.coinVal4 = ''
        this.eachBalanceOldFn(2)
      }
      if (this.coin3 !== '' && this.coin4 !== '') {
        this.eachPairBalance()
      }


    },
    eachPairBalance () {
      let req = {
        ad_id: this.resData[this.curIndex].contract_number,
        coin1_id: this.curCoin3.coin_id,
        coin2_id: this.curCoin4.coin_id,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/pair_info`, req, 'get')
          .then(res => {
            this.pairCoinData = res ? res : {}
          })
          .catch(res => {
            this.pairCoinData = {}
            this.$message.error(res.msg)
          })
    },
    eachBalanceOldFn (type) {
      let req = {
        addr: this.resData[this.curIndex].public_addr,
      }
      if (type == 1) {
        req.coin_id = this.curCoin3.coin_id
      } else {
        req.coin_id = this.curCoin4.coin_id
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_balance`, req, 'get')
          .then(res => {
            if (type == 1) {
              this.coinBalance3 = res
            } else {
              this.coinBalance4 = res
            }
          })
          .catch(res => {
            if (type == 1) {
              this.coinBalance3 = ''
            } else {
              this.coinBalance4 = ''
            }
            this.$message.error(res.msg)
          })
    },
    eachBalanceFn (type) {
      let req = {
        addr: this.resData[this.curIndex].public_addr,
      }
      if (type == 1) {
        req.coin_id = this.curCoin1.coin_id
      } else {
        req.coin_id = this.curCoin2.coin_id
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_balance`, req, 'get')
          .then(res => {
            if (type == 1) {
              this.coinBalance1 = res
            } else {
              this.coinBalance2 = res
            }
          })
          .catch(res => {
            if (type == 1) {
              this.coinBalance1 = ''
            } else {
              this.coinBalance2 = ''
            }
            this.$message.error(res.msg)
          })
    },
    showTransactionCrossFn (val, index) {
      console.log(val, index)
      this.curIndex = index
      this.two_auth_code = ''
      this.coin5 = ''
      this.coinVal5 = ''
      this.coinBalance5 = ''
      this.coinBalance6 = ''
      this.destination_addr = ''
      this.curCoin6 = {}
      this.curCoin5 = this.resData[this.curIndex]

      this.transactionCrossShow = true

      let req = {
        addr: this.curCoin5.public_addr,
      }

      req.coin_id = this.curCoin5.coin_id

      this.$ajax(`${this.Utils.URL.goURL}organ/chain_balance`, req, 'get')
          .then(res => {
            this.coinBalance5 = res
          })
          .catch(res => {
            this.coinBalance5 = ''

            this.$message.error(res.msg)
          })
      this.$ajax(`${this.Utils.URL.goURL}organ/get_base_info`, {
        ad_id: this.curCoin5.contract_number,
        to_coin_id: this.to_chain,
      }, 'get')
          .then(res => {
            this.curCoin6 = res
          })
          .catch(res => {
            this.curCoin6 = {}
            this.$message.error(res.msg)
          })

    },
    searchCur6Fn () {

    },
    showColdTransactionFn (index) {
      this.coldShow = true
      this.two_auth_code = ''
      this.coldAddress = ''
      this.coldNum = ''
      this.curIndex = index
      this.coldAddressArr = []
      this.coldToAddr = this.resData[this.curIndex].public_addr
      let req = {
        chain_type: this.resData[this.curIndex].chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/transfer_addrs`, req, 'get')
          .then(res => {
            this.coldAddressArr = res ? res : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitColdFn () {
      let index = this.curIndex
      if (this.coldAddress === '') {
        this.$message.error('请选择收款地址')
        return false
      }
      if (this.coldNum === '') {
        this.$message.error('请输入数量')
        return false
      }
      if (this.coldToAddr === '') {
        this.$message.error('请输入转出地址')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      let req = {
        ad_id: this.resData[index].contract_number,
        amount: this.coldNum,
        to_addr: this.coldAddress,
        two_auth_code: this.two_auth_code,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/transfer`, req, 'post')
          .then(res => {
            this.$message.success('交易已广播')
            this.coldShow = false
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    showTransactionFn (type, index) {
      if (type == 'liquidity') {
        this.coin3 = ''
        this.coin4 = ''
        this.coinVal3 = ''
        this.coinVal4 = ''
        this.curCoin3 = {}
        this.curCoin4 = {}
        this.pairCoinData = {}
        this.coinBalance3 = ''
        this.coinBalance4 = ''
        this.liquidityShow = true
      } else {
        this.coin1 = ''
        this.coin2 = ''
        this.coinVal1 = ''
        this.coinVal2 = ''
        this.curIndex = ''
        this.payType = ''
        this.coinBalance1 = ''
        this.coinBalance2 = ''
        this.curCoin1 = {}
        this.curCoin2 = {}
        this.coinPriceTx = ''
        this.transactionShow = true
      }
      this.transactionType = type
      this.curIndex = index
      this.gasPriceData = {}
      this.two_auth_code = ''
      this.gasPriceFn()
      this.coinListFn()

    },
    gasPriceFn() {
      let req = {
        chain_type: this.resData[this.curIndex].chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/fee_info`, req, 'get')
          .then(res => {
            this.gasPriceData = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    coinListFn() {
      let req = {
        chain_type: this.resData[this.curIndex].chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_type_coin_list`, req, 'get')
          .then(res => {
            if (this.transactionType == 'liquidity') {
              this.coin3 = ''
              this.coin4 = ''
            } else {
              this.coin1 = ''
              this.coin2 = ''
            }

            this.option2 = res.list ? res.list : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    showDelLoaneFn (index) {
      this.two_auth_code = ''
      this.curIndex = index
      this.delLoanShow = true
    },
    showRateFn (index) {
      this.curIndex = index
      this.rateShow = true
      this.two_auth_code = ''
      this.rateVal = this.resData[index].daily_borrow_rate
    },
    submitDelLoanFn () {
      let index = this.curIndex
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'organ/cancel_order'
      let req = {
        order_id: this.resData[index].contract_number,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否取消借贷', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('取消成功')
              this.delLoanShow = false
              this.resData[index].status = 8
              this.resData[index].is_cancel = false
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    editRateFn () {
      let index = this.curIndex
      if (this.rateVal === '') {
        this.$message.error('请输入收益率')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'organ/edit_profit_rate'
      let req = {
        id: this.resData[index].contract_number,
        profit_rate: this.rateVal,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否修改预计每日收益率', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('修改成功')
              this.rateShow = false
              this.resData[index].daily_borrow_rate = this.rateVal

            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    resInit () {
      let req = {
        page: this.page,
        size: this.size,
        order_type: 2,
      }
      if (this.selectVal1) {
        req.is_clear = this.selectVal1
      }
      if (this.selectVal2) {
        req.is_swap = this.selectVal2
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3
      }
      if (this.selectVal4) {
        req.coin_symbol = this.selectVal4
      }
      if (this.selectVal5) {
        req.status = this.selectVal5
      }
      if (this.selectVal7) {
        req.day = this.selectVal7
      }
      if (this.selectVal6 !== '') {
        req.is_real_order = this.selectVal6
      }
      if (this.inputVal1) {
        req.borrow_addr = this.inputVal1
      }
      if (this.inputVal2) {
        req.contract_number = this.inputVal2
      }
      if (this.inputVal3) {
        req.name = this.inputVal3
      }
      if (this.inputVal4) {
        req.min_amount = this.inputVal4
      }
      if (this.inputVal5) {
        req.max_amount = this.inputVal5
      }
      if (this.inputVal6) {
        req.min_borrow_amount = this.inputVal6
      }
      if (this.inputVal7) {
        req.max_borrow_amount = this.inputVal7
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      let query = Object.assign({}, req)
      this.$ajax(`${this.Utils.URL.goURL}organ/loan_fund_order_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
            this.other_total = res.other_total ? res.other_total : {public_all_amount: []}
            this.usd_total = res.usd_total ? res.usd_total : {}
            this.subtotal = res.subtotal ? res.subtotal : {public_all_amount: []}
            this.sum = res.sum ? res.sum : {}
            this.$router.replace({path: 'institutionLoanOrder',query: query})
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>