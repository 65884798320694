<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          包名
        </div>
        <div class="com_from_right">
          <el-input
            v-model="appStr"
            placeholder="请输入"
            size="small">
          </el-input>
          如:wallet124, wallet125
        </div>
      </div>
      <div class="com_from_item" v-show="!fileStr">
        <div class="com_from_left">
          apk包上传
        </div>
        <div class="com_from_right">
          <el-upload
            class="avatar-uploader"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-change="beforeAvatarUpload"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="com_from_item" v-show="fileStr">
        <div class="com_from_left">
          线上链接
        </div>
        <div class="com_from_right">
          <el-input
            v-model="fileStr"
            readonly="readonly"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
        <span class="color_T1 cur_po" @click="copyText(fileStr)">复制</span>
      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "androidUpload",
  data() {
    return {
      imageUrl: '',
      appStr: '',
      fileStr: ''
    }
  },
  created() {
  },
  methods: {

    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      this.uploadFn(file, 'cn')
    },
    uploadFn(file, type) {
      if (this.appStr === '') {
        this.$message.error('请输入包名')
        return false
      }
      const formData = new FormData()
      let str = file.name.split('.')
      let last = str[str.length - 1].toLowerCase()
      if (!/apk|aab/.test(last)) {
        this.$message.error('上传错误,文件格式必须为：apk/aab');
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      str = this.appStr + '.' + last
      formData.append('files', file.raw, str)
      formData.append('dir', 'android')
      this.$ajax(`${this.Utils.URL.goURL}upload`, formData, 'post', 25000000, true)
        .then(res => {
          // this.fileStr = this.Utils.URL.imgURL + res.url
          this.fileStr = this.Utils.URL.downloadURL + res.url
          loading.close()
        })
        .catch(res => {
          loading.close()
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>