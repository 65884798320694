<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">借币地址</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal1"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">合同编号</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal2"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>借币地址</th>
        <th>共管地址</th>
        <th>合同单号</th>
        <th>机构名称</th>
        <th>链类型</th>
        <th>币种</th>
        <th>基金合同状态</th>
        <th>募集基金总量</th>
        <th>已募集基金数量</th>
        <th>本单保证金数量</th>
        <th>本单剩余保证金数量</th>
        <th>已付利息</th>
        <th>基金用途</th>
        <th>基金合同周期(年)</th>
        <th>用户投资周期(天)</th>
        <th>每日收益率</th>
        <th>合同创建时间</th>
        <th>合同到期时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.borrow_addr }}</td>
          <td>{{ list.public_addr }}</td>
          <td>{{ list.contract_number }}</td>
          <td>{{ list.mechanism_name }}</td>
          <td>{{ list.chain_type }}</td>
          <td>{{ list.symbol }}</td>
          <td>{{ statusObj[list.status] }}</td>
          <td>{{ list.borrow_amount }}</td>
          <td>{{ list.curr_borrow }}</td>
          <td>{{ list.pledge_amount }}</td>
          <td>{{ list.surplus_pledge }}</td>
          <td>{{ list.interest_paid }}</td>
          <td>{{ list.swap_name }}</td>
          <td>{{ list.duration }}</td>
          <td>{{ list.duration_day }}</td>
          <td>{{ list.daily_borrow_rate }}</td>
          <td>{{ dataFormat(list.created_at) }}</td>
          <td>{{ dataFormat(list.expiration_at) }}</td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('/institutionFundAsset', {addr: list.public_addr, id: list.contract_number})">查询共管地址资产</span>
            <span class="btn" v-if="isEditButton" @click="showTransactionFn(index)">去交易</span>
            <span class="btn" v-if="isEditButton" @click="showRateFn(index)">录入收益率</span>
            <span class="btn" @click="goRouter('/institutionFundFundraising', {id: list.contract_number})">募资详情</span>
            <span class="btn" @click="goRouter('/institutionFundIncome', {id: list.contract_number})">历史每日收益率</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="payShow" title="提前还款" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">合同编号</div>
          <div class="com_from_right">
            xxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">保证金</div>
          <div class="com_from_right">
            xxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">借贷数量</div>
          <div class="com_from_right">
            xxxxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">日利率</div>
          <div class="com_from_right">
            xxxxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">借贷周期</div>
          <div class="com_from_right">
            xxxxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">总利息</div>
          <div class="com_from_right">
            xxxxxxx
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left"></div>
          <div class="com_from_right">
            <span class="color_T2">提前还款将收取借贷金额*5%的违约金，是否确定提前还款？</span>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="payShow = false">取消</el-button>
                    <el-button type="primary" >提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="rateShow" title="修改预计每日收益率" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">预计每日收益率</div>
          <div class="com_from_right">
            <el-input
                v-model="rateVal"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="rateShow = false">取消</el-button>
                    <el-button type="primary" @click="editRateFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="transactionShow" title="交易" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">Form{{payType == 2 ? '(预估)' : ''}}</div>
          <div class="com_from_right">
            <div class="d_flex">
              <el-select
                  class="mr_5"
                  v-model="coin1"
                  clearable
                  filterable
                  placeholder="请选择"
                  size="small"
                  @change="searchCoin"
              >
                <el-option v-for="(item, index) in option2"
                           :key="item.coin_id"
                           :label="item.symbol"
                           :value="index">
                </el-option>
              </el-select>
              <el-input
                  v-model="coinVal1"
                  type="text"
                  @input="searchRateFn(1)"
                  placeholder="请输入"
                  size="small"
              ></el-input>
            </div>
            <p>余额: {{coinBalance1}} {{curCoin1.symbol}}</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">To{{payType == 1 ? '(预估)' : ''}}</div>
          <div class="com_from_right">
            <div class="d_flex">
              <el-select
                  class="mr_5"
                  v-model="coin2"
                  clearable
                  filterable
                  placeholder="请选择"
                  size="small"
                  @change="searchCoin"
              >
                <el-option v-for="(item, index) in option2"
                           :key="item.coin_id"
                           :label="item.symbol"
                           :value="index">
                </el-option>
              </el-select>
              <el-input
                  v-model="coinVal2"
                  type="text"
                  placeholder="请输入"
                  @input="searchRateFn(2)"
                  size="small"
              ></el-input>
            </div>
            <p>余额: {{coinBalance2}} {{curCoin2.symbol}}</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">兑换率</div>
          <div class="com_from_right">
            {{ coinPriceTx }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="hideRateFn">取消</el-button>
                    <el-button type="primary" @click="submitTransactionFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "institutionFundOrder",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      inputVal2: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      selectArr: [{value: '钱包', label: '钱包'}],
      payShow: false,
      rateShow: false,
      two_auth_code: '',
      rateVal: '',
      statusObj: {
        1: '申请中',
        2: '申请成功募集中',
        3: '募集成功还款中',
        4: '放款成功还款中',
        5: '还款中',
        6: '已还款成功',
        7: '申请失败',
        8: '募集失败',
        9: '放款失败',
        10: '还款失败',
      },
      transactionShow: false,
      option2: [],
      coin1: '',
      coin2: '',
      coinVal1: '',
      coinVal2: '',
      curCoin1: {},
      curCoin2: {},
      payType: '',
      coinBalance1: '',
      coinBalance2: '',
      coinPriceTx: '',
    }
  },
  created() {

    this.resInit()
  },
  methods: {
    hideRateFn () {
      this.transactionShow = false
    },
    submitTransactionFn () {
      if (!this.coinVal1) {
        this.$message.error('请输入Form数量')
        return false
      }
      if (!this.coinVal2) {
        this.$message.error('请输入To数量')
        return false
      }
      if (this.coin1 === '') {
        this.$message.error('请选择Form币种')
        return false
      }
      if (this.coin2 === '') {
        this.$message.error('请选择To币种')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        ad_id: this.resData[this.curIndex].contract_number,
        pay_coin_id: this.curCoin1.coin_id,
        rec_coin_id: this.curCoin2.coin_id,
        pay_amount: this.coinVal1,
        rec_amount: this.coinVal2,
        typ: this.payType,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/swap`, req, 'post')
          .then(res => {
            this.$message.success('交易已经广播')
            this.coin1 = ''
            this.coin2 = ''
            this.two_auth_code = ''
            this.coinVal1 = ''
            this.coinVal2 = ''
            this.curIndex = ''
            this.payType = ''
            this.coinBalance1 = ''
            this.coinBalance2 = ''
            this.curCoin1 = {}
            this.curCoin2 = {}
            this.coinPriceTx = ''
            this.transactionShow = false
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    searchRateFn (type) {
      this.payType = type
      if (type == 1 && parseFloat(this.coinVal1) <= 0) {
        return false
      }
      if (type == 2 && parseFloat(this.coinVal2) <= 0) {
        return false
      }
      if (this.curCoin1.coin_id && this.curCoin2.coin_id) {

        this.coinPriceFn(this.curCoin1,this.curCoin2, type)

      }
    },
    coinPriceFn (obj1, obj2, type) {
      let req = {
        pay_coin_id: obj1.coin_id,
        rec_coin_id: obj2.coin_id,
        swap_type: this.resData[this.curIndex].swap_type,
      }
      if (type == 1) {
        req.pay_amount = this.coinVal1
      } else {
        req.rec_amount = this.coinVal2
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/pair_price`, req, 'get')
          .then(res => {


            if (type == 1) {
              this.coinVal2 = this.scientificNotationToString(res)
              this.coinPriceTx = `1 ${obj1.symbol} = ${parseFloat(res)/parseFloat(this.coinVal1)} ${obj2.symbol}`

            } else {
              this.coinVal1 = this.scientificNotationToString(res)
              this.coinPriceTx = `1 ${obj2.symbol} = ${parseFloat(res)/parseFloat(this.coinVal2)} ${obj1.symbol}`

            }

          })
          .catch(res => {
            if (type == 1) {
              this.coinVal2 = ''
            } else {
              this.coinVal1 = ''
            }
            this.$message.error(res.msg)
          })
    },
    searchCoin () {
      if (this.coin1 !== '') {
        this.coinVal1 = ''
        this.curCoin1 = this.option2[this.coin1]
        this.eachBalanceFn(1)
      }
      if (this.coin2 !== '') {
        this.coinVal2 = ''
        this.curCoin2 = this.option2[this.coin2]
        this.eachBalanceFn(2)
      }
    },
    eachBalanceFn (type) {
      let req = {
        addr: this.resData[this.curIndex].public_addr,
      }
      if (type == 1) {
        req.coin_id = this.curCoin1.coin_id
      } else {
        req.coin_id = this.curCoin2.coin_id
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_balance`, req, 'get')
          .then(res => {
            if (type == 1) {
              this.coinBalance1 = res
            } else {
              this.coinBalance2 = res
            }
          })
          .catch(res => {
            if (type == 1) {
              this.coinBalance1 = ''
            } else {
              this.coinBalance2 = ''
            }
            this.$message.error(res.msg)
          })
    },
    showTransactionFn (index) {
      this.coin1 = ''
      this.coin2 = ''
      this.two_auth_code = ''
      this.coinVal1 = ''
      this.coinVal2 = ''
      this.curIndex = ''
      this.payType = ''
      this.coinBalance1 = ''
      this.coinBalance2 = ''
      this.curCoin1 = {}
      this.curCoin2 = {}
      this.coinPriceTx = ''
      this.curIndex = index
      this.coinListFn()
      this.transactionShow = true
    },
    coinListFn() {
      let req = {
        chain_type: this.resData[this.curIndex].chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_type_coin_list`, req, 'get')
          .then(res => {
            this.coin1 = ''
            this.coin2 = ''
            this.option2 = res.list ? res.list : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    showRateFn (index) {
      this.curIndex = index
      this.rateShow = true
      this.rateVal = this.resData[index].daily_borrow_rate
    },
    editRateFn () {
      let index = this.curIndex
      if (this.rateVal === '') {
        this.$message.error('请输入收益率')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'organ/edit_profit_rate'
      let req = {
        id: this.resData[index].contract_number,
        profit_rate: this.rateVal,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否修改预计每日收益率', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('修改成功')
              this.resData[index].daily_borrow_rate = this.rateVal
              this.two_auth_code = ''
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
        order_type: 3,
      }
      if (this.selectVal1) {
        req.port_id = this.selectVal1
      }
      if (this.selectVal2) {
        req.one_type = this.selectVal2
      }
      if (this.selectVal3) {
        req.two_type = this.selectVal3
      }
      if (this.inputVal1) {
        req.borrow_addr = this.inputVal1
      }
      if (this.inputVal2) {
        req.contract_number = this.inputVal2
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/loan_fund_order_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>