<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>流动池币对</th>
        <th>流动池代币数量</th>
        <th>Token1提供数量</th>
        <th>Token2提供数量</th>
        <th>流动池份额</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.pair_symbol }}</td>
          <td>{{ list.liquidity }}</td>
          <td>{{ list.coin1_num }}</td>
          <td>{{ list.coin2_num }}</td>
          <td>{{ list.share }}</td>
          <td class="tab_btn">
            <span class="btn" v-if="isEditButton" @click="showTransactionFn('liquidity', index)">添加流动池</span>
            <span class="btn" v-if="isEditButton" @click="showRateFn(index)">移除流动池</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>
    <div class="com_from_item">
      <div class="com_from_left">预计矿工费</div>
      <div class="com_from_right">
        {{gasPriceData.rm_liquidity}}
      </div>
    </div>

    <el-dialog v-model="rateShow" title="移除流动池" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="rateShow = false">取消</el-button>
                    <el-button type="primary" @click="editRateFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

    <el-dialog v-model="liquidityShow" title="添加流动池" width="500px">
      <section class="com_from_box">
        <div class="com_from_item" v-show="pairCoinData.address === ''">
          <div class="com_from_left"></div>
          <div class="com_from_right">
            你是该币对的第一个流动性提供者
            你添加代币的比例，将决定该流动池的初始价格。
            请合理设置流动池初始比例！
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">币对</div>
          <div class="com_from_right">
            <div class="d_flex">
              <el-select
                  class="mr_5"
                  v-model="coin3"
                  clearable
                  filterable
                  placeholder="请选择"
                  size="small"
                  @change="searchLiquidityCoin"
              >
                <el-option v-for="(item, index) in option2"
                           :key="item.coin_id"
                           :label="item.symbol"
                           :value="index">
                </el-option>
              </el-select>
              <span>-</span>
              <el-select
                  class="mr_5"
                  v-model="coin4"
                  clearable
                  filterable
                  placeholder="请选择"
                  size="small"
                  @change="searchLiquidityCoin"
              >
                <el-option v-for="(item, index) in option2"
                           :key="item.coin_id"
                           :label="item.symbol"
                           :value="index">
                </el-option>
              </el-select>

            </div>

          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">数量</div>
          <div class="com_from_right">
            <div class="d_flex">
              <div>
                <el-input
                    v-model="coinVal3"
                    type="text"
                    @input="searchLiquFn(1)"
                    placeholder="请输入"
                    size="small"
                ></el-input>
                <p>余额: {{coinBalance3}} {{curCoin3.symbol}}</p>
              </div>
              <span>-</span>
              <div>
                <el-input
                    v-model="coinVal4"
                    type="text"
                    placeholder="请输入"
                    @input="searchLiquFn(2)"
                    size="small"
                ></el-input>
                <p>余额: {{coinBalance4}} {{curCoin4.symbol}}</p>
              </div>

            </div>

          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">价格和流动性池份额</div>
          <div class="com_from_right">
            <table class="com-table">
              <thead class="com_thead">
              <tr>
                <th>{{curCoin3.symbol}} / {{curCoin4.symbol}}</th>
                <th>{{curCoin4.symbol}} / {{curCoin3.symbol}}</th>
                <th>流动池中的份额</th>
              </tr>
              </thead>
              <tbody class="com_tbody">
              <tr>
                <td>{{pairCoinData.coin2_bal > 0 ? pairCoinData.coin1_bal / pairCoinData.coin2_bal : ''}}</td>
                <td>{{pairCoinData.coin1_bal > 0 ? pairCoinData.coin2_bal / pairCoinData.coin1_bal : ''}}</td>
                <td>{{coinVal3 && pairCoinData.coin1_bal > 0 ? padRight((coinVal3 / parseFloat(pairCoinData.coin1_bal) + parseFloat(coinVal3)) * 100, 2) + '%' : ''}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">预计矿工费</div>
          <div class="com_from_right">
            {{gasPriceData.add_liquidity}}
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="hideRateFn">取消</el-button>
                    <el-button type="primary" @click="submitLiquidityFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
  </article>
</template>

<script>
export default {
  name: "institutionLoanLiquidityOrder",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      inputVal2: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      selectArr: [{value: '钱包', label: '钱包'}],
      payShow: false,
      rateShow: false,
      two_auth_code: '',
      rateVal: '',
      statusObj: {
        1: '申请中',
        2: '申请成功募集中',
        3: '募集成功还款中',
        4: '放款成功还款中',
        5: '还款中',
        6: '已还款成功',
        7: '申请失败',
        8: '募集失败',
        9: '放款失败',
        10: '还款失败',
      },
      transactionShow: false,
      liquidityShow: false,
      option2: [],
      coin1: '',
      coin2: '',
      coinVal1: '',
      coinVal2: '',
      curCoin1: {},
      curCoin2: {},
      coin3: '',
      coin4: '',
      coinBalance3: '',
      coinBalance4: '',
      coinVal3: '',
      coinVal4: '',
      curCoin3: {},
      curCoin4: {},
      pairCoinData: {},
      gasPriceData: {},
      payType: '',
      coinBalance1: '',
      coinBalance2: '',
      coinPriceTx: '',
      transactionType: '',
    }
  },
  created() {
    if (this.$route.query.contract_number) {
      this.resInit()
    }
  },
  methods: {
    hideRateFn () {
      this.liquidityShow = false
      this.transactionShow = false
    },
    submitLiquidityFn () {
      if (!this.coinVal3) {
        this.$message.error('请输入币种1数量')
        return false
      }
      if (!this.coinVal4) {
        this.$message.error('请输入币种2数量')
        return false
      }
      if (this.coin3 === '') {
        this.$message.error('请选择币种1')
        return false
      }
      if (this.coin4 === '') {
        this.$message.error('请选择币种2')
        return false
      }
      if (parseFloat(this.coinVal3) > parseFloat(this.coinBalance3)) {
        this.$message.error('token1余额不足')
        return false
      }
      if (parseFloat(this.coinVal4) > parseFloat(this.coinBalance4)) {
        this.$message.error('token2余额不足')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        ad_id: this.$route.query.contract_number,
        coin1_id: this.curCoin3.coin_id,
        coin2_id: this.curCoin4.coin_id,
        coin1_num: this.coinVal3,
        coin2_num: this.coinVal4,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/add_liquidity`, req, 'post')
          .then(res => {
            this.$message.success('交易已经广播')
            this.coin3 = ''
            this.coin4 = ''
            this.two_auth_code = ''
            this.coinVal3 = ''
            this.coinVal4 = ''
            this.coinBalance3 = ''
            this.coinBalance4 = ''
            this.curCoin3 = {}
            this.curCoin4 = {}
            this.pairCoinData = {}

            this.liquidityShow = false
            this.resInit()
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    searchLiquFn (type) {

      if (type == 1 && this.coinVal3) {

        this.coinVal4 = this.pairCoinData.coin1_bal > 0 ? this.padRight(this.coinVal3 / (this.pairCoinData.coin1_bal / this.pairCoinData.coin2_bal), this.curCoin3.decimals) : ''
        return false
      }
      if (type == 2 && this.coinVal4) {
        this.coinVal3 = this.pairCoinData.coin2_bal > 0 ? this.padRight(this.coinVal4 / (this.pairCoinData.coin2_bal / this.pairCoinData.coin1_bal), this.curCoin4.decimals) : ''

        return false
      }
    },
    searchLiquidityCoin () {
      if (this.coin3 !== '') {
        this.curCoin3 = this.option2[this.coin3]
        this.coinBalance3 = ''
        this.coinVal3 = ''
        this.eachBalanceOldFn(1)
      }
      if (this.coin4 !== '') {
        this.curCoin4 = this.option2[this.coin4]
        this.coinBalance4 = ''
        this.coinVal4 = ''
        this.eachBalanceOldFn(2)
      }
      if (this.coin3 !== '' && this.coin4 !== '') {
        this.eachPairBalance()
      }

    },
    eachBalanceOldFn (type) {
      let req = {
        addr: this.resData[this.curIndex].public_addr,
      }
      if (type == 1) {
        req.coin_id = this.curCoin3.coin_id
      } else {
        req.coin_id = this.curCoin4.coin_id
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_balance`, req, 'get')
          .then(res => {
            if (type == 1) {
              this.coinBalance3 = res
            } else {
              this.coinBalance4 = res
            }
          })
          .catch(res => {
            if (type == 1) {
              this.coinBalance3 = ''
            } else {
              this.coinBalance4 = ''
            }
            this.$message.error(res.msg)
          })
    },
    eachPairBalance () {
      let req = {
        ad_id: this.$route.query.contract_number,
        coin1_id: this.curCoin3.coin_id,
        coin2_id: this.curCoin4.coin_id,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/pair_info`, req, 'get')
          .then(res => {
            this.pairCoinData = res ? res : {}
          })
          .catch(res => {
            this.pairCoinData = {}
            this.$message.error(res.msg)
          })
    },
    showTransactionFn (type, index) {
      if (type == 'liquidity') {
        this.coin3 = ''
        this.coin4 = ''
        this.coinVal3 = ''
        this.coinVal4 = ''
        this.curCoin3 = {}
        this.curCoin4 = {}
        this.pairCoinData = {}
        this.liquidityShow = true
      } else {
        this.coin1 = ''
        this.coin2 = ''
        this.coinVal1 = ''
        this.coinVal2 = ''
        this.curIndex = ''
        this.payType = ''
        this.coinBalance1 = ''
        this.coinBalance2 = ''
        this.curCoin1 = {}
        this.curCoin2 = {}
        this.coinPriceTx = ''
        this.transactionShow = true
      }
      this.transactionType = type
      this.curIndex = index
      this.gasPriceData = {}
      this.two_auth_code = ''
      this.coinListFn()

    },
    gasPriceFn() {
      let req = {
        chain_type: this.$route.query.chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/fee_info`, req, 'get')
          .then(res => {
            this.gasPriceData = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    coinListFn() {
      let req = {
        chain_type: this.$route.query.chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_type_coin_list`, req, 'get')
          .then(res => {
            if (this.transactionType == 'liquidity') {
              this.coin3 = ''
              this.coin4 = ''
            } else {
              this.coin1 = ''
              this.coin2 = ''
            }

            this.option2 = res.list ? res.list : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    showRateFn (index) {
      this.gasPriceFn()
      this.curIndex = index
      this.rateShow = true
    },
    editRateFn () {

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'organ/remove_liquidity'
      let req = {
        ad_id: this.$route.query.contract_number,
        pair_addr: this.resData[this.curIndex].pair_addr,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否移除流动池', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('移除成功')
              this.two_auth_code = ''
              this.rateShow = false
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
        ad_id: this.$route.query.contract_number,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/liquidity`, req, 'get')
          .then(res => {
            this.resData = res ? res : []
            this.total = parseFloat(this.resData.length)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>