<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search" style=" padding-right: 2rem;">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

      <div class="com_item_search">
        <p style="font-size: 16px;"><b>BSC-LP當前價格: {{ bsc_lp_price }}<br/>FTC-LP當前價格: {{ fm_lp_price }}</b></p>
      </div>
      
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>
    <!-- <p class="color_T2 mt_40">总支出=質押支出+MD用户分红支出+合伙人分红支出+团队收益（直邀+间邀+管理级别）+返还合伙人认购金额+返还投资本金+推广收益</p> -->
    <div class="tbl_scroll">
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>日期</th>
        <th>充值人數</th>
        <th>用戶充值(FUSD)</th>
        <th>提幣人數</th>
        <th>用戶提幣(FUSD)</th>
        <th>認購人數</th>
        <th>認購金額</th>
        <th>添加流動性總人數<br />(CAKE-LP)</th>
        <th>添加流動性總額<br/>(CAKE-LP鏈)</th>
        <th>添加流動性總人數<br/>(FIN-LP鏈)</th>
        <th>添加流動性總額<br/>(FIN-LP鏈)</th>
        <th>移除流動性總人數(CAKE-LP)</th>
        <th>移除流動性總額(CAKE-LP)</th>
        <th>移除流動性總人數(FIN-LP)</th>
        <th>移除流動性總額(FIN-LP)</th>
        
        <th>鑄造總支出(USDT)</th> <!-- add 0306 -->
        <th>鑄造總收入(FSU)</th>  <!-- add 0306 -->
        <th>鑄造總收入(FSP)</th>  <!-- add 0306 -->

        <th>每日挖礦收益 (SCF-FIN)</th>
        <th>每日挖礦收益已提取(SCF-FIN)</th>
        <th>MD用户分红支出</th>
        <th>团队收益(直邀+简邀+管理级别)</th>
        <th>動態收益已提取(SCF-FIN)</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.date_str}}</td>
          <td>{{scientificNotationToString(list.recharge_user_num)}}</td>
          <td>{{scientificNotationToString(list.recharge_amount)}}</td>
          <td>{{scientificNotationToString(list.withdraw_user_num)}}</td>
          <td>{{scientificNotationToString(list.withdraw_amount)}}</td>
          <td>{{scientificNotationToString(list.buy_fsp_num)}}</td>
          <td>{{scientificNotationToString(list.buy_fsp_amount)}}</td>
          <td>{{scientificNotationToString(list.total_bsc_lp_user)}}</td>
          <td>{{scientificNotationToString(list.total_bsc_lp_amt)}}</td>
          <td>{{scientificNotationToString(list.total_fm_lp_user)}}</td>
          <td>{{scientificNotationToString(list.total_fm_lp_amt)}}</td>
          <td>{{scientificNotationToString(list.bsc_remove_lp_user_count)}}</td>
          <td>{{scientificNotationToString(list.bsc_remove_lp_amt)}}</td>
          <td>{{scientificNotationToString(list.fm_remove_lp_user_count)}}</td>
          <td>{{scientificNotationToString(list.fm_remove_lp_amt)}}</td>
          
          <td>{{scientificNotationToString(list.casting_assets_withdraw_amt)}}</td> <!-- add 0306 -->
          <td>{{scientificNotationToString(list.casting_assets_fsu_recharge_amt)}}</td> <!-- add 0306 -->
          <td>{{scientificNotationToString(list.casting_assets_fsp_recharge_amt)}}</td> <!-- add 0306 -->

          

          <td>{{scientificNotationToString(list.mining_profit)}}</td>
          <td>{{scientificNotationToString(list.mining_profit_collection)}}</td>
          <td>{{scientificNotationToString(list.md_amount)}}</td>
          <td>{{scientificNotationToString(list.team_profit)}}</td>
          <td>{{scientificNotationToString(list.profit_extract)}}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.recharge_user_num)}}</td>
          <td>{{scientificNotationToString(subtotal.recharge_amount)}}</td>
          <td>{{scientificNotationToString(subtotal.withdraw_user_num)}}</td>
          <td>{{scientificNotationToString(subtotal.withdraw_amount)}}</td>
          <td>{{scientificNotationToString(subtotal.buy_fsp_num)}}</td>
          <td>{{scientificNotationToString(subtotal.buy_fsp_amount)}}</td>
          <td>{{scientificNotationToString(subtotal.total_bsc_lp_user)}}</td>
          <td>{{scientificNotationToString(subtotal.total_bsc_lp_amt)}}</td>
          <td>{{scientificNotationToString(subtotal.total_fm_lp_user)}}</td>
          <td>{{scientificNotationToString(subtotal.total_fm_lp_amt)}}</td>
          <td>{{scientificNotationToString(subtotal.bsc_remove_lp_user_count)}}</td>
          <td>{{scientificNotationToString(subtotal.bsc_remove_lp_amt)}}</td>
          <td>{{scientificNotationToString(subtotal.fm_remove_lp_user_count)}}</td>
          <td>{{scientificNotationToString(subtotal.fm_remove_lp_amt)}}</td>

          <td>{{scientificNotationToString(subtotal.casting_assets_withdraw_amt)}}</td> <!-- add 0306 -->
          <td>{{scientificNotationToString(subtotal.casting_assets_fsu_recharge_amt)}}</td> <!-- add 0306 -->
          <td>{{scientificNotationToString(subtotal.casting_assets_fsp_recharge_amt)}}</td> <!-- add 0306 -->

          

          <td>{{scientificNotationToString(subtotal.mining_profit)}}</td>
          <td>{{scientificNotationToString(subtotal.mining_profit_collection)}}</td>
          <td>{{scientificNotationToString(subtotal.md_amount)}}</td>
          <td>{{scientificNotationToString(subtotal.team_profit)}}</td>
          <td>{{scientificNotationToString(subtotal.profit_extract)}}</td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td>{{scientificNotationToString(sum.recharge_user_num)}}</td>
          <td>{{scientificNotationToString(sum.recharge_amount)}}</td>
          <td>{{scientificNotationToString(sum.withdraw_user_num)}}</td>
          <td>{{scientificNotationToString(sum.withdraw_amount)}}</td>
          <td>{{scientificNotationToString(sum.buy_fsp_num)}}</td>
          <td>{{scientificNotationToString(sum.buy_fsp_amount)}}</td>
          <td>{{scientificNotationToString(sum.total_bsc_lp_user)}}</td>
          <td>{{scientificNotationToString(sum.total_bsc_lp_amt)}}</td>
          <td>{{scientificNotationToString(sum.total_fm_lp_user)}}</td>
          <td>{{scientificNotationToString(sum.total_fm_lp_amt)}}</td>
          <td>{{scientificNotationToString(sum.bsc_remove_lp_user_count)}}</td>
          <td>{{scientificNotationToString(sum.bsc_remove_lp_amt)}}</td>
          <td>{{scientificNotationToString(sum.fm_remove_lp_user_count)}}</td>
          <td>{{scientificNotationToString(sum.fm_remove_lp_amt)}}</td>

          <td>{{scientificNotationToString(sum.casting_assets_withdraw_amt)}}</td> <!-- add 0306 -->
          <td>{{scientificNotationToString(sum.casting_assets_fsu_recharge_amt)}}</td> <!-- add 0306 -->
          <td>{{scientificNotationToString(sum.casting_assets_fsp_recharge_amt)}}</td> <!-- add 0306 -->

          
          
          <td>{{scientificNotationToString(sum.mining_profit)}}</td>
          <td>{{scientificNotationToString(sum.mining_profit_collection)}}</td>
          <td>{{scientificNotationToString(sum.md_amount)}}</td>
          <td>{{scientificNotationToString(sum.team_profit)}}</td>
          <td>{{scientificNotationToString(sum.profit_extract)}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "operationalDeport",
  data() {
    return {
      page: 1,
      size: 100,
      total: 0,
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      typeObj: {
        1: {label: '投资奖励', value: '1'},
        2: {label: '合伙人认购奖励', value: '2'},
      },
      filterVal: [
        "date_str",
        "recharge_user_num", 
        "recharge_amount",
        "withdraw_user_num",
        "withdraw_amount",
        "buy_fsp_num",
        "buy_fsp_amount",
        "total_bsc_lp_user",
        "total_bsc_lp_amt",
        "total_fm_lp_user",
        "total_fm_lp_amt",
        "bsc_remove_lp_user_count",
        "bsc_remove_lp_amt",
        "fm_remove_lp_user_count",
        "fm_remove_lp_amt",
        "casting_assets_withdraw_amt",
        "casting_assets_fsu_recharge_amt",
        "casting_assets_fsp_recharge_amt",
        "mining_profit",
        "mining_profit_collection",
        "md_amount",
        "team_profit",
        "profit_extract",
      ],
      bsc_lp_price: '',
      fm_lp_price: '',
    };
  },
  created() {

    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}operate_list`, req, "get")
          .then((res) => {
            if (res.list === null || res.list.length === 0) {
              this.$message.error("暂无数据");
              this.total = 0;
              this.resData = [];
              return;
            }

            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.bsc_lp_price = res.bsc_lp_price ? res.bsc_lp_price : ''
            this.fm_lp_price = res.fm_lp_price ? res.fm_lp_price : ''
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
.tbl_scroll {
  overflow-x: scroll;
  width: 100%;
  padding-right: 1rem;
}
</style>