<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">团队长钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">团队标签</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option :value="list.value" :label="list.label" v-for="list in typeObj"></el-option>

          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="resInit('all')"
      >
        导出excel
      </el-button>
    </div>

    <div class="com_search_box mt_40" >
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="showAlertFn('', 'add')">添加团队长</el-button>
      </div>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>团队长钱包ID</th>
        <th>团队标签</th>
        <th>创建时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.wallet_id}}
          </td>
          <td>
            {{list.user_type_tx}}
          </td>
          <td>{{ (list.created_at) }}</td>
          <td class="tab_btn">
            <span class="btn"  @click="showAlertFn(index, 'edit')">编辑</span>
            <span class="btn" @click="showRemove(index)">移除</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curType == 'edit' ? '编辑团队长' : '添加团队长'" :width="600">
      <div class="com_from_box">
        <div class="com_from_item" >
          <div class="com_from_left">团队标签</div>
          <div class="com_from_right" >
            <el-select
                v-model="type"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option v-for="item in typeObj"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>

          </div>
        </div>
        <div class="com_from_item" v-show="curType == 'add'">
          <div class="com_from_left">钱包ID</div>
          <div class="com_from_right" style="width: 230px">
            <el-input
                v-model="wallet_id"
                placeholder="请输入"
                rows="5"
                type="textarea"
                size="small">
            </el-input>
            <p>多个钱包ID请用英文逗号分隔</p>
          </div>
        </div>

      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary" @click="submitFn">提交</el-button>
              </span>
      </template>
    </el-dialog>
    <el-dialog v-model="isRemove" title="移除" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">是否移除</div>
          <div class="com_from_right" >

          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isRemove = false">关闭</el-button>
                <el-button type="primary" @click="removeSubmitFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "zlb-list-report",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      selectVal1: "",
      startTime: "",
      endTime: "",
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "wallet_id",
        "user_type_tx",
        "created_at",
      ],
      isAlert: false,
      isRemove: false,
      curIndex: 0,
      curType: '',
      option1: [{label: '全额创建', value: 1}],
      title: '',
      two_auth_code : '',
      wallet_id: '',
      type: '',
      typeObj: {
        1: {label: '俱乐部', value: '1'},
        2: {label: '工作室', value: '2'},
        3: {label: '普通用户', value: '3'},
        4: {label: '临时标签', value: '4'},
      },
    };
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
    title (val) {
      this.title = val.replace(/[^\a-\z\A-\Z\s]/g,'')
    },
  },
  methods: {
    showRemove (index) {
      this.curIndex = index
      this.two_auth_code = ''
      this.isRemove = true
    },
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.two_auth_code = ''
      this.wallet_id = ''
      this.type = ''
      if (type == 'edit') {
        this.curIndex = index
        this.wallet_id = this.resData[index].wallet_id
        this.type = this.resData[index].user_type
      }
    },
    removeSubmitFn() {


      let req = {
        two_auth_code: this.two_auth_code,
        id: this.resData[this.curIndex].id
      }
      let url = 'remove_team_club'


      /*this.$confirm('是否新增/编辑机构用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {*/
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.two_auth_code = ''
            this.isRemove = false
            this.resInit()
            this.$message.success('移除成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      /*}).catch(() => {

      });*/

    },
    submitFn() {


      if (this.type === '') {
        this.$message.error('请选择团队标签')
        return false
      }
      let req = {
        two_auth_code: this.two_auth_code,
        user_type: this.type,
      }
      let url
      if (this.curType == 'edit') {
        url = 'edit_team_club'
        req.id = this.resData[this.curIndex].id
      } else {
        url = 'add_team_club'
        if (this.wallet_id === '') {
          this.$message.error('请输入钱包ID')
          return false
        }
        req.wallet_id_list = this.wallet_id
      }

      /*this.$confirm('是否新增/编辑机构用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {*/
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.two_auth_code = ''
            this.wallet_id = ''

            this.type = ''
            this.isAlert = false
            this.resInit()
            this.$message.success('提交成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      /*}).catch(() => {

      });*/

    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit(export_type) {

      let req = {
        page: this.page,
        size: this.size,
      };
      if (export_type == 'all') {
        req.export_type = 'all'

      }
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.addr = this.inputVal2;
      }
      if (this.selectVal1) {
        req.user_type = this.selectVal1;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}team_club_list`, req, "get", 500000000)
          .then((res) => {
            if (export_type == 'all') {
              this.exportData = res.list ? res.list : []
              this.exportData.forEach((list) => {

                list.user_type_tx = this.typeObj[list.user_type] ? this.typeObj[list.user_type].label : list.user_type
                //list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
                list.created_at = this.dataFormat(list.created_at)
              })
              this.exportExcel(this.tHeader, this.filterVal, this.exportData, this.$route.meta.title)
            } else {
              this.resData = res.list ? res.list : [];
              this.total = parseFloat(res.total);
              this.subtotal = res.subtotal ? res.subtotal : {}
              this.sum = res.sum ? res.sum : {}
              this.resData.forEach((list) => {

                list.user_type_tx = this.typeObj[list.user_type] ? this.typeObj[list.user_type].label : list.user_type
                //list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
                list.created_at = this.dataFormat(list.created_at)
              })
            }

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>