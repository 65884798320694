<template>
  <article class>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">币种全称*</div>
        <div class="com_from_right">
          <el-input
            v-model="token_name"
            :disabled="infoData.id"
            placeholder="请输入币种全称,提交后不能修改"
            size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">币种简称(英文大写)*</div>
        <div class="com_from_right">
          <el-input
              v-model="symbol"
              :disabled="infoData.id"
              placeholder="请输入币种简称,提交后不能修改"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">币种精度*</div>
        <div class="com_from_right">
          <el-input
            v-model="decimals"

            placeholder="请输入币种精度,提交后不能修改"
            size="small"
          ></el-input>
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">合约地址*</div>
        <div class="com_from_right">
          <el-input v-model="contract_addr" :disabled="infoData.id" placeholder="请输入合约地址" size="small"></el-input>
        </div>
      </div>


      <div class="com_from_item">
        <div class="com_from_left">归集模式*</div>
        <div class="com_from_right">
          <el-select
              v-model="liquidate_mode"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                label="固定值归集"
                value="1"
            ></el-option>
            <el-option
                label="投资总额百分比归集"
                value="2"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">归集值</div>
        <div class="com_from_right d_flex_1">
          <el-input v-model="liquidate_thr"  placeholder="请输入归集值" size="small"></el-input>
          <span>-</span>
          <el-input v-model="liquidate_amount"  placeholder="请输入归集值" size="small"></el-input>
        </div>
        <p v-show="liquidate_mode == 2">(请输入0-1范围值)</p>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">撮合池余额过低告警值</div>
        <div class="com_from_right">
          <el-input v-model="notice_amount"  placeholder="请输入撮合池余额过低告警值" size="small"></el-input>
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">一天周期借币单间隔</div>
        <div class="com_from_right d_flex_1">
          <el-input v-model="one_day_min_sec"  placeholder="请输入最小值" size="small"></el-input>
          <span>-</span>
          <el-input v-model="one_day_max_sec"  placeholder="请输入最大值" size="small"></el-input>
          <span>秒</span>
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">一天周期借币单金额范围</div>
        <div class="com_from_right d_flex_1">
          <el-input v-model="one_day_borrow_min"  placeholder="请输入最小值" size="small"></el-input>
          <span>-</span>
          <el-input v-model="one_day_borrow_max"  placeholder="请输入最大值" size="small"></el-input>
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">一天周期借币单总额平衡值</div>
        <div class="com_from_right">
          <el-input v-model="one_day_borrow_thr"  placeholder="请输入一天周期借币单总额平衡值" size="small"></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">币种图片(JPG,PNG)*</div>
        <div class="com_from_right">
          <el-upload
            class="avatar-uploader"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            data="cn"
            :on-success="handleAvatarSuccess"
            :on-change="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrls" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="com_from_item" v-if="false">
        <div class="com_from_left">币种图片(SVG)*</div>
        <div class="com_from_right">
          <el-upload
            class="avatar-uploader"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-change="beforeAvatarUploadEn"
          >
            <img v-if="imageUrlEN" :src="imageUrlENs" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">链类型*</div>
        <div class="com_from_right">
          <el-select
            v-model="chain_type"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in chainTypeArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">是否推荐*</div>
        <div class="com_from_right">
          <el-select
            v-model="is_recommend"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">是否白名单*</div>
        <div class="com_from_right">
          <el-select
            v-model="is_white"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item" v-show="is_white">
        <div class="com_from_left">币种分区*</div>
        <div class="com_from_right">
          <el-select
            v-model="zone"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option
              label="主区"
              value="1"
            ></el-option>
            <el-option
              label="创业区"
              value="2"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">运营模式*</div>
        <div class="com_from_right">
          <el-select
            v-model="operating_mode"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option
              label="usdt模式"
              value="1"
            ></el-option>
            <el-option
              label="小币种模式"
              value="2"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">币种介绍</div>
        <div class="com_from_right">
          <el-input
            type="textarea"
            style="width: 400px"
            rows="10"
            v-model="tinyValCN"
            size="small"
          ></el-input>
          <!--                    <tinymces :value="tinyValCN" @input="tinyCNFN"></tinymces>-->
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">谷歌验证码</div>
        <div class="com_from_right">
          <el-input v-model="two_auth_code" placeholder="请输入" size="small"></el-input>

          <!--                    <tinymces :value="tinyValCN" @input="tinyCNFN"></tinymces>-->
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left"></div>
        <div class="com_from_right">
          <span class="btn" @click="sumbitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import tinymces from "../components/tinymce";

export default {
  name: "coinListEdit",
  data() {
    return {
      options1: [
        {value: true, label: "是"},
        {value: false, label: "否"}
      ],
      startTime: "",
      endTime: "",
      id: "",
      token_name: "",
      symbol: "",
      decimals: "",
      introduction: "",
      is_recommend: "",
      icon_url: "",
      chain_type: "",
      is_white: "",
      contract_addr: "",
      tinyValCN: "",
      two_auth_code: "",
      tinyValEN: "",
      imageUrls: "",
      imageUrl: "",
      imageUrlENs: "",
      imageUrlEN: "",
      zone: "",
      liquidate_amount: "",
      liquidate_mode: "",
      liquidate_thr: "",
      notice_amount: "",
      operating_mode: "",
      one_day_borrow_min: "",
      one_day_borrow_max: "",
      one_day_min_sec: "",
      one_day_max_sec: "",
      one_day_borrow_thr: "",
      infoData: {}
    };
  },
  created() {
    this.infoData = window.sessionStorage.getItem("listDataInfo")
      ? JSON.parse(window.sessionStorage.getItem("listDataInfo"))
      : {};
    this.options2 = JSON.parse(window.sessionStorage.getItem("chainType"));
    if (this.infoData.id) {
      this.id = this.infoData.id
      this.tinyValCN = this.infoData.introduction
      this.contract_addr = this.infoData.contract_addr
      this.is_white = this.infoData.is_white
      this.is_recommend = this.infoData.is_recommend
      this.chain_type = this.infoData.chain_type
      this.imageUrls = this.Utils.URL.goURL + this.infoData.icon_url
      this.imageUrl = this.infoData.icon_url
      this.decimals = this.infoData.decimals
      this.token_name  = this.infoData.token_name
      this.liquidate_amount  = this.infoData.liquidate_amount
      this.liquidate_mode  = this.infoData.liquidate_mode == 0 ? '' : this.infoData.liquidate_mode
      this.liquidate_thr  = this.infoData.liquidate_thr
      this.notice_amount  = this.infoData.notice_amount
      this.operating_mode  = this.infoData.operating_mode
      this.one_day_max_sec  = this.infoData.one_day_max_sec
      this.one_day_min_sec  = this.infoData.one_day_min_sec
      this.one_day_borrow_min  = this.infoData.one_day_borrow_min
      this.one_day_borrow_max  = this.infoData.one_day_borrow_max
      this.one_day_borrow_thr  = this.infoData.one_day_borrow_thr
      this.zone  = (this.infoData.zone == 0 ? '' : this.infoData.zone)
      this.symbol  = this.infoData.symbol
    }
  },
  components: {
    tinymces
  },
  methods: {
    tinyCNFN(msg) {
      this.tinyValCN = msg;
    },
    tinyENFN(msg) {
      this.tinyValEN = msg;
    },
    sumbitFn() {
      /*if (this.token === '' && this.chain_type != 'tl') {
              this.$message.error('请输入公链合约')
              return false
          }*/

      if (this.is_recommend === "") {
        this.$message.error("请选择推荐状态");
        return false;
      }
      if (this.is_white === "") {
        this.$message.error("请选择白名单");
        return false;
      }
      if (this.is_white && (this.zone === '' || this.zone == 0)) {
        this.$message.error("请选择分区");
        return false;
      }
      if (this.chain_type === "") {
        this.$message.error("请选择链类型");
        return false;
      }
      if (this.operating_mode === "") {
        this.$message.error("请选择运营模式");
        return false;
      }

      if (this.token_name === "") {
        this.$message.error("请输入币种全称");
        return false;
      }
      if (this.symbol === "") {
        this.$message.error("请输入币种简称");
        return false;
      }
      if (this.decimals === "") {
        this.$message.error("请输入币种精度");
        return false;
      }
      if (this.imageUrl === "") {
        this.$message.error("请上传币种图标");
        return false;
      }

      if (this.tinyValCN === "") {
        this.$message.error("请输入币种介绍");
        return false;
      }

      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }

      let liquidate_thr = parseFloat(this.liquidate_thr)
      let liquidate_amount = parseFloat(this.liquidate_amount)
      let one_day_max_sec = parseFloat(this.one_day_max_sec)
      let one_day_min_sec = parseFloat(this.one_day_min_sec)
      let one_day_borrow_min = parseFloat(this.one_day_borrow_min)
      let one_day_borrow_max = parseFloat(this.one_day_borrow_max)
      let one_day_borrow_thr = parseFloat(this.one_day_borrow_thr)

      if (this.liquidate_mode == 2) {
        if (liquidate_thr <= 0 ||  liquidate_amount >= 1) {
          this.$message.error("请输入0<归集值<1");
          return false;
        }
      }
      if (this.liquidate_mode === '') {
        this.$message.error("请选择归集模式");
        return false;
      }
      if (this.liquidate_mode !== '') {
        if (liquidate_thr > liquidate_amount) {
          this.$message.error("请输入正常归集范围值");
          return false;
        }
      }

      if (one_day_borrow_min < 0) {
        this.$message.error("请输入正常一天周期借币单最小值");
        return false;
      }
      if (one_day_borrow_min > one_day_borrow_max) {
        this.$message.error("请输入正常一天周期借币单范围值");
        return false;
      }
      if (one_day_min_sec < 0) {
        this.$message.error("请输入正常一天周期借币单间隔");
        return false;
      }
      if (one_day_min_sec > one_day_max_sec) {
        this.$message.error("请输入正常一天周期借币单间隔范围");
        return false;
      }
      if (one_day_borrow_thr < 0) {
        this.$message.error("请输入正常一天周期借币单总额平衡值");
        return false;
      }


      let url = this.infoData.id ? "coin/edit" : "coin/add";
      let req = {
        token_name: this.token_name,
        symbol: this.symbol,
        decimals: this.decimals,
        introduction: this.tinyValCN,
        icon_url: this.imageUrl,
        is_recommend: this.is_recommend,
        is_white: this.is_white,
        contract_addr: this.contract_addr,
        chain_type: this.chain_type,
        two_auth_code: this.two_auth_code,
        liquidate_amount: this.liquidate_amount,
        liquidate_mode: this.liquidate_mode,
        liquidate_thr: this.liquidate_thr,
        notice_amount: this.notice_amount,
        operating_mode: this.operating_mode,
        one_day_min_sec: this.one_day_min_sec,
        one_day_max_sec: this.one_day_max_sec,
        one_day_borrow_min: this.one_day_borrow_min,
        one_day_borrow_max: this.one_day_borrow_max,
        one_day_borrow_thr: this.one_day_borrow_thr,
      };

      if (this.infoData.id) {
        req.id = this.id;
      }
      if (this.is_white) {
        req.zone = this.zone
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
        .then(res => {
          this.$message.success("操作成功,两秒后自动跳转");
          setTimeout(() => {
            this.goBack();
          }, 1500);
        })
        .catch(res => {
          this.$message.error(res.msg);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      this.uploadFn(file, "cn");
    },
    beforeAvatarUploadEn(file) {
      this.uploadFn(file, "en");
    },
    uploadFn(file, type) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      const formData = new FormData();
      let str = file.name.split(".");
      let last = str[str.length - 1].toLowerCase();
      if (!/png|jpg|jpeg|svg/.test(last)) {
        this.$message.error("上传错误,文件格式必须为：png/jpg/jpeg");
        return;
      }
      let time = parseInt(new Date().getTime());
      str = "image_upload" + time + "." + last;
      formData.append("files", file.raw, str);
      formData.append("dir", "coinImg");
      this.$ajax(
        `${this.Utils.URL.goURL}upload`,
        formData,
        "post",
        250000,
        true
      )
        .then(res => {
          if (type == "en") {
            this.imageUrlENs = this.Utils.URL.goURL + res.url;
            this.imageUrlEN = res.url;
          } else {
            this.imageUrls = this.Utils.URL.goURL + res.url;
            this.imageUrl = res.url;
          }
        })
        .catch(res => {
          this.$message.error(res.msg);
        });
    }
  }
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>