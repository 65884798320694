<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>

    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">转出地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      
      <div class="com_item_search">
        <div class="com_item_tx">转出链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">转出币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">转出状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, key) in statusObj1"
                :key="key"
                :label="item"
                :value="key"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">转出交易Hash</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


        <div class="com_item_search">
          <div class="com_item_tx">接收地址</div>
          <div class="com_item_input">
            <el-input v-model="inputVal3" placeholder="请输入" size="small">
            </el-input>
          </div>
        </div>

        <div class="com_item_search">
          <div class="com_item_tx">接收链类型</div>
          <div class="com_item_input">
            <el-select
                v-model="selectVal4"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in chainTypeArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>

          </div>
        </div>

        <div class="com_item_search">
          <div class="com_item_tx">接收币种</div>
          <div class="com_item_input">
            <el-select
                v-model="selectVal5"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in coinInfo"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="com_item_search">
          <div class="com_item_tx">接收状态</div>
          <div class="com_item_input">
            <el-select
                v-model="selectVal6"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="(item, key) in statusObj"
                  :key="key"
                  :label="item"
                  :value="key"
              >
              </el-option>
            </el-select>
          </div>
        </div>

      <!-- <div class="com_item_search">
        <div class="com_item_tx">接收地址信息</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal7"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, key) in typeObj"
                :key="key"
                :label="item"
                :value="key"
            >
            </el-option>
          </el-select>
        </div>
      </div> -->

        <div class="com_item_search">
          <div class="com_item_tx">接收交易Hash</div>
          <div class="com_item_input">
            <el-input v-model="inputVal4" placeholder="请输入" size="small">
            </el-input>
          </div>
        </div>



      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>


    <div class="tab_box mt_40">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>转出地址</th>
        <th>转出链类型</th>
        <th>转出币种</th>
        <th>转出数量</th>
        <th>转出状态</th>
        <th>转出时间</th>
        <th>转出交易hash</th>
        <!-- <th>平台地址</th> -->
        <th>接收地址</th>
        <th>接收链类型</th>
        <!-- <th>接收地址信息</th> -->
        <th>接收币种</th>
        <th>接收数量</th>
        <th>接收状态</th>
        <th>接收时间</th>
        <th>接收交易hash</th>
        <th>备注</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.withdraw_addr }}</td>
          <td>{{ list.from_chain }}</td>
          <td>{{ list.deposit_coin_code }}</td>
          <td>{{ list.deposit_coin_amt }}</td>
          <td>{{ list.withdraw_state }}</td>
          <td>{{ list.out_time }}</td>
          <td>{{ list.withdraw_txid }}</td>
          <!-- <td>{{ list.platform_addr }}</td> -->
          <td>{{ list.destination_addr }}</td>
          <td>{{ list.rec_chain }}</td>
          <!-- <td>{{ list.title }}</td> -->
          <td>{{ list.receive_coin_code }}</td>
          <td>{{ list.receive_coin_amt }}</td>
          <td>{{ list.detail_state }}</td>
          <td>{{ list.in_time  }}</td>
          <td>{{ list.transaction_id }}</td>
          <td>{{ list.remark }}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.deposit_coin_amt)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.receive_coin_amt)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(sum.deposit_coin_amt)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(sum.receive_coin_amt)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "liquidityFlashOrder",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      selectVal5: '',
      selectVal6: '',
      // selectVal7: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      inputVal4: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      selectArr: [{value: '钱包', label: '钱包'}],
      subtotal: {},
      sum: {},
      statusObj: {
        'wait_deposit_send': '等待存币发送',
        'timeout': '超时',
        'wait_exchange_push': '等待交换信息推送',
        'wait_exchange_return': '等待交换信息返回',
        'wait_receive_send': '等待接收币种发送',
        'wait_receive_confirm': '等待转出确认',
        'receive_complete': '完成',
        'wait_refund_send': '等待退原币币种发送',
        'wait_refund_confirm': '等待退币确认',
        'refund_complete': '等待退币已完成',
        'confirm': '提币中',
        'success': '成功',
        'fail': '失败',
      },
      statusObj1: {
        'confirm': '提币中',
        'success': '成功',
        'fail': '失败',
      },
      typeObj: {
        'cold': '冷钱包地址',
        'autoInvest': '自动投资个人借币单地址',
        'rankOut': '排行榜出金地址',
        'rankBorrow': '排行榜借币地址',
        'rankInvest': '排行榜投资地址',
      },
      option2: [],
      coinInfo: [],
      tHeader: [],
      filterVal: [
        "withdraw_addr",
        "from_chain",
        "deposit_coin_code",
        "deposit_coin_amt",
        "withdraw_state",
        "out_time",
        "withdraw_txid",
        "platform_addr",
        "destination_addr",
        "rec_chain",
        "title",
        "receive_coin_code",
        "receive_coin_amt",
        "detail_state",
        "in_time",
        "transaction_id",
        "remark",
      ],
    }
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
      this.resInit()

  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.inputVal1) {
        req.addr_out = this.inputVal1;
      }
      if (this.inputVal2) {
        req.hash_out = this.inputVal2;
      }
      if (this.inputVal3) {
        req.addr_in = this.inputVal3;
      }
      if (this.inputVal4) {
        req.hash_in = this.inputVal4;
      }
      if (this.inputVal5) {
        req.public_addr = this.inputVal5;
      }
      if (this.inputVal6) {
        req.tx_id = this.inputVal6;
      }
      if (this.selectVal1) {
        req.chain_type_out = this.selectVal1;
      }
      if (this.selectVal2) {
        req.symbol_out = this.selectVal2;
      }
      if (this.selectVal3) {
        req.status_out = this.selectVal3;
      }
      if (this.selectVal4) {
        req.chain_type_in = this.selectVal4;
      }
      if (this.selectVal5) {
        req.symbol_in = this.selectVal5;
      }
      if (this.selectVal6) {
        req.status_in = this.selectVal6;
      }
      // if (this.selectVal7) {
      //   req.title = this.selectVal7;
      // }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/flash_chain_record_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.total = parseFloat(res.total)
            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.withdraw_state = this.statusObj1[list.withdraw_state] ? this.statusObj1[list.withdraw_state] : list.withdraw_state
              list.detail_state = this.statusObj[list.detail_state] ? this.statusObj[list.detail_state] : list.detail_state
              list.title = this.typeObj[list.title] ? this.typeObj[list.title] : list.title
              list.in_time = this.dataFormat(list.in_time)
              list.out_time = this.dataFormat(list.out_time)

            })
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>