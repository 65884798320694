<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>


    <section class="com_from_box mt_40">
      <div class="com_from_item">
        <div class="com_from_left">
          币种
        </div>
        <div class="com_from_right d_flex_1">
          <el-input
              v-model="coinVal"
              placeholder="请输入"
              size="small">
          </el-input>
          <el-button class="ml_10" type="primary" icon="el-icon-search" size="small" @click="resInit('search')">搜索</el-button>

        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          借币数量
        </div>
        <div class="com_from_right d_flex_1">
          <el-input
              v-model="minVal"
              placeholder="请输入100倍数"
              size="small">
          </el-input>
          <span>-</span>
          <el-input
            v-model="maxVal"
            placeholder="请输入100倍数"
            size="small">
        </el-input>



        </div>
      </div>
<!--      <div class="com_from_item">
        <div class="com_from_left">
          借币基数
        </div>
        <div class="com_from_right d_flex_1">
          <el-input
              v-model="borrow_base"
              placeholder="请输入"
              size="small">
          </el-input>

        </div>
      </div>-->

      <div class="com_from_item">
        <div class="com_from_left">
          投资白名单币种
        </div>
        <div class="com_from_right d_flex">
          <el-checkbox-group v-model="checkArr">
            <el-checkbox  v-for="list in coinArr" :key="list.id" :value="list.id" :label="list.id" size="large" >{{list.symbol}} </el-checkbox>

          </el-checkbox-group>


        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          保证金是否使用虚拟资产
        </div>
        <div class="com_from_right">
          <el-select
              v-model="pledge_virtual"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option label="是" :value="true">是</el-option>
            <el-option label="否" :value="false">否</el-option>
          </el-select>
        </div>
      </div>


      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>


      <div class="com_from_item" >
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "fundCoin",
  data() {
    return {
      wallet_id: '',
      typeObj: {
        1: {label: '正常', value: '1'},
        2: {label: '禁止提币', value: '2'},
        3: {label: '禁止投资', value: '3'},
        4: {label: '禁止借款', value: '4'},
        5: {label: '禁止交易', value: '5'},
        6: {label: '禁止全部交易', value: '6'},
      },
      remark: '',
      borrow_base: '',
      statusVal: '',
      two_auth_code: '',
      minVal: '',
      maxVal: '',
      coinVal: '',
      pledge_virtual: '',
      numArr: [],
      coinArr: [],
      coinData: [],
      checkArr: [],
      resData: [],
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    resInit(type) {
      let req = {}
      if (this.coinVal) {
        req.symbol = this.coinVal
      }

      this.$ajax(`${this.Utils.URL.goURL}trade/coin`, req, 'get')
          .then(res => {

            this.coinArr = res.trade_coins ? res.trade_coins : []
            if (type != 'search') {
              this.borrow_base = res.borrow_base ? res.borrow_base : ''
              this.maxVal = res.max_borrow ? res.max_borrow : ''
              this.minVal = res.min_borrow ? res.min_borrow : ''
              this.pledge_virtual = res.pledge_virtual ? res.pledge_virtual : false
              let arr = res.trade_coins ? JSON.stringify( res.trade_coins) : '[]'
              this.coinData = JSON.parse(arr)
              this.coinData.forEach((val, index) => {
                this.coinData[index].auto_trade = false
              })
              this.checkArr = []
              this.coinArr.forEach(val => {
                if (val.auto_trade) {
                  this.checkArr.push(val.id)
                }
              })
            }
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn() {
      let max = parseFloat(this.maxVal)
      let min = parseFloat(this.minVal)


      if (min > max) {
        this.$message.error('请选择正常的借币数量范围')
        return false
      }
      if (min % this.devModulo !== 0) {
        this.$message.error('最小数量不是100的倍数')
        return false
      }
      if (max % this.devModulo !== 0) {
        this.$message.error('最大数量不是100的倍数')
        return false
      }
      if (this.checkArr.length ) {
        this.coinData.forEach((val, index) => {
          this.coinData[index].auto_trade = false
          this.checkArr.forEach(check => {
            if (val.id == check) {
              this.coinData[index].auto_trade = true
            }
          })
        })
      }

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }



      let req = {
        borrow_base: this.borrow_base,
        max_borrow: this.maxVal,
        min_borrow: this.minVal,
        trade_coins: this.coinData,
        pledge_virtual: this.pledge_virtual,
        two_auth_code: this.two_auth_code
      }


      this.$confirm('是否提交', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$ajax(`${this.Utils.URL.goURL}trade/edit_coin`, req, 'post', 2500000, false, true)
            .then(res => {
              this.resInit()
              this.$message.success('操作成功')
            })
            .catch(res => {
              this.$message.error(res.msg)
            })

      }).catch(() => {

      });


    },
  }
}
</script>

<style scoped>

</style>