<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">


      <div class="com_item_search">
        <div class="com_item_tx">合同编号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">借贷共管地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">订单盈亏数量</div>
        <div class="com_item_input d_flex">
          <div class="com_item_input">
            <el-input
                v-model="inputVal3"
                placeholder="请输入最小值"
                size="small">
            </el-input>

          </div>
          <span>-</span>
          <div class="com_item_input">
            <el-input
                v-model="inputVal4"
                placeholder="请输入最大值"
                size="small">
            </el-input>
          </div>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">订单状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">对账日期</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D"
              value-format="YYYY-M-D"
              placeholder="选择日期时间">
          </el-date-picker>
          -
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D"
              value-format="YYYY-M-D"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>

    </div>
    <div class="">
      <div class="tab_box mt_40 ">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>对账日期</th>
        <th>对账数据范围(开始)</th>
        <th>对账数据范围(结束)</th>
        <th>合同编号</th>
        <th>借贷共管地址</th>
        <th>链类型</th>
        <th>币种</th>
        <th>借币数量</th>
        <th>盈亏数量</th>
        <th>订单状态</th>
        <th>合同开始时间</th>
        <th>订单清算时间</th>

        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.datestr}}</td>
          <td>{{list.start_block_time}}</td>
          <td>{{list.end_block_time}}</td>
          <td>{{list.ad_id}}</td>
          <td>{{list.addr}}</td>
          <td>{{ list.chain_type }}</td>
          <td>{{ list.coin_symbol }}</td>
          <td>{{ list.borrow_amount }}</td>
          <td>{{ list.net_profit }}</td>
          <td>{{ list.status_tx }}</td>
          <td>{{ list.start_at }}</td>
          <td>{{ list.end_at }}</td>
        </tr>

        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(subtotal)}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(sum)}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>



        </tbody>
      </table>
    </div>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>




  </article>
</template>

<script>
export default {
  name: "mechanismProfitExcel",
  data() {
    return {
      resData: [],
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: 'USDT',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      inputVal4: '',
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      curIndex: 0,
      curType: '',
      page: 1,
      size: 10,
      total: '',
      startTime: '',
      endTime: '',
      tHeader: [],
      isAlert: false,
      two_auth_code: '',
      typeObj: {
        'BankCard': {value: 'BankCard', label: '银行卡'},
        'Alipay': {value: 'Alipay', label: '支付宝'},
        'WeChat': {value: 'WeChat', label: '微信'},
      },
      typeObj1: {
        '1': {value: '1', label: '到期已清算'},
        '2': {value: '2', label: '放款成功还款中'},
        '3': {value: '3', label: '还款失败'},
        '4': {value: '4', label: '清算失败'},
      },
      coinInfo: [],
      sum: '',
      subtotal: '',

      filterVal: [
        "datestr",
        "start_block_time",
        "end_block_time",
        "ad_id",
        "addr",
        "chain_type",
        "coin_symbol",
        "borrow_amount",
        "net_profit",
        "status_tx",
        "start_at",
        "end_at",

      ],
    }
  },
  created() {
    if (this.$route.query.symbol) {
      this.selectVal4 = this.$route.query.symbol
    }
    if (this.$route.query.chain_type) {
      this.selectVal3 = this.$route.query.chain_type
    }
    if (this.$route.query.startTime) {
      this.startTime = this.dataFormat(new Date(this.$route.query.startTime).getTime() / 1000, 'yyyy-M-d')

    }
    if (this.$route.query.endTime) {
      this.endTime = this.dataFormat((new Date(this.$route.query.endTime).getTime() / 1000) + 86400, 'yyyy-M-d')
    }
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.resInit()
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },

    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.inputVal1) {
        req.ad_id = this.inputVal1
      }
      if (this.inputVal2) {
        req.addr = this.inputVal2
      }
      if (this.inputVal3) {
        req.net_profit_min = this.inputVal3
      }
      if (this.inputVal4) {
        req.net_profit_max = this.inputVal4
      }
      if (this.selectVal1) {
        req.status = this.selectVal1
      }
      if (this.selectVal2) {
        req.pay_type = this.selectVal2
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4
      }

      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}organ_profit`, req, 'get')
          .then(res => {
            this.resData= res.list ? res.list : []
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              list.status_tx = this.typeObj1[list.status] ? this.typeObj1[list.status].label: list.status

              list.start_block_time = this.dataFormat(list.start_block_time)
              list.end_block_time = this.dataFormat(list.end_block_time)
              list.start_at = this.dataFormat(list.start_at)
              list.end_at = this.dataFormat(list.end_at)
            })
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    }
  },
}
</script>

<style scoped>

</style>