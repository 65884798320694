<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">社区名称</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">奖励时间</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>

    </div>

    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>


    <div class="">
      <table class="com-table  mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>钱包ID</th>
        <th>社区名称</th>
        <th>奖励时间</th>
        <th>本月贡献值</th>
        <th>本月奖励(USDT)</th>
        <th>上月结余(USDT)</th>
        <th>本月实发(USDT)</th>
        <th>本月结余(USDT)</th>
        <th>本月发放总额(USDT)</th>
        <th>bsc钱包地址</th>
        <th>状态</th>
        <th>审核时间</th>
        <th>hash</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.user_id}}</td>
          <td>{{list.community_name}}</td>
          <td>{{list.reward_time}}</td>
          <td>{{list.net_income}}</td>
          <td>{{list.curr_reward}}</td>
          <td>{{list.upon_surplus}}</td>
          <td>{{list.curr_actual_amount}}</td>
          <td>{{list.curr_surplus}}</td>
          <td>{{list.curr_total}}</td>
          <td>{{list.bsc_addr}}</td>
          <td>{{list.status_tx}}</td>
          <td>{{list.review_at}}</td>
          <td>{{list.hash}}</td>
          <td class="tab_btn">
            <span class="btn " v-if="list.status == 1" @click="showFn(index, 2)">通过</span>
            <span class="btn " v-if="list.status == 1" @click="showFn(index, 3)">拒绝</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <el-dialog v-model="isShow" :title="curType == 2 ? '审核通过' : '审核拒绝' " width="500px">
      <section class="com_from_box">

        <div class="com_from_item" >
          <div class="com_from_left">审核备注</div>
          <div class="com_from_right" >
            <el-input type="textarea"
                      rows="5"
                      v-model="reason"
                      size="small">

            </el-input>

          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">温馨提示</div>
          <div class="com_from_right">
            {{curType == 2 ? '确定通过本次申请?' :  '确定拒绝本次申请？'}}
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isShow = false">取消</el-button>
                    <el-button type="primary" @click="editRateFn">提交</el-button>
                </span>
      </template>
    </el-dialog>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>



  </article>
</template>

<script>
export default {
  name: "communityAwardReport",
  data() {
    return {
      page: 1,
      size: 10,
      total: '',
      options: [],
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      expenses: '',
      reason: '',
      min: '',
      max: '',
      isShow: false,
      passShow: false,
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      typeObj: {
        1: {label: '工作室', value: '1'},
        2: {label: '俱乐部', value: '2'},
        3: {label: '体系', value: '3'},
        4: {label: '分公司', value: '4'},
      },
      typeObj1: {
        1: {label: '待审核', value: '1'},
        2: {label: '已通过', value: '2'},
        3: {label: '已拒绝', value: '3'},
        4: {label: '已发放', value: '4'},
      },
      typeObj2: {
        1: {label: '1月', value: '1'},
        2: {label: '2月', value: '2'},
        3: {label: '3月', value: '3'},
        4: {label: '4月', value: '4'},
        5: {label: '5月', value: '5'},
        6: {label: '6月', value: '6'},
        7: {label: '7月', value: '7'},
        8: {label: '8月', value: '8'},
        9: {label: '9月', value: '9'},
        10: {label: '10月', value: '10'},
        11: {label: '11月', value: '11'},
        12: {label: '12月', value: '12'},
      },
      filterVal: [
        "user_id",
        "community_name",
        "reward_time",
        "net_income",
        "curr_reward",
        "upon_surplus",
        "curr_actual_amount",
        "curr_surplus",
        "curr_total",
        "bsc_addr",
        "status_tx",
        "review_at",
        "hash",
      ],
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    showFn (index, type) {
      this.curIndex = index
      this.curType = type
      this.two_auth_code = ''
      this.reason = ''

      this.isShow = true
    },
    editRateFn () {
      let index = this.curIndex

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let url = 'submit_award_review'
      let req = {
        id: this.resData[index].id,
        status: this.curType,
        two_auth_code: this.two_auth_code,
      }

      if (this.reason == '') {

        this.$message.error('请填写备注')
        return  false
      }

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.$message.success('操作成功')
            this.resInit()
            this.isShow = false
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.user_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.community_name = this.inputVal2;
      }
      if (this.inputVal3) {
        req.name = this.inputVal3;
      }
      if (this.inputVal4) {
        req.addr = this.inputVal3;
      }
      if (this.selectVal1) {
        req.reward_time = this.selectVal1;
      }
      if (this.selectVal2) {
        req.community_level = this.selectVal2;
      }
      if (this.selectVal3 !== '') {
        req.status = this.selectVal3;
      }
      if (this.selectVal4) {
        req.fail_status = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}award_review_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :

              list.status_tx = this.typeObj1[list.status] ? this.typeObj1[list.status].label: list.status
              list.review_at = this.dataFormat(list.review_at)
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>