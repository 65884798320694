<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">团队长钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search" v-show="false">
        <div class="com_item_tx">管理级别</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, index) in levelArr"
                :key="index"
                :label="item"
                :value="index"
            >
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="goRouter('nextDetails', {wallet_id: 'ID0000000000000000000000000000000000000001'})"
        >查看全部团队数据</el-button
        >

      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>团队长钱包ID</th>
        <th>所属上级</th>
        <th>管理级别</th>
        <th>直接下级人数</th>
        <th>团队总人数</th>
        <th>充值总额</th>
        <th>提币总额</th>
        <th>团队净入金总额</th>
        <th>團隊當前認購人數</th>
        <th>團隊當前認購數量</th>
        <th>直間邀管理層 USDT</th>
        <th>直間邀管理層 FUSD</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('nextDetails', {wallet_id: list.user_id})">{{list.user_id}}</span>
          </td>
          <td class="color_T1" @click="goRouter('teamLendingReport', {team_wallet_id: list.parent_id})">{{list.parent_id}}</td>
          <td>{{list.level}}</td>
          <td>{{list.next_numb}}</td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('personalLendingReport', {team_wallet_id: list.user_id, start: startTime, end: endTime})">{{list.team_numb}}</span>
          </td>
          <td>{{scientificNotationToString(list.recharge)}} USDT</td>
          <td>{{scientificNotationToString(list.withdraw)}} USDT</td>
          <td>{{scientificNotationToString(list.net_income)}} USDT</td>
          <td>{{scientificNotationToString(list.mortgage_numb)}} </td>
          <td>{{scientificNotationToString(list.mortgage_lp_amt)}} </td>
          <td>{{scientificNotationToString(list.team_profit_usdt)}} </td>
          <td>{{scientificNotationToString(list.team_profit_fusd)}} </td>
        </tr>

        <tr v-show="resData.length">
          <td>U资产统计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(usd_total.recharge)}} USDT</td>
          <td>{{scientificNotationToString(usd_total.withdraw)}} USDT</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{scientificNotationToString(usd_total.team_profit_usdt)}} </td>
          <td>{{scientificNotationToString(usd_total.team_profit_fusd)}} </td>
        </tr>
        <tr v-show="resData.length">
          <td>非U资产统计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <p style="white-space: nowrap;"  v-for="(list, idx) in other_total.recharge" :key="idx">{{list.amount}} {{list.code}}</p>
          </td>
          <td>
            <p style="white-space: nowrap;"  v-for="(list, idx) in other_total.withdraw" :key="idx">{{list.amount}} {{list.code}}</p>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <!-- <td>
            <p style="white-space: nowrap;"  v-for="(list, idx) in other_total.mortgage_profit" :key="idx">{{list.amount}} {{list.code}}</p>
          </td>
          <td>
            <p style="white-space: nowrap;"  v-for="(list, idx) in other_total.team_profit" :key="idx">{{list.amount}} {{list.code}}</p>
          </td> -->
        </tr>


        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "teamLendingReport",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      other_total: {},
      usd_total: {},
      startTime: '',
      endTime: '',
      sum: {},
      is_top: '',
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      filterVal: [
        "user_id",
        "parent_id",
        "level",
        "next_numb",
        "team_numb",
        "recharge",
        "withdraw",
        "net_income",
        "mortgage_numb",
        "mortgage_lp_amt",
        "indirect_profit",
        "admin_profit",
      ],
    };
  },
  created() {
    this.inputVal1 = this.$route.query.team_wallet_id ? this.$route.query.team_wallet_id : ''
    this.startTime = this.$route.query.start || this.startTime ? this.$route.query.start || this.startTime : ''
    this.endTime = this.$route.query.end || this.endTime ? this.$route.query.end || this.endTime : ''
    this.page = this.$route.query.page ? parseFloat(this.$route.query.page) : this.page
    this.size = this.$route.query.size ? parseFloat(this.$route.query.size) : this.size
    if (this.inputVal1) {

      this.resInit();
    }

    // this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };

      if (this.inputVal1) {
        req.team_wallet_id = this.inputVal1;
      } else {
        this.$message.error('请输入团队长钱包ID');
        return false
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.tx_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      let query = Object.assign({}, req)
      this.$ajax(`${this.Utils.URL.goURL}team_captain_details`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(this.resData.length);
            this.other_total = res.other_total ? res.other_total : {}
            if (this.other_total?.recharge) {
              this.other_total.recharge.forEach((list) => {
                list.code = list.code == 'SCF' && list.coin_id == 'ftcmain' ? 'SCF MAIN' : list.code == 'SCF' && list.coin_id == 'ftc' ? 'SCF FIN' : list.code == 'FTC' ? 'FTC BSC' : list.code
              })
            }
            if (this.other_total?.withdraw) {
              this.other_total.withdraw.forEach((list) => {
                list.code = list.code == 'SCF' && list.coin_id == 'ftcmain' ? 'SCF MAIN' : list.code == 'SCF' && list.coin_id == 'ftc' ? 'SCF FIN' : list.code == 'FTC' ? 'FTC BSC' : list.code
              })
            }
            if (this.other_total?.mortgage_profit) {
              this.other_total.mortgage_profit.forEach((list) => {
                list.amount = this.getSixDecimal(list.amount)
              })
            }
            this.usd_total = res.usd_total ? res.usd_total : {}
            this.resData.forEach((list) => {
              list.level = this.levelArr[list.level]
            })
            this.$router.replace({path: 'teamLendingReport',query: query})
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
  watch: {
    $route(next,old) {
      if (next.path == old.path) {
        window.scrollTo(0, 0)
        if (this.inputVal1 != this.$route.query.team_wallet_id) {
          this.inputVal1 = this.$route.query.team_wallet_id
          this.resInit()
        }
      }

    }
  }
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>