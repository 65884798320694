<template>
  <article class="">
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">代币全称</div>
        <div class="com_item_input">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">代币简称</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal2"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">发行地址</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal3"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">发行状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>




      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>

    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>代币发行地址</th>
        <th>链类型</th>
        <th>状态</th>
        <th>代币全称</th>
        <th>代币简称</th>
        <th>币种精度</th>
        <th>发行数量</th>
        <th>流动池交易对</th>
        <th>流动性</th>
        <th>真实用户流动性</th>
        <th>真实用户持有代币数量(含钱包内代币和Swap内代币)</th>
        <th>真实用户持有代币占比</th>
        <th>代币合约信息</th>
        <th>代币发行时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.owner_addr }}</td>
          <td>{{ list.chain_type }}</td>
          <td>{{ typeObj[list.state] ? typeObj[list.state].label : list.state }}</td>
          <td>{{ list.token_name }}</td>
          <td>{{ list.symbol }}</td>
          <td>{{ list.decimals }}</td>
          <td>{{ list.total_supply }}</td>
          <td>{{ list.pair_symbol }}</td>
          <td>
            <p v-for="liquidity in list.liquidity">{{liquidity.amount}} {{liquidity.symbol}}</p>
          </td>
          <td>
            <p v-for="real in list.real_hold_liquidity">{{real.amount}} {{real.symbol}}</p>
          </td>
          <td>{{ list.real_hold_token_total }} {{list.symbol}}</td>
          <td>{{ list.real_hold_rate * 100 }}%</td>
          <td>{{ list.contract_addr }}</td>
          <td>{{ dataFormat(list.deploy_at) }}</td>

          <td class="tab_btn">
            <span class="btn" @click="showAlertFn(index)" v-if="isEditButton">自动清仓阈值设置</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" title="自动清仓阈值设置" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">真实用户持有代币占比清仓阈值</div>
          <div class="com_from_right d_flex_1">
            <el-input v-model="clean_threshold" type="text" placeholder="请输入" size="small"></el-input>
            <span>%</span>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
        <div>
          当真实用户持有的代币的数量占比达到设置值时，系统地址会自动抛售所持代币
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="editPassFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "tokenList",
  data() {
    return {
      options1: [{value: true, label: '是'}, {value: false, label: '否'}],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      startTime: '',
      endTime: '',
      typeObj: {
        'waitFlash': {label: '闪兑中', value: 'waitFlash'},
        'waitDeploy': {label: '发布中', value: 'waitDeploy'},
        'tokenSuccess': {label: '合约已发布,未添加流动性', value: 'tokenSuccess'},
        'deploySuccess': {label: '合约已发布,已添加流动性', value: 'deploySuccess'},

      },
      isAlert: false,
      clean_threshold: '',
      two_auth_code: '',
      selectArr: [{value: '钱包', label: '钱包'}]
    }
  },
  created() {

    this.resInit()
  },
  methods: {
    showAlertFn(index) {
      this.curIndex = index
      this.clean_threshold = (this.resData[this.curIndex].clean_threshold * 100)
      this.two_auth_code = ''
      this.isAlert = true
    },
    editPassFn() {
      if (this.clean_threshold === "") {
        this.$message.error("真实用户持有代币占比清仓阈值");
        return false;
      }

      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {
        id: this.resData[this.curIndex].id,
        clean_threshold: (this.clean_threshold / 100),
        two_auth_code: this.two_auth_code,

      };
      let url = "contract/edit_clean_threshold";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isAlert = false;
            this.resData[this.curIndex].clean_threshold = (this.clean_threshold / 100)
            this.$message.success("修改成功");
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.state = this.selectVal1
      }
      if (this.selectVal2) {
        req.chain_type = this.selectVal2
      }
      if (this.inputVal1) {
        req.token_name = this.inputVal1
      }
      if (this.inputVal2) {
        req.symbol = this.inputVal2
      }
      if (this.inputVal3) {
        req.owner_addr = this.inputVal3
      }
      if (this.startTime) {
        req.begin = this.dataFormat((new Date(this.startTime)).getTime() / 1000)
      }
      if (this.endTime) {
        req.end = this.dataFormat((new Date(this.endTime)).getTime() / 1000)
      }

      this.$ajax(`${this.Utils.URL.goURL}contract/list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },


  }

}
</script>

<style scoped>

</style>