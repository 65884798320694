<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">借款地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">共管多签地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>



      <div class="com_item_search">
        <div class="com_item_tx">投资代币币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinArr"
                :key="item.id"
                :label="item.symbol"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">投资状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">合同状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">投资涨跌幅排序</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                label="升序"
                value="asc"
            >
              升序
            </el-option>
            <el-option
                label="降序"
                value="desc"
            >
              降序
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>



      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>借币数量（USDT）</th>
        <th>投资代币币种</th>
        <th>投资代币数量</th>
        <th>投资代币价值（USDT）</th>
        <th>投资盈亏值</th>
        <th>投资涨跌幅</th>
        <th>USDT余额</th>
        <th>投资状态</th>
        <th>合同状态</th>
        <th>借款地址</th>
        <th>共管多签地址</th>
        <th>合同编号</th>
        <th>订单计息时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{scientificNotationToString(list.amount)}}</td>
          <td>{{list.coin_symbol}}</td>
          <td>{{scientificNotationToString(list.token_bal)}}</td>
          <td>{{scientificNotationToString(list.token_value)}}</td>
          <td>{{list.surplus_lose_value}}</td>
          <td>{{list.profit_rate}}</td>
          <td>{{scientificNotationToString(list.usdt_bal)}}</td>
          <td>{{list.swap_state}}</td>
          <td>{{list.borrow_status_tx}}</td>
          <td>{{list.address}}</td>
          <td>{{list.borrow_addr}}</td>
          <td>{{list.ad_id}}</td>
          <td>{{list.start_at}}</td>
          <td class="tab_btn">
            <span v-if="isEditButton && list.borrow_status == 4" @click="swapFn(index, 1)" class="btn">USDT兑换成代币</span>
            <span v-if="isEditButton && list.borrow_status == 4" @click="swapFn(index, 2)" class="btn">代币兑换成USDT</span>
          </td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td>{{scientificNotationToString(subtotal.amount)}} USDT</td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.token_bal)}} </td>
          <td>{{scientificNotationToString(subtotal.token_value)}} USDT</td>
          <td>{{scientificNotationToString(subtotal.surplus_lose_value)}}</td>
          <td></td>
          <td>{{scientificNotationToString(subtotal.usdt_bal)}} USDT</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td>{{scientificNotationToString(sum.amount)}} USDT</td>
          <td></td>
          <td>{{scientificNotationToString(sum.token_bal)}} </td>
          <td>{{scientificNotationToString(sum.token_value)}} USDT</td>
          <td>{{scientificNotationToString(sum.surplus_lose_value)}}</td>
          <td></td>
          <td>{{scientificNotationToString(sum.usdt_bal)}} USDT</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "loanOrder",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      inputVal5: "",
      inputVal6: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "asc",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: '',
      endTime: '',
      sum: {},
      typeObj1: {
        1: {label: '未投资', value: '1'},
        2: {label: '投资中', value: '2'},
        3: {label: '已投资', value: '3'},
      },
      typeObj: {
        0: {label: '准备借款', value: '0'},
        1: {label: '申请中', value: '1'},
        2: {label: '申请成功募集中', value: '2'},
        3: {label: '募集成功放款中', value: '3'},
        4: {label: '放款成功还款中', value: '4'},
        5: {label: '提前还款中', value: '5'},
        6: {label: '已还款成功', value: '6'},
        7: {label: '申请失败', value: '7'},
        8: {label: '募集失败', value: '8'},
        9: {label: '放款失败', value: '9'},
        10: {label: '还款失败', value: '10'},
        11: {label: '交易亏损', value: '11'},
        12: {label: '系统强制平仓中', value: '12'},
        13: {label: '系统强制平仓完成', value: '13'},
        14: {label: '管理后台强制平仓中', value: '12'},
        15: {label: '管理后台强制平仓完成', value: '13'},
      },
      curIndex: '',
      coinArr: [],
      filterVal: [
        "amount",
        "coin_symbol",
        "token_bal",
        "token_value",
        "profit_rate",
        "usdt_bal",
        "swap_state",
        "borrow_status",
        "address",
        "borrow_addr",
        "ad_id",
        "start_at",
      ],
    };

  },
  created() {
    this.coinInit()
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    coinInit() {
      let req = {}


      this.$ajax(`${this.Utils.URL.goURL}trade/coin`, req, 'get')
          .then(res => {
            this.coinArr = res.trade_coins ? res.trade_coins : []

          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    swapFn (index, type) {
      this.curIndex = index
      let title = type == 1 ? `确定使用 ${this.resData[index].usdt_bal} USDT 兑换 ${this.resData[index].coin_symbol}?` : `确定使用 ${this.resData[index].token_bal} ${this.resData[index].coin_symbol}兑换USDT?`
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}trade/swap`, {
          op: type,
          ad_id: this.resData[index].ad_id
        }, 'post')
            .then(res => {
              this.resInit()
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.address = this.inputVal1;
      }
      if (this.inputVal2) {
        req.borrow_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.inputVal4) {
        req.min_amount = this.inputVal4
      }
      if (this.inputVal5) {
        req.max_amount = this.inputVal5
      }
      if (this.inputVal6) {
        req.user_id = this.inputVal6
      }
      if (this.selectVal1) {
        req.coin_id = this.selectVal1;
      }
      if (this.selectVal2) {
        req.swap_state = this.selectVal2;
      }
      if (this.selectVal3) {
        req.borrow_status = this.selectVal3;
      }
      if (this.selectVal4) {
        req.invest_sort = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}trade/borrow_order`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.borrow_status_tx = this.typeObj[list.borrow_status] ? this.typeObj[list.borrow_status].label : list.borrow_status
              list.swap_state = this.typeObj1[list.swap_state] ? this.typeObj1[list.swap_state].label : list.swap_state
              list.start_at = this.dataFormat(list.start_at)

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>