<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">訂單編號</div>
        <div class="com_item_input">
          <el-input v-model="orderId" placeholder="請輸入訂單編號" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>


    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序號</th>
        <th>錢包暱稱</th>
        <th>訂單編號</th>
        <th>認購數量</th>
        <th>認購單價</th>
        <th>認購hash</th>
        <th>當前剩餘數量</th>
        <th>賣家錢包ID</th>
        <th>販賣數量</th>
        <th>付款(FSU)hash</th>
        <th>付款時間</th>
        <th>收款數量</th>
        <th>收款hash</th>
        <th>收款時間</th>
        <th>備註</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.nickname }}</td>
          <td>{{ list.order_id }}</td>
          <td>{{ list.purchase_amount }}</td>
          <td>{{ list.price }}</td>
          <td>{{ list.payment_hash }}</td>
          <td>{{ list.left_amount }}</td>
          <td>{{ list.sell_user_id }}</td>
          <td>{{ list.sell_amount }}</td>
          <td>{{ list.sell_hash }}</td>
          <td>{{ list.sell_at }}</td>
          <td>{{ list.rec_amount }}</td>
          <td>{{ list.rec_hash }}</td>
          <td>{{ list.rec_at }}</td>
          <td>{{ list?.remake }}</td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(subtotal.sub_purchase_amount) }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(subtotal.sub_sell_amount) }}</td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(subtotal.sub_rec_amount) }}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(sum.purchase_amount) }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(sum.sell_amount) }}</td>
          <td></td>
          <td></td>
          <td>{{ scientificNotationToString(sum.rec_amount) }}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "otc-fsu-sales-record",
  data() {
    return {
      page: 1,
      size: 100,
      total: 0,
      resData: [],
      inputVal1: "",
      inputVal2: "",
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        'nickname',
        'id',
        'purchase_amount',
        'price',
        'payment_hash',
        'left_amount',
        'sell_user_id',
        'sell_amount',
        'sell_hash',
        'sell_at',
        'rec_amount',
        'rec_hash',
        'rec_at',
        'remake'
      ],
      orderId: ''
    };
  },
  created() {
    // this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {

      let req = {
        page: this.page,
        size: this.size,
      };

      if (this.inputVal1) {
        req.sell_user_id = this.inputVal1
      }
      if (this.inputVal2) {
        req.sell_address = this.inputVal2
      }
      if (this.orderId) {
        req.order_id = this.orderId
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/system_order_sell_list`, req, "get")
          .then((res) => {
            if (res.list === null || res.list.length === 0) {
              this.$message.error("暂无数据");
              this.total = 0;
              this.resData = [];
              return;
            }
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {

            list.sell_at = list.sell_at > 0 ? this.dataFormat(list.sell_at) : '-'
            list.rec_at = list.rec_at > 0 ? this.dataFormat(list.rec_at) : '-'
            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>