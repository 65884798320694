<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="tab_box">
      <p class="tab_item " :class="tabIndex == 1 ? 'tab_item_cur' : ''" @click="tabFn(1)">商户详情</p>
      <p class="tab_item " :class="tabIndex == 2 ? 'tab_item_cur' : ''" @click="tabFn(2)">商户订单</p>
      <p class="tab_item " :class="tabIndex == 3 ? 'tab_item_cur' : ''" @click="tabFn(3)">收款方式</p>
      <p class="tab_item " :class="tabIndex == 4 ? 'tab_item_cur' : ''" @click="tabFn(4, 1)">充值记录</p>
      <p class="tab_item " :class="tabIndex == 5 ? 'tab_item_cur' : ''" @click="tabFn(5, 2)">提币记录</p>
      <p class="tab_item " :class="tabIndex == 6 ? 'tab_item_cur' : ''" @click="tabFn(6, 3)">罚金记录</p>
      <p class="tab_item " :class="tabIndex == 7 ? 'tab_item_cur' : ''" @click="tabFn(7, 4)">放币记录</p>
    </div>
    <div v-show="tabIndex == 1">
      <section class="com_from_box">
        <div class="com_from_item" >
          <div class="com_from_left">
            商户ID
          </div>
          <div class="com_from_right">
            {{ resData.id }}
          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">
            商户钱包ID
          </div>
          <div class="com_from_right">
            {{ resData.user_id }}
          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">
            商户名
          </div>
          <div class="com_from_right">
            {{ resData.merchant_nick }}
          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">
            OTC钱包地址
          </div>
          <div class="com_from_right">
            <p v-for="list in resData.quota_list">{{list.chain_type}}: {{list.sell_contract_addr}}</p>
          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">
            商户状态
          </div>
          <div class="com_from_right">
            {{ resData.merchant_status ? '正常' : '禁用' }}
          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">
            接单状态
          </div>
          <div class="com_from_right">
            {{ resData.is_market ? '开启' : '关闭' }}
          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">
            联系人
          </div>
          <div class="com_from_right">
            {{ resData.name }}
          </div>
        </div>
        <div class="com_from_item" >
          <div class="com_from_left">
            电话
          </div>
          <div class="com_from_right">
            +{{ resData.area_code }}-{{ resData.phone }}
          </div>
        </div>

        <div class="com_from_item" v-for="(lists, index) in resData.social_account_list">
          <div class="com_from_left">
            卖家社交工具{{index + 1}}
          </div>
          <div class="com_from_right">
            <p >{{lists.name}}: {{lists.account}}</p>
          </div>
        </div>






        <div class="com_from_item" >
          <div class="com_from_left">

          </div>
          <div class="com_from_right">
            <span class="btn cur_po" @click="isAlert = true">{{ resData.merchant_status ? '禁用用户' : '恢复用户' }}</span>
          </div>

        </div>


      </section>

    </div>

    <div v-show="tabIndex == 2">


      <div>
        <table class="com-table ">
          <thead class="com_thead">
          <th>序号</th>
          <th>商户订单编号</th>
          <th>币种</th>
          <th>链类型</th>
          <th>收款币种</th>
          <th>单价</th>
          <th>最低收款额</th>
          <th>最高收款额</th>
          <th>剩余数量</th>
          <th>卖出数量</th>
          <th>待放币数量</th>
          <th>待付款</th>
          <th>申诉中</th>
          <th>订单状态</th>
          <th>收款账号</th>
          <th>订单创建时间</th>
          <th>订单下架时间</th>
          </thead>
          <tbody class="com_tbody">
          <tr v-for="(list, index) in excelData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{list.order_id}}</td>
            <td>{{list.symbol}}</td>
            <td>{{list.chain_type}}</td>
            <td>{{list.currency}}</td>
            <td>{{list.price}}</td>
            <td>{{list.min_quota}}</td>
            <td>{{list.max_quota}}</td>
            <td>{{list.surplus_amount}}</td>
            <td>{{list.sell_amount}}</td>
            <td>{{list.put_amount}}</td>
            <td>{{list.wait_amount}}</td>
            <td>{{list.appeal_amount}}</td>
            <td>{{list.status == 1 ? '出售中' : '已下架'}}</td>
            <td>
              <p v-for="pay in list.card_details">{{pay.pay_way == 'Alipay' ? '支付宝账号' : pay.pay_way == 'WeChat' ? '微信账号' : '银行账号'}}: {{pay.account}}</p>
            </td>
            <td>{{list.created_at}}</td>
            <td>{{dataFormat(list.down_at)}}</td>

          </tr>

          </tbody>
        </table>
      </div>
      <div class="com_page">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageFn"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100, 1000]"
            :page-size="size"
            layout="total, prev, pager, next, sizes"
            :total="total"
        >
        </el-pagination>
      </div>

    </div>

    <div v-show="tabIndex == 3">


      <div>
        <table class="com-table ">
          <thead class="com_thead">
          <th>序号</th>
          <th>收款货币</th>
          <th>收款类型</th>
          <th>姓名</th>
          <th>银行名称</th>
          <th>支行名行</th>
          <th>收款账号</th>
          <th>收款二维码</th>
          <th>状态</th>

          </thead>
          <tbody class="com_tbody">
          <tr v-for="(list, index) in excelData" :key="index">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{list.currency}}</td>
            <td>{{list.pay_way == 'Alipay' ? '支付宝' : list.pay_way == 'WeChat' ? '微信' : '银行卡'}}</td>
            <td>{{list.name}}</td>
            <td>{{list.bank_name}}</td>
            <td>{{list.bank_branch}}</td>
            <td>{{list.bank_account}}</td>
            <td><a v-if="list.qr_code" :href="Utils.URL.imgURL + list.qr_code" target="_blank"><img :src="Utils.URL.imgURL + list.qr_code" width="100"/></a></td>
            <td>{{list.status ? '启用' : '禁用'}}</td>

          </tr>



          </tbody>
        </table>
      </div>
      <div class="com_page">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageFn"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100, 1000]"
            :page-size="size"
            layout="total, prev, pager, next, sizes"
            :total="total"
        >
        </el-pagination>
      </div>

    </div>

    <div v-show="tabIndex == 4 || tabIndex == 5 || tabIndex == 6 || tabIndex == 7">


      <div>
        <table class="com-table ">
          <thead class="com_thead">
          <th>序号</th>
          <th>交易时间</th>
          <th>交易类型</th>
          <th>链类型</th>
          <th>币种</th>
          <th>交易数量</th>
          <th width="150">交易hash</th>
          </thead>
          <tbody class="com_tbody">
          <tr v-for="(list, index) in excelData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>
              {{list.created_at}}
            </td>
            <td>
              {{list.tx_type}}
            </td>
            <td>
              {{list.chain_type}}
            </td>
            <td>
              {{list.symbol}}
            </td>
            <td>
              {{scientificNotationToString(list.amount)}}
            </td>
            <td>{{ list.hash }}</td>
          </tr>

          </tbody>
        </table>
      </div>
      <div class="com_page">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageFn"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100, 1000]"
            :page-size="size"
            layout="total, prev, pager, next, sizes"
            :total="total"
        >
        </el-pagination>
      </div>

    </div>

    <el-dialog v-model="isAlert" :title="resData.merchant_status ? '禁用用户' : '恢复用户'" width="500px">
      <section class="com_from_box">

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>

      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="submitFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "merchantDetails",
  data() {
    return {
      isAlert: false,
      resData: {
        quota_list: [],
        social_account_list: [],
      },
      two_auth_code: '',
      tinyValCN: '',
      examine_status : '',
      tabIndex: 1,
      page: 1,
      size: 30,
      total: '',
      inputVal1: "",
      inputVal2: "",
      op_type: 1,
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      excelData: [],
      typeObj: {
        1: {label: '充值', value: '1'},
        2: {label: '提币', value: '2'},
        3: {label: '罚金', value: '3'},
        4: {label: '放币', value: '4'},
      },
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    tabFn (index, opIndex) {
      if (this.tabIndex == index) {
        return false
      }
      this.tabIndex = index
      this.op_type = opIndex
      if (index == 2 || index == 3 || index == 4 || index == 5 || index == 6 || index == 7) {
        this.page = 1
        this.excelData = []
        this.excelInit()
      }
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.excelInit();
    },
    pageFn(index) {
      this.page = index;
      this.excelInit();
    },
    excelInit() {
      let req = {
        page: this.page,
        size: this.size,
        merchant_id: this.$route.query.id
      };
      let url = ''
      if (this.tabIndex == 2) {
        url = 'buy/otc_merchant_order'
      } else if (this.tabIndex == 3) {
        url = 'buy/bank_list'
        req.card_type = 2
      } else {
        req.op_type = this.op_type
        url = 'buy/otc_recharge_list'
      }

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "get")
          .then((res) => {
            this.excelData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.excelData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.created_at = this.dataFormat(list.created_at)
              list.tx_type = this.typeObj[list.op_type] ? this.typeObj[list.op_type].label : list.op_type

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
    resInit () {
      this.$ajax(`${this.Utils.URL.goURL}buy/merchant_detail`, {
        merchant_id: this.$route.query.id
      }, 'get')
          .then(res => {
            this.resData = res ? res : {
              quota_list: [],
              social_account_list: [],
            }
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn() {

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      let url = 'buy/set_merchant_status'
      let req = {
        merchant_id: this.$route.query.id,
        status: !this.resData.merchant_status,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否提交', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success("操作成功");
              this.resData.merchant_status = !this.resData.merchant_status
              this.two_auth_code = ''
              this.isAlert = false
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    }
  }
}
</script>

<style  lang="scss">
.tab_box{
  display: flex;
  .tab_item{
    padding: 10px 18px;
    border: 1px solid $BR;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 40px;
  }
  .tab_item_cur{
    color: $FFF;
    background: $BG1;
  }

}

</style>