<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">地址</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <!-- <div class="com_item_search">
        <div class="com_item_tx">時間</div>
        <div class="com_item_input">
          <el-date-picker
            v-model="startTime"
            type="datetime"
            size="small"
            format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div> -->
      <!-- <div class="com_item_search">
        <div class="com_item_tx">請輸入任務編號</div>
        <div class="com_item_input">
          <el-input v-model="task_number" placeholder="請输入任務編號" size="small">
          </el-input>
        </div>
      </div> -->
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>

      </div>

    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
      <!-- <el-button type="primary" size="small" @click="isShow = true">取消任務</el-button> -->
    </div>
    <div>
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>地址</th>
          <th>策略類型</th>
          <th>鏈類型</th>
          <th>FTC數量</th><!-- update 0806 -->
          <th>FUSD/USDT數量</th><!-- update 0806 -->
          <th>哈希</th>
          <th>時間</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.addr }}</td>
            <td>{{ list.follow_type }}</td>
            <td>{{ list.chain_type }}</td>
            <td>{{ list.ftc_amount }}</td>
            <td>{{ list.usdt_amount }}</td>
            <td>{{ list.hash }}</td>
            
            <td>
              <p>{{ list.create_time.length > 0 ? list.create_time : '-' }}</p>
            </td>
          </tr>
          <tr v-show="resData.length == 0">
            <td colspan="10">目前没有执行中任务</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{scientificNotationToString(subtotal.ftc_amount)}}</td>
            <td> {{scientificNotationToString(subtotal.usdt_amount)}}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{scientificNotationToString(sum.ftc_amount)}}</td>
            <td> {{scientificNotationToString(sum.usdt_amount)}}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes, jumper" :total="total">
      </el-pagination>
    </div>

    <!-- <el-dialog v-model="isShow" title="取消任務" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">任務編號</div>
          <div class="com_from_right">
            <el-input v-model="task_number_id" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷哥驗證</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShow = false">取消</el-button>
          <el-button type="primary" @click="submitFn">提交</el-button>
        </span>
      </template>
    </el-dialog> -->
  </article>
</template>

<script>
export default {
  name: "ftcMarketRecord",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      subtotal: {},
      sum: {},
      // task_number: '',
      // task_number_id: '',
      // isShow: false,
      // two_auth_code: '',
      wallet_id: '',
      // startTime: '',
      tHeader: [],
      filterVal: [
        'addr',
        'follow_type',
        'chain_type',
        'amount',
        'hash',
        'FUSD/USDT數量',
        'create_time',
      ],
    };
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  created() {
    this.resInit();
  },
  methods: {
    // submitFn() {
    //   if (this.task_number_id.length == 0) {
    //     this.$message.error('請輸入任務編號');
    //     return false;
    //   }
    //   if (this.two_auth_code.length == 0) {
    //     this.$message.error('請輸入谷哥驗證');
    //     return false;
    //   }
    //   let req = {
    //     task_id: +this.task_number_id,
    //     two_auth_code: this.two_auth_code,
    //   };
    //   this.$ajax(`${this.Utils.URL.goURL}finswap-market/cancel_task`, req, 'post', 2500000, false, true)
    //     .then((res) => {
    //       if (res.result) {
    //         this.isShow = false
    //         this.$message.success('成功');
    //         this.resInit()
    //         this.task_number = ''
    //       } else {
    //         this.$message.error(res.msg);
    //       }
    //     })
    //     .catch((res) => {
    //       this.isShow = false
    //       this.$message.error(res.msg);
    //     });
    // },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id.length > 0) {
        req.address = this.wallet_id;
      }
      this.$ajax(`${this.Utils.URL.goURL}finswap-market/get_strategy_tx_list`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {
            
            list.create_time = this.dataFormat(list.create_time)
            // list.updated_time = this.dataFormat(list.updated_time)
            // list.execute_time = this.dataFormat(list.execute_time)
            list.follow_type = list.follow_type == 1 ? '策略一 跟隨' : list.follow_type == 2 ? '策略二 那邊高賣那個' : list.follow_type == 3 ? '你賣我賣' : '-';
            // list.status = list.status == 1 ? '待處理' : list.status == 2 ? '處理中' : list.status == 3 ? '失敗' : list.status == 4 ? '成功' : list.status == 5 ? '取消' : '-'
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>

</style>