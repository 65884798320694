<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">借贷多签地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">合同编号</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal4"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in coinInfo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>


      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >

      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>
    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>钱包ID</th>
        <th>借贷多签地址</th>
        <th>借贷地址</th>
        <th>合同编号</th>
        <th>链类型</th>
        <th>借贷币种</th>
        <th>借贷数量</th>
        <th>已借数量</th>
        <th>保证金数量</th>
        <th>合同状态</th>
        <th>合同周期</th>
        <th>日利率</th>
        <th>利息总额</th>
        <th>违约金支出</th>
        <th>合同开始时间</th>
        <th>合同到期时间</th>
        <th>详情</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.wallet_id}}
          </td>
          <td>
            {{list.multi_addr}}
          </td>
          <td>
            {{list.borrowing_addr}}
          </td>
          <td>
            {{list.numb}}
          </td>
          <td>
            {{list.chain_type}}
          </td>
          <td>
            {{list.symbol}}
          </td>
          <td>
            {{scientificNotationToString(list.borrowing_num)}}
          </td>
          <td>
            {{scientificNotationToString(list.curr_borrow)}}
          </td>
          <td>
            {{scientificNotationToString(list.PledgeNum)}}
          </td>
          <td>
            {{ typeObj[list.status] ? typeObj[list.status].label : list.status }}
          </td>
          <td>
            {{ list.invest_duration_day }}
          </td>
          <td>
            {{ list.daily_interest_rate }}
          </td>
          <td>
            {{ scientificNotationToString(list.interest) }}
          </td>
          <td>
            {{ scientificNotationToString(list.breach_contract) }}
          </td>
          <td>
            {{ list.start }}
          </td>
          <td>
            {{ list.End }}
          </td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('teamLoanFundraising', {code: list.numb})">查看</span>
          </td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

          <td> {{scientificNotationToString(subtotal.borrow_amount)}}</td>
          <td> {{scientificNotationToString(subtotal.curr_borrow)}}</td>
          <td> {{scientificNotationToString(subtotal.pledge_amount)}}</td>
          <td></td>
          <td></td>
          <td></td>

          <td> {{scientificNotationToString(subtotal.daily_borrow_rate)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.borrow_amount)}}</td>
          <td> {{scientificNotationToString(sum.curr_borrow)}}</td>
          <td> {{scientificNotationToString(sum.pledge_amount)}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.daily_borrow_rate)}}</td>

          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "teamLoanDetails",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      typeObj: {
        1: {label: '申请中', value: '1'},
        2: {label: '筹款中', value: '2'},
        3: {label: '募集成功-还款中', value: '3'},
        4: {label: '放款成功-还款中', value: '4'},
        5: {label: '提前还款中', value: '5'},
        6: {label: '已还款成功', value: '6'},
        7: {label: '申请失败', value: '7'},
        8: {label: '募集失败', value: '8'},
        9: {label: '放款失败', value: '9'},
        10: {label: '还款失败', value: '10'},
        11: {label: '正常还款', value: '11'},
        12: {label: '交易亏损-系统强制平仓中', value: '12'},
        13: {label: '交易亏损-系统强制平仓已完成', value: '13'},
        14: {label: '管理后台强制平仓中', value: '14'},
        15: {label: '管理后台强制平仓完成', value: '15'},
      },
      filterVal: [
        "wallet_id",
        "multi_addr",
        "borrowing_addr",
        "numb",
        "chain_type",
        "symbol",
        "lending_profit",
        "borrowing_num",
        "curr_borrow",
        "PledgeNum",
        "status",
        "invest_duration_day",
        "daily_interest_rate",
        "interest",
        "breach_contract",
        "start",
        "End",
      ],
    };
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
        (res) => {
          for (let key in res) {
            const val = { value: key, label: key };
            this.coinInfo.push(val);
          }
          // this.coinInfo = res.list;
        }
    );
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.$route.query.captain_id) {
        req.captain_id = this.$route.query.captain_id
      } else {
        return false
      }
      if (this.inputVal1) {
        req.wallet_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.numb = this.inputVal3;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.selectVal2) {
        req.tx_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}team_borrowing_details`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>