<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal6" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">用戶地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">付款地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">交易hash</div>
        <div class="com_item_input">
          <el-input v-model="inputVal3" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">范围</div>
        <div class="com_item_input">
          <el-input v-model="inputVal4" placeholder="请输入最小值" size="small">
          </el-input>

        </div>
        <span>-</span>
        <div class="com_item_input">
          <el-input v-model="inputVal5" placeholder="请输入最大值" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search" v-if="false">
        <div class="com_item_tx">交易方向</div>
        <div class="com_item_input">
          <el-select v-model="selectVal1" clearable filterable placeholder="请选择" size="small">
            <el-option value="1" label="确认中"></el-option>
            <el-option value="2" label="认购中"></el-option>
            <el-option value="3" label="失败"></el-option>
            <el-option value="4" label="失败-认购数量超出"></el-option>
            <el-option value="5" label="已完成"></el-option>
            <el-option value="6" label="失败-认购不足"></el-option>
          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">交易类型</div>
        <div class="com_item_input">
          <el-select v-model="selectVal2" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in typeObj" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select v-model="selectVal3" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in chainTypeArr" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种</div>
        <div class="com_item_input">
          <el-select v-model="selectVal4" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in coinInfo" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>交易时间</th>
          <th>交易类型</th>
          <th>链类型</th>
          <th>币种</th>
          <th>交易数量</th>
          <th width="150">钱包ID</th>
          <th width="150">用户地址</th>
          <th width="150">付款地址</th>
          <th width="150">交易hash</th>
          
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>
              {{ list.tx_time_stamp }}
            </td>
            <td>
              {{ list.tx_type }}
            </td>
            <td>
              {{ list.chain_type }}
            </td>
            <td>
              {{ list.symbol }}
            </td>
            <td>
              {{ scientificNotationToString(list.numb) }}
            </td>
            <td>
              {{ list.user_id }}
            </td>
            <td>
              {{ list.addr }}
            </td>
            <td>
              {{ list.wallet_addr }}
            </td>
            <td>{{ list.hash }}</td>
            
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(subtotal.amount) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(sum.amount) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>U资产统计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(usd_total.amount) }} USDT</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>非U资产统计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <p style="white-space: nowrap;" v-for="(list, index) in other_total.amount" :key="index">{{ list.amount }} {{ list.code }}</p>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "otc-withdrawList",
  data() {
    return {
      page: 1,
      size: 100,
      total: 0,
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      inputVal5: "",
      inputVal6: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      other_total: {},
      usd_total: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      typeObj: {
        61: { label: 'OTC委託銷售', value: '61' },
        62: { label: 'OTC委託取消退款', value: '62' },
      },
      filterVal: [
        "tx_time",
        "tx_type",
        "chain_type",
        "symbol",
        "numb",
        "user_id",
        "addr",
        "wallet_addr",
        "hash",
      ],
    };
  },
  created() {
    this.$ajax(`${this.Utils.URL.goURL}get_coin_option`, {}, "get").then(
      (res) => {
        for (let key in res) {
          const val = { value: key, label: key };
          this.coinInfo.push(val);
        }
        // this.coinInfo = res.list;
      }
    );
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.addr = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.inputVal4) {
        req.min_amount = this.inputVal4
      }
      if (this.inputVal5) {
        req.max_amount = this.inputVal5
      }
      if (this.inputVal6) {
        req.user_id = this.inputVal6
      }
      if (this.selectVal1) {
        req.status = this.selectVal1;
      }
      if (this.selectVal2) {
        req.tx_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/extract_details`, req, "get")
        .then((res) => {
          if (res.list === null || res.list.length === 0) {
            this.$message.error("暂无数据");
            this.total = 0;
            this.resData = [];
            return;
          }
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);

          this.other_total = res.other_total ? res.other_total : {}
          this.usd_total = res.usd_total ? res.usd_total : {}
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {
            list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
            list.tx_time_stamp = this.dataFormat(list.tx_time_stamp);
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>