<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">共管地址</div>
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">地址类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                label="借币地址"
                value="4"
            >
              借币地址
            </el-option>
            <el-option
                label="投资地址"
                value="5"
            >
              投资地址
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">借款状态</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                label="空闲"
                value="1"
            >
              空闲
            </el-option>
            <el-option
                label="正在借款"
                value="2"
            >
              正在借款
            </el-option>
          </el-select>

        </div>
      </div>



      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>


    <div class="com_search_box mt_40">
      <el-button
          type="primary"
          @click="isAlert = true"
      >
        一键生成地址
      </el-button>
      <el-button
          type="primary"
          @click="openLinkFn"
      >
        批量转账
      </el-button>
      <el-button
          type="primary"
          @click="goRouter('batchTransferList')"
      >
        批量转账计划表
      </el-button>

      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>

    </div>

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>转账勾选</th>
        <th>序号</th>
        <th>钱包地址</th>
        <th>借款多签共管地址</th>
        <th>地址类型</th>
        <th>BNB余额</th>
        <th>USDT余额</th>
        <th>借款状态</th>
        <th>创建时间</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td><el-checkbox @change="val => {checkFn(val, ((page - 1) * size + 1 + index), index)}"></el-checkbox></td>
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{list.addr}}</td>
          <td>{{list.multi_addr}}</td>
          <td>{{list.wallet_type}}</td>
          <td>{{scientificNotationToString(list.bnb_balance)}}</td>
          <td>{{scientificNotationToString(list.usdt_balance)}}</td>
          <td>{{list.borrow_state}}</td>
          <td>{{list.created_at}}</td>

        </tr>

        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" title="一键生成地址" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">地址类型</div>
          <div class="com_from_right">
            <el-select
                v-model="selectVal1"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option
                  label="借币地址"
                  value="4"
              >
                借币地址
              </el-option>
              <el-option
                  label="投资地址"
                  value="5"
              >
                投资地址
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">生成地址数量</div>
          <div class="com_from_right">
            <el-input v-model="num" type="text" placeholder="请输入" size="small"></el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isAlert = false">取消</el-button>
                    <el-button type="primary" @click="editPassFn">提交</el-button>
                </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "swftAddress",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      inputVal4: "",
      inputVal5: "",
      inputVal6: "",
      selectVal1: "",
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      typeObj: {
        1: {label: '返还投资金额', value: '1'},
        2: {label: '返还用户质押资金', value: '2'},
        3: {label: '返还认购合伙人资金', value: '3'},
        4: {label: '返还创建合伙人资金', value: '4'},
        5: {label: '合伙人上下级关系奖励', value: '5'},
        6: {label: '投资收益', value: '6'},
        7: {label: '提取收益', value: '7'},
        8: {label: '解锁合伙人收益', value: '8'},
        9: {label: '释放认购合伙人资金', value: '9'},
        10: {label: '借贷池返还多余主币给借币者', value: '10'},
        11: {label: '管理后台转出撮合池资产', value: '11'},
        12: {label: 'fth兑换usdt交易', value: '12'},
        13: {label: '监管节点发放fth', value: '13'},
        17: {label: '聚餐活动奖励', value: '17'},
        26: {label: '新年送红包', value: '26'},
      },
      two_auth_code: '',
      num: '',
      isAlert: false,
      checkArr: [],
      filterVal: [
        "addr",
        "multi_addr",
        "wallet_type",
        "bnb_balance",
        "usdt_balance",
        "borrow_state",
        "created_at",
      ],
    };
  },
  created() {

    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    openLinkFn () {
      if (this.checkArr.length) {
        let arr = this.checkArr.filter(Boolean);
        window.sessionStorage.setItem('checkArr', JSON.stringify(arr))
      }

      this.goRouter('batchTransfer')
    },
    checkFn (val, index, idx) {
      if (val) {
        this.checkArr[index] = this.resData[idx].addr
      } else {
        this.checkArr[index] = ''
      }
    },
    editPassFn() {
      if (!this.num) {
        this.$message.error("请输入创建数量");
        return false;
      }
      if (!this.selectVal1) {
        this.$message.error("请选择钱包类型");
        return false;
      }
      if (this.two_auth_code === "") {
        this.$message.error("请输入谷歌验证码");
        return false;
      }
      let req = {
        wallet_type: this.selectVal1,
        two_auth_code: this.two_auth_code,
        num : this.num
      };
      let url = "trade/new_addrs";

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.isAlert = false
            this.resInit()
            this.$message.success("成功");
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.inputVal1) {
        req.addr = this.inputVal1;
      }
      if (this.inputVal2) {
        req.multi_addr = this.inputVal2;
      }
      if (this.inputVal3) {
        req.hash = this.inputVal3;
      }
      if (this.inputVal4) {
        req.min_amount = this.inputVal4
      }
      if (this.inputVal5) {
        req.max_amount = this.inputVal5
      }
      if (this.inputVal6) {
        req.user_id = this.inputVal6
      }
      if (this.selectVal1) {
        req.status = this.selectVal1;
      }
      if (this.selectVal2) {
        req.wallet_type = this.selectVal2;
      }
      if (this.selectVal3) {
        req.borrow_state = this.selectVal3;
      }
      if (this.selectVal4) {
        req.symbol = this.selectVal4;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}trade/addrs`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.created_at = this.dataFormat(list.created_at)
              list.borrow_state = list.borrow_state == 1 ? '空闲' : '正在借款'
              list.wallet_type = list.wallet_type == 4 ? '借币地址' : '投资地址'

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>