<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">用戶钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">用戶钱包地址</div>
        <div class="com_item_input">
          <el-input v-model="wallet_addr" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">状态</div>
        <div class="com_item_input">
          <el-select v-model="selectVal1" clearable filterable placeholder="请选择" size="small">
            <el-option v-for="item in typeObj" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
      </div>
      

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <!-- <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
    </div> -->

    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>组织者钱包ID</th>
          <th>组织者钱包地址</th>
          <th>国家/地区</th>
          <th>社区名称</th>
          <th>视频资料</th>
          <th>提交资料时间</th>
          <th>審核狀態</th>
          <th>獎勵金額</th>
          <th>操作时间</th>
          <th>操作</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>
              {{ list.user_id }}
            </td>
            <td>
              {{ list.wallet_address }}
            </td>
            <td>
              {{ list.country }}
            </td>
            <td>
              {{ list.community }}
            </td>
            <td><a :href="Utils.URL.imgURL + list.video_link" target="_blank">{{list.video_link}}</a></td>
            <td>{{ list.created_at }}</td>
            <td>
              {{ list.status }}
            </td>
            <td>
              {{ list.reward_amount }}
            </td>
            <td>
              {{ list.updated_at }}
            </td>
            <td class="tab_btn">
              <span class="btn" @click="showFn(list)" style="margin-right: 0;" v-show="list.status == '待審核'">审核</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="isShow" title="審核" width="500px">
      <section class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">
            審核狀態
          </div>
          <div class="com_from_right">
            <el-radio-group v-model="approval_status">
              <el-radio label="2" size="large">通过</el-radio>
              <el-radio label="3" size="large">拒绝</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- <div class="com_from_item" v-if="approval_status == '1'">
          <div class="com_from_left">
            拒絕原因
          </div>
          <div class="com_from_right">
            <el-input
              v-model="reason"
                type="text"
                placeholder="请输入"
                size="small">
            </el-input>
          </div>
        </div> -->
        <div class="com_from_item">
          <div class="com_from_left">谷歌驗證</div>
          <div class="com_from_right">
            <el-input
                v-model="two_auth_code"
                type="text"
                placeholder="请输入"
                size="small"
            ></el-input>
          </div>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
            <el-button @click="isShow = false">取消</el-button>
            <el-button type="primary" @click="submit">確認</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>
  
<script>
export default {
  name: "worldVideo",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      // tHeader: [],
      subtotal: {},
      wallet_id: '',
      wallet_addr: '',
      selectVal1: '',
      typeObj: {
        1: { label: '待審核', value: '1' },
        2: { label: '審核通過', value: '2' },
        3: { label: '審核拒絕', value: '3' },
        4: { label: '獎勵已發放', value: '4' },
      },
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      isShow: false,
      two_auth_code: '',
      approval_status: '',
      // filterVal: [
      //   "tx_time",
      //   "tx_type",
      //   "chain_type",
      //   "symbol",
      //   "numb",
      //   "user_id",
      //   "addr",
      //   "wallet_addr",
      //   "hash",
      // ],
      // reason: '',
      id: ''
    };
  },
  created() {
    
    this.resInit();
  },
  mounted() {
    // document.querySelector(".com_thead").childNodes.forEach((item) => {
    //   if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
    //   this.tHeader.push(item.innerText);
    // });
  },
  methods: {
    submit() {
      if (this.approval_status == '') {
        this.$message.error('請選擇審核狀態');
        return
      }
      if (this.two_auth_code == '') {
        this.$message.error('谷歌驗證碼不能為空');
        return
      }
      let req = {
        id: +this.id,
        op_type: +this.approval_status,
        two_auth_code: this.two_auth_code
      }
      this.$ajax(`${this.Utils.URL.goURL}horse/v2/submit_horse_operation`, req, 'post', 2500000, false, true)
        .then(res => {
          if (res.result) {
            this.isShow = false;
            this.$message.success('成功');
            this.two_auth_code = ''
            
            this.resInit();
          } else {
            this.$message.error(res.msg)
          }
          
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    showFn(list) {
      this.isShow = true;
      this.id = list.id
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id) {
        req.user_id = this.wallet_id
      }
      if (this.wallet_addr) {
        req.wallet_address = this.wallet_addr
      }
      if (this.selectVal1) {
        req.status = this.selectVal1
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}horse/v2/horse_activity_record`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);

          this.resData.forEach((list) => {
            list.status = this.typeObj[list.status] ? this.typeObj[list.status].label : list.status
            list.created_at = this.dataFormat(list.created_at)
            list.updated_at = this.dataFormat(list.updated_at)
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>
  
<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>