<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="user_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">LP類型</div>
        <div class="com_item_input">
          <el-select
            v-model="chain_type"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in listChainType"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">状态</div>
        <div class="com_item_input">
          <el-select
            v-model="status"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in statusList"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">質押類別</div>
        <div class="com_item_input">
          <el-select
            v-model="order_type"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in orderTypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">質押週期</div>
        <div class="com_item_input">
          <el-select
            v-model="duration_day"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in listPledgeCycle"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">利息幣種</div>
        <div class="com_item_input">
          <el-select
            v-model="profit_coin_type"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in profitCoinTypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">訂單結束時間搜尋</div>
        <div class="com_item_input">
          <el-date-picker
            v-model="date"
            type="daterange"
            size="small"
            format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss"
            start-placeholder="选择日期时间"
            end-placeholder="选择日期时间"
          />
        </div>
      </div>
      
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>

      </div>

    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>
    <div>
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>錢包ID</th>
          <th>質押類別</th>
          <th>質押幣種</th>
          <th>質押金額</th>
          <th>鏈類型</th>
          <th>質押時LP價格</th>
          <th>LP數量</th>
          <th>質押週期</th>
          <th>復押次數</th>
          <th>状态</th>
          <th>開始時間</th>
          <th>結束時間</th>
          <th>預計利息(FUSD)</th>
          <th>預計利息(SCF)</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ list.order_type }}</td>
            <td>{{ list.usdt_coin }}</td>
            <td>{{ list.usdt_amt }}</td>
            <td>{{ list.chain_type }}</td>
            <td>{{ scientificNotationToString(list.lp_price) }}</td>
            <td>{{ scientificNotationToString(list.real_amt) }}</td>
            <td>{{ list.duration_day }}</td>
            <td>{{ list.invest_time }}</td>
            <td>{{ list.status }}</td>
            <td>{{  list.start_at }}</td>
            <td>{{ list.end_at }}</td>
            <td>{{ scientificNotationToString(list.fusd_expect_profit) }}</td>
            <td>{{ scientificNotationToString(list.scf_expect_profit) }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(subtotal?.usdt_amt) }}</td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(subtotal?.real_amt) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(subtotal?.fusd_expect_profit) }}</td>
            <td>{{ scientificNotationToString(subtotal?.scf_expect_profit) }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(sum?.usdt_amt) }}</td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(sum?.real_amt) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(sum?.fusd_expect_profit) }}</td>
            <td> {{ scientificNotationToString(sum?.scf_expect_profit) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes"
        :total="total">
      </el-pagination>
    </div>
  </article>
</template>
  
<script>
export default {
  name: "liquidStakeOrder",
  data() {
    return {
      date: '',
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      subtotal: {},
      sum: {},
      user_id: '',
      chain_type: '',
      listChainType: [
        {
          value: 4,
          text: 'PancakeSwap',
        },
        {
          value: 5,
          text: 'FinSwap',
        },
      ],
      duration_day: '',
      listPledgeCycle: [
        {
          value: 1,
          text: '7 天',
        },
        {
          value: 2,
          text: '15 天',
        },
        {
          value: 3,
          text: '30 天',
        }
      ],
      startTime: '',
      endTime: '',
      tHeader: [],
      filterVal: [
        'user_id',
        'order_type',
        'usdt_coin',
        'usdt_amt',
        'chain_type',
        'lp_price',
        'real_amt',
        'duration_day',
        'invest_time',
        'status',
        'start_at',
        'end_at',
        'fusd_expect_profit',
        'scf_expect_profit',
      ],
      status: '',
      statusList: [
        {
          value: 1,
          text: '初始状态，交易确认中',
        },
        {
          value: 2,
          text: '下单成功，计息中',
        },
        {
          value: 3,
          text: '下单失败',
        },
        {
          value: 4,
          text: '订单到期，本金等待返还',
        },
        {
          value: 5,
          text: '订单到期，本金已返还',
        },
      ],
      resDataFullForExport: [],
      order_type: '',
      orderTypeList: [
        {
          value: 1,
          text: '快捷質押',
        },
        {
          value: 2,
          text: 'LP質押',
        },
        {
          value: 3,
          text: 'OTC快捷質押',
        },
      ],
      profit_coin_type: '',
      profitCoinTypeList: [
        {
          value: 1,
          text: 'FUSD',
        },
        {
          value: 2,
          text: 'SCF',
        },
      ],
    };
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  created() {
    this.resInit();
  },
  methods: {
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
      }
      if (this.user_id) {
        req.user_id = this.user_id;
      }
      if (this.chain_type) {
        req.chain_type = this.chain_type;
      }
      if (this.duration_day) {
        req.duration_day = this.duration_day;
      }
      if (this.profit_coin_type) {
        req.profit_coin_type = this.profit_coin_type;
      }
      if (this.startTime) {
        req.begin_time = this.startTime;
      }
      if (this.endTime) {
        req.end_time = this.endTime;
      }
      if (this.status) {
        req.status = this.status; // 1,2 3,
      }
      if (this.order_type) {
        req.order_type = this.order_type;
      }
      if (this.date) {
        req.end_date_start = this.date[0];
        req.end_date_end = this.date[1];
      }
      this.$ajax(`${this.Utils.URL.goURL}liquidity_mortgage_order_list`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.start_at = parseFloat(list.start_at) > 0 ? this.dataFormat(list.start_at) : '-'
            list.end_at = parseFloat(list.end_at) > 0 ? this.dataFormat(list.end_at) : '-'
            list.duration_day = list.duration_day == 7 ? '7天' : list.duration_day == 15 ? '15天' : list.duration_day == 30 ? '30天' : '-'
            if (list.order_type === '2') {
              list.usdt_coin = '-'
              list.usdt_amt = '-' // example
            }
            list.order_type = this.orderTypeList[list.order_type - 1].text
            list.status = this.statusList[list.status - 1].text

            list.lp_price = list.lp_price.length > 0 ? list.lp_price : '-'
          })
          if (this.resDataFullForExport.length > 0) {
            this.exportExcel(this.tHeader, this.filterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.user_id) {
        req.user_id = this.user_id;
      }
      if (this.chain_type) {
        req.chain_type = this.chain_type;
      }
      if (this.duration_day) {
        req.duration_day = this.duration_day;
      }
      if (this.profit_coin_type) {
        req.profit_coin_type = this.profit_coin_type;
      }
      if (this.startTime) {
        req.begin_time = this.startTime;
      }
      if (this.endTime) {
        req.end_time = this.endTime;
      }
      if (this.status) {
        req.status = this.status; // 1,2 3,
      }
      if (this.order_type) {
        req.order_type = this.order_type;
      }

      // end_date_start,end_date_end
      if (this.date) {
        req.end_date_start = this.date[0];
        req.end_date_end = this.date[1];
      }
      this.$ajax(`${this.Utils.URL.goURL}liquidity_mortgage_order_list`, req, "get")
        .then((res) => {
          if (res.list.length == 0) {
            this.$message.error("暂无数据");
          }
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {

            list.start_at = parseFloat(list.start_at) > 0 ? this.dataFormat(list.start_at) : '-'
            list.end_at = parseFloat(list.end_at) > 0 ? this.dataFormat(list.end_at) : '-'
            // list.chain_type = list.chain_type == 1 ? 'Pincake' : list.status == 2 ? 'Pinswap' : '-'
            list.duration_day = list.duration_day == 7 ? '7天' : list.duration_day == 15 ? '15天' : list.duration_day == 30 ? '30天' : '-'
            if (list.order_type === '2') {
              list.usdt_coin = '-'
              list.usdt_amt = '-' // example
            }
            // list.order_type = list.order_type == 1 ? '快捷质押' : list.order_type == 2 ? 'LP质押' : '-'
            list.order_type = this.orderTypeList[list.order_type - 1].text
            list.status = this.statusList[list.status - 1].text

            list.lp_price = list.lp_price.length > 0 ? list.lp_price : '-'
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>
  
<style scoped></style>