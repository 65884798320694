<template>
  <article class="income-record-3-view">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">錢包地址</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">收益類別</div>
        <div class="com_item_input">
          <el-select
            v-model="profit_type"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in profitTypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>

    <div class="com_search_box">
      <el-button type="primary" @click="getAllData()">
        导出excel（全部）
      </el-button>
    </div>
    <!-- <div class="com_search_box">
        <el-button
            type="primary"
            @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
        >
          导出excel（本页）
        </el-button>
      </div> -->
    <!-- <div class="intro">
      <div class="intro_item">
        <p>FTCFIN 總發放 : {{ intro?.ftc_fin_total_profit_amount ?? '-' }}</p>
        <p>FUSD 總發放 : {{ intro?.fusd_total_profit_amount ?? '-' }}</p>
        <p>USDT 總發放 : {{ intro?.usdt_total_profit_amount ?? '-' }}</p>
        <p>FTH 總發放 : {{ intro?.fth_total_profit_amount ?? '-' }}</p>
      </div>
      <div class="intro_item">
        <p>FTCFIN 總提取 : {{ intro?.ftc_fin_total_receive_amount ?? '-' }}</p>
        <p>FUSD 總提取 : {{ intro?.fusd_total_receive_amount ?? '-' }}</p>
        <p>USDT 總提取 : {{ intro?.usdt_total_receive_amount ?? '-' }}</p>
        <p>FTH 總提取 : {{ intro?.fth_total_receive_amount ?? '-' }}</p>
      </div>
    </div> -->
    <div>
      <table class="com-table mt_20">
        <thead class="com_thead">
          <th>序号</th>
          <th>錢包ID</th>
          <th>收益類別</th>
          <th>發放收益幣種</th>
          <th>發放金額</th>
          <th>未提領金額</th>
          <th>已提領金額</th>
          <th>發放時間</th>
          <th>提取時間</th>
        </thead>
        <tbody class="com_tbody">
          <!-- <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.wallet_id }}</td>
            <td>{{ list.profit_type }}</td>
            <td>{{ list.coin_symbol }}</td>
            
            <td>{{ list.profit_amount }}</td>

            <td>{{ list.uncollected_amount }}</td>

            <td>{{ list.withdrawn_amount }}</td>

            <td>{{ list.profit_date }}</td>
            <td>{{ list.receive_date }}</td>
          </tr> -->
          <!-- <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(subtotal.profit_amount) }}</td>
            <td> {{ scientificNotationToString(subtotal.uncollected_amount) }}</td>
            <td> {{ scientificNotationToString(subtotal.withdrawn_amount) }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(sum.profit_amount) }}</td>
            <td> {{ scientificNotationToString(sum.uncollected_amount) }}</td>
            <td> {{ scientificNotationToString(sum.withdrawn_amount) }}</td>
            <td></td>
            <td></td>
          </tr> -->
        </tbody>
        <tbody class="com_tbody" v-for="(list, index) in resData" :key="list?.id">
          <tr v-show="list.profit_type === '直邀收益' || list.profit_type === '間邀收益' || list.profit_type === '管理收益' || list.profit_type === '小区收益'">
            <td rowspan="2">{{ (page - 1) * size + 1 + index }}</td>
            <td rowspan="2">{{ list.wallet_id }}</td>
            <td rowspan="2">{{ list.profit_type }}</td>
            <td>{{ list.coin_symbol }}</td>
            <td>{{ list.profit_amount }}</td>
            <td>{{ list.uncollected_amount }}</td>
            <td>{{ list.withdrawn_amount }}</td>
            <td rowspan="2">{{ list.profit_date }}</td>
            <td rowspan="2">{{ list.receive_date }}</td>
          </tr>
          <tr v-show="list.profit_type === '直邀收益' || list.profit_type === '間邀收益' || list.profit_type === '管理收益' || list.profit_type === '小区收益'">
            <td>{{ list.coin_symbol_fusd }}</td>
            <td>{{ list.profit_amount_fusd }}</td>
            <td>{{ list.uncollected_amount_fusd }}</td>
            <td>{{ list.withdrawn_amount_fusd }}</td>
          </tr>
          <tr v-show="list.profit_type === 'MD+收益' || list.profit_type === '質押收益' || list.profit_type === '挖礦收益'">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.wallet_id }}</td>
            <td>{{ list.profit_type }}</td>
            <td>{{ list.coin_symbol }}</td>
            <td>{{ list.profit_amount }}</td>
            <td>{{ list.uncollected_amount }}</td>
            <td>{{ list.withdrawn_amount }}</td>
            <td>{{ list.profit_date }}</td>
            <td>{{ list.receive_date }}</td>
          </tr>
        </tbody>
        <tbody>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(subtotal.profit_amount) }}</td>
            <td> {{ scientificNotationToString(subtotal.uncollected_amount) }}</td>
            <td> {{ scientificNotationToString(subtotal.withdrawn_amount) }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{ scientificNotationToString(sum.profit_amount) }}</td>
            <td> {{ scientificNotationToString(sum.uncollected_amount) }}</td>
            <td> {{ scientificNotationToString(sum.withdrawn_amount) }}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div v-show="isHasData" style="text-align: center;">
      <p><img src="../../assets/img/no_data.png" alt=""></p>
    </div> -->
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

  </article>
</template>
  
<script>
export default {
  name: "incomeRecord3",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      subtotal: {},
      sum: {},
      resData: [],
      wallet_id: '',
      startTime: '',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      profit_type: '',
      profitTypeList: [ //5-直邀收益 6-間邀收益 7-管理收益 30-MD+收益 35-質押收益 99-挖礦收益
        {
          value: '5',
          text: '直邀收益',
        },
        {
          value: '6',
          text: '間邀收益',
        },
        {
          value: '7',
          text: '管理收益',
        },
        {
          value: '30',
          text: 'MD+收益',
        },
        {
          value: '35',
          text: '質押收益',
        },
        {
          value: '41',
          text: '小区收益',
        },
        {
          value: '99',
          text: '挖礦收益',
        }
      ],
      tHeader: [],
      filterValFusd: [
        "wallet_id",
        "profit_type",
        "coin_symbol",
        "profit_amount",
        'uncollected_amount',
        'withdrawn_amount',
        "coin_symbol_fusd", // 發放收益幣種
        "profit_amount_fusd",
        'uncollected_amount_fusd',
        'withdrawn_amount_fusd',
        "profit_date",
        "receive_date"
      ],
      filterVal: [
        "wallet_id",
        "profit_type",
        "coin_symbol",
        "profit_amount",
        'uncollected_amount',
        'withdrawn_amount',
        "profit_date",
        "receive_date"
      ],
      // isHasData: false,
      // time: this.dataFormat(new Date().getTime() / 1000),
      // intro: null,
      resDataFullForExport: [],
    };
  },
  created() {
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    getAllData() {
      const req = {
        page: 1,
        size: this.total,
        profit_type: this.profit_type,
      }
      this.$ajax(`${this.Utils.URL.goURL}get_profit_record`, req, "get")
        .then((res) => {
          this.resDataFullForExport = res.list ? res.list : [];
          this.resDataFullForExport.forEach((list) => {
            list.profit_date = this.dataFormat(list.profit_date)
            list.receive_date = this.dataFormat(list.receive_date)
            // 5-直邀收益 6-間邀收益 7-管理收益 30-MD+收益 35-質押收益 99-挖礦收益
            list.profit_type = list.profit_type == 5 ? '直邀收益' : list.profit_type == 6 ? '間邀收益' : list.profit_type == 7 ? '管理收益' : list.profit_type == 30 ? 'MD+收益' : list.profit_type == 35 ? '質押收益' : list.profit_type == 41 ? '小区收益' : list.profit_type == 99 ? '挖礦收益' : ''
          })
          if (this.resDataFullForExport.length > 0) {
            let finalFilterVal = this.profit_type == 5 || this.profit_type == 6 || this.profit_type == 7 || this.profit_type == 41 ? this.filterValFusd : this.filterVal

            let tHeaderCustom = ['錢包ID', '收益類別', '發放收益幣種', '發放金額', '未提領金額', '已提領金額', '發放收益幣種', '發放金額', '未提領金額', '已提領金額', '發放時間', '提取時間']
            let finalHeader = this.profit_type == 5 || this.profit_type == 6 || this.profit_type == 7 || this.profit_type == 41 || this.profit_type == '' ? tHeaderCustom : this.tHeader
            this.exportExcel(finalHeader, finalFilterVal, this.resDataFullForExport, this.$route.meta.title)
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.resData = [];
      this.intro = {}
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id) {
        req.wallet_id = this.wallet_id;
      }
      if (this.profit_type) {
        req.profit_type = parseInt(this.profit_type);
      }
      if (this.startTime) {
        req.start = this.startTime;
      }
      if (this.endTime) {
        req.end = this.endTime;
      }
      this.$ajax(`${this.Utils.URL.goURL}get_profit_record`, req, 'get')
        .then((res) => {
          if (res.list === null || res.list.length === 0) {
            this.$message.error("暂无数据");
            this.total = 0;
            return;
          }
          // this.intro = res.list ? res.list : {}
          this.resData = res.list ? res.list : []
          this.resData.forEach((list) => {
            list.profit_date = this.dataFormat(list.profit_date)
            list.receive_date = this.dataFormat(list.receive_date)
            // 5-直邀收益 6-間邀收益 7-管理收益 30-MD+收益 35-質押收益 41-小区收益 99-挖礦收益
            list.profit_type = list.profit_type == 5 ? '直邀收益' : list.profit_type == 6 ? '間邀收益' : list.profit_type == 7 ? '管理收益' : list.profit_type == 30 ? 'MD+收益' : list.profit_type == 35 ? '質押收益' : list.profit_type == 41 ? '小区收益' : list.profit_type == 99 ? '挖礦收益' : ''

            
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
          this.subtotal = res.subtotal ? res.subtotal : {};
          this.sum = res.sum ? res.sum : {};
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>
  
<style scoped lang="scss">
.income-record-3-view {
  .intro {
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    font-weight: bold;

    &_item {
      flex-basis: 50%;
    }
  }

  .mt_20 {
    margin-top: 20px;
  }
}
</style>
