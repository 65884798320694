<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="resInit"
        >搜索</el-button
        >
      </div>
    </div>

    <section class="com_from_box mt_40" v-show="isShow">
      <div class="com_from_item">
        <div class="com_from_left">
          钱包ID
        </div>
        <div class="com_from_right">
          {{wallet_id}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          钱包地址
        </div>
        <div class="com_from_right">
          <p v-for="list in wallet_addr" :key="list.toString()">{{list}}</p>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          绑定上级
        </div>
        <div class="com_from_right">
          {{resData.prev_level}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          直接下级数量
        </div>
        <div class="com_from_right">
          <span class="mr_10">{{resData.next_numb}}</span>
          <el-button
            type="primary"
            size="small"
            @click="goRouter('/nextDetails', {wallet_id: wallet_id})"
          >
            详情
          </el-button>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          管理级别
        </div>
        <div class="com_from_right">
          {{levelArr[resData.manager_level]}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          是否合伙人
        </div>
        <div class="com_from_right">
          {{resData.partnership ? '是' : '否'}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          现金劵数量
        </div>
        <div class="com_from_right">
          {{resData.super_amount}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          当前投资总额
        </div>
        <div class="com_from_right">
          {{resData.investment_amount}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          累计借贷金额
        </div>
        <div class="com_from_right">
          {{resData.borrowing_amount}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          当前FTH数量
        </div>
        <div class="com_from_right">
          <span class="mr_10">{{resData.sto_numb}}</span>
          <el-button
              type="primary"
              size="small"
              @click="goRouter('/stoDetailsDeport', {wallet_id: wallet_id})"
          >
            详情
          </el-button>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          累计收益
        </div>
        <div class="com_from_right">
          {{resData.earnings}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          复利池数量
        </div>
        <div class="com_from_right">
          {{resData.compound_interest_pool_numb}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          理财券数量
        </div>
        <div class="com_from_right">
          {{resData.financing_volume}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          钱包资产
        </div>
        <div class="com_from_right">
          {{wallet_assets}}
        </div>
      </div>


    </section>

  </article>
</template>

<script>
export default {
  name: "userInfo",
  data() {
    return {
      wallet_id: '',
      typeObj: {
        1: {label: '正常', value: '1'},
        2: {label: '禁止提币', value: '2'},
        3: {label: '禁止投资', value: '3'},
        4: {label: '禁止借款', value: '4'},
        5: {label: '禁止交易', value: '5'},
        6: {label: '禁止全部交易', value: '6'},
      },
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      remark: '',
      statusVal: '',
      two_auth_code: '',
      resData: {},
      wallet_addr: [],
      wallet_assets: [],
      isShow: false

    }
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.wallet_id = this.$route.query.wallet_id
      this.resInit()
    }

  },
  methods: {
    submitFn() {
      let req = {
      }
      this.$ajax(`${this.Utils.URL.goURL}created_version`, req, 'post')
        .then(res => {
          this.$message.success('操作成功')
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    resInit() {
      if (!this.wallet_id) {
        return false
      }
      let req = {
        wallet_id: this.wallet_id
      }
      this.$ajax(`${this.Utils.URL.goURL}get_user_info`, req, 'get')
        .then(res => {
          this.resData = res ? res : {}
          this.wallet_addr = res.wallet_addr ? res.wallet_addr : []
          this.wallet_assets = res.wallet_assets ? res.wallet_assets : {}
          this.isShow = true
        })
        .catch(res => {
          this.$message.error(res.msg)
        })


    }
  }
}
</script>

<style scoped>

</style>