<template>
    <article class="">
      <h2 class="com_h2_title">{{$route.meta.title}}</h2>
        <div class="com_search_box">



          <div class="com_item_search">
            <div class="com_item_tx">开始时间</div>
            <div class="com_item_input">
              <el-date-picker
                  v-model="startTime"
                  type="datetime"
                  size="small"
                  format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
              </el-date-picker>
            </div>
          </div>
          <div class="com_item_search">
            <div class="com_item_tx">结束时间</div>
            <div class="com_item_input">
              <el-date-picker
                  v-model="endTime"
                  type="datetime"
                  size="small"
                  format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
              </el-date-picker>
            </div>
          </div>


            <div class="com_item_search">
                <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
            </div>
        </div>
        <div class="tab_box">
            <table class="com-table">
                <thead class="com_thead">
                    <th>序号</th>
                    <th>日期</th>
                    <th>每日收益率</th>
                </thead>
                <tbody class="com_tbody">
                    <tr v-for="(list, index) in resData" :key="index">
                        <td>{{ (page - 1) * size + 1 + index }}</td>
                      <td>{{dataFormat(list.created_at)}}</td>
                      <td>{{list.profit_rate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
      <div class="com_page">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageFn"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100, 1000]"
            :page-size="size"
            layout="total, prev, pager, next, sizes"
            :total="total"
        >
        </el-pagination>
      </div>

    </article>
</template>

<script>
export default {
  name: "institutionFundIncome",
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      inputVal1: '',
      inputVal2: '',
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      selectArr: [{value: '钱包', label: '钱包'}]
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    resInit() {
      let req = {
        id: this.$route.query.id,
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.port_id = this.selectVal1
      }
      if (this.selectVal2) {
        req.one_type = this.selectVal2
      }
      if (this.selectVal3) {
        req.two_type = this.selectVal3
      }
      if (this.inputVal1) {
        req.title = this.inputVal1
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/invest_profit_rate_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },
  }

}
</script>

<style scoped>

</style>