<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="walletId" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">訂單編號</div>
        <div class="com_item_input">
          <el-input v-model="orderId" placeholder="請輸入訂單編號" size="small">
          </el-input>
        </div>
      </div>

      <!-- <div class="com_item_search">
        <div class="com_item_tx">鏈類別</div>
        <div class="com_item_input">
          <el-select
            v-model="chainType"
            clearable
            filterable
            placeholder="請選擇鏈類別"
            size="small">
            <el-option v-for="item in chainTypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div> -->

      <div class="com_item_search">
        <div class="com_item_tx">狀態</div>
        <div class="com_item_input">
          <el-select v-model="status" clearable filterable placeholder="請選擇狀態 " size="small">
            <el-option v-for="(item, key) in statusList" :key="key" :label="item.label" :value="key">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="startTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker v-model="endTime" type="datetime" size="small" format="YYYY-M-D H:mm:ss"
            value-format="YYYY-M-D H:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>

    <div class="com_search_box" style="align-items: flex-start;">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
      <div class="balance">
        <div class="balance_content">
          <div class="balance_item">
            <p>FSU額度 :</p>
            <p style="margin-left: 10px;">{{ balance?.fsu || 0 }}</p>
          </div>
          <div class="balance_item">
            <p>USDT額度 :</p>
            <p style="margin-left: 10px;">{{ balance?.usdt || 0 }}</p>
          </div>
        </div>
        <div class="balance_content">
          <div class="balance_item" style="font-size: 1.1rem">
            <p>系统用户ID :</p>
            <p style="margin-left: 10px;">{{ balance?.system_otc_user_id || '' }}</p>
          </div>

        </div>
        <div class="balance_content">
          <div class="balance_item" style="font-size: 1.1rem">
            <p>系统用户地址 :</p>
            <p style="margin-left: 10px;">{{ balance?.system_otc_user_address || '' }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="com_search_box mt_40">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="showAlertFn('', 'add')">建立委托買單</el-button>
      </div>
    </div>
    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
          <th>序号</th>
          <th>用戶暱稱</th>
          <th>錢包ID</th>
          <th>訂單編號</th>
          <th>委托購買數量</th>
          <th>委托單價</th>
          <th>完成度</th>
          <th>申请时间</th>
          <th>状态</th>
          <th>详情</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.nickname }}</td>
            <td>{{ list.user_id }}</td>
            <td>{{ list.id }}</td>
            <td>{{ list.purchase_amount }} FSU</td>
            <td>{{ list.price }} $</td>
            <td>{{ list.progress }}%</td>
            <td>{{ list.created_at }}</td>
            <td>{{ list.status }}</td>
            <td class="tab_btn">
              <span class="btn"
                @click="goRouter('otcCommissionOrderListDetail', { order_id: list.id, purchase_amount: list.purchase_amount, price: list.price })">查看</span>
              <!-- <span class="btn" v-if="isEditButton" @click="showAlertFn(index, 'edit')">編輯系統訂單</span> -->
              <button @click="showAlertFn(index, 'edit')" :disabled="!list.is_admin"
                :class="{ active: !list.is_admin }">
                編輯系統訂單
              </button>
              <button @click="goRouter('otcFsuSales', {
      order_id: list.id
    })" :disabled="list.status_int != 2" :class="{ active: list.status_int != 2 }">
                販賣FSU
              </button>
              <!-- admin={{ list.is_admin }} -->
              <button @click="isCancelOrder = true" :disabled="!list.is_admin || list.status_int != 2"
                :class="{ active: !list.is_admin || list.status_int != 2 }">
                取消訂單
              </button>
              <!-- <span class="btn" @click="goRouter('otcFsuSales', {
              order_id: list.id
              })">販賣FSU</span> -->
            </td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(subtotal.sub_purchase_amount) }} FSU</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(sum.sub_purchase_amount) }} FSU</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>


    <el-dialog v-model="isAlert" :title="curType == 'edit' ? '编辑订單' : '建立委托買單'" :width="600">
      <div class="com_from_box">
        <div class="com_from_item" style="align-items: flex-start;">
          <div class="com_from_left" style="padding-top: 7px;">用戶暱稱</div>
          <div class="com_from_right">
            <el-input v-model="username" placeholder="請輸入暱稱" type="text" size="small">
            </el-input>
            <!-- <p class="text_error">{{ textErrorUserName }}</p> -->
          </div>
          <p style="margin-left: 10px; padding-top: 7px;"> 請使用英文名字</p>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">委托購買數量</div>
          <div class="com_from_right">
            <el-input v-model="quantity" placeholder="請輸入數量" type="text" size="small" :disabled="curType == 'edit'"
              @input="handleInput">
            </el-input>
            <p class="text_error">{{ textError }}</p>
          </div>
          <p style="margin-left: 10px;">FSU</p>
          <p style="margin-left: 10px;">最小數量為: {{ limit }}</p>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">委托購買單價</div>
          <div class="com_from_right">
            <el-input v-model="price" placeholder="請輸入單價" type="number" size="small" :disabled="curType == 'edit'">
            </el-input>
          </div>
          <p style="margin-left: 10px;">$</p>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" placeholder="請輸入谷歌驗證碼" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAlert = false">关闭</el-button>
          <el-button type="primary" @click="submitFn">提交</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- cancel order -->
    <el-dialog v-model="isCancelOrder" title="是否取消訂單" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code_cancel" placeholder="請輸入谷歌驗證碼" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isCancelOrder = false">关闭</el-button>
          <el-button type="primary" @click="submitCancelOrderFn">提交</el-button>
        </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>

export default {
  name: "otc-commission-order-list",
  data() {
    return {
      page: 1,
      size: 100,
      total: 0,
      resData: [],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "nickname",
        "user_id",
        "id",
        "purchase_amount",
        "price",
        "progress",
        "created_at",
      ],
      isAlert: false,
      curIndex: 0,
      curType: '',
      two_auth_code: '',
      username: '',
      quantity: '',
      price: '',
      type: '',
      balance: {},
      textError: '',
      // textErrorUserName: '',
      walletId: '',
      // chainType: '',
      // chainTypeList: [
      //   {
      //     value: 'bsc',
      //     text: 'BSC',
      //   },
      //   {
      //     value: 'eth',
      //     text: 'ETH',
      //   },
      //   {
      //     value: 'tron',
      //     text: 'TRON',
      //   }
      // ],
      startTime: '',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      limit: '',
      status: '',
      statusList: {
        2: { label: '委托中', value: '2' },
        5: { label: '已取消', value: '5' },
      },
      orderId: '',
      isCancelOrder: false,
      two_auth_code_cancel: '',
    };
  },
  created() {
    this.resInit();
    this.balanceFn();
    this.limitConfigFn();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {
    limitConfigFn() {
      this.$ajax(`${this.Utils.URL.goURL}otc/system_purchase_config`, {}, "get")
        .then((res) => {
          this.limit = res?.min_purchase_amount || ''
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    submitCancelOrderFn() {
      if (this.two_auth_code_cancel === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      const req = {
        order_id: this.resData[this.curIndex].id,
        two_auth_code: this.two_auth_code_cancel
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/system_order_cancel`, req, 'post')
        .then(res => {
          this.isCancelOrder = false
          this.two_auth_code_cancel = ''
          this.resInit()
          this.$message.success('取消成功')
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    handleInput(val) {
      // quantity must be integer
      if (val.includes('.')) {
        this.textError = '委托購買數量必須是整數'
        return false
      } else {
        this.textError = ''
      }
      // quantity must be number
      if (isNaN(val)) {
        this.textError = '委托購買數量必須是數字'
        return false
      } else {
        this.textError = ''
      }
    },
    balanceFn() {
      this.$ajax(`${this.Utils.URL.goURL}otc/system_admin_credits`, {}, "get")
        .then((res) => {
          this.balance = res
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.two_auth_code = ''
      this.type = ''
      this.username = ''
      this.quantity = ''
      this.price = ''
      if (type == 'edit') {
        this.curIndex = index
        this.username = this.resData[index].nickname
        this.quantity = this.resData[index].purchase_amount
        this.price = this.resData[index].price
      }
    },
    submitFn() {

      if (this.username === '') {
        this.$message.error('请输入用戶暱稱')
        return false
      }
      // check username is english
      if (this.username.match(/[^a-zA-Z0-9]/g)) {
        this.$message.error('用戶暱稱只能是英文')
        return false
      }
      if (this.quantity === '' && this.curType !== 'edit') {
        this.$message.error('请输入委托購買數量')
        return false
      }
      // quantity must be integer
      if (+this.quantity % 1 !== 0 || this.quantity.includes('.')) {
        this.$message.error('委托購買數量必須是整數')
        return false
      }
      // quantity must be equal or greater than limit
      if (+this.quantity < this.limit) {
        this.$message.error(`委托購買數量必須大於等於${this.limit}`)
        return false
      }
      if (this.price === '') {
        this.$message.error('请输入委托購買單價')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      let req = {
        two_auth_code: this.two_auth_code,
        nick_name: this.username,
        purchase_amount: this.quantity,
        price: this.price,
      }
      let url
      if (this.curType == 'edit') {
        url = 'otc/system_order_update'
        req.order_id = this.resData[this.curIndex].id
        // check if the quantity can not be changed
        if (this.resData[this.curIndex].purchase_amount !== this.quantity) {
          this.$message.error('委托購買數量不能修改')
          return false
        }
      } else {
        url = 'otc/system_order_create'
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
        .then(res => {
          this.two_auth_code = ''
          this.username = ''
          this.quantity = ''
          this.price = ''
          this.type = ''
          this.isAlert = false
          this.resInit()
          this.$message.success('提交成功')
        })
        .catch(res => {
          this.$message.error(res.msg)
        })

    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {

      let req = {
        page: this.page,
        size: this.size,
      };

      if (this.walletId) {
        req.wallet_address = this.walletId;
      }
      if (this.orderId) {
        req.order_id = this.orderId;
      }
      // if (this.chainType) {
      //   req.chan_type = this.chainType;
      // }
      if (this.status) {
        req.status = this.status;
      }
      this.$ajax(`${this.Utils.URL.goURL}otc/system_order_list`, req, "get")
        .then((res) => {
          if (res.list === null || res.list.length === 0) {
            this.$message.error("暂无数据");
            this.total = 0;
            this.resData = [];
            return;
          }
          this.resData = res.list ? res.list : [];
          this.total = parseFloat(res.total);
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {

            list.created_at = this.dataFormat(list.created_at)
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}

.com-table .com_tbody .tab_btn span {
  display: inline-block;
}

.balance {
  display: flex;
  margin-left: 2rem;
  flex-wrap: wrap;
  flex-direction: column;
}

.balance_content {
  display: flex;
}

.balance_item {
  margin-right: 30px;
  display: flex;
  font-size: 1.6rem;
}

.balance_item p {
  line-height: 1.5;
}

.tab_btn button {
  background: transparent;
  border: 0;
  padding: 0;
  color: rgb(0, 136, 187);
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.tab_btn button.active {
  color: rgb(0, 136, 187);
  cursor: not-allowed;
}

.tab_btn button:hover,
.tab_btn button:active {
  color: rgb(0, 136, 187);
}

.tab_btn button:disabled {
  color: #c0c4cc;
}

.text_error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>