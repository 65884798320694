<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">钱包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="resInit"
        >搜索</el-button
        >
      </div>
    </div>

    <div v-show="resData.length">
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>币种</th>
        <th>链类型</th>
        <th>当前资产</th>
        <th>实际资产</th>
        <th>资产差额</th>
        <th>审计结果</th>
        <th>详情</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ 1 + index }}</td>
          <td>{{list.coin_symbol}}</td>
          <td>{{list.chain_type}}</td>
          <td>{{scientificNotationToString(list.curr_assets)}}</td>
          <td>{{scientificNotationToString(list.actual_assets)}}</td>
          <td>{{scientificNotationToString(list.assets_diff)}}</td>
          <td>{{list.risk_result}}</td>
          <td class="tab_btn"><span class="btn" @click="goRouter('alarmTransferAbnormal', {wallet_id: wallet_id})">查看</span></td>

        </tr>
        </tbody>
      </table>
    </div>

    <section class="com_from_box mt_40">
      <div class="com_from_item">
        <div class="com_from_left">
          钱包ID
        </div>
        <div class="com_from_right">
          {{wallet_id}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          当前状态
        </div>
        <div class="com_from_right">
          {{typeObj[statusVal] ? typeObj[statusVal].label : statusVal}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          设定状态
        </div>
        <div class="com_from_right">
          <el-select
              v-model="statusVal"
              clearable
              filterable
              placeholder="请选择"
              size="small">
            <el-option
                v-for="item in typeObj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          备注
        </div>
        <div class="com_from_right">
          <el-input type="textarea"
                    rows="5"
                    v-model="remark"
                    size="small">

          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>


      <div class="com_from_item" >
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "alarmStatusManagement",
  data() {
    return {
      wallet_id: '',
      typeObj: {
        1: {label: '正常', value: '1'},
        2: {label: '禁止提币', value: '2'},
        3: {label: '禁止投资', value: '3'},
        4: {label: '禁止借款', value: '4'},
        5: {label: '禁止交易', value: '5'},
        6: {label: '禁止全部交易', value: '6'},
      },
      remark: '',
      statusVal: '',
      two_auth_code: '',
      resData: [],
    }
  },
  created() {

  },
  methods: {
    submitFn() {
      if (!this.wallet_id) {
        this.$message.error('请输入钱包ID')
        return false
      }
      if (!this.statusVal) {
        this.$message.error('请选择状态')
        return false
      }
      if (!this.remark) {
        this.$message.error('请输入备注')
        return false
      }
      if (!this.two_auth_code) {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        wallet_id: this.wallet_id,
        status: this.statusVal,
        note: this.remark,
        code: this.two_auth_code
      }


      this.$confirm('是否更改用户交易状态', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$ajax(`${this.Utils.URL.goURL}alarm_update_risk`, req, 'post')
            .then(res => {
              this.$message.success('操作成功')
            })
            .catch(res => {
              this.$message.error(res.msg)
            })

      }).catch(() => {

      });


    },
    resInit() {
      if (!this.wallet_id) {
        return false
      }
      let req = {
        wallet_id: this.wallet_id
      }
      this.$ajax(`${this.Utils.URL.goURL}alarm_status_management`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []
          this.statusVal = res.status
          this.remark = res.remark

          this.resData.forEach((list) => {
            //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
            list.risk_result =  list.risk_result == 1 ? '正常' : '异常'

          })
        })
        .catch(res => {
          this.$message.error(res.msg)
        })


    }
  }
}
</script>

<style scoped>

</style>