<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">地址</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="請輸入地址" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>

      </div>

    </div>
    <div class="com_search_box">
      <el-button type="primary" style="min-width: 150px;" @click="addAddress">
        新增地址
      </el-button>
      <el-button type="primary" style="min-width: 150px;" @click="updateAllAddress">
        更新所有地址
      </el-button>
      <el-button type="primary" style="min-width: 150px;" @click="goRouter('/mixtokentrecord')">
        轉帳記錄
      </el-button>
    </div>
    <div class="sort_table">
      <!-- <table class="com-table mt_40">
        <thead class="com_thead">
          <th>地址編號</th>
          <th>地址</th>
          <th>BNB數量</th>
          <th>USDT數量</th>
          <th>最後更新時間</th>
          <th>操作</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list) in resData" :key="list.id">
            <td>{{ list.id }}</td>
            <td>{{ list.addr }}</td>
            <td>{{ list.bnb_value }}</td>
            <td>{{ list.usdt_value }}</td>
            <td>{{ list.created_at }}</td>
            <td class="tab_btn">
              <el-button type="primary" size="mini" @click="transferFn(list)" style="min-width: 100px;">
                轉賬
              </el-button>
              <el-button type="primary" size="mini" @click="swiftInstantExchange(list)">SWIFT閃兌</el-button>
            </td>
          </tr>
        </tbody>
      </table> -->
      <el-table :data="resData" border style="width: 100%" >
        <el-table-column prop="id" label="地址編號" header-align="center" />
        <el-table-column prop="addr" label="地址" header-align="center"/>
        <!-- <el-table-column prop="btc_value"  label="BTC數量" header-align="center"/> -->
        <el-table-column prop="bnb_value"  label="BNB數量" header-align="center"/>
        <el-table-column prop="usdt_value"  label="USDT數量" sortable :sort-method="test" header-align="center"/>
        <el-table-column prop="created_at" label="最後更新時間" header-align="center" />
        <!-- <el-table-column prop="" label="操作" header-align="center" /> -->
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" size="mini" @click="transferFn(scope.row)" style="min-width: 100px;"
              >轉賬</el-button
            >
            <el-button type="primary" size="mini" @click="swiftInstantExchange(scope.row)">SWIFT閃兌</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="com_page" v-show="resData.length">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes" :total="total">
      </el-pagination>
    </div>

    <el-dialog v-model="isShowAddAddress" title="生成地址" :width="500">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">生成地址數量</div>
          <div class="com_from_right">
            <el-input v-model="number_generate_address" placeholder="请输入" type="number" size="small">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right">
            <el-input v-model="two_auth_code" placeholder="请输入" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowAddAddress = false">取消</el-button>
          <el-button type="primary" @click="submitAddAddressFn">確認</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isShowTransfer" title="轉賬" :width="750">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left"></div>
          <div class="com_from_right">
            <p>BNB餘額: {{ bnbBalance }}</p>
            <p>USDT餘額: {{ usdtBalance }}</p>
          </div>
        </div>
        <div class="com_from_item flex-start">
          <div class="com_from_left">地址編號</div>
          <div class="com_from_right com_from_item_group" style="max-width: 405px;">
            <el-input v-model="transferObj.address" placeholder="请输入" type="text" size="small">
            </el-input>
            <p class="note">多選地址：使用,隔開<br />指定地址編號：輸入51-100就是指包含51到100的地址</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">幣種選擇</div>
          <div class="com_from_right mw405">
            <el-select v-model="transferObj.currency" clearable filterable placeholder="请选择" size="small">
              <el-option v-for="item in currencyList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">轉賬數量</div>
          <div class="com_from_right flex-2-column">
            <p class="mw192">
              <el-input v-model="transferObj.quantity.min" placeholder="输入最小值" type="number" size="small"></el-input>
            </p>
            <p class="mw192">
              <el-input v-model="transferObj.quantity.max" placeholder="输入最大值" type="number" size="small"></el-input>
            </p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">間隔時間</div>
          <div class="com_from_right flex-2-column">
            <p class="mw192">
              <el-input v-model="transferObj.time.from" placeholder="输入最小值" type="number" size="small"></el-input>
            </p>
            <p class="mw192">
              <el-input v-model="transferObj.time.to" placeholder="输入最大值" type="number" size="small"></el-input>
            </p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right mw405">
            <el-input v-model="two_auth_code" placeholder="请输入" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowTransfer = false">取消</el-button>
          <el-button type="primary" @click="submitTransferFn(list)">確認</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isShowSwiftInstantExchange" title="swft跨鏈閃兌" :width="500">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">选择币种</div>
          <div class="com_from_right">
            <el-select v-model="currencySwift" clearable filterable placeholder="请选择" size="small">
              <el-option v-for="item in currencySwiftList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">閃兌數量</div>
          <div class="com_from_right mw192">
            <div class="flex">
              <el-input v-model="swftExchange.quantity" placeholder="请输入" type="number" size="small">
              </el-input>
              <el-button type="primary" size="small" @click="getPaymentAddress(swftExchange.quantity)" v-show="swftExchange.quantity != '' || currencySwift == ''">確認閃兌數量</el-button>
            </div>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">預計獲得</div>
          <div class="com_from_right mw192">
            <el-input v-model="swftExchange.expected" placeholder="请输入" type="number" size="small" :disabled="true">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">收款地址</div>
          <div class="com_from_right">
            <el-select v-model="swftExchange.payment_address" clearable filterable placeholder="请选择" size="small" :disabled="!isDisabled">
              <el-option v-for="item in paymentAddressList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right mw192">
            <el-input v-model="two_auth_code" placeholder="请输入" type="text" size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowSwiftInstantExchange = false">取消</el-button>
          <el-button type="primary" @click="submitSwiftInstantExchangeFn(list)">確認</el-button>
        </span>
      </template>
    </el-dialog>
  </article>
</template>
  
<script>
export default {
  name: "mix-token-record",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      wallet_id: '',
      isShowAddAddress: false,
      number_generate_address: '',
      two_auth_code: '',
      isShowTransfer: false,
      transferObj: {
        address: '',
        currency: '',
        quantity: {
          min: '',
          max: '',
        },
        time: {
          from: '',
          to: '',
        },
      },
      currencyList: {
        1: { label: 'BNB', value: 'bnb' },
        2: { label: 'USDT', value: 'usdt' },
      },
      isShowSwiftInstantExchange: false,
      swftExchange: {
        quantity: '',
        expected: '',
        payment_address: '',
      },
      paymentAddressList: {
        // 1: { label: '此列表會由API提供', value: '1' },
      },
      bnbBalance: '',
      usdtBalance: '',
      isValid: true,
      finalData: '',
      paymentAddress: '',
      isDisabled: false,
      currId: '',
      currAddr: '',
      currencySwift: 'usdt',
      currencySwiftList: {
        1: { label: 'usdt', value: 'usdt' },
        2: { label: 'btc', value: 'btc' }
      },
    };
  },
  created() {
    this.resInit();
  },
  methods: {
    test: function(a, b) {
      return this.toFloat(a.usdt_value) - this.toFloat(b.usdt_value)
    },
    toFloat: function(num) {
      return parseFloat(num.replace('.','').replace(',','.'))
    },
    updateAllAddress() {
      this.$ajax(`${this.Utils.URL.goURL}organ/update_flash_balance`, {}, "get")
        .then((res) => {
          if (res.result) {
            this.resInit();
            this.$message.success('更新成功');
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    checkContent(content) {
      if (content == '') {
        this.isValid = false
        this.$message.error('地址不得為空');
        return
      }

      if (content.includes('.')) {
        this.isValid = false
        this.$message.error('間隔符號僅可使用,');
        return
      }

      if (content.includes(',') && content.includes('-')) {
        this.isValid = false
        this.$message.error('間隔符號僅可使用,');
        return
      }

      if (content.includes(',')) {
        const arr = content.split(',')
        const last = arr[arr.length - 1]
        if (last == '') {
          arr.pop()
        }
        // only acceptable one comma, no continuous comma
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == '') {
            this.isValid = false
            this.$message.error('两个连续的逗号是不可接受的');
            return
          }
        }
        this.isValid = true
        // remove duplicate
        const set = new Set(arr)
        const interactor = [...set]
        this.finalData = interactor.join(',')
      } else if (content.includes('-')) {

        const arr2 = content.split('-')
        const last = arr2[arr2.length - 1]

        if (last == '') {
          this.isValid = false
          this.$message.error('請輸入最大值');
          return
        }

        if (arr2.length > 1) {
          this.isValid = true
          const start = parseInt(arr2[0])
          const end = parseInt(arr2[1])

          if (start > end) {
            this.isValid = false
            this.$message.error('最小值不得大於最的值');
            return
          }

          const arr3 = []
          for (let i = start; i <= end; i++) {
            arr3.push(i)
          }
          // convert arr3 to string
          this.finalData = arr3.join(',')
          return
        }


      } else {
        // single value
        this.isValid = true
        this.finalData = content
      }
    },
    addAddress() {
      this.isShowAddAddress = true;
      this.isShowTransfer = false;
      this.isShowSwiftInstantExchange = false;
      this.two_auth_code = '';
      this.number_generate_address = '';
    },
    submitAddAddressFn() {
      if (!this.number_generate_address) {
        this.$message.error('請輸入生成地址數量');
        return;
      }
      // not allow float
      if (this.number_generate_address.includes('.')) {
        this.$message.error('生成地址數量不得為小數');
        return;
      }
      if (!this.two_auth_code) {
        this.$message.error('請輸入谷歌驗證碼');
        return;
      }
      let req = {
        num: +this.number_generate_address,
        two_auth_code: this.two_auth_code,
      };

      this.$ajax(`${this.Utils.URL.goURL}organ/new_addr`, req, "post")
        .then((res) => {
          if (res.result) {
            this.resInit();
            this.isShowAddAddress = false;
            this.$message.success('生成成功');
          } else {
            this.$message.error(res.msg);
          }

        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    transferFn(list) {
      this.isShowAddAddress = false;
      this.isShowTransfer = true;
      this.isShowSwiftInstantExchange = false;
      // reset form
      this.transferObj = {
        address: '',
        currency: '',
        quantity: {
          min: '',
          max: '',
        },
        time: {
          from: '',
          to: '',
        },
      };
      this.two_auth_code = '';
      // set balance
      this.bnbBalance = list.bnb_value;
      this.usdtBalance = list.usdt_value;

      this.currId = list.id;
    },
    submitTransferFn() {
      // check form
      if (!this.transferObj.address) {
        this.$message.error('請輸入地址');
        return;
      }
      this.checkContent(this.transferObj.address)

      if (this.isValid) {
        if (!this.transferObj.currency) {
          this.$message.error('請選擇幣種');
          return;
        }
        if (!this.transferObj.quantity.min) {
          this.$message.error('請輸入最小值');
          return;
        }
        if (!this.transferObj.quantity.max) {
          this.$message.error('請輸入最大值');
          return;
        }
        // not allow min > max
        if (+this.transferObj.quantity.min > +this.transferObj.quantity.max) {
          this.$message.error('最小值不得大於最大值');
          return;
        }
        // only positive number
        if (+this.transferObj.quantity.min < 0 || +this.transferObj.quantity.max < 0) {
          this.$message.error('數量不得為負數');
          return;
        }
        if (!this.transferObj.time.from) {
          this.$message.error('請輸入最小值');
          return;
        }
        if (!this.transferObj.time.to) {
          this.$message.error('請輸入最大值');
          return;
        }
        // not allow min > max
        if (+this.transferObj.time.from > +this.transferObj.time.to) {
          this.$message.error('最小值不得大於最大值');
          return;
        }
        // only positive number
        if (+this.transferObj.time.from < 0 || +this.transferObj.time.to < 0) {
          this.$message.error('數量不得為負數');
          return;
        }
        if (!this.two_auth_code) {
          this.$message.error('請輸入谷歌驗證碼');
          return;
        }

        let req = {
          from_id:  this.currId,
          address_id: this.finalData,
          symbol: this.transferObj.currency,
          min_amt: this.transferObj.quantity.min,
          max_amt: this.transferObj.quantity.max,
          min_milli_second: this.transferObj.time.from,
          max_milli_second: this.transferObj.time.to,
          two_auth_code: this.two_auth_code,

        }
        this.$ajax(`${this.Utils.URL.goURL}organ/transfer_random`, req, "post")
          .then((res) => {
            if (res.result) {
              this.resInit();
              this.isShowTransfer = false;
              this.$message.success('生成成功');
            } else {
              this.$message.error(res.msg);
            }

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
      }
    },
    swiftInstantExchange(list) {
      this.isShowAddAddress = false;
      this.isShowTransfer = false;
      this.isShowSwiftInstantExchange = true;
      // reset form
      this.swftExchange = {
        quantity: '',
        expected: '',
        payment_address: '',
      };
      this.two_auth_code = '';
      this.currAddr = list.addr;
    },
    getPaymentAddress(v) {
      this.$ajax(`${this.Utils.URL.goURL}organ/flash_price?amt=${v}&rec_symbol=${this.currencySwift}`, {}, "get")
        .then((res) => {
          this.swftExchange.expected = res.to_amount;
          // conver array destination_addrs to object
          let obj = Object.entries(res.destination_addrs).map(([key, value]) => ({ label: value, value: value }))

          this.paymentAddressList = obj;
          this.isDisabled = true
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    submitSwiftInstantExchangeFn() {
      if (!this.swftExchange.quantity) {
        this.$message.error('請輸入閃兌數量');
        return;
      }
      if (!this.swftExchange.expected) {
        this.$message.error('請輸入預計獲得');
        return;
      }
      if (!this.swftExchange.payment_address) {
        this.$message.error('請選擇收款地址');
        return;
      }
      if (!this.two_auth_code) {
        this.$message.error('請輸入谷歌驗證碼');
        return;
      }
      let req = {
        addr:  this.currAddr, // address from table
        deposit_coin_amt: this.swftExchange.quantity,
        receive_coin_amt: this.swftExchange.expected,
        destination_addr: this.swftExchange.payment_address,
        two_auth_code: this.two_auth_code,
        rec_symbol: this.currencySwift,
      };
      this.$ajax(`${this.Utils.URL.goURL}organ/create_flash`, req, "post")
        .then((res) => {
          if (res.result) {
            this.resInit();
            this.isShowSwiftInstantExchange = false;
            this.$message.success('生成成功');
          } else {
            this.$message.error(res.msg);
          }

        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id) {
        req.addr = this.wallet_id;
      }

      this.$ajax(`${this.Utils.URL.goURL}organ/flash_addrs`, req, "get")
        .then((res) => {
          this.resData = res.list ? res.list : []
          this.total = parseFloat(res.total);

          this.resData.forEach((list) => {
            list.created_at = this.dataFormat(list.created_at)
          })
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};  
</script>

<style scoped lang="scss">

.flex-2-column {
  display: flex;

  p {
    flex-basis: 50%;
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.mw405 {
  min-width: 405px;
}

.mw192 {
  min-width: 192px;
}

.com_from_item_group {
  display: flex;
  place-items: center;
  flex-wrap: wrap;
}

.com_from_item_group .note {
  color: #409eff;
  margin-top: 5px;
  flex-basis: 100%;
}

.com_from_item {
  &.flex-start {
    align-items: flex-start;

    .com_from_left {
      padding-top: 10px;
    }
  }
}
.flex {
  display: flex;
  .el-input {
    min-width: 192px;
  }
  .el-button {
    margin-left: 10px;
  }
}
.sort_table {
  margin-top: 20px;
  :deep(.el-table) {
    border-color: black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    td {
      border-color: black;
      color:black;
      border-bottom: 1px solid black ;
    }
    th {
      color: black;
      &.is-leaf {
        border-color: black;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
      }
    }
    .cell {
      text-align: center;
    }
    .sort-caret {
      &.ascending {
        border-bottom-color: black;
      }
      &.descending {
        border-top-color: black;
      }
    }
    .ascending {
      .sort-caret {
        &.ascending {
          border-bottom-color: #409eff;
        }
      }
    }
    .descending {
      .sort-caret {
        &.descending {
          border-top-color: #409eff;
        }
      }
    }
  }
}
</style>