<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box" v-if="isEditButton">
      <div class="com_item_search">
        <div class="com_item_tx">是否显示APPID账号</div>
        <div class="com_item_input">
          <el-select
            v-model="selectVal2"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option v-for="item in option1"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="subfin">提交</el-button>
      </div>
    </div>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">账号</div>
        <div class="com_item_input">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box" v-if="isEditButton">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="showAlertFn('', 'add')">添加账号</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>账号</th>
        <th>密码</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ page == 1 ? index + 1 : index == 0 ? ((page - 1) * 10) + 1 : ((page - 1) * 10) + index + 1 }}</td>
          <td>{{ list.account_number }}</td>
          <td>{{ list.pass }}</td>
          <td class="tab_btn">
            <span class="btn" v-if="isEditButton" @click="showAlertFn(index, 'edit')">编辑</span>
            <span class="btn" v-if="isDelButton" @click="delFn(index)">移除</span>
          </td>
        </tr>

        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
        layout="pager"
        :total="total"
        :current-page="page"
        @current-change="pageFn"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" :title="'appID账号'" :width="600">
      <div class="com_search_box">
        <div class="com_item_search">
          <div class="com_item_tx">账号</div>
          <div class="com_item_input mr_10" style="width: 460px">
            <el-input
              v-model="account"
              placeholder="请输入"
              type="text"
              size="small">
            </el-input>
          </div>
        </div>
        <div class="com_item_search">
          <div class="com_item_tx">密码</div>
          <div class="com_item_input" style="width: 460px">
            <el-input
              v-model="password"
              placeholder="请输入"
              type="text"
              size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary" @click="submitFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "appidList",
  data() {
    return {
      options2: [],
      resData: [{}],
      selectArr: [],
      selectVal1: '',
      inputVal1: '',
      isAlert: false,
      account: '',
      password: '',
      curIndex: 0,
      curType: '',
      page: 1,
      size: 10,
      total: '',
      selectVal2: '',
      option1: [{value: '1', label: '是'}, {value: '0', label: '否'}],
    }
  },
  created() {
    this.appIDstatus()
    this.resInit()

  },
  methods: {
    pageFn(index) {

      this.page = index
      this.resInit()
    },
    appIDstatus() {
      let req = {}
      this.$ajax(`${this.Utils.URL.goURL}get_apple_open`, req, 'get')
        .then(res => {
          this.selectVal2 = res.is_open_apple + ''
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    subfin() {
      if (!this.selectVal2) {
        return false
      }
      let req = {
        is_open: this.selectVal2
      }
      this.$ajax(`${this.Utils.URL.goURL}update_apple_open`, req, 'post')
        .then(res => {
          this.$message.success('操作成功')
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.curIndex = index
      if (type == 'edit') {
        this.account = this.resData[index].account_number
        this.password = this.resData[index].pass
      }
    },
    delFn(index) {
      this.curIndex = index
      this.$confirm('是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}delete_apple`, {
          id: this.resData[index].id
        }, 'post')
          .then(res => {
            this.resData.splice(index, 1)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      }).catch(() => {

      });
    },
    submitFn() {
      if (!this.account) {
        return false
      }
      if (!this.password) {
        return false
      }
      let req = {
        user_name: this.account,
        pass: this.password,
      }
      let url
      if (this.curType == 'add') {
        url = 'created_apple_id'
      } else {
        url = 'update_apple'
        req.id = this.resData[this.curIndex].id
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
        .then(res => {
          this.account = ''
          this.password = ''
          this.isAlert = false
          this.resInit()
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.coin = this.selectVal1
      }
      if (this.inputVal1) {
        req.account_number = this.inputVal1
      }
      this.$ajax(`${this.Utils.URL.goURL}apple_list`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped>

</style>