<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">平台</div>
        <div class="com_item_input">
          <el-select
            v-model="selectVal2"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in options2"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">更新</div>
        <div class="com_item_input">
          <el-select
            v-model="selectVal1"
            clearable
            filterable
            placeholder="请选择"
            size="small">
            <el-option v-for="item in options1"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
            v-model="startTime"
            type="datetime"
            size="small"
            format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
            v-model="endTime"
            type="datetime"
            size="small"
            format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>更新类型</th>
        <th>平台</th>
        <th>渠道</th>
        <th>版本号</th>
        <th>版本值</th>
        <th>是否强更</th>
        <th>更新提示</th>
        <th>下载地址</th>
        <th>APP包下载地址</th>
        <th>更新时间</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ page == 1 ? index + 1 : index == 0 ? ((page - 1) * 10) + 1 : ((page - 1) * 10) + index + 1 }}</td>
          <td>{{ list.release_type == 1 ? '正式' : '灰度' }}</td>
          <td>{{ list.op_system }}</td>
          <td>{{ list.channel_name }}</td>
          <td>{{ list.version_str }}</td>
          <td>{{ list.sub_version_str }}</td>
          <td>{{ list.must_upgrade != 2 ? '否' : '是' }}</td>
          <td>
            <p v-if="!list.version_tip_list.length">{{ list.release_log }}</p>
            <p v-if="list.version_tip_list.length">{{ list.version_tip_list[0].value }}</p>
          </td>
          <td>{{ list.download_url }}</td>
          <td>{{ list.h5_download_url }}</td>
          <td>{{ dataFormat(list.created_at) }}</td>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
        layout="pager"
        :total="total"
        :current-page="page"
        @current-change="pageFn"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "versionList",
  data() {
    return {
      selectVal1: '',
      selectVal2: '',
      options1: [{value: '0', label: '不需要'}, {value: '1', label: '否'}, {value: '2', label: '强制'}],
      options2: [{value: '1', label: 'Android'}, {value: '2', label: 'iOS'}],
      startTime: '',
      endTime: '',
      page: 1,
      size: 10,
      total: '',
      resData: {}
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1) {
        req.must_upgrade = this.selectVal1
      }
      if (this.selectVal2) {
        req.op_system_id = this.selectVal2
      }
      if (this.startTime) {
        req.begin_time = this.startTime
      }
      if (this.endTime) {
        req.end_time = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}version_list`, req, 'get')
        .then(res => {
          this.resData = res.list ? res.list : []
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    }
  }
}
</script>

<style scoped>

</style>