<template>
<article class="">
    <h2 class="com_h2_title">{{wallet_id || $route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <!-- <div class="com_item_tx">轉出錢包ID</div> -->
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="轉出錢包ID" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_input">
          <el-input v-model="inputVal2" placeholder="收款錢包ID" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>
    <div class="com_search_box mt_40" >
      <div class="com_item_search">
        <el-button type="primary" v-if="isGenerateButton" size="small" @click="showAlertFn('', 'add')">创建Fin-Chain节点</el-button>
      </div>
    </div>
    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>轉出錢包ID</th>
        <th>轉帳類別</th>
        <th>數量</th>
        <th>收款錢包ID</th>
        <th>時間</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>
            {{list.from_user_id}}
          </td>
          <td>
            {{list.reward_detail == 1 ? '系统发放' : list.reward_detail == 2 ? '购买节点' : list.reward_detail == 3 ? '转账' : '退款'}}
          </td>
          <td>
            <span v-show="list.reward_type == 1" style="color: green">+{{list.amount}}</span>
            <span v-show="list.reward_type == 2" style="color: red">-{{list.amount}}</span>
          </td>
          <td>
            {{list.to_user_id}}
          </td>
          <td>
            {{dataFormat(list.created_at)}}
          </td>
        </tr>
        <tr v-show="resData.length">
          <td>小计</td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(subtotal.amount)}}</td>
          <td></td>
          <td></td>
        </tr>
        <tr v-show="resData.length">
          <td>总计</td>
          <td></td>
          <td></td>
          <td> {{scientificNotationToString(sum.amount)}}</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>
  </article>
</template>

<script>
export default {
  name: "cash_coupon_transfer_record",
  data() {
    return {

      totalNode: 0,
      page: 1,
      size: 10,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "from_user_id",
        "reward_detail",
        "amount",
        "reward_detail",
        "to_user_id",
        "time",
      ],
      isAlert: false,
      curIndex: 0,
      curType: '',
      option1: [{label: '全额创建', value: 1},{label: '10%认购费用创建', value: 2}],
      title: '',
      two_auth_code : '',
      area: '',
      introduction: '',
      type: '',
      typeObj: {
        1: {label: '确认中', value: '1'},
        2: {label: '认购中', value: '2'},
        3: {label: '交易失败-无需退款', value: '3'},
        4: {label: '失败-认购数量超出需退款', value: '4'},
        5: {label: '已完成', value: '5'},
        6: {label: '失败-认购不足需退款', value: '6'},
      },
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.wallet_id = this.$route.query.wallet_id
      this.resInit();
    } else {
      this.resInit();
    }
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
    title (val) {
      this.title = val.replace(/[^\a-\z\A-\Z\s]/g,'')
    },
  },
  methods: {
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.title = ''
      this.two_auth_code = ''
      this.introduction = ''
      this.area = ''
      this.type = ''
      if (type == 'edit') {
        this.curIndex = index
        this.title = this.resData[index].title
        this.introduction = this.resData[index].introduction
        this.type = this.resData[index].type
      }
    },
    submitFn() {
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      if (this.title === '') {
        this.$message.error('请输入Fin-Chain节点名称')
        return false
      }

      let req = {
        two_auth_code: this.two_auth_code,
        title: this.title,
        area: this.area,
        introduction: this.introduction,
      }
      let url
      if (this.curType == 'edit') {
        url = 'super/edit'
        req.id = this.resData[this.curIndex].node_id
      } else {
        url = 'super/add'
        if (this.type === '') {
          return false
        }
        req.type = this.type
      }

      /*this.$confirm('是否新增/编辑机构用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {*/
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
          .then(res => {
            this.two_auth_code = ''
            this.introduction = ''
            this.area = ''
            this.title = ''
            this.type = ''
            this.isAlert = false
            this.resInit()
            this.$message.success('提交成功')
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      /*}).catch(() => {

      });*/

    },
    statusTx (type) {
      if (type == 1) {
        return '确认中'
      }
      if (type == 2) {
        return '认购中'
      }
      if (type == 3) {
        return '交易失败-无需退款'
      }
      if (type == 4) {
        return '失败-认购数量超出需退款'
      }
      if (type == 5) {
        return '已完成'
      }
      if (type == 6) {
        return '失败-认购不足需退款'
      }
      return type
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
        from_user_id: '',
        to_user_id: '',
        wallet_id: this.wallet_id || ''
      };
      if (this.inputVal1) {
        req.from_user_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.to_user_id = this.inputVal2;
      }
      this.$ajax(`${this.Utils.URL.goURL}super/super_node_reward_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
.com-table .com_tbody .tab_btn span {
  display: inline-block;
}
</style>