<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>


    <section class="com_from_box mt_40">
      <div class="com_from_item">
        <div class="com_from_left">
          借款链类型
        </div>
        <div class="com_from_right">
          <el-select
              v-model="chainVal"
              clearable
              filterable
              placeholder="请选择"
              size="small"
              @change="coinListFN"
          >
            <el-option label="bsc" value="bsc">bsc</el-option>
            <el-option label="tron" value="tron">tron</el-option>
            <el-option label="eth" value="eth">eth</el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          币种
        </div>
        <div class="com_from_right" style="width: 550px">
          <el-select
              v-model="coinVal"
              clearable
              filterable
              placeholder="请选择"
              @change="resInit"
              size="small">
            <el-option v-for="item in options2"
                       :key="item.id"
                       :label="item.symbol"
                       :value="item.id">
            </el-option>

          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          自动借款
        </div>
        <div class="com_from_right">
          <el-radio-group v-model="resData.auto_borrow" class="ml-4">
            <el-radio :label="true" size="large">开启</el-radio>
            <el-radio :label="false" size="large">关闭</el-radio>
          </el-radio-group>
        </div>
      </div>

      <!--      <div class="com_from_item">
              <div class="com_from_left">
                机构名称
              </div>
              <div class="com_from_right">
                xxx
              </div>
            </div>

            <div class="com_from_item">
              <div class="com_from_left">
                机构账号
              </div>
              <div class="com_from_right">
                xxx
              </div>
            </div>-->



      <div class="com_from_item">
        <div class="com_from_left">
          借款单保有量
        </div>
        <div class="com_from_right d_flex_1">
          <el-input
              v-model="resData.reserve_num"
              placeholder="请输入"
              size="small">
          </el-input>
          <span class="ml_5">单</span>

        </div>
        <span class="ml_5 color_T5">(该机构存在的借款订单数量会≥该设置值)</span>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          借款金额范围
        </div>
        <div class="com_from_right d_flex_1">
          <el-input
              v-model="resData.borrow_min"
              placeholder="请输入最小"
              size="small">
          </el-input>
          -
          <el-input
              v-model="resData.borrow_max"
              placeholder="请输入最大"
              size="small">
          </el-input>

        </div>
        <span class="ml_5 color_T5">(输入值为{{ resData.base_amount }}整数倍)</span>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          投资周期
        </div>
        <div class="com_from_right">
          <el-select
              v-model="dayVal"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option label="7天" value="7">7天</el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          自动投资进度
        </div>
        <div class="com_from_right">
          <el-select
              v-model="auto_interest_rate"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option label="不自动投资" value="-1">不自动投资</el-option>
            <el-option label=">=0%" value="0">>=0%</el-option>
            <el-option label=">=5%" value="0.05">>=5%</el-option>
            <el-option label=">=10%" value="0.1">>=10%</el-option>
            <el-option label=">=20%" value="0.2">>=20%</el-option>
            <el-option label=">=30%" value="0.3">>=30%</el-option>
            <el-option label=">=40%" value="0.4">>=40%</el-option>
            <el-option label=">=50%" value="0.5">>=50%</el-option>
            <el-option label=">=60%" value="0.6">>=60%</el-option>
            <el-option label=">=70%" value="0.7">>=70%</el-option>
            <el-option label=">=80%" value="0.8">>=80%</el-option>
            <el-option label=">=90%" value="0.9">>=90%</el-option>
          </el-select>
        </div>
        <span class="ml_5 color_T5">(当借款单的筹款进度达到该设置值时，系统投资地址会在1 ~5 分钟内自动投满本借款单)</span>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          重复借款订单数
        </div>
        <div class="com_from_right">
          {{resData.reserve_num - (((resData.borrow_max - resData.borrow_min) + 100) / 100) > 0 ? padRight(resData.reserve_num - (((resData.borrow_max - resData.borrow_min) + 100) / 100), 0)  : 0}}
        </div>

      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          投资超时取消借贷时间
        </div>
        <div class="com_from_right d_flex_1">
          <el-input
              v-model="resData.timeout_min"
              placeholder="请输入最小"
              size="small">
          </el-input>
          -
          <el-input
              v-model="resData.timeout_max"
              placeholder="请输入最大"
              size="small">
          </el-input>

        </div>
        <span class="ml_5 ">分钟</span>
        <span class="ml_5 color_T5">(订单创建后如果在设置的时间范围内无任何投资时,自动取消借贷并重新发起借贷.)</span>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          投资超时自动认购时间
        </div>
        <div class="com_from_right d_flex_1">
          <el-input
              v-model="resData.auto_invest_min"
              placeholder="请输入最小"
              size="small">
          </el-input>
          -
          <el-input
              v-model="resData.auto_invest_max"
              placeholder="请输入最大"
              size="small">
          </el-input>

        </div>
        <span class="ml_5 ">分钟</span>
        <span class="ml_5 color_T5">(借款单在设置时间范围内没筹满款时,由系统账号自动进行认购)</span>
      </div>




      <div class="com_from_item">
        <div class="com_from_left">
          借款地址
        </div>
        <div class="com_from_right">
          {{resData.borrow_addr}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          预计总矿工费
        </div>
        <div class="com_from_right">
          {{ (resData.reserve_num - resData.exist_multi_amt) > 0 ? (resData.reserve_num - resData.exist_multi_amt) * resData.borrow_fee : 0}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          借款地址余额
        </div>
        <div class="com_from_right">
          {{resData.balance}} {{chainVal == 'bsc' ? 'BNB' : chainVal == 'eth' ? 'ETH' :'TRX'}}
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          借款地址剩余虚拟额度
        </div>
        <div class="com_from_right">
          {{resData.virtual_balance}} {{ coinCode }}
        </div>
      </div>



      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>


      <div class="com_from_item" >
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "institutionLoanSetting",
  data() {
    return {
      two_auth_code: '',
      numVal: '',
      chainVal: 'bsc',
      auto_interest_rate: '',
      dayVal: '7',
      resData: {},
      options2: [],
      coinCode: '',
      coinVal: ''
    }
  },
  created() {
    this.coinListFN()
  },
  methods: {
    coinListFN() {
      let req = {
        chain_type: this.chainVal,
        is_white: true,
        size: 10000,
      }
      this.$ajax(`${this.Utils.URL.goURL}coin/list`, req, 'get')
          .then(res => {
            this.options2 = res ? res.list : []
            this.coinVal = ''
            this.resData = {}
            window.sessionStorage.setItem('coinList', JSON.stringify(this.options2))
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },

    resInit (index, val) {
      this.options2.forEach(val => {
        if (val.id == this.coinVal) {
          this.coinCode = val.symbol
        }
      })
      this.$ajax(`${this.Utils.URL.goURL}organ/auto_borrow`, {
        chain_type: this.chainVal,
        coin_id: this.coinVal
      }, 'get')
          .then(res => {
            this.resData = res ? res : {}
            this.auto_interest_rate = res.auto_interest_rate >= 0 ? res.auto_interest_rate : '-1'
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn() {

      let borrow_max = parseFloat(this.resData.borrow_max )
      let borrow_min = parseFloat(this.resData.borrow_min )
      let time_max = parseFloat(this.resData.timeout_max )
      let time_min = parseFloat(this.resData.timeout_min )
      let auto_invest_min = parseFloat(this.resData.auto_invest_min )
      let auto_invest_max = parseFloat(this.resData.auto_invest_max )
      let base_amount = parseFloat(this.resData.base_amount )
      let fee = (this.resData.reserve_num - this.resData.exist_multi_amt) > 0 ? (this.resData.reserve_num - this.resData.exist_multi_amt) * this.resData.borrow_fee : 0
      if (this.resData.auto_borrow) {
        if (this.resData.reserve_num === '' ) {
          this.$message.error('请输入借款单保有量')
          return false
        }
        if (this.resData.auto_borrow === '' ) {
          this.$message.error('请选择是否自动借款')
          return false
        }
        if (this.auto_interest_rate === '' ) {
          this.$message.error('请选择自动投资进度')
          return false
        }
        if (this.dayVal === '' ) {
          this.$message.error('请选择投资周期')
          return false
        }
        if (parseFloat(fee) > parseFloat(this.resData.balance)) {
          this.$message.error('钱包余额不足支付矿工费')
          return false
        }
        if ((borrow_min > borrow_max) || borrow_min === '' || borrow_max === '') {
          this.$message.error('请输入正常的借款范围')
          return false
        }
        if ((time_min > time_max)) {
          this.$message.error('请输入正常的借贷超时时间')
          return false
        }
        if ((auto_invest_min > auto_invest_max)) {
          this.$message.error('请输入正常的投资超时自动认购时间')
          return false
        }

        if (new window.windowBigNumber(borrow_min).mod(base_amount).toString() != 0) {
          this.$message.error(`最小范围不是${base_amount}的倍数`)
          return false
        }
        if (new window.windowBigNumber(borrow_max).mod(base_amount).toString() != 0) {
          this.$message.error(`最大范围不是${base_amount}的倍数`)
          return false
        }
      }
      if (this.coinVal === '') {
        this.$message.error('请选择币种')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      let req = {
        borrow_max: this.resData.borrow_max,
        borrow_min: this.resData.borrow_min,
        auto_day: this.dayVal,
        auto_interest_rate: this.auto_interest_rate,
        auto_borrow: this.resData.auto_borrow,
        reserve_num: this.resData.reserve_num,
        timeout_max: this.resData.timeout_max,
        timeout_min: this.resData.timeout_min,
        auto_invest_max: this.resData.auto_invest_max,
        auto_invest_min: this.resData.auto_invest_min,
        chain_type: this.chainVal,
        two_auth_code: this.two_auth_code,
        coin_id: this.coinVal,
      }


      this.$confirm('是否提交自动借款配置', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$ajax(`${this.Utils.URL.goURL}organ/edit_auto_borrow`, req, 'post', )
            .then(res => {

              this.resInit()
              this.$message.success('成功')
            })
            .catch(res => {
              this.$message.error(res.msg)
            })

      }).catch(() => {

      });


    },
  }
}
</script>

<style scoped>

</style>
