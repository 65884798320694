<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>


    <section class="com_from_box mt_40">

      <div class="com_from_item">
        <div class="com_from_left">
          转账方式
        </div>
        <div class="com_from_right">
          <el-select
              v-model="typeVal"
              clearable
              filterable
              placeholder="请选择"
              size="small"
              @change="arrFn"
          >
            <el-option

                label="Swft转账"
                value="borrow"
            >
              Swft转账
            </el-option>

          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          已选择收款地址
        </div>
        <div class="com_from_right">
          <p v-for="list in checkArr">{{list}}</p>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          转账币种
        </div>
        <div class="com_from_right">
          <el-select
              v-model="coinVal"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option

                label="USDT"
                value="USDT"
            >
              USDT
            </el-option>
            <el-option

                label="BNB"
                value="BNB"
            >
              BNB
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          转账金额
        </div>
        <div class="com_from_right">
          <el-input
              v-model="num"
              placeholder="请输入每个地址转入金额"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          转出地址
        </div>
        <div class="com_from_right">
          {{resData.addr}}
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          转出地址余额
        </div>
        <div class="com_from_right">
          {{resData.balance}} USDT
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          转账总额
        </div>
        <div class="com_from_right">
          {{num && checkArr.length ? coinVal == 'BNB' ? ((num * checkArr.length) / resData.instant_rate) : (num * checkArr.length)  : '-'}} USDT
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          预计转账完成时间
        </div>
        <div class="com_from_right">
          <el-select
              v-model="hours"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option label="1小时" value="1">1小时</el-option>
            <el-option label="4小时" value="4">4小时</el-option>
            <el-option label="8小时" value="8">8小时</el-option>
            <el-option label="12小时" value="12">12小时</el-option>
            <el-option label="24小时" value="24">24小时</el-option>
            <el-option label="48小时" value="48">48小时</el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>


      <div class="com_from_item" >
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "batchTransfer",
  data() {
    return {
      num: '',
      wallet_id: '',
      typeObj: {
        1: {label: '正常', value: '1'},
        2: {label: '禁止提币', value: '2'},
        3: {label: '禁止投资', value: '3'},
        4: {label: '禁止借款', value: '4'},
        5: {label: '禁止交易', value: '5'},
        6: {label: '禁止全部交易', value: '6'},
      },
      remark: '',
      statusVal: '',
      two_auth_code: '',
      numVal: '',
      numArr: [],
      addArr: [],
      checkArr: [],
      typeVal: 'borrow',
      hours: '24',
      coinVal: '',
      resData: {},
    }
  },
  created() {
    this.checkArr = window.sessionStorage.getItem('checkArr') ? JSON.parse(window.sessionStorage.getItem('checkArr') ): []
    this.resInit()
  },
  methods: {
    arrFn () {
      let url = 'trade/addrs'
      let req = {
        page: 1,
        size: 100
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'get')
          .then(res => {
            this.checkArr = []
            this.addArr = res.list ? res.list : [];
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    resInit () {
      this.$ajax(`${this.Utils.URL.goURL}trade/borrow_addr`, {}, 'get')
          .then(res => {
            this.resData = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn() {
      let num = parseFloat(this.num)
      let balance = parseFloat(this.resData.balance)
      let total = this.coinVal == 'BNB' ? ((num * this.checkArr.length) / this.resData.instant_rate) : (num * this.checkArr.length)

      if (!this.checkArr.length ) {
        this.$message.error('请在地址管理页面勾选地址')
        return false
      }
      if (!this.coinVal) {
        this.$message.error('请选择币种')
        return  false
      }
      if (total > balance) {
        this.$message.error('钱包余额不足')
        return false
      }

      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      let req = {
        addrs: this.checkArr,
        amount: this.num,
        coin_symbol: this.coinVal,
        in_hours: parseFloat(this.hours),
        two_auth_code: this.two_auth_code
      }


      this.$confirm('是否提交批量转账', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$ajax(`${this.Utils.URL.goURL}trade/new_transfer_plan`, req, 'post', 2500000, false, true)
            .then(res => {
              this.resInit()
              this.$message.success('交易已广播')
            })
            .catch(res => {
              this.$message.error(res.msg)
            })

      }).catch(() => {

      });


    },
  }
}
</script>

<style scoped>

</style>