<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">

      <div class="com_item_search">
        <div class="com_item_tx">时间</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, index) in 24"
                :key="item"
                :label="item + '小时'"
                :value="item"
            >
            </el-option>
          </el-select>

        </div>
      </div>


      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>


    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>支出类型</th>
        <th>预计支出数量</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{(list.type)}}</td>
          <td>
            <p>USDT-BEP20: {{ list.usdt_bep}}</p>
            <p>USDT-TRC20: {{ list.usdt_trc}}</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>



  </article>
</template>

<script>
export default {
  name: "makeSpendEstimate",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      inputVal3: "",
      selectVal1: 1,
      selectVal2: "",
      selectVal3: "",
      selectVal4: "",
      minNum: "",
      maxNum: "",
      coinInfo: [],
      tx_id: "",
      tHeader: [],
      subtotal: {},
      startTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()+ ' ' + '00:00:00',
      endTime: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + '23:59:59',
      sum: {},
      curType: '',
      curIndex: '',
      two_auth_code: '',
      isShow: false,
      typeObj: {
        'pool_amount': {label: '当前未提取的撮合池总额', value: 'pool_amount'},
        'ticket_profit': {label: '预计理财券收益', value: 'ticket_profit'},
        'partner_profit': {label: '合伙人激励预计收益', value: 'partner_profit'},
        'team_profit': {label: '预计团队收益', value: 'team_profit'},
        'invest_profit': {label: '预计投资单收益', value: 'invest_profit'},
        'fth_receive': {label: '预计撮合池转账至fth合约地址', value: 'fth_receive'},
        'back_invest': {label: '预计到期不复投的机构订单投资本金', value: 'back_invest'},
        'back_pledge': {label: '预计用户借币保证金', value: 'back_pledge'},
      },
      filterVal: [
        "wallet_id",
        "name",
        "card_id",
        "pic_link",
        "type",
        "created_at",
        "updated_at",
      ],
    };
  },
  created() {
    if (this.$route.query.wallet_id) {
      this.inputVal1 = this.$route.query.wallet_id
    }
    this.resInit();
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  methods: {

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.selectVal1) {
        req.after_hours = this.selectVal1

      }
      this.$ajax(`${this.Utils.URL.goURL}fin_estimate`, req, "get")
          .then((res) => {
            this.resData = res ? res : [];
            this.total = parseFloat(res.total);
            this.subtotal = res.subtotal ? res.subtotal : {}
            this.sum = res.sum ? res.sum : {}

            this.resData.forEach((list) => {
              //typeObj[list.tx_type] ? typeObj[list.tx_type].label :
              list.type = this.typeObj[list.type] ? this.typeObj[list.type].label: list.type

            })
          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>