<template>
  <article class="">
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">币种全称</div>
        <div class="com_item_input">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">币种简称</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal2"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">是否白名单</div>
        <div class="com_item_input">
          <el-select
            v-model="selectVal1"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option v-for="item in options1"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search" v-show="selectVal1">
        <div class="com_item_tx">币种分区</div>
        <div class="com_item_input">
          <el-select
            v-model="selectVal4"
            clearable
            filterable
            placeholder="请选择"
            size="small"
          >
            <el-option label="主区"
                       value="1">
            </el-option>

            <el-option label="创业区"
                       value="2">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">是否推荐</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal2"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option v-for="item in options1"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">合约地址</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal3"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box" v-if="isEditButton">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="goDetails">新增币种</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>币种全称</th>
        <th>币种简称</th>
        <th>链类型</th>
        <th>精度</th>
        <th>白名单</th>
        <th>币种分区</th>
        <th>推荐</th>
        <th>合约地址</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.token_name }}</td>
          <td>{{ list.symbol }}</td>
          <td>{{ list.chain_type }}</td>
          <td>{{ list.decimals }}</td>
          <td>{{ list.is_white ? '是' : '否' }}</td>
          <td>{{ list.zone == '1' ? '主区' :  list.zone == '2' ?'创业区' : '' }}</td>
          <td>{{ list.is_recommend ? '是' : '否' }}</td>
          <td>{{ list.contract_addr }}</td>
          <td class="tab_btn">
            <span class="btn" @click="goDetails(list)" v-if="isEditButton">编辑</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "coinList",
  data() {
    return {
      options1: [{value: true, label: '是'}, {value: false, label: '否'}],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      startTime: '',
      endTime: '',
      selectArr: [{value: '钱包', label: '钱包'}]
    }
  },
  created() {

    this.resInit()
  },
  methods: {
    goDetails(node) {
      let obj
      if (node) {
        obj = node
      } else {
        obj = {}
      }
      window.sessionStorage.setItem('listDataInfo', JSON.stringify(obj))
      this.goRouter('coinListEdit')
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1 !== '') {
        req.is_white = this.selectVal1
      }
      if (this.selectVal2 !== '') {
        req.is_recommend = this.selectVal2
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3
      }
      if (this.selectVal4) {
        req.zone = this.selectVal4
      }
      if (this.inputVal1) {
        req.name = this.inputVal1
      }
      if (this.inputVal2) {
        req.symbol = this.inputVal2
      }
      if (this.inputVal3) {
        req.contract_addr = this.inputVal3
      }
      if (this.startTime) {
        req.begin = this.dataFormat((new Date(this.startTime)).getTime() / 1000)
      }
      if (this.endTime) {
        req.end = this.dataFormat((new Date(this.endTime)).getTime() / 1000)
      }

      this.$ajax(`${this.Utils.URL.goURL}coin/list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },


  }

}
</script>

<style scoped>
</style>