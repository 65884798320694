<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item" >
        <div class="com_from_left">
          机构名称
        </div>
        <div class="com_from_right">
          {{ mechanism.name }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          机构账号
        </div>
        <div class="com_from_right">
          {{ mechanism.account }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          合同类型
        </div>
        <div class="com_from_right">
          机构借贷
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          借贷链类型
        </div>
        <div class="com_from_right">
          <el-select
              v-model="chain_type"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option v-for="item in option1"
                       :key="item"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          钱包地址
        </div>
        <div class="com_from_right">
          {{gasPriceData.addr}}
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          钱包余额
        </div>
        <div class="com_from_right">
          {{gasPriceData.bal}}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          借贷币种
        </div>
        <div class="com_from_right">
          <el-select
              v-model="coin_id"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option v-for="item in option2"
                       :key="item.coin_id"
                       :label="item.symbol"
                       :value="item.coin_id">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          借贷数量
        </div>
        <div class="com_from_right">
          <el-input
              v-model="amount"
              :placeholder="'请输入'+ curCoinObj.borrow_base +'的倍数'"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          保证金数量
        </div>
        <div class="com_from_right">
          <el-input
              v-model="pledge_amount"
              readonly="readonly"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          投资周期
        </div>
        <div class="com_from_right">
          <el-select
              class="mr_5"
              v-model="days"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option v-for="(item, index) in option4"
                       :key="index"
                       :label="item.days"
                       :value="index">
            </el-option>
          </el-select>
          <span>天</span>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          日利率
        </div>
        <div class="com_from_right">
          <el-input
              v-model="profit_rate"
              readonly="readonly"
              placeholder=""
              size="small">
          </el-input>

        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          借贷用途
        </div>
        <div class="com_from_right">
          <el-select
              v-model="swap_type"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option v-for="item in option5"
                       :key="item.swap_type"
                       :label="item.swap_name"
                       :value="item.swap_type">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">
          预计矿工费
        </div>
        <div class="com_from_right">
          {{gasPriceData.borrow_fee}}
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "createdInstitutionLoan",
  data() {
    return {
      order_type: 2,
      chain_type: '',
      coin_id: '',
      amount: '',
      pledge_amount: '',
      days: '',
      profit_rate: '',
      swap_type: '',
      duration: '',
      two_auth_code: '',
      curCoinObj: {borrow_base: ''},
      gasPriceData: {},
      option1: [],
      option2: [],
      option3: [],
      option4: [],
      option5: [],
      mechanism: {}
    }
  },
  created() {
    this.mechanism = window.localStorage.getItem('mechanism') ? JSON.parse(window.localStorage.getItem('mechanism')) : {}

    this.coinTypeFn()
  },
  methods: {
    investDaysListFn() {
      let req = {
        chain_type: this.chain_type,
        coin_id: this.coin_id,
        order_type: 2,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/invest_days_list`, req, 'get')
          .then(res => {
            this.days = ''
            this.option4 = res.days_list ? res.days_list : []
            this.option5 = res.swap_list ? res.swap_list : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    coinListFn() {
      let req = {
        opt_type: 1,
        chain_type: this.chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/chain_type_coin_list`, req, 'get')
          .then(res => {
            this.coin_id = ''
            this.option2 = res.list ? res.list : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    coinTypeFn() {
      let req = {}
      this.$ajax(`${this.Utils.URL.goURL}organ/admin_chain_type`, req, 'get')
          .then(res => {
            this.option1 = res.chain_type ? res.chain_type : []
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    gasPriceFn() {
      this.gasPriceData = {}
      let req = {
        chain_type: this.chain_type
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/fee_info`, req, 'get')
          .then(res => {
            this.gasPriceData = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    submitFn() {
      if (this.chain_type === '') {
        this.$message.error('请选择链类型')
        return false
      }
      if (this.coin_id === '') {
        this.$message.error('请选择币种')
        return false
      }
      if (parseFloat(this.amount) < parseFloat(this.curCoinObj.min_borrow_amount)) {
        this.$message.error('请输入最小的借贷数量' + this.curCoinObj.min_borrow_amount)
        return false
      }
      if (parseFloat(this.amount) > parseFloat(this.curCoinObj.max_borrow_amount)) {
        this.$message.error('请输入最大的借贷数量' + this.curCoinObj.max_borrow_amount)
        return false
      }
      /*if ((this.amount % this.curCoinObj.borrow_base) != 0) {
        this.$message.error('请输入正确的借贷数量')
        return false
      }*/
      if (this.days === '') {
        this.$message.error('请选择投资周期')
        return false
      }
      if (this.profit_rate === '') {
        this.$message.error('请输入日收益率')
        return false
      }
      if (this.swap_type === '') {
        this.$message.error('请选择借贷用途')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      if  (parseFloat(this.gasPriceData.borrow_fee) > parseFloat(this.gasPriceData.bal)) {
        this.$message.error('矿工费不足')
        return false
      }
      let url = 'organ/create_borrow_order_admin'
      let req = {
        order_type: this.order_type,
        chain_type: this.chain_type,
        coin_id: this.coin_id,
        amount: this.amount,
        pledge_amount: this.pledge_amount,
        days: this.option4[this.days].days,
        profit_rate: this.profit_rate,
        swap_type: this.swap_type,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否创建机构借贷', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.$message.success('创建成功')
              this.chain_type = ''
              this.coin_id = ''
              this.amount = ''
              this.pledge_amount = ''
              this.days = ''
              this.profit_rate = ''
              this.swap_type = ''
              this.two_auth_code = ''
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    }
  },
  watch: {
    days () {
      if (this.days !== '') {
        this.profit_rate = ''
        this.profit_rate = this.option4[this.days].rate
      }
    },
    coin_id () {
      this.swap_type = ''
      if (this.coin_id) {
        this.option2.forEach(val => {
          if (val.coin_id == this.coin_id) {
            this.curCoinObj = val
          }
        })
      }
      if (this.coin_id !== ''  && this.chain_type !== '') {
        this.investDaysListFn()
      }
    },
    amount () {
      if (this.amount !== '') {
        this.pledge_amount = this.amount / 2
      }
    },
    chain_type () {
      this.swap_type = ''
      if (this.chain_type) {

        this.gasPriceFn()
        this.coinListFn()
      }
      if (this.coin_id !== '' && this.chain_type !== '') {
        this.investDaysListFn()
      }
    }
  }
}
</script>

<style scoped>

</style>