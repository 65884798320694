<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item" >
        <div class="com_from_left">
          订单编号
        </div>
        <div class="com_from_right">
          {{ resData.order_id }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          用户钱包ID
        </div>
        <div class="com_from_right">
          {{ resData.wallet_id }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          币种
        </div>
        <div class="com_from_right">
          {{ resData.symbol }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          链类型
        </div>
        <div class="com_from_right">
          {{ resData.chain_type }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          购买数量
        </div>
        <div class="com_from_right">
          {{ resData.amount }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          单价
        </div>
        <div class="com_from_right">
          {{ resData.price }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          付款币种
        </div>
        <div class="com_from_right">
          {{ resData.currency }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          付款金额
        </div>
        <div class="com_from_right">
          {{ resData.total }}
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          支付方式
        </div>
        <div class="com_from_right">
          {{ resData.pay_way_tx }}
        </div>
      </div><div class="com_from_item" >
        <div class="com_from_left">
          买家姓名
        </div>
        <div class="com_from_right">
          {{ resData.buyer_bank_name}}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          账号/昵称
        </div>
        <div class="com_from_right">
          {{ resData.buyer_account }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          转账截图
        </div>
        <div class="com_from_right">
          <a v-if="resData.transfer_pic" :href="Utils.URL.imgURL + resData.transfer_pic" target="_blank"><img :src="Utils.URL.imgURL + resData.transfer_pic" width="100"/></a>
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          商户名称
        </div>
        <div class="com_from_right">
          {{ resData.sell_name }}
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          收款渠道
        </div>
        <div class="com_from_right">
          {{ resData.bank_name ? resData.bank_name : resData.pay_way_tx }}
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          支行信息
        </div>
        <div class="com_from_right">
          {{ resData.branch_name }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          收款姓名
        </div>
        <div class="com_from_right">
          {{ resData.sell_name }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          收款账号
        </div>
        <div class="com_from_right">
          {{ resData.sell_account }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          收款二维码
        </div>
        <div class="com_from_right">
          <a v-if="resData.qr_code" :href="Utils.URL.imgURL + resData.qr_code" target="_blank"><img :src="Utils.URL.imgURL + resData.qr_code" width="100"/></a>

        </div>
      </div>





      <div class="com_from_item" >
        <div class="com_from_left">
          下单时间
        </div>
        <div class="com_from_right">
          {{ resData.created_at }}
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          申诉时间
        </div>
        <div class="com_from_right">
          {{ resData.appeal_at }}
        </div>
      </div>


      <div class="com_from_item" >
        <div class="com_from_left">
          订单状态
        </div>
        <div class="com_from_right">
          {{ resData.status_tx }}
        </div>
      </div>


      <div class="com_from_item" >
        <div class="com_from_left">
          申诉方
        </div>
        <div class="com_from_right">
          {{ resData.appeal_status_tx}}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          申诉原因
        </div>
        <div class="com_from_right">
          {{ resData.appeal_status == 1 ? (resData.buy_evidence_data.appeal_type == 1 ? '已付款,未放币' : '') : resData.appeal_status == 2 ? (resData.sell_evidence_data.appeal_type == 1 ? '未收到买家的付款' : resData.sell_evidence_data.appeal_type == 2 ? '付款金额与订单金额不一致' : resData.sell_evidence_data.appeal_type == 3 ? '疑似使用不合法的资金' : '') : ''}}

        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          申诉理由
        </div>
        <div class="com_from_right">
          {{ resData.appeal_status == 1 ? (resData.buy_evidence_data.reason) : resData.appeal_status == 2 ? (resData.sell_evidence_data.reason) : ''}}
        </div>
      </div>

      <div class="com_from_item" >
        <div class="com_from_left">
          申诉图片
        </div>
        <div class="com_from_right">
          <p v-for="img in resData.pic_link">
            <a :href="Utils.URL.imgURL + img" target="_blank"><img :src="Utils.URL.imgURL + img" width="100"/></a>
          </p>
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          提交证据
        </div>
        <div class="com_from_right">
          {{ resData.evidence_tx}}

        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          证据理由
        </div>
        <div class="com_from_right">
          {{ resData.appeal_status == 2 ? (resData.buy_evidence_data.appeal_type == 1 ? '已付款,未放币' : '') : resData.appeal_status == 1 ? (resData.sell_evidence_data.appeal_type == 1 ? '未收到买家的付款' : resData.sell_evidence_data.appeal_type == 2 ? '付款金额与订单金额不一致' : resData.sell_evidence_data.appeal_type == 3 ? '疑似使用不合法的资金' : '') : ''}}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          证据详情
        </div>
        <div class="com_from_right">
          {{ resData.appeal_status == 2 ? (resData.buy_evidence_data.reason) : resData.appeal_status == 1 ? (resData.sell_evidence_data.reason ) : ''}}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          证据图片
        </div>
        <div class="com_from_right">
          <p v-for="imgs in resData.evidence_pic_link">
            <a :href="Utils.URL.imgURL + imgs" target="_blank"><img :src="Utils.URL.imgURL + imgs" width="100"/></a>
          </p>
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          买家姓名
        </div>
        <div class="com_from_right">
          {{ resData.buy_evidence_data.name }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          买家电话
        </div>
        <div class="com_from_right">
          +{{ resData.buy_evidence_data.area_code }}-{{ resData.buy_evidence_data.phone }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          卖家姓名
        </div>
        <div class="com_from_right">
          {{ resData.sell_evidence_data.name }}
        </div>
      </div>
      <div class="com_from_item" >
        <div class="com_from_left">
          卖家电话
        </div>
        <div class="com_from_right">
          +{{ resData.sell_evidence_data.area_code }}-{{ resData.sell_evidence_data.phone }}
        </div>
      </div>
      <div class="com_from_item" v-for="(lists, index) in resData.social_account_list">
        <div class="com_from_left">
          卖家社交工具{{index + 1}}
        </div>
        <div class="com_from_right">
          <p >{{lists.name}}: {{lists.account}}</p>
        </div>
      </div>


      <div class="com_from_item" v-if="isEditButton && resData.status == 6">
        <div class="com_from_left">
          审核状态
        </div>
        <div class="com_from_right">
          <el-select
              v-model="examine_status"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option value="1" label="买家胜诉"></el-option>
            <el-option value="2" label="卖家胜诉"></el-option>
          </el-select>
        </div>
      </div>

      <div class="com_from_item" v-if="isEditButton && resData.status == 6">
        <div class="com_from_left">审核备注</div>
        <div class="com_from_right">
          <el-input
              type="textarea"
              style="width: 400px"
              rows="10"
              v-model="tinyValCN"
              size="small"
          ></el-input>
        </div>
      </div>

      <div class="com_from_item" v-if="isEditButton && resData.status == 6">
        <div class="com_from_left">
          谷歌验证码
        </div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item" v-if="isEditButton && resData.status == 6">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "c2cAppealDetails",
  data() {
    return {
      resData: {
        sell_evidence_data: {},
        buy_evidence_data: {},
        pic_link: [],
        evidence_pic_link: [],
        social_account_list: [],
      },
      two_auth_code: '',
      tinyValCN: '',
      examine_status: '',

    }
  },
  created() {

    this.resData = window.sessionStorage.getItem('listDataInfo') ? JSON.parse(window.sessionStorage.getItem('listDataInfo') ) : {}

    if (this.resData.appeal_status == 1) {

      this.resData.pic_link = this.resData.buy_evidence_data.pic_link ? this.resData.buy_evidence_data.pic_link.split(',') : []
      this.resData.evidence_pic_link = this.resData.sell_evidence_data.pic_link ? this.resData.sell_evidence_data.pic_link.split(',') : []
    }
    if (this.resData.appeal_status == 2) {
      this.resData.pic_link = this.resData.sell_evidence_data.pic_link ? this.resData.sell_evidence_data.pic_link.split(',') : []
      this.resData.evidence_pic_link = this.resData.buy_evidence_data.pic_link ? this.resData.buy_evidence_data.pic_link.split(',') : []
    }

  },
  methods: {

    submitFn() {

      if (this.examine_status  === '') {
        this.$message.error('请选择审核状态')
        return false
      }
      if (this.tinyValCN === '') {
        this.$message.error('请输入审核备注')
        return false
      }
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }

      let url = 'buy/examine_submit'
      let req = {
        id: this.resData.order_id,
        examine_status: this.examine_status,
        examine_remark: this.tinyValCN,
        two_auth_code: this.two_auth_code,
      }
      this.$confirm('是否提交审核', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.chain_type = ''
              this.coin_id = ''
              this.amount = ''
              this.pledge_amount = ''
              this.days = ''
              this.profit_rate = ''
              this.swap_type = ''
              this.two_auth_code = ''
              this.$message.success("操作成功,两秒后自动跳转");
              setTimeout(() => {
                this.goBack();
              }, 1500);
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    }
  }
}
</script>

<style scoped>

</style>