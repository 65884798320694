<template>
  <article class="">
    <h2 class="com_h2_title">{{ $route.meta.title }}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">錢包ID</div>
        <div class="com_item_input">
          <el-input v-model="wallet_id" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">類別</div>
        <div class="com_item_input">
          <el-select v-model="category" clearable filterable placeholder="請選擇鏈類別" size="small">
            <el-option v-for="item in listCategory" :key="item.value" :label="item.text" :value="item.value">
            </el-option>

          </el-select>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>

      </div>

    </div>
    <div class="com_search_box">
      <el-button type="primary" @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)">
        导出excel（本页）
      </el-button>
    </div>
    <div>
      <table class="com-table  mt_40">
        <thead class="com_thead">
          <th>序號</th>
          <th>錢包ID</th>
          <th>類別</th>
          <th>數量</th>
          <th>時間</th>
          <th>備註</th>
        </thead>
        <tbody class="com_tbody">
          <tr v-for="(list, index) in resData" :key="list.id">
            <td>{{ (page - 1) * size + 1 + index }}</td>
            <td>{{ list.wallet_address }}</td>
            <td>{{ list.tx_type }}</td>
            <td>
              <span v-if="+list.amount < 0" style="color: red;">{{ scientificNotationToString(list.amount) }}</span>
              <span v-else>{{ scientificNotationToString(list.amount) }}</span>
            </td>
            <td>{{ list.created_at }}</td>
            <td>{{ list?.remark }}</td>
          </tr>
          <tr v-show="resData.length">
            <td>小计</td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(subtotal.amount) }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-show="resData.length">
            <td>总计</td>
            <td></td>
            <td></td>
            <td>{{ scientificNotationToString(sum.amount) }}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination @size-change="handleSizeChange" @current-change="pageFn" :current-page="page"
        :page-sizes="[10, 20, 50, 100, 1000]" :page-size="size" layout="total, prev, pager, next, sizes, jumper"
        :total="total">
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "ftcRunningTask",
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      resData: [],
      subtotal: {},
      sum: {},
      wallet_id: '',
      tHeader: [],
      filterVal: [
        'wallet_address',
        'tx_type',
        'amount',
        'created_at',
        'remark',
      ],
      category: '',
      listCategory: [
        {
          text: '捐赠',
          value: '捐赠'
        },
        {
          text: '铸造',
          value: '铸造'
        },
        {
          text: '奖励',
          value: '奖励'
        },
      ],
      startTime: '',
      endTime: '',
    };
  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "序號" || item.innerText == "狀態" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  created() {
    this.resInit();
  },
  methods: {
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size,
      };
      if (this.wallet_id) {
        req.wallet_address = this.wallet_id;
      }
      if (this.category) {
        req.chain_type = this.category;
      }
      if (this.startTime) {
        req.start = this.startTime;
      }
      if (this.endTime) {
        req.end = this.endTime;
      }
      this.$ajax(`${this.Utils.URL.goURL}invest/fsp_running_record`, req, "get")
        .then((res) => {
        if (res.list === null || res.list.length === 0) {
            this.$message.error("暂无数据");
            this.total = 0;
            this.resData = [];
            return;
          }
          this.resData = res.list ? res.list : []
          this.subtotal = res.subtotal ? res.subtotal : {}
          this.sum = res.sum ? res.sum : {}
          this.resData.forEach((list) => {

            list.created_at = this.dataFormat(list.created_at)
          })
          this.total = parseFloat(res.total) ? parseFloat(res.total) : 0;
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style scoped></style>