<template>
  <article class="">
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">币种名称</div>
        <div class="com_item_input">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>

      <div class="com_item_search">
        <div class="com_item_tx">链类型</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal3"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in chainTypeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>

        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">开始时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="startTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">结束时间</div>
        <div class="com_item_input">
          <el-date-picker
              v-model="endTime"
              type="datetime"
              size="small"
              format="YYYY-M-D H:mm:ss"
              value-format="YYYY-M-D H:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box" v-if="isEditButton">
      <div class="com_item_search">
        <el-button type="primary" size="small" @click="goDetails">新增币种</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>币种ID</th>
        <th>币种</th>
        <th>链类型</th>
        <th>借款开关</th>
        <th>最低借款额</th>
        <th>最高借款额</th>
        <th>本金赎回最低额</th>
        <th>提收益最低服务费</th>
        <th>提收益最小数量</th>
        <th>借款合约清算费</th>
        <th>最小投资金额</th>
        <th>修改时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td>{{ list.coin_data.id }}</td>
          <td>{{ list.coin_data.code }}</td>
          <td>{{ list.coin_data.type }}</td>
          <td>{{ list.is_open ? '开启' : '关闭' }}</td>
          <td>{{ list.min_borrow_amount }}</td>
          <td>{{ list.max_borrow_amount }}</td>
          <td>{{ list.min_redeem_amount }}</td>
          <td>{{ list.min_extract_fee }}</td>
          <td>{{ list.min_extract_amount }}</td>
          <td>{{ list.clear_fee }}</td>
          <td>{{ list.min_invest_amount }}</td>
          <td>{{ dataFormat(list.modify_at) }}</td>
          <td class="tab_btn">
            <span class="btn" @click="goDetails(list)" >编辑</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

  </article>
</template>

<script>
export default {
  name: "coinLoanList",
  data() {
    return {
      options1: [{value: true, label: '是'}, {value: false, label: '否'}],
      options2: [],
      options3: [],
      page: 1,
      size: 10,
      total: '',
      resData: [],
      curIndex: '',
      selectVal1: '',
      selectVal2: '',
      selectVal3: '',
      selectVal4: '',
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      startTime: '',
      endTime: '',
      selectArr: [{value: '钱包', label: '钱包'}]
    }
  },
  created() {

    this.resInit()
  },
  methods: {
    goDetails(node) {
      let obj
      if (node) {
        obj = node
      } else {
        obj = {}
      }
      window.sessionStorage.setItem('listDataInfo', JSON.stringify(obj))
      this.goRouter('coinLoanEditList')
    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.selectVal1 !== '') {
        req.is_white = this.selectVal1
      }
      if (this.selectVal2 !== '') {
        req.is_recommend = this.selectVal2
      }
      if (this.selectVal3) {
        req.chain_type = this.selectVal3
      }
      if (this.inputVal1) {
        req.symbol = this.inputVal1
      }
      if (this.inputVal2) {
        req.symbol = this.inputVal2
      }
      if (this.inputVal3) {
        req.contract_addr = this.inputVal3
      }
      if (this.startTime) {
        req.start = this.dataFormat((new Date(this.startTime)).getTime() / 1000)
      }
      if (this.endTime) {
        req.end = this.dataFormat((new Date(this.endTime)).getTime() / 1000)
      }

      this.$ajax(`${this.Utils.URL.goURL}organ/more_coin_deploy_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {

      this.page = index
      this.resInit()
    },


  }

}
</script>

<style scoped>

</style>