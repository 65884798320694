<template>
  <article class>
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">币种全称*</div>
        <div class="com_from_right">
          <el-input
              v-model="code"
              disabled="disabled"
              placeholder="请输入币种全称,提交后不能修改"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">多币种借款状态*</div>
        <div class="com_from_right">
          <el-select
              v-model="is_open"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option :value="true" label="开启"></el-option>
            <el-option :value="false" label="关闭"></el-option>

          </el-select>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">链类型*</div>
        <div class="com_from_right">
          <el-input
              v-model="chain_type"
              disabled="disabled"
              placeholder="请输入链类型,提交后不能修改"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">自动复投虚拟额度*</div>
        <div class="com_from_right">
          <div class="d_flex_1">
            <el-input
                v-model="accountAssest.virtual_amount"
                disabled="disabled"
                placeholder="请输入复投虚拟额度,提交后不能修改"
                size="small"
            ></el-input>
            <div class="ml_10" style="width: 200px">
              机构账号: {{accountAssest.account}}
            </div>
          </div>

        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">撮合池合约地址*</div>
        <div class="com_from_right">
          <div class="d_flex_1">
            {{accountAssest.multi_addr}}
          </div>

        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">最低借款额*</div>
        <div class="com_from_right">
          <el-input
              v-model="min_borrow_amount"
              placeholder="请输入最低借款额"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">最高借款额*</div>
        <div class="com_from_right">
          <el-input
              v-model="max_borrow_amount"
              placeholder="请输入最高借款额"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">本金赎回最低额*</div>
        <div class="com_from_right">
          <el-input
              v-model="min_redeem_amount"
              placeholder="请输入本金赎回最低额"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">提收益最低服务费*</div>
        <div class="com_from_right">
          <el-input
              v-model="min_extract_fee"
              placeholder="请输入提收益最低服务费"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">提收益最小数量*</div>
        <div class="com_from_right">
          <el-input
              v-model="min_extract_amount"
              placeholder="请输入提收益最小数量"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">借款合约清算费*</div>
        <div class="com_from_right">
          <el-input
              v-model="clear_fee"
              placeholder="请输入借款合约清算费"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">最小投资金额*</div>
        <div class="com_from_right">
          <el-input
              v-model="min_invest_amount"
              placeholder="请输入最小投资金额"
              size="small"
          ></el-input>
        </div>
      </div>
      <div class="com_from_item">
        <div class="com_from_left">谷歌验证码*</div>
        <div class="com_from_right">
          <el-input
              v-model="two_auth_code"
              placeholder="请输入"
              size="small"
          ></el-input>
        </div>
      </div>


      <div class="com_from_item">
        <div class="com_from_left"></div>
        <div class="com_from_right">
          <span class="btn" @click="sumbitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>
      </div>
    </section>
  </article>
</template>

<script>


export default {
  name: "coinLoanEditList",
  data() {
    return {
      code: '',
      chain_type: '',
      coin_id: '',
      clear_fee: '',
      max_borrow_amount: '',
      min_borrow_amount: '',
      min_extract_amount: '',
      min_extract_fee: '',
      min_invest_amount: '',
      min_redeem_amount: '',
      is_open: '',
      two_auth_code: '',
      infoData: {},
      accountAssest: {}
    };
  },
  created() {
    this.infoData = window.sessionStorage.getItem("listDataInfo")
        ? JSON.parse(window.sessionStorage.getItem("listDataInfo"))
        : {};
    if (this.infoData.coin_data) {
      this.code = this.infoData.coin_data.code
      this.chain_type = this.infoData.coin_data.type
      this.coin_id = this.infoData.coin_data.id
      this.clear_fee = this.infoData.clear_fee
      this.max_borrow_amount = this.infoData.max_borrow_amount
      this.min_borrow_amount = this.infoData.min_borrow_amount
      this.min_extract_amount = this.infoData.min_extract_amount
      this.min_extract_fee = this.infoData.min_extract_fee
      this.min_invest_amount = this.infoData.min_invest_amount
      this.min_redeem_amount = this.infoData.min_redeem_amount
      this.is_open = this.infoData.is_open
      this.initFn()
    }
  },
  methods: {
    initFn () {
      this.$ajax(`${this.Utils.URL.goURL}organ/virtually`, {
        coin_id: this.coin_id
      }, 'get')
          .then(res => {
            this.accountAssest = res ? res : {}
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
    },

    sumbitFn() {
      /*if (this.token === '' && this.chain_type != 'tl') {
              this.$message.error('请输入公链合约')
              return false
          }*/

      if (this.accountAssest.virtual_amount == "" || this.accountAssest.virtual_amount == 0) {
        this.$message.error("请先给机构账号配置此币种的虚拟额度，再设置币种信息");
        return false;
      }
      if (this.min_borrow_amount == "" || this.min_borrow_amount == 0) {
        this.$message.error("请输入最低借款额且不能为0");
        return false;
      }
      if (this.max_borrow_amount == "" || this.max_borrow_amount == 0) {
        this.$message.error("请输入最高借款额且不能为0");
        return false;
      }
      if (this.min_redeem_amount == "" || this.min_redeem_amount == 0) {
        this.$message.error("请输入本金赎回最低额且不能为0");
        return false;
      }
      if (this.min_extract_fee == "" || this.min_extract_fee == 0) {
        this.$message.error("请输入提收益最低服务费且不能为0");
        return false;
      }
      if (this.min_extract_amount == "" || this.min_extract_amount == 0) {
        this.$message.error("请输入提收益最小数量且不能为0");
        return false;
      }
      if (this.min_invest_amount == "" || this.min_invest_amount == 0) {
        this.$message.error("请输入最小投资金额且不能为0");
        return false;
      }
      if (this.clear_fee === "" || this.clear_fee == 0) {
        this.$message.error("请输入借款合约清算费且不能为0");
        return false;
      }
      if (this.is_open === '') {
        this.$message.error("请选择借款状态");
        return false;
      }
      if (this.is_open && !this.accountAssest.multi_addr) {
        this.$message.error("该币种没有撮合池合约地址,无法开启借款");
        return  false
      }
      if (this.two_auth_code === '') {
        this.$message.error("请输入谷歌验证码");
        return false;
      }


      let url = this.infoData.id ? "organ/edit_more_coin" : "organ/edit_more_coin";
      let req = {
        coin_id: this.coin_id,
        min_borrow_amount: this.min_borrow_amount,
        max_borrow_amount: this.max_borrow_amount,
        min_redeem_amount: this.min_redeem_amount,
        min_extract_fee: this.min_extract_fee,
        min_extract_amount: this.min_extract_amount,
        clear_fee: this.clear_fee,
        min_invest_amount: this.min_invest_amount,
        is_open: this.is_open,
        two_auth_code: this.two_auth_code,

      };

      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, "post")
          .then(res => {
            this.$message.success("操作成功,两秒后自动跳转");
            setTimeout(() => {
              this.goBack();
            }, 1500);
          })
          .catch(res => {
            this.$message.error(res.msg);
          });
    },

  }
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>