<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">机构名称</div>
        <div class="com_item_input">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">机构账号</div>
        <div class="com_item_input">
          <el-input
              v-model="inputVal2"
              placeholder="请输入"
              size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <el-button type="primary" icon="el-icon-search" size="small" @click="pageFn(1, 'search')">搜索</el-button>
      </div>
    </div>
    <div class="com_search_box" >
      <div class="com_item_search">
        <el-button type="primary" v-if="isGenerateButton" size="small" @click="showAlertFn('', 'add')">添加账号</el-button>
      </div>
    </div>
    <div class="tab_box">
      <table class="com-table">
        <thead class="com_thead">
        <th>序号</th>
        <th>机构名称</th>
        <th>机构账号</th>
        <th>钱包ID</th>
        <th>钱包地址</th>
        <th>链类型</th>
        <th>虚拟额度</th>
        <th>账号状态</th>
        <th>机构说明</th>
        <th>创建时间</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="index">
          <td :rowspan="list.row " v-show="list.isShow && list.isaddrRowShow">{{ (page - 1) * size + 1 + list.index }}</td>
          <td :rowspan="list.row "  v-show="list.isShow && list.isaddrRowShow">{{ list.name }}</td>
          <td :rowspan="list.row "  v-show="list.isShow && list.isaddrRowShow">{{ list.account }}</td>
          <td :rowspan="list.row " v-show="list.isShow && list.isaddrRowShow">{{ list.wallet_id }}</td>
          <td :rowspan="list.addrRow && list.isaddrRowShow ? list.addrRow : ''"  v-show=" list.addrRow ? list.isaddrRowShow : true">
            <span v-if="list.walletObj">{{list.walletObj.addr}}</span>
          </td>
          <td :rowspan="list.addrRow && list.isaddrRowShow ? list.addrRow : ''"  v-show=" list.addrRow ? list.isaddrRowShow : true">
            <span v-if="list.walletObj">{{list.walletObj.chain_type}}</span>
          </td>
          <td  v-show="list.amount">
            <span v-if="list.amount">{{list.amount.symbol}}: {{list.amount.amount}}</span>
          </td>

          <td :rowspan="list.row " v-show="list.isShow && list.isaddrRowShow">{{ list.status == 1 ? '正常' : '冻结' }}</td>
          <td :rowspan="list.row " v-show="list.isShow && list.isaddrRowShow">{{ list.introduce }}</td>
          <td :rowspan="list.row " v-show="list.isShow && list.isaddrRowShow">{{ dataFormat(list.created_at) }}</td>
          <td :rowspan="list.row " v-show="list.isShow && list.isaddrRowShow"  class="tab_btn">
            <span class="btn" v-if="isEditButton" @click="showAlertFn(index, 'edit')">编辑</span>
            <span class="btn" v-if="isExtractButton" @click="showAmountAlertFn(index)">虚拟额度管理</span>
            <span class="btn" @click="goRouter('/institutionAccountAssetDetails', {id: list.wallet_id})">额度变更详情</span>
          </td>
        </tr>

        </tbody>
      </table>
    </div>

    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog v-model="isAlert" :title="curType == 'edit' ? '编辑账号' : '新建账号'" :width="600">
      <div class="com_from_box">
        <div class="com_from_item" v-show="curType !== 'edit'">
          <div class="com_from_left">管理后台账号</div>
          <div class="com_from_right" >
            <el-select
                v-model="user_id"
                clearable
                filterable
                placeholder="请选择"
                size="small"
                @change="accountFn"
            >
              <el-option v-for="(item, index) in option1"
                         :key="item.user_id"
                         :label="item.nick"
                         :value="index">
              </el-option>
            </el-select>

          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">机构账号</div>
          <div class="com_from_right" >
            <el-input
              :value="account"
              placeholder="请输入"
              type="text"
              disabled="disabled"
              size="small">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">机构名称</div>
          <div class="com_from_right">
            <el-input
              v-model="name"
              placeholder="请输入"
              type="text"
              size="small">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">机构说明</div>
          <div class="com_from_right" style="width: 230px">
            <el-input
                v-model="info"
                placeholder="请输入"
                type="textarea"
                size="small">
            </el-input>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right" >
            <el-input
                v-model="two_auth_code"
                placeholder="请输入"
                type="text"
                size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary" @click="submitFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isAmountAlert" :title="'虚拟额度设置'" :width="600">
      <div class="com_from_box">
        <div class="com_from_item">
          <div class="com_from_left">机构账号</div>
          <div class="com_from_right" >
            {{ amountObj.account }}
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">机构名称</div>
          <div class="com_from_right">
            {{ amountObj.name }}
          </div>
        </div>

        <div class="com_from_item">
          <div class="com_from_left">钱包地址</div>
          <div class="com_from_right" >
            <el-select
                v-model="walletIndex"
                clearable
                filterable
                placeholder="请选择"
                size="small"
            >
              <el-option v-for="(item, index) in walletArr"
                         :key="item.addr"
                         :label="item.addr"
                         :value="index">
              </el-option>
            </el-select>

          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">链类型</div>
          <div class="com_from_right" >
            <span v-if="walletArr[walletIndex].chain_type">{{walletArr[walletIndex].chain_type}}</span>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">额度管理</div>
          <div class="com_from_right" >

          </div>
        </div>
        <div class="com_from_item" v-for="list in option2">
          <div class="com_from_left">{{ list.symbol }}</div>
          <div class="com_from_right" >
            <div class="d_flex">
              <el-input
                  style="width: 180px;"
                  v-model="list.value"
                  placeholder="请输入"
                  type="text"
                  size="small">
              </el-input>
              <p style="width: 200px;" v-show="list.value > 0">({{list.amount}} {{ (list.value < 0) ? '-' : '+' }} {{list.value}}) = {{list.amount - (-list.value)}}</p>
            </div>
            <p>当前总虚拟额度:{{list.amount}} {{ list.symbol }}</p>
          </div>
        </div>
        <div class="com_from_item">
          <div class="com_from_left">谷歌验证码</div>
          <div class="com_from_right" >
            <el-input
                v-model="two_auth_code"
                placeholder="请输入"
                type="text"
                size="small">
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAmountAlert = false">关闭</el-button>
                <el-button type="primary" @click="submitAmountFn">提交</el-button>
              </span>
      </template>
    </el-dialog>

  </article>
</template>

<script>
export default {
  name: "institutionalAccountList",
  data() {
    return {
      options2: [],
      resData: [{}],
      selectArr: [],
      selectVal1: '',
      inputVal1: '',
      inputVal2: '',
      isAlert: false,
      isAmountAlert: false,
      curIndex: 0,
      curType: '',
      page: 1,
      size: 10,
      total: '',
      selectVal2: '',
      option1: [],
      option2: [],
      user_id: '',
      account: '',
      name: '',
      info: '',
      two_auth_code: '',
      amountObj: {},
      walletArr: [],
      walletIndex: 0,
    }
  },
  created() {

    this.resInit()
    if (this.isGenerateButton) {
      this.adminListFn()
    }
  },
  methods: {
    accountFn (index) {
      this.account = this.option1[index].nick
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index
      this.resInit()
    },
    adminListFn() {
      let req = {}
      this.$ajax(`${this.Utils.URL.goURL}organ/mechanism_admin_list`, req, 'get')
        .then(res => {
          this.option1 = res.list ? res.list : []
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    },
    showAmountAlertFn (index) {
      this.amountObj = this.resData[index]
      this.walletArr = this.resData[index].wallet_list
      this.isAmountAlert = true
      this.amountCoinFn()

    },
    amountCoinFn () {
      if (this.walletArr[this.walletIndex].chain_type) {
      let req = {
        chain_type: this.walletArr[this.walletIndex].chain_type,
        wallet_id: this.amountObj.wallet_id,
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/virtual_amount_list`, req, 'get')
          .then(res => {
            let arr  = res.list ? res.list : []
            arr.forEach((val, index) =>{
              arr[index].value = 0
            })
            this.option2 = arr
          })
          .catch(res => {
            this.$message.error(res.msg)
          })
      }
    },
    showAlertFn(index, type) {
      this.isAlert = true
      this.curType = type
      this.user_id = ''
      this.two_auth_code = ''
      this.account = ''
      this.info = ''
      this.name = ''
      if (type == 'edit') {
        this.curIndex = index
        this.account = this.resData[index].account
        this.info = this.resData[index].introduce
        this.name = this.resData[index].name
      }
    },
    submitAmountFn () {
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      if (!this.option2.length) {
        this.$message.error('不存在虚拟额度')
        return false
      }
      let arr = []
      this.option2.forEach(val => {
        arr.push({
          coin_id: val.coin_id,
          amount: val.value + ''
        })
      })
      let req = {
        chain_type: this.walletArr[this.walletIndex].chain_type,
        wallet_id: this.amountObj.wallet_id,
        two_auth_code: this.two_auth_code,
        list: arr,
      }
      let url = 'organ/add_virtual_amount'

      this.$confirm('是否提交虚拟额度设置', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post',2500000, false, true)
            .then(res => {
              this.two_auth_code = ''
              this.isAmountAlert = false
              this.option2 = []
              this.resInit()
              this.$message.success('提交成功')
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      }).catch(() => {

      });
    },
    submitFn() {
      if (this.two_auth_code === '') {
        this.$message.error('请输入谷歌验证码')
        return false
      }
      if (this.account === '') {
        this.$message.error('请输入账号')
        return false
      }
      if (this.name === '') {
        this.$message.error('请输入昵称')
        return false
      }
      if (this.info === '') {
        this.$message.error('请输入简介')
        return false
      }
      let req = {
        two_auth_code: this.two_auth_code,
        account: this.account,
        name: this.name,
        introduce: this.info,
      }
      let url
      if (this.curType == 'edit') {
        url = 'organ/edit_mechanism_account'
        req.wallet_id = this.resData[this.curIndex].wallet_id
      } else {
        url = 'organ/create_mechanism_account'
        if (this.user_id === '') {
          return false
        }
        req.user_id = this.option1[this.user_id].user_id
      }

      /*this.$confirm('是否新增/编辑机构用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {*/
        this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
            .then(res => {
              this.user_id = ''
              this.two_auth_code = ''
              this.account = ''
              this.info = ''
              this.name = ''
              this.isAlert = false
              this.resInit()
              this.$message.success('提交成功')
            })
            .catch(res => {
              this.$message.error(res.msg)
            })
      /*}).catch(() => {

      });*/

    },
    resInit() {
      let req = {
        page: this.page,
        size: this.size
      }
      if (this.inputVal1) {
        req.name = this.inputVal1
      }
      if (this.inputVal2) {
        req.account = this.inputVal2
      }
      this.$ajax(`${this.Utils.URL.goURL}organ/mechanism_account_list`, req, 'get')
        .then(res => {
          let arr = res.list ? res.list : []
          let arrs = []
          let arrRow = []

          arr.forEach((val, index) => {
            let lenx = val.wallet_list.length
              val.wallet_list.forEach((coin,coinIndex) => {
                lenx = lenx + (coin.amount_list.length > 1 ? coin.amount_list.length - 1 : 0)
              })
            arrRow[index] = lenx
          })
          arr.forEach((val, index) => {
            let ist = true

            let arrx = {}

            if (val.wallet_list.length) {
              val.wallet_list.forEach((coin,coinIndex) => {

                let istAdd = true
                arrx = Object.assign({},val)
                arrx.walletObj = {}
                arrx.index = index
                arrx.row = arrRow[index]
                arrx.amount = {}
                if (ist) {

                  arrx.isShow = true
                  ist = false
                } else {
                  arrx.isShow = false

                }

                arrx.walletObj = coin
                if (coin.amount_list.length) {
                  coin.amount_list.forEach((amount, amountIndex) => {

                    arrx = Object.assign({},arrx)
                    if (istAdd) {
                      arrx.addrRow = coin.amount_list.length
                      arrx.isaddrRowShow = true
                      istAdd = false
                    } else {
                      arrx.isaddrRowShow = false
                      arrx.addrRow = coin.amount_list.length
                    }
                    arrx.amount = amount
                    arrs.push(arrx)
                  })
                } else {
                  arrx.addrRow = coin.amount_list.length
                  arrx.isaddrRowShow = true
                  arrs.push(arrx)
                }

              })
            } else{
              arrs.push(arrx)
            }
          })
          this.resData = arrs
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  },
  watch: {
    walletIndex () {
      this.amountCoinFn()
    }
  }
}
</script>

<style scoped>

</style>