<template>
    <article class="">
      <h2 class="com_h2_title">{{$route.meta.title}}</h2>
        <div class="com_search_box" >
            <div class="com_item_search">
                <div class="com_item_tx">币种</div>
                <div class="com_item_input">
                    <el-select
                            v-model="selectVal1"
                            clearable
                            filterable
                            placeholder="请选择"
                            size="small">
                        <el-option  v-for="item in selectArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                        </el-option>

                    </el-select>
                </div>
            </div>
            <div class="com_item_search">
                <div class="com_item_tx">钱包地址列表</div>
                <div class="com_item_input">
                    <el-select
                            v-model="selectVal2"
                            clearable
                            filterable
                            placeholder="请选择"
                            size="small">
                        <el-option  v-for="item in selectArr2"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                        </el-option>

                    </el-select>
                </div>
            </div>
            <div class="com_item_search">
                <el-button type="primary" icon="el-icon-search" size="small" @click="resInit()">搜索</el-button>
            </div>
        </div>
        <div class="tab_box">
            <table class="com-table">
                <thead class="com_thead">
                    <th>序号</th>
                    <th>热钱包地址</th>
                    <th>状态</th>
                    <th>操作</th>
                </thead>
                <tbody class="com_tbody">
                    <tr v-for="(list, index) in resData" :key="list.id">
                        <td>{{index + 1}}</td>
                        <td>{{list.addr}}</td>
                        <td>{{enabled(list.enabled)}}</td>
                        <td class="tab_btn">
                            <span v-if='list.enabled == 3 && isEditButton' class="btn" >--</span> 
                            <span v-else class="btn" @click="showAlertFn(index)">启用</span>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>

        <el-dialog v-model="isAlert" :title="'启用地址' + resData[curIndex].addr + '(' + selectVal1 + ')'" :width="750">
            <div class="com_search_box">
                <div class="com_item_search">
                    <div class="com_item_tx">公钥</div>
                    <div class="com_item_input mr_10" style="width: 550px">
                        <el-input
                                v-model="keyR.pub_key"
                                placeholder=""
                                :readonly="true"
                                rows="7"
                                type="textarea"
                                size="small">
                        </el-input>
                    </div>
                    <span class="color_T1 cur_po" @click="copyText(keyR.pub_key)">复制</span>
                </div>
                <div class="com_item_search">
                    <div class="com_item_tx">秘钥</div>
                    <div class="com_item_input" style="width: 550px">
                        <el-input
                                v-model="keyRs"
                                placeholder="请输入"
                                rows="7"
                                type="textarea"
                                size="small">
                        </el-input>
                    </div>
                </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="isAlert = false">关闭</el-button>
                <el-button type="primary" v-if="isEditButton" @click="submitFn">提交</el-button>
              </span>
            </template>
        </el-dialog>

    </article>
</template>

<script>
    export default {
        name: "keyList",
        data() {
            return {
                options2: [],
                resData: [{}],
                selectArr: [],
                selectArr2: [],
                multi_addr_list:{},
                selectVal1: '',
                selectVal2: '',
                isAlert: false,
                keyR: {},
                keyRs: '',
                curIndex: 0
            }
        },
        created () {
            this.resCoinFn()

        },
        watch:{
                selectVal1(newval){
                    this.selectArr2 =  this.multi_addr_list[newval]
                    this.selectVal2 = this.selectArr2[0].value
                }
        },
        methods: {
            enabled(num){
                const enabled_obj = {
                    1:'未启用',
                    2:'部分已启用',
                    3:'已全部启用',
                }
                return enabled_obj[num]
            },
            showAlertFn (index) {
                this.singFn()
                this.isAlert = true
                this.curIndex = index
            },
            submitFn () {
                let req = {
                    key: this.keyRs,
                    addr: this.resData[this.curIndex].addr,
                    coin: this.selectVal1,
                    id: this.keyR.id,
                    multi_addr:this.selectVal2
                }
                this.$ajax(`${this.Utils.URL.goURL}sign/upload_key`, req, 'post')
                    .then(res => {
                        this.resData[this.curIndex].enabled = true
                        this.isAlert = false
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },
            singFn () {
                let req = {
                }
                this.$ajax(`${this.Utils.URL.goURL}sign/public_key`, req, 'get')
                    .then(res => {
                        this.keyR = res ? res : {}
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },
            resCoinFn () {
                let req = {
                }
                this.$ajax(`${this.Utils.URL.goURL}sign/coin`, req, 'get')
                    .then(res => {
                        let arr = res.list ? res.list : []
                        arr.forEach(val => {
                            this.selectArr.push({
                                value: val.coin,
                                label: val.coin,
                            })
                                let multi_arr = []
                            if(Array.isArray(val.multi_addrs)){
                                val.multi_addrs.forEach(item=>{
                                    multi_arr.push({
                                        value:item,
                                        label:item
                                    })
                                })
                            }
                        this.multi_addr_list[val.coin] = multi_arr
                        })
                        this.selectVal1 = arr[0].coin
                        this.selectVal2 = arr[0].multi_addrs[0]
                        this.resInit()
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            },
            resInit () {
                let req = {
                }
                if (this.selectVal1) {
                    req.coin = this.selectVal1
                }
                if (this.selectVal2) {
                    req.multi_addr = this.selectVal2
                }
                this.$ajax(`${this.Utils.URL.goURL}sign/address`, req, 'get')
                    .then(res => {
                        this.resData = res.list ? res.list : []
                    })
                    .catch(res => {
                        this.$message.error(res.msg)
                    })
            }
        }
    }
</script>

<style scoped>

</style>