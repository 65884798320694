<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <div class="com_search_box">
      <div class="com_item_search">
        <div class="com_item_tx">团队长ID</div>
        <div class="com_item_input">
          <el-input v-model="inputVal1" placeholder="请输入" size="small">
          </el-input>
        </div>
      </div>
      <div class="com_item_search">
        <div class="com_item_tx">直属下级管理级别</div>
        <div class="com_item_input">
          <el-select
              v-model="selectVal1"
              clearable
              filterable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="(item, index) in levelArr"
                :key="index"
                :label="item"
                :value="index"
            >
            </el-option>
          </el-select>

        </div>
      </div>




      <div class="com_item_search">
        <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="pageFn(1, 'search')"
        >搜索</el-button
        >
      </div>
    </div>
    <div class="com_search_box">
      <el-button
          type="primary"
          @click="exportExcel(tHeader, filterVal, resData, $route.meta.title)"
      >
        导出excel（本页）
      </el-button>
    </div>



    <div>
      <table class="com-table mt_40">
        <thead class="com_thead">
        <th>序号</th>
        <th>直属下级用户ID</th>
        <th>直属下级管理级别</th>
        <th>操作</th>
        </thead>
        <tbody class="com_tbody">
        <tr v-for="(list, index) in resData" :key="list.id">
          <td>{{ (page - 1) * size + 1 + index }}</td>
          <td class="tab_btn">
            <span class="btn" @click="goRouter('teamInvitationRelationship', {id: list.user_id})">{{list.user_id}}</span>
          </td>
          <td>{{list.user_type_tx}}</td>
          <td class="tab_btn">
            <span class="btn"  @click="goRouter('/teamLevelRecord', {id: list.user_id})">团队管理级别变更记录</span>
            <span class="btn"  @click="goRouter('/teamLevelUser', {id: list.user_id})">管理层用户列表</span>

          </td>

        </tr>
        </tbody>
      </table>
    </div>
    <div class="com_page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageFn"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      >
      </el-pagination>
    </div>


  </article>
</template>

<script>
export default {
  name: "team-invitation-relationship",
  data() {
    return {
      page: 1,
      size: 100,
      total: '',
      resData: [],
      balanceRangeInfo: "",
      balanceRangeStatus: "",
      inputVal1: "",
      inputVal2: "",
      selectVal1: "",
      startTime: "",
      endTime: "",
      minNum: "",
      maxNum: "",
      coinInfo: "",
      tx_id: "",
      levelArr: ['普通用户', 'GM', 'VP', 'SVP', 'ED', 'MD', 'GP'],
      tHeader: [],
      subtotal: {},
      sum: {},
      filterVal: [
        "user_id",
        "user_type_tx",
      ],
      isAlert: false,
      isRemove: false,
      curIndex: 0,
      curType: '',
      option1: [{label: '全额创建', value: 1}],
      title: '',
      two_auth_code : '',
      wallet_id: '',
      type: '',
      typeObj: {
        1: {label: '俱乐部', value: '1'},
        2: {label: '工作室', value: '2'},
        3: {label: '普通用户', value: '3'},
        4: {label: '临时标签', value: '4'},
      },
      exportData: []
    };
  },
  created() {
    if (this.$route.query.id) {
      this.inputVal1 = this.$route.query.id
      this.resInit()
    }


  },
  mounted() {
    document.querySelector(".com_thead").childNodes.forEach((item) => {
      if (item.innerText == "操作" || item.innerText == "详情" || item.innerText == "序号") return;
      this.tHeader.push(item.innerText);
    });
  },
  watch: {
    $route(next,old) {
      if (next.path == old.path) {
        window.scrollTo(0, 0)
        if (this.inputVal1 != this.$route.query.id) {
          this.inputVal1 = this.$route.query.id
          this.resInit()
        }
      }

    }
  },
  methods: {

    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.resInit();
    },
    pageFn(index) {
      this.page = index;
      this.resInit();
    },
    resInit(export_type) {

      let req = {
        page: this.page,
        size: this.size,
      };
      if (export_type == 'all') {
        req.export_type = 'all'
      }
      if (this.inputVal1 == '') {
        this.$message.error('请输入团队长ID');
        return false
      }
      if (this.inputVal1) {
        req.user_id = this.inputVal1;
      }
      if (this.inputVal2) {
        req.wallet_id = this.inputVal2;
      }
      if (this.selectVal1) {
        req.level = this.selectVal1;
      }
      if (this.startTime) {
        req.start = this.startTime
      }
      if (this.endTime) {
        req.end = this.endTime
      }
      this.$ajax(`${this.Utils.URL.goURL}team_invite_relation_list`, req, "get")
          .then((res) => {


              this.resData = res.list ? res.list : [];
              this.total = parseFloat(res.total);
              this.subtotal = res.subtotal ? res.subtotal : {}
              this.sum = res.sum ? res.sum : {}
              this.resData.forEach((list) => {

              list.user_type_tx = this.levelArr[list.level] ? this.levelArr[list.level] : list.level
              //list.tx_type = this.typeObj[list.tx_type] ? this.typeObj[list.tx_type].label : list.tx_type
            })

          })
          .catch((res) => {
            this.$message.error(res.msg);
          });
    },
  },
};
</script>

<style scoped>
.textStyle {
  width: 300px;
  word-break: break-all;
}
</style>