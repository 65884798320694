<template>
  <article class="">
    <h2 class="com_h2_title">{{$route.meta.title}}</h2>
    <section class="com_from_box">
      <div class="com_from_item">
        <div class="com_from_left">
          终端
        </div>
        <div class="com_from_right">
          <el-input
            v-model="inputVal1"
            placeholder="请输入"
            size="small">
          </el-input>
        </div>
      </div>

      <div class="com_from_item">
        <div class="com_from_left">

        </div>
        <div class="com_from_right">
          <span class="btn" @click="submitFn">提交</span>
          <span class="btn" @click="goBack">返回</span>
        </div>

      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "terminalListEdit",
  data() {
    return {
      options2: [],
      selectVal2: '',
      selectVal1: '',
      inputVal2: '',
      inputVal1: '',
      startTime: '',
      endTime: '',
      selectArr: [{value: '钱包', label: '钱包'}],
      type: this.$route.query.type ? this.$route.query.type : '1'
    }
  },
  methods: {
    submitFn() {

      if (this.inputVal1 === '') {
        this.$message.error('请输入终端')
        return false
      }

      let url = 'created_port'
      let req = {
        port: this.inputVal1
      }
      this.$ajax(`${this.Utils.URL.goURL}${url}`, req, 'post')
        .then(res => {
          this.$message.success('操作成功,两秒后自动跳转')
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
        .catch(res => {
          this.$message.error(res.msg)
        })
    }
  }
}
</script>

<style scoped>

</style>